export const initialState = {

    ActiveBatchesList: {
        PaginatedActiveBatches: [],
        filters: {
            fromDate: "",
            toDate: "",
            branch: "",
            trainerName: "",
            batchTiming: "",
        },
        searchResultBatches: null,
        perPage: 10,
        totalBatches: null,
        totalPages: null,
        loading: false,
        startBatch: null,
        endBatch: null,
        search: "",
        currentPage: 1,
    },


    UpcomingBatchesList: {
        PaginatedUpcomingBatches: [],
        filters: {
            fromDate: "",
            toDate: "",
            branch: "",
            trainerName: "",
            batchTiming: "",
        },
        searchResultBatches: null,
        perPage: 10,
        totalBatches: null,
        totalPages: null,
        loading: false,
        startBatch: null,
        endBatch: null,
        search: "",
        currentPage: 1,
    },


    CompletedBatchesList: {
        PaginatedCompletedBatches: [],
        filters: {
            fromDate: "",
            toDate: "",
            branch: "",
            trainerName: "",
            batchTiming: "",
        },
        searchResultBatches: null,
        perPage: 10,
        totalBatches: null,
        totalPages: null,
        loading: false,
        startBatch: null,
        endBatch: null,
        search: "",
        currentPage: 1,
    },



}