import React from 'react'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { ERPApi } from '../../../../serviceLayer/interceptor';
import { useEffect } from 'react';
import BackButton from '../../../components/backbutton/BackButton';
import { toast } from 'react-toastify';
import GateKeeper from '../../../../rbac/GateKeeper';



function JobPostView() {
    const { id } = useParams();
    const [job, setJob] = useState(null);
    const [applications, setApplications] = useState([]);
    console.log(job, "jobdatataaaa")

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchJob = async () => {
            try {
                console.log(`Fetching job post with id: ${id}`);
                const { data } = await ERPApi.get(`${process.env.REACT_APP_API_URL}/jobs/job-posting/${id}`);
                console.log('Fetchedjobdata:', data?.jobPosting);
                console.log('Fetchedapplicants', data?.applications)
                setJob(data?.jobPosting);
                setApplications(data?.applications)
            } catch (error) {
                console.error('Error fetching job:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchJob();
    }, [id]);


    const downloadCV = (fileUrl) => {
        const baseURL = "https://teksacademy.s3.ap-south-1.amazonaws.com/HRM/Applicants_CV/";
        if (fileUrl) {
            const fullURL = baseURL + fileUrl; // Concatenate the base URL with the fileUrl
            console.log(fullURL, "fullURL");
            const link = document.createElement("a");
            link.href = fullURL;
            link.download = fileUrl; // Use the fileUrl as the filename
            link.target = '_blank'; // Open the download in a new tab
            link.style.display = 'none'; // Hide the link element
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            toast.error("CV is not Available");
        }
    };



    return (
        <div>  <div>
            <BackButton
                heading="Job Details"
                content="Back"
                to="/"
            />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 mt-2 profile_view text-center">
                        <div className="card h-100">
                            <div className="rounded-top">
                                <div className="btn_primary text-center rounded-top">
                                    <h6 className="py-2 mb-0">{job?.company_name}</h6>
                                </div>
                                <div className="mt-5 py-1">
                                    <img
                                        src={`https://teksacademy.s3.ap-south-1.amazonaws.com/HRM/jobposting_companylogos/${job?.company_logo}`}
                                        alt="user-img" className="w-50 h-50 thumbnail shadow bg-body-tertiary rounded p-2"
                                    />
                                </div>
                                <div className="mt-5">
                                    <h3 className="black_300 mb-0">
                                        {job?.title}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mt-2 profile_view">
                        <div className="card h-100">
                            <div className="card-body">
                                <ul class="nav mb-3 nav-tabs" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button
                                            class="card card_animate nav-link active"
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true">
                                            Job Details
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button
                                            class="card card_animate nav-link ms-3"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false" >
                                            Job Description
                                        </button>
                                    </li>

                                    <GateKeeper requiredModule="HR Management" requiredPermission="all" submenumodule="Applicants" submenuReqiredPermission="canRead">
                                        <li
                                            className="nav-item "
                                            role="presentation"
                                        >
                                            <button
                                                className="nav-link card card_animate ms-3"
                                                id="pills-edit-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-edit"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-contact"
                                                aria-selected="false"
                                            >
                                                Applicants
                                            </button>
                                        </li>
                                    </GateKeeper>

                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div
                                        class="tab-pane fade show active "
                                        id="pills-home"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabindex="0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                <div className="profile_bg table-scroll">
                                                    <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                                                        <tbody className="fs-13">

                                                            <tr className="lh-400">
                                                                <td className="ps-0 black_300 fw-500" scope="row">
                                                                    Job Title
                                                                </td>
                                                                <td
                                                                    className="text-mute"
                                                                >
                                                                    <span className="ms-5">: {job?.title}</span>

                                                                </td>
                                                            </tr>
                                                            <tr className="lh-400">
                                                                <td className="ps-0 black_300 fw-500" scope="row">
                                                                    Company Name
                                                                </td>
                                                                <td className="text-mute">
                                                                    <span className="ms-5">: {job?.company_name}</span>

                                                                </td>
                                                            </tr>
                                                            <tr className="lh-400">
                                                                <td className="ps-0 black_300 fw-500" scope="row">
                                                                    WorkPlace Type
                                                                </td>
                                                                <td className="text-mute">
                                                                    <span className="ms-5">: {job?.workplace_type}</span>

                                                                </td>
                                                            </tr>
                                                            <tr className="lh-400">
                                                                <td className="ps-0 black_300 fw-500" scope="row">
                                                                    Job Location
                                                                </td>
                                                                <td className="text-mute">
                                                                    <span className="ms-5">: {job?.location}</span>

                                                                </td>
                                                            </tr>
                                                            <tr className="lh-400">
                                                                <td className="ps-0 black_300 fw-500" scope="row">
                                                                    Job Type
                                                                </td>
                                                                <td className="text-mute">
                                                                    <span className="ms-5">: {job?.job_type}</span>

                                                                </td>
                                                            </tr>
                                                            <tr className="lh-400">
                                                                <td className="ps-0 black_300 fw-500" scope="row">
                                                                    Experience
                                                                </td>
                                                                <td className="text-mute">
                                                                    <span className="ms-5">: {job?.experience}</span>

                                                                </td>
                                                            </tr>
                                                            <tr className="lh-400">
                                                                <td className="ps-0 black_300 fw-500" scope="row">
                                                                    Number Of Position
                                                                </td>
                                                                <td className="text-mute">
                                                                    <span className="ms-5">: {job?.number_of_positions}</span>

                                                                </td>
                                                            </tr>
                                                            <tr className="lh-400">
                                                                <td className="ps-0 black_300 fw-500" scope="row">
                                                                    CTC Range
                                                                </td>
                                                                <td className="text-mute">
                                                                    {
                                                                        job?.ctc_range? <span className="ms-5">: {job?.ctc_range}</span> :
                                                                            <span className="ms-5">: N/A</span>
                                                                    }

                                                                </td>
                                                            </tr>

                                                            <tr className="lh-400">
                                                                <td className="ps-0 black_300 fw-500" scope="row">
                                                                    Skills
                                                                </td>
                                                                {/* <td className="text-mute ">
                                                                    <span className="ms-5 d-flex text-wrap">:
                                                                        {Array.isArray(job?.skills) && job?.skills?.length > 0 ? (
                                                                            job.skills.map(skill => skill?.name).join(', ')
                                                                        ) : (
                                                                            'No skills available'
                                                                        )}
                                                                    </span>
                                                                </td> */}

                                                                <td className="text-mute ">
                                                                    <span className="ms-5 d-flex text-wrap">:
                                                                        {(() => {
                                                                            try {
                                                                                const skillsArray = job?.skills ? JSON.parse(job.skills) : [];
                                                                                return Array.isArray(skillsArray) && skillsArray.length > 0
                                                                                    ? skillsArray.map(skill => skill.name).join(', ')
                                                                                    : 'No skills available';
                                                                            } catch (error) {
                                                                                return 'Invalid skills data';
                                                                            }
                                                                        })()}
                                                                    </span>
                                                                </td>

                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div
                                        class="tab-pane fade "
                                        id="pills-profile"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                        tabindex="0">
                                        <div className="row">
                                            <div className="col-lg-12" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                <p dangerouslySetInnerHTML={{ __html: job?.description }}></p>
                                            </div>
                                        </div>
                                    </div>

                                    <GateKeeper requiredModule="HR Management" requiredPermission="all" submenumodule="Applicants" submenuReqiredPermission="canRead">
                                        <div className="tab-content" id="pills-tabContent">
                                            <div
                                                className="tab-pane fade"
                                                id="pills-edit"
                                                role="tabpanel"
                                                aria-labelledby="pills-edit-tab"
                                            >

                                                <div className=" mt-2">
                                                    <div className="card-header">
                                                        <div className="card-body">
                                                            <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                                                                <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                                                                    <thead>
                                                                        <tr className="">
                                                                            <th
                                                                                scope="col"
                                                                                className="fs-13 lh-xs fw-600  "
                                                                            >
                                                                                S.No
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="fs-13 lh-xs  fw-600  "
                                                                            >
                                                                                Name
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="fs-13 lh-xs  fw-600  "
                                                                            >
                                                                                Contact No
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="fs-13 lh-xs  fw-600 text-truncate"
                                                                            >
                                                                                Email Id
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="fs-13 lh-xs  fw-600 text-truncate"

                                                                            >
                                                                                CV Download
                                                                            </th>
                                                                        </tr>


                                                                        {applications && applications.length > 0 ? applications?.map((item, index) => (
                                                                            <tr key={item?.id}>
                                                                                <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td className="fs-13 black_300 lh-xs bg_light">

                                                                                    {item?.applicant_name}
                                                                                </td>
                                                                                <td className="fs-13 black_300 lh-xs bg_light">
                                                                                    {item?.applicant_phone
                                                                                    }
                                                                                </td>
                                                                                <td className="fs-13 black_300 lh-xs bg_light ">
                                                                                    {item?.applicant_email}
                                                                                </td>
                                                                                <td className="fs-13 black_300  lh-xs bg_light cursor-pointer">
                                                                                    <button className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white"
                                                                                        onClick={() => downloadCV(item?.resume)}
                                                                                    >
                                                                                        Download CV
                                                                                    </button>

                                                                                </td>
                                                                            </tr>
                                                                        )) :
                                                                            (
                                                                                <tr className="fs-13 black_300 lh-xs bg_light">
                                                                                    <td className="fs-13 black_300 lh-xs bg_light">
                                                                                        No Applicants Found
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }

                                                                    </thead>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </GateKeeper>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></div >
    )
}

export default JobPostView;