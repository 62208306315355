export const InitialState = {

    TotalStudents: [],

    singleStudentData: null,

    // Enrolled students
    EnrolledStudents: {
        PaginatedStudents: [],
        filters: {
            enquiry: "",
            fromDate: "",
            toDate: "",
            branch: "",
            modeOfTraining: "",
            lead: "",
        },
        searchResultStudents: null,
        perPage: 10,
        totalStudents: null,
        totalPages: null,
        loading: false,
        startStudent: null,
        endStudent: null,
        search: "",
        currentPage: 1,
    },

    // No Due Records
    NoDueFeeRecords_Students: {
        PaginatedNoDueFeeRecords_Students: [],
        filters: {
            fromDate: "",
            toDate: "",
            branch: "",
            modeOfTraining: "",
            enquiry: "",
            lead: "",
        },
        currentPage: 1,
        perPage: 10,
        totalStudents: null,
        totalPages: null,
        searchResultStudents: null,
        loading: false,
        startStudent: null,
        endStudent: null,
        search: "",
    },

    // Certificate Students
    CertificateStudents: {
        PaginatedCertificateStudents: [],
        filters: {
            fromDate: "",
            toDate: "",
            branch: "",
            course: "",
            enquiry: "",
            certificateStatus: "",
            internShipStatus: "",
            iepStatus: "",
            studentType: "",
        },
        currentPage: 1,
        perPage: 10,
        searchResultStudents: null,
        totalStudents: null,
        totalPages: null,
        loading: false,
        startStudent: null,
        endStudent: null,
        search: "",
    },

    // Requested Certificate
    Requested_CertificateStudents: {
        Paginated_Requested_CertificateStudents: [],
        filters: {
            fromDate: "",
            toDate: "",
            branch: "",
            course: "",
            internShipStatus: "",
            iepStatus: "",
            studentType:"",
        },
        currentPage: 1,
        perPage: 10,
        filteredSearchstudents: null,
        totalStudents: null,
        totalPages: null,
        loading: false,
        startStudent: null,
        endStudent: null,
        search: "",
    },

    // Issued Ceritificate
    Issued_CerificateStudents: {
        Paginated_Issued_CerificateStudents: [],
        filters: {
            fromDate: "",
            toDate: "",
            course: "",
            branch: "",
            internShipStatus: "",
            iepStatus: "",
            studentType:"",
        },
        searchResultStudents: null,
        perPage: 10,
        totalStudents: null,
        totalPages: null,
        loading: false,
        startStudent: null,
        endStudent: null,
        search: "",
        currentPage: 1,
    },

    // Fee Details
    FeeDetailsStudents: {
        PaginatedFeeDetailsStudents: [],
        filters: {
            fromDate: "",
            toDate: "",
            branch: "",
            modeOfTraining: "",
            enquiry: "",
            lead: "",
        },
        search: "",
        currentPage: 1,
        perPage: 10,
        totalStudents: null,
        totalPages: null,
        searchResultStudents: null,
        loading: false,
        startStudent: null,
        endStudent: null,

    },

    // Dashboard Students
    DashboardStudents: {
        PaginatedDashboardStudents: [],
        filters: {
            fromDate: "",
            toDate: "",
            branch: "",
            modeOfTraining: "",
        },
        currentPage: 1,
        perPage: 10,
        filteredSearchstudents: null,
        totalStudents: null,
        totalPages: null,
        loading: false,
        startStudent: null,
        endStudent: null,
        search: "",
    },

    // Today feeDetails students
    TodayFeeDetailsStudents: {
        PaginatedTodayFeeDetails: [],
        filters: {
            fromDate: "",
            toDate: "",
            course: "",
            branch: "",
            enquiry: "",
            lead: "",
        },
        searchResultStudents: null,
        perPage: 10,
        totalStudents: null,
        totalPages: null,
        loading: false,
        startStudent: null,
        endStudent: null,
        search: "",
        currentPage: 1,
        totalDueAmount: 0,
    },

    // OverDue feeDetails students
    OverDueFeeDetailsStudents: {
        PaginatedOverDueFeeDetails: [],
        filters: {
            fromDate: "",
            toDate: "",
            course: "",
            branch: "",
            enquiry: "",
            lead: "",
            admissiontoDate: "",
            admissionfromDate: "",
        },
        searchResultStudents: null,
        perPage: 10,
        totalStudents: null,
        totalPages: null,
        loading: false,
        startStudent: null,
        endStudent: null,
        search: "",
        currentPage: 1,
        totalDueAmount: 0,
    },

    // Upcoming feeDetails students
    UpComingFeeDetailsStudents: {
        PaginatedUpComingFeeDetails: [],
        filters: {
            fromDate: "",
            toDate: "",
            course: "",
            branch: "",
            enquiry: "",
            lead: "",
            admissiontoDate: "",
            admissionfromDate: "",
        },
        searchResultStudents: null,
        perPage: 10,
        totalStudents: null,
        totalPages: null,
        loading: false,
        startStudent: null,
        endStudent: null,
        search: "",
        currentPage: 1,
        totalDueAmount: 0,
    },

}