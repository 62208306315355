export const SET_ALL_STUDENTS_DATA = "SET_ALL_STUDENTS_DATA ";
export const SET_SEARCH = "SET_SEARCH";
export const SET_CUSTOM_PAGE = "SET_CUSTOM_PAGE";
export const SET_LOADING = "SET_LOADING";
export const SET_PER_PAGE = "SET_PER_PAGE"
export const SET_PAGINATED_STUDENTS="SET_PAGINATED_STUDENTS"
export const SET_FROM_DATE ="SET_FROM_DATE"
export const SET_TO_DATE="SET_TO_DATE"
export const SET_BRANCH="SET_BRANCH"
export const SET_ENQUIRY_TAKEN_BY= "SET_ENQUIRY_TAKEN_BY"
export const SET_MODE_OF_TRAINING = "SET_MODE_OF_TRAINING"
export const CREATE_STUDENT = "CREATE_STUDENT";
export const SET_FILTERS="SET_FILTERS";
export const UPDATE_CERTIFICATE_STATUS = "UPDATE_CERTIFICATE_STATUS";
export const GET_STUDENT_BY_ID ="GET_STUDENT_BY_ID"
export const UPDATE_ADMISSIONFEE ="UPDATE_ADMISSIONFEE"
export const UPDATE_NO_OF_INSTALLMENTS ="UPDATE_NO_OF_INSTALLMENTS"
export const UPDATE_INSTALLMENTS ="UPDATE_INSTALLMENTS";
export const UPDATE_DUE_DATE_DUE_AMOUNT ="UPDATE_DUE_DATE_DUE_AMOUNT"
export const UPDATE_EXTRA_DISCOUNT = "UPDATE_EXTRA_DISCOUNT"