import React, { useEffect, useState } from 'react';
import Button from '../../../components/button/Button';
import BackButton from '../../../components/backbutton/BackButton';
import { IoMdArrowBack } from "react-icons/io";
// import 'quill/dist/quill.snow.css';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { useTheme } from '../../../../dataLayer/context/themeContext/ThemeContext';
import { MdDelete } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { ERPApi } from '../../../../serviceLayer/interceptor';
import { toast } from 'react-toastify';

let nextId = 0;

function AddjobPost() {
  const [user_id, setuserid] = useState(() => {
    const userData = JSON.parse(localStorage.getItem("data"));
    return userData?.user?.id || "";
  });

  const { id } = useParams();
  const navigate = useNavigate();

  const [gettingCompanyLogo, setGettingCompanyLogo] = useState(null);

  console.log(gettingCompanyLogo, "gettingCompanyLogo")



  const [formData, setFormData] = useState({
    jobTitle: '',
    companyName: '',
    workPlaceType: '',
    jobLocation: '',
    jobType: '',
    description: '',
    skills: [],
    name: '',
    rphoto: null,
    closingDate: '',
    experience: '',
    position: '',
    ctcRange: ''

  });
  console.log("SDfsdfsdf", formData)
  const [showImage, setShowImage] = useState({
    url: null,
  });

  console.log("formdateformdatesafdsfsf", showImage);


  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const { theme } = useTheme();

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],//add "iamge" inside this array if you want image section 
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": [] }],
    ]
  };

  const formats = [
    "header", "size", "bold", "italic", "underline", "strike", "blockquote",
    "list", "bullet", "indent", "link", "image", "align", "color"
  ];



  useEffect(() => {
    // Fetch data based on id if needed
    if (id) {
      fetchJobPostData(id);
    }
  }, [id]);

  const fetchJobPostData = async (id) => {
    try {
      const response = await ERPApi.get(`${process.env.REACT_APP_API_URL}/jobs/job-posting/${id}`);
      const { data } = response;
      console.log('Fetcheddataaaaaaaaa:', data);
      const jobPostingData = data.jobPosting;
      // Format the closing_date to YYYY-MM-DD format
      const formattedClosingDate = formatDateForInputDate(jobPostingData.closing_date);

      // Parse skills if necessary
      // Parse skills if necessary
      let parsedSkills = [];
      if (typeof jobPostingData.skills === 'string') {
        try {
          parsedSkills = JSON.parse(jobPostingData.skills);
        } catch (e) {
          console.error('Error parsing skills:', e);
        }
      } else if (Array.isArray(jobPostingData.skills)) {
        parsedSkills = jobPostingData.skills;
      }

      setGettingCompanyLogo(jobPostingData?.company_logo)

      setFormData({
        jobTitle: jobPostingData.title,
        companyName: jobPostingData.company_name,
        workPlaceType: jobPostingData.workplace_type,
        jobLocation: jobPostingData.location,
        jobType: jobPostingData.job_type,
        experience: jobPostingData.experience,
        position: jobPostingData.number_of_positions,
        ctcRange: jobPostingData.ctc_range,
        // companyLogo: jobPostingData.company_logo,
        closingDate: formattedClosingDate,
        description: jobPostingData.description,
        skills: parsedSkills || [],
      });

      console.log('Fetched jobPosting data:', jobPostingData);
    } catch (error) {
      console.error('Error fetching job post data:', error);
    }
  };


  const formatDateForInputDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    return formattedDate;
  };
  const handleChange = (event) => {
    const { id, value, files } = event.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: ''
    }));
    if (id === 'rphoto' && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          // rphoto: reader.result,
          rphoto: file
        }));
        setShowImage((prev) => ({
          ...prev,
          url: reader.result,
        }))
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: value,
      }));
    }
  };


  const handleProcedureContentChange = (content) => {
    setFormData(prevState => ({
      ...prevState,
      description: content
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      description: ''
    }));
  };

  const handleAddSkill = (e) => {

    if (formData.name.trim() !== '') {
      const newSkill = {
        id: formData.skills.length + 1,
        name: formData.name
      };
      setFormData(prevState => ({
        ...prevState,
        skills: [...prevState.skills, newSkill],
        name: ''
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: ''
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: 'Skills is required'
      }));
    }
    setPreviewError('');
  };
  const handleDeleteSkill = (index) => {
    const updatedSkills = [...formData.skills];
    updatedSkills.splice(index, 1);

    const updatedSkillsWithIds = updatedSkills.map((skill, idx) => ({
      ...skill,
      id: idx + 1,
    }));

    setFormData({ ...formData, skills: updatedSkillsWithIds });
  };


  const handleNext = () => {
    const tempErrors = validate();
    if (Object.keys(tempErrors).length === 0) {
      setActiveTab(prevActiveTab => prevActiveTab + 1);
    } else {
      // Set only the first error
      const firstErrorKey = Object.keys(tempErrors)[0];
      setErrors({ [firstErrorKey]: tempErrors[firstErrorKey] });
    }
  };

  const handlePrev = () => {
    setActiveTab(prevActiveTab => prevActiveTab - 1);
  };

  const validate = () => {
    let tempErrors = {};
    const { jobTitle, companyName, workPlaceType, jobLocation, jobType, description, rphoto, name, closingDate, experience, position } = formData;
    if (activeTab === 1) {
      if (!jobTitle) {
        tempErrors.jobTitle = "Job title is required";
      } else if (jobTitle.length < 3) {
        tempErrors.jobTitle = "Job title must be at least 3 characters long";
      }
      if (!companyName) {
        tempErrors.companyName = "Company name is required";
      } else if (companyName.length < 3) {
        tempErrors.companyName = "Company name must be at least 3 characters long";
      }
      if (!workPlaceType) {
        tempErrors.workPlaceType = "Work Mode is required";
      }
      if (!jobLocation) {
        tempErrors.jobLocation = "Job location is required";
      } else if (jobLocation.length < 3) {
        tempErrors.jobLocation = "Job location must be at least 3 characters long";
      }
      if (!jobType) {
        tempErrors.jobType = "Job type is required";
      }

      if (!rphoto && !gettingCompanyLogo) {
        tempErrors.rphoto = "Company logo is required";
      }

      if (!closingDate) {
        tempErrors.closingDate = "End date is required";
      }
      if (!experience) {
        tempErrors.experience = "Experience is required";
      }
      if (!position) {
        tempErrors.position = "Position is required";
      }
    } else if (activeTab === 2) {
      // Validation for Tab 2 fields (description)
      const descriptionText = description.trim().replace(/<[^>]+>/g, '').trim();

      if (!descriptionText) {
        tempErrors.description = 'Job Description is required';
      } else if (descriptionText.length < 3) {
        tempErrors.description = 'Description must be at least 3 characters long';
      }
    }

    return tempErrors;
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const tempErrors = validate();
    if (Object.keys(tempErrors).length === 0) {


      // const jobData = {
      //   title: formData?.jobTitle,
      //   company_name: formData?.companyName,
      //   company_logo: formData?.rphoto,
      //   workplace_type: formData?.workPlaceType,
      //   job_type: formData?.jobType,
      //   skills: formData?.skills,
      //   description: formData?.description,
      //   location: formData?.jobLocation,
      //   closing_date: formData?.closingDate,
      //   createdBy: user_id,
      //   // department_id: 11,
      //   // position_id: 11,
      // }

      const formDataObj = new FormData();

      // Append your form data fields to the FormData object
      formDataObj.append('title', formData?.jobTitle);
      formDataObj.append('company_name', formData?.companyName);
      if (formData?.rphoto) {
        formDataObj.append('company_logo', formData?.rphoto);
      }
      // Append the file object
      formDataObj.append('workplace_type', formData?.workPlaceType);
      formDataObj.append('job_type', formData?.jobType);
      formDataObj.append('skills', JSON.stringify(formData?.skills)); // Serialize the array
      formDataObj.append('description', formData?.description);
      formDataObj.append('location', formData?.jobLocation);
      formDataObj.append('closing_date', formData?.closingDate);
      formDataObj.append('createdBy', user_id);
      formDataObj.append("experience", formData.experience);
      formDataObj.append("number_of_positions", formData.position);
      formDataObj.append("ctc_range", formData.ctcRange);
      console.log(formDataObj, "formDataObj");

      if (!id) {
        try {
          const { data, status } = await toast.promise(ERPApi.post(`${process.env.REACT_APP_API_URL}/jobs/job-postings`, formDataObj),
            {
              loading: "Loading...",
              success: "Job Position created Successfully",
              error: {},
            }
          );
          console.log(data, status, "somedata")

          if (status === 201) {
            navigate("/hrmanagement/joblist")
          }
        } catch (error) {
          if (error?.response?.status >= 500 || error?.response?.status <= 600) {
            toast.error(error?.response?.data?.message)
          }
        }
      }
      if (id) {
        try {
          const { data, status } = await toast.promise(ERPApi.patch(`${process.env.REACT_APP_API_URL}/jobs/job-posting/${id}`, formDataObj),
            {
              loading: "Loading...",
              success: "Job Position Updated Successfully",
              error: {},
            }
          );
          console.log(data, status, "somedatafggfgf");

          if (status === 200) {
            navigate("/hrmanagement/joblist")
          }
        } catch (error) {
          if (error?.response?.status >= 500 || error?.response?.status <= 600) {
            toast.error(error?.response?.data?.message)
          }
        }
      }
    }
    else {
      const firstErrorKey = Object.keys(tempErrors)[0];
      setErrors({ [firstErrorKey]: tempErrors[firstErrorKey] });
    }
  }


  const [previewError, setPreviewError] = useState('')
  const handlePreview = () => {
    if (formData.skills.length === 0) {
      setPreviewError('At least one skill is required to preview.');
    } else {
      setPreviewError('');
      // Trigger the modal to open
      const previewButton = document.getElementById('previewButton');
      previewButton.click();
    }
  };

  return (
    <div>
      <BackButton heading="Job Post" content="Back" />
      <div className="container-fluid">
        <div className="registration_form_section">
          <div className="top">
            <div className="registration_form_tabs row">
              <div className="button_grp col-lg-12 p-0">
                <button
                  type="button"
                  className={activeTab === 1 ? `${theme === "light" ? "form_tab_btn active w-100" : "form_tab_btn dark active"}` : "form_tab_btn"}
                  style={{ cursor: "auto" }}
                >
                  Job Details
                </button>
                <button
                  type="button"
                  className={activeTab === 2 ? `${theme === "light" ? "form_tab_btn active w-100" : "form_tab_btn dark active"}` : "form_tab_btn"}
                  style={{ cursor: "auto" }}
                >
                  Job Descriptions
                </button>
                <button
                  type="button"
                  className={activeTab === 3 ? `${theme === "light" ? "form_tab_btn active w-100" : "form_tab_btn dark active"}` : "form_tab_btn"}
                  style={{ cursor: "auto" }}
                >
                  Skills
                </button>

              </div>
            </div>
          </div>
          <div className="bottom mt-3">
            <form >
              {activeTab === 1 && (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="jobTitle" className="form-label fs-s fw-medium black_300">
                          Job Title<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            errors && errors.jobTitle
                              ? "form-control input_bg_color error-input"
                              : "form-control input_bg_color "
                          }
                          placeholder="Enter The Job Role"
                          id="jobTitle"
                          value={formData.jobTitle}
                          onChange={handleChange}
                        />
                        <div className="response" style={{ height: "8px" }}>
                          {errors && errors.jobTitle && (
                            <p className="text-danger m-0 fs-xs">
                              {errors.jobTitle}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="companyName" className="form-label fs-s fw-medium black_300">
                          Company Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            errors && errors.companyName
                              ? "form-control input_bg_color error-input"
                              : "form-control input_bg_color "
                          }
                          placeholder="Enter The Company Name"
                          id="companyName"
                          value={formData.companyName}
                          onChange={handleChange}
                        />
                        <div className="response" style={{ height: "8px" }}>
                          {errors && errors.companyName && (
                            <p className="text-danger m-0 fs-xs">
                              {errors.companyName}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label fs-s fw-medium black_300">
                        Work Mode<span className="text-danger">*</span>
                      </label>
                      <select
                        className={
                          errors && errors.workPlaceType
                            ? "form-control input_bg_color error-input"
                            : "form-control input_bg_color "
                        }
                        id="workPlaceType"
                        value={formData.workPlaceType}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>Select Work Mode</option>
                        <option value="on-site">On-Site</option>
                        <option value="hybrid">Hybrid</option>
                        <option value="remote">Remote</option>
                      </select>
                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.workPlaceType && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.workPlaceType}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="jobLocation" className="form-label fs-s fw-medium black_300">
                          Job Location<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            errors && errors.jobLocation
                              ? "form-control input_bg_color error-input"
                              : "form-control input_bg_color "
                          }
                          id="jobLocation"
                          value={formData.jobLocation}
                          onChange={handleChange}
                          placeholder='Enter The Job Location'
                        />
                        <div className="response" style={{ height: "8px" }}>
                          {errors && errors.jobLocation && (
                            <p className="text-danger m-0 fs-xs">
                              {errors.jobLocation}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label fs-s fw-medium black_300">
                        Job Type<span className="text-danger">*</span>
                      </label>
                      <select
                        className={
                          errors && errors.jobType
                            ? "form-control input_bg_color error-input"
                            : "form-control input_bg_color "
                        }
                        id="jobType"
                        value={formData.jobType}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>Select Job Type</option>
                        <option value="full-time">Full-time</option>
                        <option value="part-time">Part-time</option>
                        <option value="contract">Contract</option>
                        <option value="volunteer">Volunteer</option>
                        <option value="internship">Internship</option>
                        <option value="other">Other</option>
                      </select>
                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.jobType && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.jobType}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="rphoto" className="form-label fs-s fw-medium black_300">
                          Company Logo<span className="text-danger">*</span>
                        </label>
                        <input
                          type="file"
                          className={
                            errors && errors.rphoto
                              ? "form-control input_bg_color error-input"
                              : "form-control input_bg_color "
                          }
                          id="rphoto"
                          onChange={handleChange}
                        // onChange={handleCompanyLog}
                        />
                        <div className="response" style={{ height: "8px" }}>
                          {errors && errors.rphoto && (
                            <p className="text-danger m-0 fs-xs">
                              {errors.rphoto}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="closingDate" className="form-label fs-s fw-medium black_300">
                          End Date<span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="closingDate"
                          value={formData.closingDate}
                          onChange={handleChange}
                        />
                        <div className="response" style={{ height: "8px" }}>
                          {errors && errors.closingDate && (
                            <p className="text-danger m-0 fs-xs">
                              {errors.closingDate}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label fs-s fw-medium black_300">
                        Experience<span className="text-danger">*</span>
                      </label>
                      <select
                        className={
                          errors && errors.experience
                            ? "form-control input_bg_color error-input"
                            : "form-control input_bg_color "
                        }
                        id="experience"
                        value={formData.experience}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>Select the Experience</option>
                        <option value="fresher">Fresher</option>
                        <option value="0-1 year">0-1 year</option>
                        <option value="1-3 years">1-3 years</option>
                        <option value="3-5 years">3-5 years</option>
                        <option value="5-7 years">5-7 years</option>
                        <option value="7-10 years">7-10 years</option>
                        <option value="others">Others</option>
                      </select>
                      <div className="response" style={{ height: "8px" }}>
                        {errors && errors.experience && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.experience}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="position" className="form-label fs-s fw-medium black_300">
                          Number Of Position<span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className={
                            errors && errors.position
                              ? "form-control input_bg_color error-input"
                              : "form-control input_bg_color "
                          }
                          placeholder="Enter The Number of Position"
                          id="position"
                          value={formData.position}
                          onChange={handleChange}
                        />
                        <div className="response" style={{ height: "8px" }}>
                          {errors && errors.position && (
                            <p className="text-danger m-0 fs-xs">
                              {errors.position}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label fs-s fw-medium black_300">
                        CTC Range(Monthly)
                      </label>
                      <select
                        className={
                          errors && errors.ctcRange
                            ? "form-control input_bg_color error-input"
                            : "form-control input_bg_color "
                        }
                        id="ctcRange"
                        value={formData.ctcRange}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>Select CTC Range</option>
                        <option value="5000-7000">5000-7000</option>
                        <option value="7000-10000">7000-10,000</option>
                        <option value="10000-20000">10,000-20,000</option>
                        <option value="20000-30000">20,000-30,000</option>
                        <option value="30000-45000">30,000-45,000</option>
                        <option value="45000-60000">45,000-60,000</option>
                        <option value="60000-75000">60,000-75,000</option>
                        <option value="75000-90000">75,000-90,000</option>
                        <option value="more than 90000">More than 90000</option>
                      </select>
                      {/* <div className="response" style={{ height: "8px" }}>
                        {errors && errors.experience && (
                          <p className="text-danger m-0 fs-xs">
                            {errors.experience}
                          </p>
                        )}
                      </div> */}
                    </div>
                  </div>
                  <div className="controls d-flex justify-content-between mt-4">
                    <div>
                      {activeTab !== 1 && (
                        <Button
                          type="button"
                          className="control_prev_btn text_color"
                          onClick={handlePrev}
                          icon={<IoMdArrowBack className="button_icons" />}
                        >
                          Go Back
                        </Button>
                      )}
                    </div>
                    <div>
                      {activeTab !== 4 && (
                        <Button
                          type="button"
                          className="btn right btn_primary"
                          onClick={handleNext}
                        >
                          Continue
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}
              {activeTab === 2 && (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="description" className="form-label fs-s fw-medium black_300">
                          Description<span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          theme="snow"
                          formats={formats}
                          modules={modules}
                          placeholder="Write your content..."
                          value={formData.description}
                          onChange={handleProcedureContentChange}
                          style={{ height: "300px" }}
                        />
                        <div className="response mt-5" style={{ height: "3px" }}>
                          {errors && errors.description && (
                            <p className="text-danger m-0 fs-xs">
                              {errors.description}
                            </p>
                          )}
                        </div>
                        {/* {errors.description && <div className="text-danger fs-s fw-medium mt-5">{errors.description}</div>} */}
                      </div>
                    </div>
                  </div>
                  <div className="controls d-flex justify-content-between mt-4 job-respons">
                    <div>
                      <Button
                        type="button"
                        className="btn control_prev_btn reg_btn text_color job-respons"
                        onClick={handlePrev}
                        icon={<IoMdArrowBack className="button_icons" />}
                      >
                        Go Back
                      </Button>
                    </div>
                    <div>
                      <Button
                        type="button"
                        className="btn right btn_primary job-respons"
                        onClick={handleNext}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {activeTab === 3 && (
                <>
                  <div className="col-md-4 d-flex p-2">
                    <input
                      type="text"
                      className={
                        errors && errors.name
                          ? "form-control input_bg_color error-input"
                          : "form-control input_bg_color"
                      }
                      placeholder="Enter Skill"
                      id="name"
                      value={formData.name}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault(); // Prevent the default action of form submission
                          handleAddSkill(); // Call your function to add the skill
                        }
                      }}
                    />

                    <Button className="btn right btn_primary ms-2" onClick={handleAddSkill}>Add</Button>

                  </div>
                  <div className="response" style={{ height: "8px" }}>
                    {errors && errors.name && (
                      <p className="text-danger m-0 fs-xs">
                        {errors.name}
                      </p>
                    )}
                  </div>
                  {formData.skills.length > 0 && (
                    <div className='overflow-auto border border-white  p-3 mb-5 bg-white rounded mt-3 jobwidth' style={{ maxHeight: '200px', width: '33%' }}>
                      <div className='h-100'>
                        {formData.skills?.map((skill, index) => (
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 d-flex p-2" key={skill.id} style={{ backgroundColor: "#f0f0f0", borderRadius: "8px", marginBottom: "8px", padding: "12px", display: "flex", alignItems: "center" }}>
                            <div style={{ flex: 1 }}>
                              <span>{skill.name}</span>
                            </div>
                            <div style={{ display: "flex", gap: "8px" }}>
                              <MdDelete
                                className="delete_icon me-3"
                                onClick={() => handleDeleteSkill(index)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="response" style={{ height: "8px" }}>
                    {previewError && (
                      <p className="text-danger m-0 fs-xs">{previewError}</p>
                    )}
                  </div>

                  <div className="controls d-flex justify-content-between mt-4">
                    <div>
                      <Button
                        type="button"
                        className="btn control_prev_btn reg_btn text_color"
                        onClick={handlePrev}
                        icon={<IoMdArrowBack className="button_icons" />}
                      >
                        Go Back
                      </Button>
                    </div>
                    <div>
                      <button
                        type="button"
                        id="previewButton"
                        className="btn right btn_primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={{ display: 'none' }}
                      >
                        Hidden Preview Button
                      </button>
                      <button
                        type="button"
                        className="btn right btn_primary"
                        onClick={handlePreview}
                      >
                        Preview
                      </button>

                      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-scrollable">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h1 class="modal-title fs-5" id="exampleModalLabel">Preview</h1>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <form>
                                <div className='box '>
                                  <div className="col-xl-12 col-lg-12 ">
                                    <div className="row profile-bg">
                                      <div className="col-lg-7 col-md-6 col-sm-12 ">
                                        <table>
                                          <tbody>
                                            <tr className="lh-400">
                                              <td className="ps-0 black_300 fw-500 fs-14" scope="row">Job Title</td>
                                              <td className="text-mute text-truncate fs-14 ps-2 fw-500"><span className="ms-4">: </span>{formData.jobTitle}</td>
                                            </tr>
                                            <tr className="lh-400">
                                              <td className="ps-0 black_300 fw-500 fs-14" scope="row">Company Name</td>
                                              <td className="text-mute text-truncate fs-14 ps-2 fw-500"><span className="ms-4">: </span>{formData.companyName}</td>
                                            </tr>
                                            <tr className="lh-400">
                                              <td className="ps-0 black_300 fw-500 fs-14" scope="row">Work Mode</td>
                                              <td className="text-mute text-truncate fs-14 ps-2 fw-500"><span className="ms-4">: </span>{formData.workPlaceType}</td>
                                            </tr>
                                            <tr className="lh-400">
                                              <td className="ps-0 black_300 fw-500 fs-14" scope="row">Job Location</td>
                                              <td className="text-mute text-truncate fs-14 ps-2 fw-500"><span className="ms-4">: </span>{formData.jobLocation}</td>
                                            </tr>
                                            <tr className="lh-400">
                                              <td className="ps-0 black_300 fw-500 fs-14" scope="row">Job Type</td>
                                              <td className="text-mute text-truncate fs-14 ps-2 fw-500"><span className="ms-4">: </span>{formData.jobType}</td>
                                            </tr>
                                            <tr className="lh-400">
                                              <td className="ps-0 black_300 fw-500 fs-14" scope="row">Experience</td>
                                              <td className="text-mute text-truncate fs-14 ps-2 fw-500"><span className="ms-4">: </span>{formData.experience}</td>
                                            </tr>
                                            <tr className="lh-400">
                                              <td className="ps-0 black_300 fw-500 fs-14" scope="row">Position</td>
                                              <td className="text-mute text-truncate fs-14 ps-2 fw-500"><span className="ms-4">: </span>{formData.position}</td>
                                            </tr>
                                            <tr className="lh-400">
                                              <td className="ps-0 black_300 fw-500 fs-14" scope="row">CTC Range</td>
                                              {
                                                formData.ctcRange ? <td className="text-mute text-truncate fs-14 ps-2 fw-500"><span className="ms-4">: </span>{formData.ctcRange}</td> :
                                                  <td className="text-mute text-truncate fs-14 ps-2 fw-500"><span className="ms-4">: </span>N/A</td>
                                              }
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="col-lg-5 col-md-6 col-sm-4 d-flex justify-content-end ">
                                        {/* <img src={showImage?.url ? showImage?.url : gettingCompanyLogo} alt="Company Logo" width="40%" /> */}
                                        <img
                                          src={showImage?.url ? showImage.url : `https://teksacademy.s3.ap-south-1.amazonaws.com/HRM/jobposting_companylogos/${gettingCompanyLogo}`}
                                          alt="Company Logo"
                                          width="40%"
                                        />

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='box mt-3'>
                                  <div className='mb-3'>
                                    <td className="ps-0 black_300 fw-500 fs-14" scope="row">Skills: </td>
                                    <div className='d-flex flex-wrap'>
                                      {formData?.skills?.map((skill, index) => (
                                        <td key={index} className="text-mute text-truncate fs-14 ps-2 fw-500 ">{skill.name},</td>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="black_300 fw-500 fs-14" scope="row">
                                    Description:
                                    <div className="" dangerouslySetInnerHTML={{ __html: formData.description }} />
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn right btn_primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleSubmit}>Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </>
              )}

            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddjobPost;