import React, { useEffect, useRef, useState } from 'react';
import { FaWhatsapp, FaFacebook, FaInstagram, FaRegCopy } from 'react-icons/fa';


const SharePopup = ({ job, onClose }) => {
  const popupRef = useRef();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const jobTitle = job?.title || 'default-title';
  const companyName = job?.company_name || 'default-company';
  const jobId = job?.id || 'default-id';

  const shareUrl = `https://teksacademy.com/${jobId}/${companyName.replace(/\s+/g, '-').toLowerCase()}/${jobTitle.replace(/\s+/g, '-').toLowerCase()}`;

  const message = `Check out this job opening: ${shareUrl}`;
  const encodedMessage = encodeURIComponent(message);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
  
    <div className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-50" style={{ zIndex: 9999 }}>
      <div className="bg-white rounded pt-4" ref={popupRef} style={{ maxWidth: '400px', width: '90%', maxHeight:'330px' ,height:'90%'}}>
        <div className="border-bottom pb-2 mb-3 d-flex justify-content-between align-items-center">
          <h3 className="ms-3 fs-20">Share Job</h3>
          <button className="btn  p-0 me-3 text-muted" onClick={onClose}>Cancel</button>
        </div>
        <div className="d-flex justify-content-center gap-5 my-3 mt-5">
          <a href={`https://api.whatsapp.com/send?text=${encodedMessage}`} target="_blank" rel="noopener noreferrer" className="text-success">
            <FaWhatsapp className="fs-1" title="Share on WhatsApp" />
          </a>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`} target="_blank" rel="noopener noreferrer" className="text-primary">
            <FaFacebook className="fs-1" title="Share on Facebook" />
          </a>
          <a href={`https://www.instagram.com`} target="_blank" rel="noopener noreferrer" className="text-danger">
            <FaInstagram className="fs-1" title="Share on Instagram" />
          </a>
        </div>
        <div className="mt-4 px-4">
         <div className='mt-5'> 
          <h6 className="fs-10 fw-bold mb-5  text-center">Or share with link</h6>
          <div className="share-box d-flex align-items-center justify-content-between  px-2 ">
            <a  href={shareUrl} target="_blank" rel="noopener noreferrer" className="text-truncate black_300 fs-12 fw-bold" style={{ maxWidth: '80%' }}>
              {shareUrl}
            </a>
            <FaRegCopy className="copy-icon" onClick={copyToClipboard} />
            {copied && <span className="copied-popup">Copied!</span>}
          </div></div>
        </div>
      </div>
    </div>
  );
};

export default SharePopup;
