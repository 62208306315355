import { useEffect, useReducer } from "react";
import BatchesReducer from "./BatchesReducer";
import { initialState } from "./utils/BatchesConfig";
import * as Actions from "./utils/BatchesActions";
import * as Api from "./utils/BatchesAPI";


const useBatchesProvider = () => {

    const [BatchesState, DispatchBatches] = useReducer(BatchesReducer, initialState);
    console.log(BatchesState, "BatchesState")

    const getPaginatedActiveBatches = async () => {
        const { filters, perPage, search, currentPage } = BatchesState.ActiveBatchesList;
        DispatchBatches(Actions.setLoading("ACTIVE_BATCHES_LIST"))
        try {
            const { data, status } = await Api.getActiveBatches(currentPage, perPage, search, filters);
            console.log(data, "dataseen")
            if (status === 200) {
                DispatchBatches(Actions.setPaginatedActiveBatches(data, "ACTIVE_BATCHES_LIST"))
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            DispatchBatches(Actions.setLoading("ACTIVE_BATCHES_LIST"))
        }
    }


    const getPaginatedUpcomingBatchs = async () => {
        const { filters, perPage, search, currentPage } = BatchesState?.UpcomingBatchesList;
        DispatchBatches(Actions.setLoading("UPCOMING_BATCHES_LIST"))
        try {
            const { data, status } = await Api.getUpCompingBatches(currentPage, perPage, search, filters)

            console.log(data, status, "jhgfghjfgjfdgfdjs")
            if (status === 200) {
                DispatchBatches(Actions.setPaginatedUpcomingBatches(data, "UPCOMING_BATCHES_LIST"))
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            DispatchBatches(Actions.setLoading("UPCOMING_BATCHES_LIST"))
        }
    }

    const getPaginatedCompletedBatches = async () => {
        const { filters, perPage, search, currentPage } = BatchesState?.CompletedBatchesList;
        DispatchBatches(Actions.setLoading("COMPLETED_BATCHES_LIST"))
        try {
            const { data, status } = await Api.getCompltedBatches(currentPage, perPage, search, filters);
            console.log(data, status, "getPaginatedCompletedBatches");
            if (status === 200) {
                DispatchBatches(Actions.setPaginatedCompltedBatches(data, "COMPLETED_BATCHES_LIST"))
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            DispatchBatches(Actions.setLoading("COMPLETED_BATCHES_LIST"))
        }
    }


 

    useEffect(() => {
        getPaginatedActiveBatches();
    }, [BatchesState.ActiveBatchesList.search,
    BatchesState.ActiveBatchesList.perPage,
    BatchesState.ActiveBatchesList.currentPage,
    BatchesState.ActiveBatchesList.filters
    ]);


    useEffect(() => {
        getPaginatedUpcomingBatchs();
    }, [BatchesState.UpcomingBatchesList.search,
    BatchesState.UpcomingBatchesList.perPage,
    BatchesState.UpcomingBatchesList.currentPage,
    BatchesState.UpcomingBatchesList.filters
    ]);

    useEffect(() => {
        getPaginatedCompletedBatches();
    }, [BatchesState.CompletedBatchesList.search,
    BatchesState.CompletedBatchesList.perPage,
    BatchesState.CompletedBatchesList.currentPage,
    BatchesState.CompletedBatchesList.filters
    ]);



    return {
        BatchesState,
        DispatchBatches,
        getPaginatedActiveBatches,
        getPaginatedUpcomingBatchs,
        getPaginatedCompletedBatches,
    }


}
export default useBatchesProvider;