import { createContext, useCallback, useEffect, useReducer } from "react";
import { ERPApi } from "../../../../serviceLayer/interceptor";



const JoblistProvider = () => {
    const intialState = {
        JobsList: {
            PaginatedJobs: [],
            filters: {
                jobType: "",
                workPlaceType: "",
                experience:"",
                // companyName: "",
                // jobTitle: "",
                // location: "",
            },
            searchResultJobs: null,
            perPage: 10,
            totalJobs: null,
            totalPages: null,
            loading: false,
            startJob: null,
            endJob: null,
            search: "",
            currentPage: 1,
        },
    }

    const JobsReducer = (state, action) => {
        switch (action.type) {
            case "SET_PAGINATED_JOBS":

                const reducerData = action?.payload;
                console.log(reducerData, "fgfggfgfdff")
                return {
                    ...state,
                    JobsList: {
                        ...state.JobsList,
                        PaginatedJobs: reducerData?.reversedjobs,
                        totalPages: reducerData?.totalPages,
                        searchResultJobs: reducerData?.searchResultJobs,
                        perPage: reducerData?.pageSize,
                        startJob: reducerData?.startJob,
                        endJob: reducerData?.endJob,
                        totalJobs: reducerData?.totalJobs,
                        currentPage: reducerData?.currentPage,
                    }
                }
            case "SET_LOADING":
                return {
                    ...state,
                    JobsList: {
                        ...state.JobsList,
                        loading: !state.JobsList.loading,

                    }
                }

            case "SET_SEARCH":
                return {
                    ...state,
                    JobsList: {
                        ...state.JobsList,
                        search: action?.payload?.data,
                        currentPage: 1,
                    }
                }


            case "SET_PER_PAGE":
                return {
                    ...state,
                    JobsList: {
                        ...state.JobsList,
                        perPage: action?.payload?.data,
                        currentPage: 1,
                    }
                }

            case "SET_CUSTOM_PAGE":
                return {
                    ...state,
                    JobsList: {
                        ...state.JobsList,
                        currentPage: action?.payload?.data,
                    }
                }

            case "SET_FILTERS":
                return {
                    ...state,
                    JobsList: {
                        ...state.JobsList,
                        filters: action?.payload?.data,
                        currentPage: 1,
                    }
                }

            case "DELETE_JOB_POSITION":
                let id = action?.payload?.id;
                id = parseInt(id);
                return {
                    ...state,
                    JobsList: {
                        ...state.JobsList,
                        PaginatedJobs: state.JobsList.PaginatedJobs.filter((job) => job.id !== id)
                    }
                }
            default:
                return state;
        }
    }



    const [JobsState, DispatchJobs] = useReducer(JobsReducer, intialState);

    const getPaginatedJobsList = useCallback(async () => {
        const { currentPage, perPage, search, filters } = JobsState.JobsList;

        DispatchJobs({ type: "SET_LOADING" });
        try {
            const { status, data } = await ERPApi.get(`${process.env.REACT_APP_API_URL}/jobs/job-postings?page=${currentPage}&pageSize=${perPage}&search=${search}&filter[job_type]=${filters.jobType}&filter[workplace_type]=${filters.workPlaceType}&filter[experience]=${filters.experience}`);
            console.log(status, data, "joblistdata");

            if (status === 200) {
                DispatchJobs({ type: "SET_PAGINATED_JOBS", payload: data });
            }

        } catch (error) {
            console.error("Failed to fetch paginated jobs:", error);
        } finally {
            DispatchJobs({ type: "SET_LOADING" });
        }
    }, [JobsState?.JobsList?.currentPage,
    JobsState?.JobsList?.perPage,
    JobsState?.JobsList?.search,
    JobsState?.JobsList?.filters]);


    useEffect(() => {
        getPaginatedJobsList();
    }, []);

    useEffect(() => {
        getPaginatedJobsList();
    }, [JobsState?.JobsList?.search,
    JobsState?.JobsList?.currentPage,
    JobsState?.JobsList?.perPage,
    JobsState?.JobsList?.filters]);



    return {
        JobsState, DispatchJobs, getPaginatedJobsList
    }

}
export default JoblistProvider;