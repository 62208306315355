import { Route, Routes } from "react-router-dom";
import BatchManagement from "../../componentLayer/pages/batchManagement/batchDashboard/BatchManagement";
import Attendances from "../../componentLayer/pages/batchManagement/attendances/Attendances";
import Trainers from "../../componentLayer/pages/batchManagement/trainers/Trainers";
import Batches from "../../componentLayer/pages/batchManagement/batches/Batches";
import BatchView from "../../componentLayer/pages/batchManagement/batches/BatchView";
import AttendanceView from "../../componentLayer/pages/batchManagement/attendances/AttendanceView";
import LaunchButton from "../../componentLayer/pages/batchManagement/batches/LaunchButton";
import RouteBlocker from "../../rbac/RouteBlocker";
import Error from "../../componentLayer/pages/Error/Error";
function BatchManagementRoutes() {

  return (
    <Routes>
      <Route path="/dashboard" element={< BatchManagement />} />
      <Route path='*' element={<Error/>} />

      <Route path="/batches" element={
        <RouteBlocker
          requiredModule="Batch Management"
          requiredPermission="all"
          submenumodule="Batches"
          submenuReqiredPermission="canRead"
        >
          <Batches />
        </RouteBlocker>
      } />


      <Route path="/attendances" element={
        <RouteBlocker
          requiredModule="Batch Management"
          requiredPermission="all"
          submenumodule="Attendance"
          submenuReqiredPermission="canRead"
        >
          <Attendances />
        </RouteBlocker>
      } />

      <Route path="/trainers" element={
        <RouteBlocker
          requiredModule="Batch Management"
          requiredPermission="all"
          submenumodule="Trainer"
          submenuReqiredPermission="canRead"
        >
          <Trainers />
        </RouteBlocker>
      } />

      <Route path="/batcheview" element={
        <RouteBlocker
          requiredModule="Batch Management"
          requiredPermission="all"
          submenumodule="Batches"
          submenuReqiredPermission="canRead"
        >
          <BatchView />
        </RouteBlocker>
      } />

      <Route path="/launchbutton" element={<LaunchButton />} />
      <Route path="/attendanceview" element={<AttendanceView />} />
    </Routes>
  );
}
export default BatchManagementRoutes;