export const  SET_DASHBOARD = "SET_DASHBOARD";
export const  SET_LOADING ="SET_LOADING";
export const SET_TOTAL_ENROLLMENT_DETAILS ="SET_TOTAL_ENROLLMENT_DETAILS";
export const SET_FILTER_DATE = "SET_FILTER_DATE";

// export const SET_COUNCELLOR_LIST ="SET_COUNCELLOR_LIST";

export const SET_PAGINATED_TOTAL_ENROLLMENT_DETAILS ="SET_PAGINATED_TOTAL_ENROLLMENT_DETAILS";
export const SET_STUDENTS_LISTS ="SET_STUDENTS_LISTS";
export const SET_PAGINATED_FEE_DETAILS ="SET_PAGINATED_FEE_DETAILS"
export const SET_PAGINATED_TOTAL_USERS ="SET_PAGINATED_TOTAL_USERS";

export const SET_STUDENT_DETAILS ="SET_STUDENT_DETAILS"

export const SET_PAGINATED_TOTAL_FEE_DETAILS ="SET_PAGINATED_TOTAL_FEE_DETAILS"
export const SET_BRANCH_DETAILS ="SET_BRANCH_DETAILS";


export const SET_COUNCELLOR_DETAILS ="SET_COUNCELLOR_DETAILS"