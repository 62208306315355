import React from 'react'
import Button from "../../../components/button/Button";
import GateKeeper from '../../../../rbac/GateKeeper';
import { RiEdit2Line } from "react-icons/ri";
import { FaDownload } from "react-icons/fa";
const Curriculum = () => {
    return (
        <div>
            <div>
                <div className="container-fluid mt-5">
                    <div className="">
                        <div className="">
                            <div className="card border-0">
                                <div className="row p-4">
                                    <div className="form-group text-start col-lg-4 col-md-6">
                                        <label className="form-label fs-s fw-medium text_color">
                                            Course
                                        </label>
                                        <select
                                            className="form-select form-control text_color input_bg_color select"
                                            aria-label="Default select example"
                                            placeholder="course*"
                                            name="course"
                                            id="course"

                                            required
                                        >
                                            <option value="" disabled selected>
                                                {" "}
                                                Select the Course{" "}
                                            </option>
                                            <option value="" >
                                                {" "}
                                                Java
                                            </option>
                                            <option value="" >
                                                {" "}
                                                Python
                                            </option>
                                            <option value="" >
                                                {" "}
                                                salesforce
                                            </option>
                                            <option value="" >
                                                {" "}
                                                ReactJs
                                            </option>

                                        </select>
                                    </div>
                                    <div className="form-group text-start col-lg-4 col-md-6">
                                        <label
                                            htmlhtmlFor="rphoto"
                                            className="form-label fs-s text_color"
                                        >
                                            Upload File
                                        </label>
                                        <input

                                            id="rphoto"
                                            className='form-select form-control text_color input_bg_color select'
                                            type="file"

                                        // value={imageName || ""}
                                        />

                                    </div>
                                    <div className="col-2 mt-4 pt-2 ">
                                        <Button className="btn btn_primary ">Submit</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header'>
                                <div className="card-body">
                                    <div className="table-responsive table-card table-container table-scroll border-0">
                                        <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                                            <thead>
                                                <tr className="">

                                                    <th
                                                        scope="col"
                                                        className="fs-13 lh-xs fw-600 "
                                                    >
                                                        S.No
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="fs-13 lh-xs  fw-600  "
                                                    >
                                                        Course
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="fs-13 lh-xs  fw-600  "
                                                    >
                                                        Syllabus
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="fs-13 lh-xs  fw-600  "
                                                    >
                                                        Action
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody className="">
                                                <tr>
                                                    <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                        01
                                                    </td>
                                                    <td className="fs-13 black_300  lh-xs bg_light ">
                                                        Java
                                                    </td>
                                                    <td className="fs-13 black_300  lh-xs bg_light ">
                                                        Java Programming
                                                    </td>
                                                    <td className="fs-14 text_mute bg_light lh-xs ">
                                                        <GateKeeper requiredModule="User Mangement" requiredPermission="all" submenumodule="User Details" submenuReqiredPermission="canRead">
                                                            <FaDownload className="ms-2 sidebar_color fs-s "
                                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Download"
                                                            />
                                                        </GateKeeper>
                                                        <GateKeeper requiredModule="User Mangement" requiredPermission="all" submenumodule="User Details" submenuReqiredPermission="canUpdate">
                                                            <RiEdit2Line className="edit_icon ms-4" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" />
                                                        </GateKeeper>


                                                    </td>

                                                </tr>
                                                {/* 1st row */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className=" mt-3 align-items-center d-flex justify-content-between row text-center text-sm-start">
                                        <div className="col-sm">
                                            <div className="text_mute pagination-text">
                                                Showing <span className="fw-semibold">0</span>
                                                {"  "}
                                                to{"  "}
                                                <span className="fw-semibold">0</span>
                                                {"  "}
                                                of{"  "}
                                                <span className="fw-semibold">
                                                    {"  "}

                                                </span>{" "}
                                                Results
                                            </div>
                                        </div>
                                        <div className="col-sm-auto mt-3 mt-sm-0 d-flex pagination-res">
                                            <div className="mt-2">
                                                <select

                                                    className="form-select form-control me-3 input_bg_color pagination-select "
                                                    aria-label="Default select example"
                                                    required

                                                >
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="75">75</option>
                                                    <option value="100">100</option>
                                                    <option value="200">200</option>
                                                    <option value="300">300</option>
                                                    <option value="500">500</option>
                                                </select>
                                            </div>
                                            <ul className="mt-2 pagination pagination-separated pagination-sm mb-0 justify-content-center">
                                                <li className="page-item p-1">
                                                    <button

                                                        className={`border border-1 rounded `}
                                                    >
                                                        <span className="">←</span>
                                                    </button>
                                                </li>
                                                <li className="page-item p-1">
                                                    <button
                                                        className={`border border-1 rounded `}
                                                    >
                                                        <span className="">→</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Curriculum