import { ERPApi } from "../../../../../../serviceLayer/interceptor";


// Active Batches
export const getActiveBatches = async (currentPage, perPage, search, filters) => {
    // const url = `${process.env.REACT_APP_API_URL}/student/list_students`;

    console.log(currentPage, perPage, search, "goooooosddd")
    const url = `${process.env.REACT_APP_API_URL}/student/list_students?page=${currentPage}&pageSize=${perPage}&search=${search}`
    return ERPApi.get(url)
}

// Upcoming Batches
export const getUpCompingBatches = async (currentPage, perPage, search, filters) => {
    const url = `${process.env.REACT_APP_API_URL}/jobs/job-postings?page=${currentPage}&pageSize=${perPage}&search=${search}`;
    return ERPApi.get(url)
}

// Completed Batches 
export const getCompltedBatches = async (currentPage, perPage, search, filters ) => {
    const url = `${process.env.REACT_APP_API_URL}/jobs/applications?page=${currentPage}&pageSize=${perPage}&search=${search}`;
    return ERPApi.get(url)
} 