
import React, { useEffect } from "react";
import "../../../../assets/css/Table.css"
import { Link } from "react-router-dom";
import { useState } from "react";
import { MdFilterList } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import { FaUsers, FaWpforms } from "react-icons/fa6";
import { FaIndianRupeeSign } from "react-icons/fa6";
import ReactApexChart from "react-apexcharts";
import { FiArrowDownRight } from "react-icons/fi";
import { MdArrowOutward } from "react-icons/md";
import { TbMoneybag } from "react-icons/tb";
import Button from "../../../components/button/Button"
import "react-datepicker/dist/react-datepicker.css";
import CountUp from "../../../../utils/CountUp";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { toast } from "react-toastify";
import GateKeeper from "../../../../rbac/GateKeeper";
import { Offcanvas } from "bootstrap";
import { useBranchContext } from "../../../../dataLayer/hooks/useBranchContext";
import DashboardProvider from "./DashboardUtils/DashboardProvider";


const StudentManagementDasboard = () => {

  const { BranchState } = useBranchContext();
  const { Dashboardstate: {
    TotalEnrollementDetails,
    TotalFeeDetails,
    BranchwiseCouncellers,
    CouncellerwiseStudents,
    TotalUsersInDashboad,
    UsersListInBranchWise,
    FeeDetailsBranchwiseCouncellers,
    FeeDetailsCouncellerwiseStudents,
    TotalEnrollmentGraph,
    TotalFeeDetailsGraph,
  },
    DispatchDashboard,
  } = DashboardProvider();


  const [userData, setUserData] = useState(() => {
    const data = JSON.parse(localStorage.getItem("data"));
    return data || "";
  });


  useEffect(() => {
    function settingCouncellorname() {
      if (
        userData?.user?.profile === "Counsellor" ||
        userData?.user?.profile === "counsellor"
      ) {
        let enquirytakenby = userData?.user?.fullname;
        let counceller = { enquirytakenby };
        DispatchDashboard({
          type: "SET_COUNCELLOR_DETAILS",
          payload: {
            data: counceller,
            context: "TOTAL_ENROLLMENTS_DETAILS_COUNCELLOR_WISE_STUDENTS",
          },
        });

        DispatchDashboard({
          type: "SET_COUNCELLOR_DETAILS",
          payload: {
            data: counceller,
            context: "FEE_DETAILS_COUNCELLORS_WISE_STUDENTS",
          },
        });
      }
    }
    settingCouncellorname();
  }, []);


  //------------------------------Total Enrollemts Tab Details---------------------------------------------------
  const [filterDatesTotalEnrollments, setFilterDatesTotalEnrollments] =
    useState({
      fromDate: "",
      toDate: "",
    });

  // handle Branch submit in TotalEnrollemts
  const [activeBranch, setActiveBranch] = useState(null);
  const [activeCouncellor, setActiveCouncellor] = useState(null);
  const [activeUsersInTotalUsers, setactiveUsersInTotalUsers] = useState(null);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFilterDatesTotalEnrollments((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filterResetTotalEnrollementDates = () => {
    setFilterDatesTotalEnrollments({
      fromDate: "",
      toDate: "",
    });
    DispatchDashboard({
      type: "SET_FILTER_DATE",
      payload: {
        data: {
          fromDate: "",
          toDate: "",
        },
        context: "TOTAL_ENROLLMENTS_DETAILS",
      },
    });

  };

  const filterSubmitTotalEnrollemntDates = () => {
    if (!filterDatesTotalEnrollments.fromDate && !filterDatesTotalEnrollments.toDate) {
      toast.error("Please fill in at least one filter criteria.");
      return;
    }
    DispatchDashboard({
      type: "SET_FILTER_DATE",
      payload: {
        data: {
          fromDate: filterDatesTotalEnrollments?.fromDate,
          toDate: filterDatesTotalEnrollments?.toDate,
        },
        context: "TOTAL_ENROLLMENTS_DETAILS",
      },
    });
    const offcanvasElement = document.getElementById('offcanvasRight');
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();


  };

  const handleBranchSubmit = (branch) => {
    setActiveBranch(branch);
    const BranchDetails = { branch };
    DispatchDashboard({
      type: "SET_BRANCH_DETAILS",
      payload: {
        data: BranchDetails,
        context: "TOTAL_ENROLLMENTS_DETAILS_BRANCH_WISE_COUNCELLERS",
      },
    });
  };

  const handleCouncellorSubmit = (enquirytakenby) => {
    setActiveCouncellor(enquirytakenby);
    const counceller = { enquirytakenby };
    DispatchDashboard({
      type: "SET_COUNCELLOR_DETAILS",
      payload: {
        data: counceller,
        context: "TOTAL_ENROLLMENTS_DETAILS_COUNCELLOR_WISE_STUDENTS",
      },
    });
  };

  //------------------Total fee Details tab----------------

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 2);
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  const formattedFirstDay = firstDay.toISOString().slice(0, 10); // Format to YYYY-MM-DD 
  const formattedLastDay = lastDay.toISOString().slice(0, 10);
  const currentMonthYear = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(today);



  const [filteredDatesFeeDetails, setFilteredDatesFeeDetails] = useState({
    fromDate: formattedFirstDay,
    toDate: formattedLastDay,
    admissionFromDate: "",
    admissionToDate: "",
  });
  const [activeBranchFeeDetails, setactiveBranchFeeDetails] = useState(null);
  const [activeCouncellorFeeDetails, setactiveCouncellorFeeDetails] =
    useState(null);

  useEffect(() => {
    DispatchDashboard({
      type: "SET_FILTER_DATE",
      payload: {
        data: {
          fromDate: filteredDatesFeeDetails?.fromDate,
          toDate: filteredDatesFeeDetails?.toDate,
          admissionFromDate: filteredDatesFeeDetails?.admissionFromDate,
          admissionToDate: filteredDatesFeeDetails?.admissionToDate,
        },
        context: "TOTAL_FEE_DETAILS",
      },
    });
  }, []);
  const handleDateChangeInFeeDeatils = (e) => {
    const { name, value } = e.target;
    setFilteredDatesFeeDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filterReset_FeeDetailsDates = () => {
    setFilteredDatesFeeDetails({
      fromDate: formattedFirstDay,
      toDate: formattedLastDay,
      admissionFromDate: "",
      admissionToDate: "",
    });
    DispatchDashboard({
      type: "SET_FILTER_DATE",
      payload: {
        data: {
          fromDate: formattedFirstDay,
          toDate: formattedLastDay,
          admissionFromDate: "",
          admissionToDate: "",
        },
        context: "TOTAL_FEE_DETAILS",
      },
    });

  };

  const filterSubmit_FeeDetailsDates = () => {
    if (!filteredDatesFeeDetails.fromDate && !filteredDatesFeeDetails.toDate && !filteredDatesFeeDetails.admissionFromDate && !filteredDatesFeeDetails.admissionToDate) {
      toast.error("Please fill in at least one filter criteria.");
      return;
    }
    DispatchDashboard({
      type: "SET_FILTER_DATE",
      payload: {
        data: {
          fromDate: filteredDatesFeeDetails?.fromDate,
          toDate: filteredDatesFeeDetails?.toDate,
          admissionFromDate: filteredDatesFeeDetails?.admissionFromDate,
          admissionToDate: filteredDatesFeeDetails?.admissionToDate,
        },
        context: "TOTAL_FEE_DETAILS",
      },
    });
    const offcanvasElement = document.getElementById('offcanvasRightOne');
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance?.hide();


  };

  const handleBranchSubmitFeeDetails = (branch) => {
    setactiveBranchFeeDetails(branch);
    const BranchDetails = { branch };
    DispatchDashboard({
      type: "SET_BRANCH_DETAILS",
      payload: {
        data: BranchDetails,
        context: "FEE_DETAILS_BRANCH_WISE_COUNCELLORS",
      },
    });
  };

  const handleCouncellorSubmitfeeDetails = (enquirytakenby) => {
    setactiveCouncellorFeeDetails(enquirytakenby);
    const counceller = { enquirytakenby };

    DispatchDashboard({
      type: "SET_COUNCELLOR_DETAILS",
      payload: {
        data: counceller,
        context: "FEE_DETAILS_COUNCELLORS_WISE_STUDENTS",
      },
    });
  };

  // ---------------------Total Users tab---------------------------

  const handleBranchSubmitInUsers = (branch) => {
    setactiveUsersInTotalUsers(branch);
    const branchName = { branch };
    DispatchDashboard({
      type: "SET_BRANCH_DETAILS",
      payload: {
        data: branchName,
        context: "ALL_USERS_LIST_IN_BRANCH_WISE",
      },
    });
  };

  // Enrollement graph--------------
  const [formDataGraph, setFormDataGraph] = useState({
    branch: "",
  });
  const HandleBranchGraph = (e) => {
    const { name, value } = e.target;
    setFormDataGraph((prev) => ({
      ...prev,
      [name]: value,
    }));
    const branch = e.target.value;

    const branchDetails = { branch };
    DispatchDashboard({
      type: "SET_BRANCH_DETAILS",
      payload: {
        data: branchDetails,
        context: "TOTAL_ENROLLMENTS_GRAPH",
      },
    });
  };

  useEffect(() => {
    if (TotalEnrollmentGraph?.yearlyEnrollments) {
      setChartData((prev) => ({
        ...prev,
        Enrollements: transformData([TotalEnrollmentGraph?.yearlyEnrollments]),
      }));
    }
  }, [TotalEnrollmentGraph?.yearlyEnrollments]);

  const transformData = (monthlyData) => {
    const categories = Object.keys(monthlyData[0]);
    const data = categories.map((month) => monthlyData[0][month]);
    return [{ data }];
  };


  const [chartData, setChartData] = useState({
    Enrollements: [
      {
        name: "Total Enrollments Count",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -18,
        style: {
          fontSize: '12px',
          colors: ['#fff'],
        },
        formatter: function (val) {
          return val;
        }
      },
      colors: ["#405189"],
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {},
        axisTicks: {
          show: true,
          style: {
            colors: "#405189",
            lineAtIndex: 1,
            beginAtZero: true,
          },
        },
      },
      yaxis: {
        gridLines: {
          zeroLineColor: "#ffcc33",
        },
      },
    },
  });

  const enrollementDifference = TotalEnrollmentGraph?.difference;
  const formattedValueEnrollementPercent =
    enrollementDifference !== null
      ? enrollementDifference.toFixed(2).padStart(5, "0")
      : "00.00";

  // FeeDetails Graph ---------
  const feeRecivedDifference = TotalFeeDetailsGraph?.feeReceiveddifference;
  const formatedValueFeeRecivedPercent =
    feeRecivedDifference !== null
      ? feeRecivedDifference.toFixed(2).padStart(5, "0")
      : "00:00";

  const feeYetToReceiveDifference =
    TotalFeeDetailsGraph?.feeYetToReceiveddifference;
  const formatedValueFeeYettoRecivePercent =
    feeYetToReceiveDifference !== null
      ? feeYetToReceiveDifference.toFixed(2).padStart(5, "0")
      : "00:00";

  const [branchGraph, setBranchGraph] = useState({
    branch: "",
  });

  const handleBranchFeeDetailGraph = (e) => {
    const { name, value } = e.target;
    setBranchGraph((prev) => ({
      ...prev,
      [name]: value,
    }));
    const branch = e.target.value;

    const branchDetails = { branch };
    DispatchDashboard({
      type: "SET_BRANCH_DETAILS",
      payload: {
        data: branchDetails,
        context: "TOTAL_FEEDETAILS_GRAPH",
      },
    });
  };

  useEffect(() => {
    if (
      TotalFeeDetailsGraph?.yearlyFeeReceived &&
      TotalFeeDetailsGraph?.yearlyFeeYetRecevie
    ) {
      setChartDatas((prev) => ({
        ...prev,
        Enrollments: {
          ...prev.Enrollments,
          series: transformData1(
            [TotalFeeDetailsGraph?.yearlyFeeReceived],
            [TotalFeeDetailsGraph?.yearlyFeeYetRecevie]
          ),
        },
      }));
    }
  }, [
    TotalFeeDetailsGraph?.yearlyFeeReceived,
    TotalFeeDetailsGraph?.yearlyFeeYetRecevie,
  ]);
  const transformData1 = (feeReceive, feeYetToRecive) => {
    const feeCategories = Object.keys(feeReceive[0]);
    const enrollmentCategories = Object.keys(feeYetToRecive[0]);
    const feeReceivedValues = feeCategories.map(
      (month) => feeReceive[0][month]
    );
    const feeYetToReciveValues = enrollmentCategories.map(
      (month) => feeYetToRecive[0][month]
    );

    const transformedData = [
      { name: "Fee Received", data: feeReceivedValues, color: "#405189" },
      { name: "Fee Yet Recevie", data: feeYetToReciveValues, color: "#eb6329" },
    ];
    return transformedData;
  };
  const [chartDatas, setChartDatas] = useState({
    Enrollments: {
      series: [
        {
          name: "FeeReceived",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          color: "#405189",
        },
        {
          name: "FeeYetRecevie",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          color: "#eb6329",
        },
      ],
    },
    yaxis: [
      {
        min: 1000000,
        max: 500000000,
        tickAmount: 4,
        logarithmic: true,
        seriesName: "FeeReceived",
      },
      {
        min: 1000000,
        max: 500000000,
        opposite: true,
        tickAmount: 4,
        seriesName: "FeeYetRecevie",
      },
    ],
    options: {
      yAxes: [
        {
          gridLines: {
            zeroLineColor: "#eb6329",
          },
        },
      ],
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "100%",
          dataLabels: {
            position: 'top', // Position the data labels inside the bars
          },
        },
      },
      colors: ["#405189", "#eb6329"],
      dataLabels: {
        enabled: false, // Enable data labels
        offsetY: -18,
        style: {
          fontSize: '12px', // Decrease font size here
          colors: ['#fff'],
        },
        formatter: function (val) {
          return val;
        }
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {},
        axisTicks: {
          show: true,
          style: {
            colors: "#405189",
            lineAtIndex: 1,
            beginAtZero: true,
          },
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
      },

      responsive: [
        {
          breakpoint: 1000,
          options: {
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  ticks: {
                    autoSkip: false,
                    minRotation: 45,
                    maxRotation: 90,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    autoSkip: false,
                    rotation: -45,
                  },
                },
              ],
            },

          },
        },
      ],
    },
  });


  //  Greeeting Message
  const getCurrentTime = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return "Morning";
    else if (currentHour < 18) return "Afternoon";
    else return "Evening";
  };
  useEffect(() => {
    const greetingTime = getCurrentTime();
    console.log(`Current Time of Day: ${greetingTime}`);
  }, []);


  // handle the Tabs
  const [activeTabs, setActiveTable] = useState({
    TotalEnrollments: true,
    FeeDetails: false,
    FeeFollowUps: false,
    TotalUsers: false,
  });

  const handleTabs = (tab) => {
    setActiveTable(prevState => ({
      ...prevState,
      TotalEnrollments: tab === "TotalEnrollments",
      FeeDetails: tab === "FeeDetails",
      FeeFollowUps: tab === "FeeFollowUps",
      TotalUsers: tab === "TotalUsers",
    }));
  };




  return (
    <div>
      <div className="container-fluid mt-3">
        <div className="row mb-1 pb-1">
          <div className="col-12">
            <div className="d-flex align-items-lg-center flex-lg-row flex-column">
              <div className="flex-grow-1">
                <h4 className="fs-16 fw-500 black_300 mb-1">
                  Good {getCurrentTime()}, {userData?.user?.fullname}!
                </h4>
                <p className="fs-13 text-mute mb-0 mt-0 fw-100">
                  Here's what happened till now.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* 4 Dashboard Tabs*/}

        <ul
          className="row nav mb-3 nav-tabs nav-justified mb-3 nav-fill"
          id="pills-tab"
          role="tablist"
        >
          <GateKeeper
            requiredModule="Student Management"
            submenumodule="Enrolled Students"
            submenuReqiredPermission="canRead"
          >
            <li
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 nav-item mt-2"
              role="presentation"
            >
              <button
                className={`card nav-link card_animate ${activeTabs.TotalEnrollments ? "active" : ""}`}
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected={activeTabs.TotalEnrollments}
                onClick={() => handleTabs("TotalEnrollments")}

              >
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-start text-uppercase fw-medium text-mute text-truncate mt-1 fs-14">
                      Total Enrollments{" "}
                    </p>
                  </div>
                  <div className="flex-shrink-0 text-end">
                    <h5 className="text-success fs-14 mb-0">
                      {/* < MdArrowOutward className="text-success " /> */}
                      {/* +16.24 % */}
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-2 mb-2 w-100">
                  <div className="text-start">
                    <h4 className="fs-22 fw-semibold ff-secondary mb-4 display_no">
                      <span className="counter-value" data-target="559.25">
                        <CountUp finalValue={TotalEnrollmentGraph?.currentmonthEnrollments} />
                      </span>{" "}
                    </h4>
                    <Link to="" className="fs-12 mb-0">
                      View Enrollments
                    </Link>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-success-subtle rounded fs-3">
                      <FaWpforms className="text-success fs-20" />
                    </span>
                  </div>
                </div>
              </button>
            </li>
          </GateKeeper>

          <GateKeeper
            requiredModule="Student Management"
            submenumodule="Fee Details"
            submenuReqiredPermission="canRead"
          >
            <li
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 nav-item mt-2"
              role="presentation"
            >
              <button
                className={`card nav-link card_animate ${activeTabs.FeeDetails ? "active" : ""}`}
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected={activeTabs.FeeDetails}
                onClick={() => handleTabs("FeeDetails")}
              >

                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-start text-uppercase fw-medium text-mute text-truncate mt-1 fs-14">
                      Fee Details
                    </p>
                  </div>
                  <div className="flex-shrink-0 text-end">
                    <h5 className="text-danger fs-14 mb-0">
                      {/* <FiArrowDownRight className="text-danger" />
                    -3.57 % */}
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-end mt-2 mb-2 justify-content-between w-100">
                  <div className="text-start">
                    <h4 className="fs-22 fw-semibold ff-secondary mb-4 display_no">
                      <span className="counter-value" data-target="36894">
                        <LiaRupeeSignSolid />
                        <CountUp finalValue={TotalFeeDetailsGraph?.currentFeeReceived} />
                      </span>
                    </h4>
                    <Link to="" className="fs-12">
                      View Fee Details
                    </Link>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-info-subtle rounded fs-3">
                      <FaIndianRupeeSign className="light-blue-color fs-20" />
                    </span>
                  </div>
                </div>



              </button>
            </li>
          </GateKeeper>

          <GateKeeper
            requiredModule="Student Management"
            submenumodule="Fee Details"
            submenuReqiredPermission="canRead"
          >
            <li
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 nav-item mt-2"
              role="presentation"
            >
              <Link to={"/student/feedetailspage"}>
                <button
                  className={`card nav-link card_animate ${activeTabs.FeeFollowUps ? "active" : ""}`}
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected={activeTabs.FeeFollowUps}
                  onClick={() => handleTabs("FeeFollowUps")}
                >



                  <div className="d-flex align-items-center w-100 justify-content-between">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-start text-uppercase fw-medium text-mute text-truncate mt-1 fs-14">
                        Fee FollowUps
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      <h5 className="text-success fs-14 mb-0">
                        <i className="ri-arrow-right-up-line fs-14 align-middle"></i>{" "}
                        {/* +29.08 % */}
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-2 mb-2 w-100">
                    <div className="text-start">
                      <h4 className="fs-22 fw-semibold ff-secondary mb-4 display_no">
                        <span className="counter-value" data-target="183.35">
                          <LiaRupeeSignSolid />
                          <CountUp finalValue={TotalFeeDetailsGraph?.currentFeeYetToReceived} />
                        </span>
                      </h4>
                      <Link to={"/student/feedetailspage"} className="fs-12">
                        View Fee FollowUps
                      </Link>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-warning-subtle rounded fs-3">
                        <TbMoneybag className="text_yellow fs-20" />
                      </span>
                    </div>
                  </div>


                </button>
              </Link>
            </li>
          </GateKeeper>

          <GateKeeper
            requiredModule="User Mangement"
            requiredPermission="all"
            submenumodule="User Details"
            submenuReqiredPermission="canRead"
          >
            <li
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 nav-item mt-2"
              role="presentation"
            >
              <button
                className={`card nav-link card_animate ${activeTabs.TotalUsers ? "active" : ""}`}
                id="pills-fee-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-fee"
                type="button"
                role="tab"
                aria-controls="pills-fee"
                aria-selected={activeTabs.TotalUsers}
                onClick={() => handleTabs("TotalUsers")}
              >

                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-start text-uppercase fw-medium text-mute text-truncate mt-1 fs-14">
                      Total Users
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <h5 className="text-muted fs-14 mb-0">{/* +0.00 % */}</h5>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-2 mb-2 w-100">
                  <div className="text-start">
                    <h4 className="fs-22 fw-semibold ff-secondary mb-4 display_no">
                      <span className="counter-value" data-target="165.89">
                        <CountUp finalValue={TotalUsersInDashboad?.totalNoOfUsers} />
                      </span>
                    </h4>
                    <Link to="" className="fs-12">
                      View Total Users
                    </Link>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-primary-subtle rounded fs-3">
                      <FaUsers className="dwnld_icon fs-18" />
                    </span>
                  </div>
                </div>

              </button>
            </li>
          </GateKeeper>
        </ul>

        <div className="tab-content" id="pills-tabContent">

          {/* ---------------------------------------------------------------------- */}
          {/*---------------------  Total enrollments -------------------------------*/}
          {/* ---------------------------------------------------------------------- */}

          {
            activeTabs.TotalEnrollments && (
              <div
                className={`tab-pane fade ${activeTabs.TotalEnrollments ? "show active" : ""}`}
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div>
                  <div className="row">

                    {/* Enrollement Graph */}
                    <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body mb-3">
                          <div className=" d-flex justify-content-between">
                            <div>
                              {" "}
                              <h6 className="">Overall Status Graph</h6>
                            </div>
                            <div className="col-lg-3 mb-2">
                              {
                                userData && userData?.user && userData?.user?.profile !== "Counsellor" && userData?.user?.profile !== "counsellor" && userData?.user?.profile !== "Branch Manager" && (
                                  <select class="form-control fs-s bg-form text_color input_bg_color select form-select"
                                    aria-label=""
                                    placeholder=""
                                    id="branch"
                                    name="branch"
                                    value={formDataGraph?.branch}
                                    onChange={HandleBranchGraph}
                                    required
                                  >
                                    <option value="allbranches" selected >All</option>
                                    {BranchState.branches && BranchState.branches.length > 0
                                      ? BranchState.branches.map((item, index) => (
                                        <option key={index} value={item.fullname}>
                                          {item?.branch_name}
                                        </option>
                                      ))
                                      : null}
                                  </select>
                                )
                              }

                            </div>
                          </div>
                          <div className="row g-0 text-center text_background">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-3 border border-dashed border-start-1">
                                <h5 className="mb-1 fs-16 display_no">
                                  <span
                                    className="counter-value fw-500 black_500"
                                    data-target="7585"
                                  >

                                    <CountUp finalValue={TotalEnrollmentGraph?.totalEnrollments} />
                                  </span>
                                </h5>
                                <p className="mb-0 fs-7 text-mute bottom_space">
                                  Total Enrollments
                                </p>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1 fs-16 display_no">
                                  <span
                                    className="counter-value fw-500 black_500 "
                                    data-target="22.89"
                                  >
                                    <CountUp finalValue={TotalEnrollmentGraph?.lastMonthEnrollments} />
                                  </span>
                                </h5>
                                <p className="mb-0 fs-7 text-mute">
                                  Last Month Enrollments
                                </p>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1 fs-16 display_no">
                                  <span
                                    className="counter-value fw-500 black_500"
                                    data-target="367"
                                  >
                                    <CountUp finalValue={TotalEnrollmentGraph?.currentmonthEnrollments} />
                                  </span>
                                </h5>
                                <p className=" mb-0 fs-7 text-mute ">
                                  Current Enrollments
                                </p>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1 fs-16 display_no">
                                  <span
                                    className="counter-value fw-500 black_500"
                                    data-target="367"
                                  >
                                    {formattedValueEnrollementPercent &&
                                      formattedValueEnrollementPercent > 0 ? (
                                      <>
                                        <MdArrowOutward className="text-success fs-14  mb-0" />
                                        <span className="text-success">
                                          +{formattedValueEnrollementPercent} %
                                        </span>
                                      </>
                                    ) : formattedValueEnrollementPercent < 0 ? (
                                      <>
                                        <FiArrowDownRight className="text-danger" />
                                        <span className="text-danger">
                                          {formattedValueEnrollementPercent} %
                                        </span>
                                      </>
                                    ) : (
                                      <span className="">
                                        {formattedValueEnrollementPercent} %
                                      </span>
                                    )}
                                  </span>
                                </h5>
                                <p className=" mb-0 fs-7 text-mute bottom_space">
                                  Difference
                                </p>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div id="chart">
                              <ReactApexChart
                                className="apex-charts"
                                options={chartData.options}
                                series={chartData?.Enrollements}
                                type="bar"
                                height={350}
                              />
                            </div>
                            <div id="html-dist"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ProgressiveBar Branches Count   and percentage*/}
                    {
                      userData && userData?.user && userData?.user?.profile !== "Counsellor" && userData?.user?.profile !== "counsellor" && userData?.user?.profile !== "Branch Manager" && (
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 black_300">
                          <div className="card">
                            <div className="card-body">
                              <h6 className="mt-1"> Top Enrollers for {currentMonthYear && currentMonthYear}</h6>
                              <div className="table-container-one table-responsive ">
                                <div className="p-2 ">
                                  {TotalEnrollmentGraph?.progressPercentageBranches !=
                                    null &&
                                    Object.keys(
                                      TotalEnrollmentGraph?.progressPercentageBranches[0]
                                    ).map((branch) => {
                                      console.log(
                                        TotalEnrollmentGraph
                                          .progressPercentageBranches[0][branch],
                                        "kdhsgfjgjfsd"
                                      );
                                      const percentage =
                                        TotalEnrollmentGraph?.progressPercentageBranches[0][
                                          branch
                                        ]?.percent.toFixed(0);
                                      const enrollments =
                                        TotalEnrollmentGraph
                                          ?.progressPercentageBranches[0][branch]
                                          ?.enrollments;
                                      return (
                                        <div key={branch} className="">
                                          <p>
                                            {branch} {"("}
                                            {enrollments}
                                            {")"}
                                            <span className="float-end me-3">
                                              {percentage}%
                                            </span>
                                          </p>
                                          <div className="progress">
                                            <div
                                              className="progress-bar progress-bar-striped progress-bar-animated"
                                              role="progressbar"
                                              style={{ width: `${percentage}%` }}
                                              aria-valuenow={percentage}
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      )
                    }

                    {/* Progressivebar counsellors count and percentage */}
                    {
                      userData && userData?.user && (userData?.user?.profile === "Counsellor" || userData?.user?.profile === "counsellor" || userData?.user?.profile === "Branch Manager") && (
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 black_300">
                          <div className="card">
                            <div className="card-body">
                              <h6 className="mt-1">Top Enrollers for {currentMonthYear && currentMonthYear}</h6>
                              <div className="table-container-one table-responsive ">
                                <div className="p-2 ">
                                  {TotalEnrollmentGraph?.progressPercentageCounsellors !=
                                    null &&
                                    Object.keys(
                                      TotalEnrollmentGraph?.progressPercentageCounsellors[0]
                                    ).map((counsellor) => {
                                      console.log(
                                        TotalEnrollmentGraph
                                          .progressPercentageCounsellors[0][counsellor],
                                        "kdhsgfjgjfsd"
                                      );
                                      const percentage =
                                        TotalEnrollmentGraph?.progressPercentageCounsellors[0][
                                          counsellor
                                        ]?.percent.toFixed(0);
                                      const enrollments =
                                        TotalEnrollmentGraph
                                          ?.progressPercentageCounsellors[0][counsellor]
                                          ?.enrollments;
                                      return (
                                        <div key={counsellor} className="">
                                          <p>
                                            {counsellor} {"("}
                                            {enrollments}
                                            {")"}
                                            <span className="float-end me-3">
                                              {percentage}%
                                            </span>
                                          </p>
                                          <div className="progress">
                                            <div
                                              className="progress-bar progress-bar-striped progress-bar-animated"
                                              role="progressbar"
                                              style={{ width: `${percentage}%` }}
                                              aria-valuenow={percentage}
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>

                  {/* Enrollement Filters */}
                  <div className="mt-2 mb-2 mt-lg-0">
                    <div className="mb-0 card">
                      <div className="card-body">
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                          >
                            <MdFilterList className="me-1 mb-1 text_white" />
                            Filters
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="offcanvas offcanvas-end bg_light"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                  >
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body p-2">

                      <div>
                        <label className="form-label fs-s fw-medium text_color">
                          From Date
                        </label>
                        <input
                          className="  form-control input_bg_color  date_input_color "
                          id="rdob"
                          name="fromDate"
                          type="date"
                          value={filterDatesTotalEnrollments?.fromDate}
                          onChange={handleDateChange}
                        />
                      </div>

                      <div className="mt-2">
                        <label className="form-label fs-s fw-medium text_color">
                          To Date
                        </label>
                        <input
                          className=" form-control input_bg_color  date_input_color "
                          id="rdob"
                          name="toDate"
                          type="date"
                          onChange={handleDateChange}
                          value={filterDatesTotalEnrollments?.toDate}
                        />
                      </div>

                      <div className="position-absolute bottom-0 start-0 ms-2 mb-2">
                        <button
                          className="btn btn_primary"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          onClick={
                            filterResetTotalEnrollementDates
                          }
                        >
                          Clear
                        </button>
                      </div>
                      <div className="position-absolute bottom-0 end-0 me-2 mb-2">
                        <Button
                          className="btn btn_primary"
                          onClick={
                            filterSubmitTotalEnrollemntDates
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>


                  {/* Tables */}

                  {userData &&
                    userData?.user &&
                    userData?.user?.profile !== "Counsellor" &&
                    userData?.user?.profile !== "counsellor" && (
                      <div className="row">
                        {/* Enrollements Branch Table */}
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="card">
                            <div className="card-header">
                              <h6>Enrollments In Branch</h6>
                              <div className="card-body">
                                <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                                  <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                                    <thead>
                                      <tr className="">
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600  "
                                        >
                                          S.No
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600  "
                                        >
                                          Branch
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600 text-truncate"
                                        >
                                          Enrollments
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600 text-truncate"
                                        >
                                          Booking Amount
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600  text-truncate"
                                        >
                                          Fee Received
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600 text-truncate"
                                        >
                                          Fee Yet To Receive
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {TotalEnrollementDetails?.PaginatedBranchs &&
                                        TotalEnrollementDetails?.PaginatedBranchs
                                          ?.length > 0 ? (
                                        TotalEnrollementDetails?.loading ? (
                                          "loading..."
                                        ) : (
                                          TotalEnrollementDetails?.PaginatedBranchs?.map(
                                            (item, index) => {
                                              const BranchDetails = item?.branch;

                                              return (
                                                <tr
                                                  className={
                                                    activeBranch === item?.branch
                                                      ? "table-active"
                                                      : ""
                                                  }
                                                >
                                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                    {index + 1}
                                                  </td>
                                                  <td
                                                    className="fs-13 black_300  lh-xs bg_light "
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() =>
                                                      handleBranchSubmit(
                                                        BranchDetails
                                                      )
                                                    }
                                                  >
                                                    {item?.branch}
                                                  </td>

                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.enrollments}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.finalTotal?.toLocaleString("en-IN")}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.feeReceived?.toLocaleString("en-IN")}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.feeYetToReceive?.toLocaleString("en-IN")}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                            No data
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Enrollements Top rated counceller &&  Barch wise Councellors Table */}

                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="card" id="navbar-example2">
                            <div className="card-header">
                              <h6>Enrollments By Counsellors</h6>
                              <div className="card-body">
                                <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                                  <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                                    <thead>
                                      <tr className="">
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600  "
                                        >
                                          S.No
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600  "
                                        >
                                          Counsellors
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600 text-truncate"
                                        >
                                          Enrollments
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600 text-truncate "
                                          style={{ maxWidth: "100px" }}
                                          title="  Booking Amount"
                                        >
                                          Booking Amount
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600  text-truncate "
                                          style={{ maxWidth: "100px" }}
                                          title="   Fee Received  "
                                        >
                                          Fee Received
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600 text-truncate "
                                          style={{ maxWidth: "100px" }}
                                          title="     Fee Yet To Received  "
                                        >
                                          Fee Yet To Received
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>

                                      {BranchwiseCouncellers?.paginatedBranchwiseCouncellers &&
                                        BranchwiseCouncellers
                                          ?.paginatedBranchwiseCouncellers?.length >
                                        0 ? (
                                        BranchwiseCouncellers?.loading ? (
                                          "loading..."
                                        ) : (
                                          BranchwiseCouncellers?.paginatedBranchwiseCouncellers.map(
                                            (item, index) => {
                                              const councellerDetails =
                                                item?.enquirytakenby;
                                              return (
                                                <tr
                                                  className={
                                                    activeCouncellor ===
                                                      item?.enquirytakenby
                                                      ? "table-active"
                                                      : ""
                                                  }
                                                >
                                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                    {index + 1}
                                                  </td>
                                                  <td
                                                    className="fs-13 black_300  lh-xs bg_light text-truncate"
                                                    style={{
                                                      cursor: "pointer",
                                                      maxWidth: "100px",
                                                    }}

                                                    title={item?.enquirytakenby}
                                                  >
                                                    <a href="#scrollspyHeading" onClick={() =>
                                                      handleCouncellorSubmit(
                                                        councellerDetails
                                                      )
                                                    }>  {item?.enquirytakenby}</a>
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.enrollments}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.finalTotal?.toLocaleString("en-IN")}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.feeReceived?.toLocaleString("en-IN")}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.feeYetToReceive?.toLocaleString("en-IN")}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        )
                                      ) : TotalEnrollementDetails?.PaginatedTopRatedCouncellers &&
                                        TotalEnrollementDetails
                                          ?.PaginatedTopRatedCouncellers?.length >
                                        0 ? (
                                        TotalEnrollementDetails?.loading ? (
                                          "loading..."
                                        ) : (
                                          TotalEnrollementDetails?.PaginatedTopRatedCouncellers.map(
                                            (item, index) => {
                                              const councellerDetails =
                                                item?.enquirytakenby;
                                              return (
                                                <tr
                                                  className={
                                                    activeCouncellor ===
                                                      item?.enquirytakenby
                                                      ? "table-active"
                                                      : ""
                                                  }
                                                >
                                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                    {index + 1}
                                                  </td>
                                                  <td
                                                    className="fs-13 black_300  lh-xs bg_light text-truncate"
                                                    style={{
                                                      cursor: "pointer",
                                                      maxWidth: "100px",
                                                    }}
                                                    title={item?.enquirytakenby}

                                                  >
                                                    <a href="#scrollspyHeading" onClick={() =>
                                                      handleCouncellorSubmit(
                                                        councellerDetails
                                                      )
                                                    }> {item?.enquirytakenby}</a>
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.enrollments}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.finalTotal?.toLocaleString("en-IN")}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.feeReceived?.toLocaleString("en-IN")}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.feeYetToReceive?.toLocaleString("en-IN")}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            No data
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* Enrollement Student table */}

                  {CouncellerwiseStudents &&
                    CouncellerwiseStudents?.paginatedCouncellerwiseStudents?.length >
                    0 && (
                      <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="card scrollspy-example" data-bs-spy="scroll"
                            data-bs-target="#navbar-example2"
                            data-bs-offset="70"
                            tabindex="0">
                            <div className="card-header">
                              <h6>Student Details</h6>
                              <div className="card-body">
                                <div className="table-responsive dashboard-tables table-scroll table-card border-0">
                                  <table className="table table-hover table-centered align-middle table-nowrap equal-cell-table  table-hover">
                                    {" "}
                                    <thead>
                                      <tr className="" id="scrollspyHeading" >
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600 "
                                        >
                                          S.No
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600"
                                          style={{ maxWidth: "100px" }}
                                          title=" Student Name"
                                        >
                                          Student Name
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600 text-truncate "
                                          style={{ maxWidth: "100px" }}
                                          title=" course"
                                        >
                                          Course
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600 text-truncate "
                                          style={{ maxWidth: "100px" }}
                                          title=" Admission Date"
                                        >
                                          Admission Date
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600 text-truncate "
                                          style={{ maxWidth: "100px" }}
                                          title=" Booking Amount"
                                        >
                                          Booking Amount
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600 text-truncate "
                                          style={{ maxWidth: "100px" }}
                                          title=" Paid Fee"
                                        >
                                          Paid Fee
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600 text-truncate "
                                          style={{ maxWidth: "100px" }}
                                          title=" Fee Yet to Recieved"
                                        >
                                          Fee Yet To Receive
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {CouncellerwiseStudents &&
                                        CouncellerwiseStudents
                                          ?.paginatedCouncellerwiseStudents?.length >
                                        0 ? (
                                        CouncellerwiseStudents?.loading ? (
                                          "loading..."
                                        ) : (
                                          CouncellerwiseStudents?.paginatedCouncellerwiseStudents.map(
                                            (item, index) => {
                                              console.log(item, "itemitem")
                                              return (
                                                <tr>
                                                  <td className="fs-13 black_300  lh-xs bg_light ">
                                                    {index + 1}
                                                  </td>
                                                  <td
                                                    className="fs-13 black_300  lh-xs bg_light text-truncate"
                                                    style={{ maxWidth: "150px" }}
                                                  >
                                                    {item?.name}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.course}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.admissiondate}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.finalTotal?.toLocaleString("en-IN")}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.totalPaidAmount?.toLocaleString("en-IN")}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.dueAmount?.toLocaleString("en-IN")}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            No data
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )
          }



          {/* ---------------------------------------------------------------------- */}
          {/*------------------------------ Fee Details start----------------------- */}
          {/* ---------------------------------------------------------------------- */}

          {
            activeTabs.FeeDetails && (
              <div
                className={`tab-pane fade ${activeTabs.FeeDetails ? "show active" : ""}`}
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div>

                  <div className="row">

                    {/* FeeDetails Graph-1 */}
                    <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="card">
                        <div className="card-body mb-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              {" "}
                              <h6 className="">Overall Status Graph</h6>
                            </div>
                            <div className="col-lg-3 mb-2">
                              {
                                userData && userData?.user && userData?.user?.profile !== "Counsellor" && userData?.user?.profile !== "counsellor" && userData?.user?.profile !== "Branch Manager" && (
                                  <select class="form-control fs-s bg-form text_color input_bg_color select form-select"
                                    aria-label=""
                                    placeholder=""
                                    id="branch"
                                    name="branch"
                                    value={branchGraph?.branch}
                                    onChange={handleBranchFeeDetailGraph}
                                    required
                                  >
                                    <option value="allbranches" selected >All</option>
                                    {BranchState?.branches && BranchState?.branches.length > 0
                                      ? BranchState.branches.map((item, index) => (
                                        <option key={index} value={item.fullname}>
                                          {item?.branch_name}
                                        </option>
                                      ))
                                      : null}
                                  </select>
                                )
                              }
                            </div>
                          </div>
                          <div className="row g-0 text-center text_background">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-2 border border-dashed border-start-1 h-100">
                                <p className="mb-0 fs-7 text-mute">
                                  Total Fee Received
                                </p>
                                <h5
                                  className="mb-1 fs-16 display_no"
                                  data-target="7585"
                                >
                                  <span
                                    className="counter-value fw-500 black_500"
                                    data-target="367"
                                  >
                                    <LiaRupeeSignSolid />
                                    <CountUp finalValue={TotalFeeDetailsGraph?.totalFeeReceived} />
                                  </span>
                                </h5>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-2 border border-dashed border-start-0 h-100">
                                <p className="mb-0 fs-7 text-mute">
                                  Last Month Fee Received
                                </p>
                                <h5 className="mb-1 fs-16 display_no">
                                  <span
                                    className="counter-value fw-500 black_500"
                                    data-target="367"
                                  >
                                    <LiaRupeeSignSolid />
                                    <CountUp finalValue={TotalFeeDetailsGraph?.lastMonthFeeReceived} />
                                  </span>
                                </h5>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-2 border border-dashed border-start-0 h-100">
                                <p className="mb-0 fs-7 text-mute">
                                  Current Month Fee Received
                                </p>
                                <h5 className="mb-1 fs-16 display_no">
                                  <span
                                    className="counter-value fw-500 black_500"
                                    data-target="367"
                                  >
                                    <LiaRupeeSignSolid />
                                    <CountUp finalValue={TotalFeeDetailsGraph?.currentFeeReceived} />
                                  </span>
                                </h5>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-2 border border-dashed border-start-0 h-100">
                                <div className="">
                                  <p className=" mb-0 fs-7 text-mute ">
                                    Fee Received Difference
                                  </p>
                                  <h5 className="mb-1 fs-16 display_no">
                                    <span
                                      className="counter-value fw-500 black_500"
                                      data-target="367"
                                    >
                                      {formatedValueFeeRecivedPercent &&
                                        formatedValueFeeRecivedPercent > 0 ? (
                                        <>
                                          <MdArrowOutward className="text-success fs-14  mb-0" />
                                          <span className="text-success">
                                            +{formatedValueFeeRecivedPercent} %
                                          </span>
                                        </>
                                      ) : formatedValueFeeRecivedPercent < 0 ? (
                                        <>
                                          <FiArrowDownRight className="text-danger" />
                                          <span className="text-danger">
                                            {formatedValueFeeRecivedPercent} %
                                          </span>
                                        </>
                                      ) : (
                                        <span className="">
                                          {formatedValueFeeRecivedPercent} %
                                        </span>
                                      )}
                                    </span>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* --- */}
                          <div className="row g-0 text-center text_background">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-2 border border-dashed border-start- h-100">
                                <p className="mb-0 fs-7 text-mute">
                                  Total Fee Yet To Receive
                                </p>
                                <h5
                                  className="mb-1 fs-16 display_no"
                                  data-target="7585"
                                >
                                  <span
                                    className="counter-value fw-500 black_500"
                                    data-target="367"
                                  >
                                    <LiaRupeeSignSolid />
                                    <CountUp finalValue={TotalFeeDetailsGraph?.totalFeeYetToReceive} />
                                  </span>
                                </h5>
                              </div>
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-2 border border-dashed border-start-0 h-100">
                                <p className="mb-0 fs-7 text-mute">
                                  Last Month Fee Yet to Receive
                                </p>
                                <h5 className="mb-1 fs-16 display_no">
                                  <span
                                    className="counter-value fw-500 black_500"
                                    data-target="367"
                                  >
                                    <LiaRupeeSignSolid />
                                    <CountUp finalValue={TotalFeeDetailsGraph?.lastMonthFeeYetToReceived} />
                                  </span>
                                </h5>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-2 border border-dashed border-start-0 h-100">
                                <p className=" mb-0 fs-7 text-mute ">
                                  Current Month Fee Yet to Receive
                                </p>
                                <h5 className="mb-1 fs-16 display_no">
                                  <span
                                    className="counter-value fw-500 black_500"
                                    data-target="367"
                                  >
                                    <LiaRupeeSignSolid />
                                    <CountUp finalValue={TotalFeeDetailsGraph?.currentFeeYetToReceived} />
                                  </span>
                                </h5>
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                              <div className="p-2 border border-dashed border-start-0 h-100">
                                <p className="mb-0 fs-7 text-mute">
                                  Fee Yet to Receive Difference
                                </p>
                                <h5 className="mb-1 fs-16 display_no">
                                  <span
                                    className="counter-value fw-500 black_500"
                                    data-target="367"
                                  >
                                    {formatedValueFeeYettoRecivePercent &&
                                      formatedValueFeeYettoRecivePercent > 0 ? (
                                      <>
                                        <MdArrowOutward className="text-success fs-14  mb-0" />
                                        <span className="text-success">
                                          +{formatedValueFeeYettoRecivePercent} %
                                        </span>
                                      </>
                                    ) : formatedValueFeeYettoRecivePercent < 0 ? (
                                      <>
                                        <FiArrowDownRight className="text-danger" />
                                        <span className="text-danger">
                                          {formatedValueFeeYettoRecivePercent} %
                                        </span>
                                      </>
                                    ) : (
                                      <span className="">
                                        {formatedValueFeeYettoRecivePercent} %
                                      </span>
                                    )}
                                  </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div id="chart">
                            <ReactApexChart
                              className="apex-charts"
                              options={chartDatas.options}
                              series={chartDatas?.Enrollments.series}
                              type="bar"
                              height={350}
                            />
                          </div>
                          <div id="html-dist"></div>
                        </div>
                      </div>
                    </div>

                    {/* FeeDetails ProgressiveBar For Branches */}
                    {
                      userData && userData?.user && userData?.user?.profile !== "Counsellor" && userData?.user?.profile !== "counsellor" && userData?.user?.profile !== "Branch Manager" && (
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 black_300">
                          <div className="card">
                            <div className="card-body">
                              <h6 className="mt-1">Top Fee Receivers for {currentMonthYear && currentMonthYear}</h6>

                              <div className="table-container-two table-responsive ">
                                <div className="p-2 ">

                                  {TotalFeeDetailsGraph?.progressiveFeeRecevied != null &&
                                    Object.keys(
                                      TotalFeeDetailsGraph?.progressiveFeeRecevied[0]
                                    ).map((branch) => {
                                      const percentage =
                                        TotalFeeDetailsGraph?.progressiveFeeRecevied[0][
                                          branch
                                        ]
                                          ?.toFixed(0)
                                          ?.toLocaleString();
                                      return (
                                        <div key={branch} className="">
                                          <p>
                                            {branch}
                                            <span className="float-end me-3">
                                              {percentage}%
                                            </span>
                                          </p>
                                          <div className="progress">
                                            <div
                                              className="progress-bar progress-bar-striped progress-bar-animated"
                                              role="progressbar"
                                              style={{ width: `${percentage}%` }}
                                              aria-valuenow={percentage}
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }

                    {/*FeeDetails ProgressiveBar For Counsellors */}
                    {
                      userData && userData?.user && (userData?.user?.profile === "Counsellor" || userData?.user?.profile === "counsellor" || userData?.user?.profile === "Branch Manager") && (
                        <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 black_300">
                          <div className="card">
                            <div className="card-body">
                              <h6 className="mt-1">Top Fee Receivers for {currentMonthYear && currentMonthYear}</h6>

                              <div className="table-container-two table-responsive ">
                                <div className="p-2 ">

                                  {TotalFeeDetailsGraph?.progressiveFeeReceviedCounsellors != null &&
                                    Object.keys(
                                      TotalFeeDetailsGraph?.progressiveFeeReceviedCounsellors[0]
                                    ).map((counsellor) => {
                                      const percentage =
                                        TotalFeeDetailsGraph?.progressiveFeeReceviedCounsellors[0][
                                          counsellor
                                        ]
                                          ?.toFixed(0)
                                          ?.toLocaleString();
                                      // const percentage = Number(TotalFeeDetailsGraph.progressiveFeeRecevied[0][branch].toFixed(0)).toLocaleString("en-IN");
                                      console.log(percentage, counsellor, "jhafjhgfjdsgf");
                                      return (
                                        <div key={counsellor} className="">
                                          <p>
                                            {counsellor}
                                            <span className="float-end me-3">
                                              {percentage}%
                                            </span>
                                          </p>
                                          <div className="progress">
                                            <div
                                              className="progress-bar progress-bar-striped progress-bar-animated"
                                              role="progressbar"
                                              style={{ width: `${percentage}%` }}
                                              aria-valuenow={percentage}
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            ></div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>

                  {/* FeeDetails Filters */}
                  <div className="mt-2 mb-2 mt-lg-0">
                    <div className="mb-0 card">
                      <div className="card-body">
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightOne"
                            aria-controls="offcanvasRight"
                          >
                            <MdFilterList className="me-1 mb-1 text_white" />
                            Filters
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="offcanvas offcanvas-end bg_light"
                    id="offcanvasRightOne"
                    aria-labelledby="offcanvasRightLabel"
                  >
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body p-2">
                      {/* Profile filter */}
                      <div>
                        <label className="form-label fs-s fw-medium text_color">
                          From Date
                        </label>
                        <input
                          className="  form-control input_bg_color  date_input_color "
                          id="rdob"
                          name="fromDate"
                          type="date"
                          onChange={handleDateChangeInFeeDeatils}
                          value={filteredDatesFeeDetails?.fromDate}
                        />
                      </div>

                      <div className="mt-2">
                        <label className="form-label fs-s fw-medium text_color">
                          To Date
                        </label>
                        <input
                          className=" form-control input_bg_color  date_input_color "
                          id="rdob"
                          name="toDate"
                          type="date"
                          onChange={handleDateChangeInFeeDeatils}
                          value={filteredDatesFeeDetails?.toDate}
                        />
                      </div>

                      <div className="mt-2">
                        <label className="form-label fs-s fw-medium text_color">
                          Admission From Date
                        </label>
                        <input
                          className=" form-control input_bg_color  date_input_color "
                          id="rdob"
                          name="admissionFromDate"
                          type="date"
                          onChange={handleDateChangeInFeeDeatils}
                          value={filteredDatesFeeDetails?.admissionFromDate}
                        />
                      </div>
                      <div className="mt-2">
                        <label className="form-label fs-s fw-medium text_color">
                          Admission To Date
                        </label>
                        <input
                          className=" form-control input_bg_color  date_input_color "
                          id="rdob"
                          name="admissionToDate"
                          type="date"
                          onChange={handleDateChangeInFeeDeatils}
                          value={filteredDatesFeeDetails?.admissionToDate}
                        />
                      </div>

                      {/* Buttons */}
                      <div className="position-absolute bottom-0 start-0 ms-2 mb-2">
                        <button
                          className="btn btn_primary"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          onClick={
                            filterReset_FeeDetailsDates
                          }
                        >
                          Clear
                        </button>
                      </div>
                      <div className="position-absolute bottom-0 end-0 me-2 mb-2">
                        <Button
                          className="btn btn_primary"
                          onClick={
                            filterSubmit_FeeDetailsDates
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>

                  {userData &&
                    userData?.user &&
                    userData?.user?.profile !== "Counsellor" &&
                    userData?.user?.profile !== "counsellor" && (
                      <>
                        {/* FeeDetails Branches Table */}
                        <div className="row">
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="card">
                              <div className="card-header">
                                <h6>Fee Received In Branch</h6>
                                <div className="card-body">
                                  <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                                    <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover ">
                                      <thead>
                                        <tr className="">
                                          <th
                                            scope="col"
                                            className="fs-13 lh-xs fw-600 "
                                          >
                                            S.No
                                          </th>
                                          <th
                                            scope="col"
                                            className="fs-13 lh-xs fw-600 "
                                          >
                                            Branch
                                          </th>
                                          <th
                                            scope="col"
                                            className="fs-13 lh-xs  fw-600  text-truncate"
                                          >
                                            Fee Received
                                          </th>
                                          <th
                                            scope="col"
                                            className="fs-13 lh-xs  fw-600 text-truncate"
                                          >
                                            Fee Yet To Receive
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>

                                        {
                                          TotalFeeDetails?.PaginatedBranchs && TotalFeeDetails?.PaginatedBranchs.length > 0 ? TotalFeeDetails?.loading ?
                                            "loading..." :
                                            TotalFeeDetails?.PaginatedBranchs.map((item, index) => {
                                              return (
                                                <tr className={activeBranchFeeDetails === item.branch ? 'table-active' : ''}>
                                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                    {index + 1}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light" style={{ cursor: "pointer" }} onClick={() => handleBranchSubmitFeeDetails(item?.branch)}>
                                                    {item?.branch}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.feeReceived?.toLocaleString("en-IN")}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.feeYetToReceive?.toLocaleString("en-IN")}
                                                  </td>
                                                </tr>
                                              )
                                            })
                                            :
                                            <tr>
                                              <td className="fs-13 black_300 fw-500 lh-xs bg_light ">

                                                No data

                                              </td>
                                            </tr>
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Feedetails TopRated Councellors && Councellors Table */}

                        <div>
                          <div className="row">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="card">
                                <div className="card-header">
                                  <h6>Fee Received By Counsellors</h6>
                                  <div className="card-body">
                                    <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                                      <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                                        <thead>
                                          <tr className="">
                                            <th
                                              scope="col"
                                              className="fs-13 lh-xs fw-600  "
                                            >
                                              S.No
                                            </th>
                                            <th
                                              scope="col"
                                              className="fs-13 lh-xs fw-600  "
                                            >
                                              Counsellor
                                            </th>
                                            <th
                                              scope="col"
                                              className="fs-13 lh-xs fw-600 text-truncate"
                                            >
                                              Fee Received
                                            </th>
                                            <th
                                              scope="col"
                                              className="fs-13 lh-xs fw-600 text-truncate"
                                            >
                                              Fee Yet To Receive
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {FeeDetailsBranchwiseCouncellers?.paginatedFeeDetailsBranchwiseCouncellers &&
                                            FeeDetailsBranchwiseCouncellers?.paginatedFeeDetailsBranchwiseCouncellers
                                              .length > 0 ? (
                                            FeeDetailsBranchwiseCouncellers?.loading ? (
                                              "loading..."
                                            ) : (
                                              FeeDetailsBranchwiseCouncellers?.paginatedFeeDetailsBranchwiseCouncellers?.map(
                                                (item, index) => {
                                                  return (
                                                    <tr
                                                      className={
                                                        activeCouncellorFeeDetails ===
                                                          item?.enquirytakenby
                                                          ? "table-active"
                                                          : ""
                                                      }
                                                    >
                                                      <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                        {index + 1}
                                                      </td>
                                                      <td
                                                        className="fs-13 black_300  lh-xs bg_light"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() =>
                                                          handleCouncellorSubmitfeeDetails(
                                                            item?.enquirytakenby
                                                          )
                                                        }
                                                      >
                                                        {item?.enquirytakenby}
                                                      </td>
                                                      <td className="fs-13 black_300  lh-xs bg_light">
                                                        {item?.feeReceived?.toLocaleString("en-IN")}
                                                      </td>
                                                      <td className="fs-13 black_300  lh-xs bg_light">
                                                        {item?.feeYetToReceive?.toLocaleString("en-IN")}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            )
                                          ) : TotalFeeDetails?.PaginatedTopRatedCouncellers &&
                                            TotalFeeDetails
                                              ?.PaginatedTopRatedCouncellers?.length >
                                            0 ? (
                                            TotalFeeDetails?.loading ? (
                                              "loading..."
                                            ) : (
                                              TotalFeeDetails?.PaginatedTopRatedCouncellers.map(
                                                (item, index) => {
                                                  return (
                                                    <tr
                                                      className={
                                                        activeCouncellorFeeDetails ===
                                                          item?.enquirytakenby
                                                          ? "table-active"
                                                          : ""
                                                      }
                                                    >
                                                      <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                        {index + 1}
                                                      </td>
                                                      <td
                                                        className="fs-13 black_300  lh-xs bg_light"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() =>
                                                          handleCouncellorSubmitfeeDetails(
                                                            item?.enquirytakenby
                                                          )
                                                        }
                                                      >
                                                        {item?.enquirytakenby}
                                                      </td>
                                                      <td className="fs-13 black_300  lh-xs bg_light">
                                                        {item?.feeReceived?.toLocaleString("en-IN")}
                                                      </td>
                                                      <td className="fs-13 black_300  lh-xs bg_light">
                                                        {item?.feeYetToReceive?.toLocaleString("en-IN")}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td className="fs-13 black_300  lh-xs bg_light">
                                                no data
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  {/* Feedetails Students  table */}

                  {FeeDetailsCouncellerwiseStudents?.paginatedFeeDetailsCouncellerwiseStudents &&
                    FeeDetailsCouncellerwiseStudents
                      ?.paginatedFeeDetailsCouncellerwiseStudents.length > 0 && (
                      <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="card">
                            <div className="card-header">
                              <h6>Student Details</h6>
                              <div className="card-body">
                                <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                                  <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                                    <thead>
                                      <tr className="">
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600  "
                                        >
                                          S.No
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600  "
                                        >
                                          Student Name
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600  "
                                        >
                                          Admission Date
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600  "
                                        >
                                          Paid Fee
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600  "
                                        >
                                          Next Due Date
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs  fw-600 "
                                        >
                                          Due Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {FeeDetailsCouncellerwiseStudents?.paginatedFeeDetailsCouncellerwiseStudents &&
                                        FeeDetailsCouncellerwiseStudents
                                          ?.paginatedFeeDetailsCouncellerwiseStudents
                                          .length > 0 ? (
                                        FeeDetailsCouncellerwiseStudents?.loading ? (
                                          "loading..."
                                        ) : (
                                          FeeDetailsCouncellerwiseStudents?.paginatedFeeDetailsCouncellerwiseStudents?.map(
                                            (item, index) => {
                                              return (
                                                <tr>
                                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                    {index + 1}
                                                  </td>
                                                  <td
                                                    className="fs-13 black_300  lh-xs bg_light text-truncate"
                                                    style={{ maxWidth: "150px" }}
                                                  >
                                                    {item?.name}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.admissiondate}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.totalpaidamount?.toLocaleString("en-IN")}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.nextduedate}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.dueamount?.toLocaleString("en-IN")}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                            no data
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )
          }



          {/* ---------------------------------------------- */}
          {/*----------------- T0TAL USERS  TAB -------------*/}
          {/* ---------------------------------------------- */}

          {
            activeTabs.TotalUsers && (
              <div
                className={`tab-pane fade ${activeTabs.TotalUsers ? "show active" : ""}`}
                id="pills-fee"
                role="tabpanel"
                aria-labelledby="pills-fee-tab"
              >
                <div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card">
                        <div className="card-header">
                          <div className="card-body">
                            <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                              <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                                <thead>
                                  <tr className="">
                                    <th scope="col" className="fs-13 lh-xs fw-600 ">
                                      S.No
                                    </th>
                                    <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                      Branch
                                    </th>
                                    <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                      Users Count
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {TotalUsersInDashboad?.paginatedBranchesList &&
                                    TotalUsersInDashboad?.paginatedBranchesList.length >
                                    0 ? (
                                    TotalUsersInDashboad?.loading ? (
                                      "loading"
                                    ) : (
                                      TotalUsersInDashboad?.paginatedBranchesList.map(
                                        (item, index) => {
                                          const branch = item?.branch;

                                          return (
                                            <tr
                                              className={
                                                activeUsersInTotalUsers ===
                                                  item?.branch
                                                  ? "table-active"
                                                  : ""
                                              }
                                            >
                                              <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                {index + 1}
                                              </td>
                                              <td
                                                className="fs-13 black_300  lh-xs bg_light"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  handleBranchSubmitInUsers(branch)
                                                }
                                              >
                                                {item?.branch}
                                              </td>
                                              <td className="fs-13 black_300  lh-xs bg_light">
                                                {item?.users}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td className="fs-13 black_300  lh-xs bg_light">
                                        No data
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">

                      {/* Branchwise Councelloers */}
                      {UsersListInBranchWise?.paginatedUsersListInBranchWise &&
                        UsersListInBranchWise?.paginatedUsersListInBranchWise.length >
                        0 && (
                          <div className="card">
                            <div className="card-header">
                              <div className="card-body">
                                <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                                  <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                                    <thead>
                                      <tr className="">
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600  "
                                        >
                                          S.No
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600  "
                                        >
                                          Username
                                        </th>
                                        <th
                                          scope="col"
                                          className="fs-13 lh-xs fw-600  "
                                        >
                                          Profile
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {UsersListInBranchWise?.paginatedUsersListInBranchWise &&
                                        UsersListInBranchWise
                                          ?.paginatedUsersListInBranchWise.length >
                                        0 ? (
                                        UsersListInBranchWise?.loading ? (
                                          <tr>
                                            <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                              loading...
                                            </td>
                                          </tr>
                                        ) : (
                                          UsersListInBranchWise?.paginatedUsersListInBranchWise?.map(
                                            (item, index) => {
                                              return (
                                                <tr>
                                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                                    {index + 1}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.fullname}
                                                  </td>
                                                  <td className="fs-13 black_300  lh-xs bg_light">
                                                    {item?.profile}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        )
                                      ) : (
                                        <tr className="fs-13 black_300  lh-xs bg_light">
                                          No data / Select the Branch
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );



};

export default StudentManagementDasboard;
