import React, { useState } from 'react';

const CurriculumManager = () => {
  const [curriculum, setCurriculum] = useState([ ]);


  console.log(curriculum, "curriculumcurriculum");

  

  const handleModuleChange = (e, moduleIndex) => {
    const newCurriculum = [...curriculum];
    newCurriculum[moduleIndex].moduleName = e.target.value;
    setCurriculum(newCurriculum);
  };

  const handleSubmoduleChange = (e, moduleIndex, submoduleIndex) => {
    const newCurriculum = [...curriculum];
    newCurriculum[moduleIndex].submodules[submoduleIndex].submoduleName = e.target.value;
    setCurriculum(newCurriculum);
  };

  const handleTopicChange = (e, moduleIndex, submoduleIndex, topicIndex) => {
    const newCurriculum = [...curriculum];
    newCurriculum[moduleIndex].submodules[submoduleIndex].topics[topicIndex].topicName = e.target.value;
    setCurriculum(newCurriculum);
  };

  const handleSessionsChange = (e, moduleIndex, submoduleIndex, topicIndex) => {
    const newCurriculum = [...curriculum];
    newCurriculum[moduleIndex].submodules[submoduleIndex].topics[topicIndex].sessions = Number(e.target.value);
    setCurriculum(newCurriculum);
  };

  const addModule = () => {
    setCurriculum([...curriculum, { moduleName: '', submodules: [{ submoduleName: '', topics: [{ topicName: '', sessions: null }] }] }]);
  };

  const addSubmodule = (moduleIndex) => {
    const newCurriculum = [...curriculum];
    newCurriculum[moduleIndex].submodules.push({ submoduleName: '', topics: [{ topicName: '', sessions: null }] });
    setCurriculum(newCurriculum);
  };

  const addTopic = (moduleIndex, submoduleIndex) => {
    const newCurriculum = [...curriculum];
    newCurriculum[moduleIndex].submodules[submoduleIndex].topics.push({ topicName: '', sessions: null });
    setCurriculum(newCurriculum);
  };

  const deleteModule = (moduleIndex) => {
    const newCurriculum = [...curriculum];
    newCurriculum.splice(moduleIndex, 1);
    setCurriculum(newCurriculum);
  };

  const deleteSubmodule = (moduleIndex, submoduleIndex) => {
    const newCurriculum = [...curriculum];
    newCurriculum[moduleIndex].submodules.splice(submoduleIndex, 1);
    setCurriculum(newCurriculum);
  };

  const deleteTopic = (moduleIndex, submoduleIndex, topicIndex) => {
    const newCurriculum = [...curriculum];
    newCurriculum[moduleIndex].submodules[submoduleIndex].topics.splice(topicIndex, 1);
    setCurriculum(newCurriculum);
  };

  const handleSubmit = () => {
    // Perform API call with the entire curriculum state
    console.log('Submitting curriculum:', curriculum);
  };

  const renderCurriculum = () => {
    return curriculum.map((module, moduleIndex) => (

<div className="container-fluid">
      <div key={`module-${moduleIndex}`}>
        <input
          type="text"
          value={module.moduleName}
          onChange={(e) => handleModuleChange(e, moduleIndex)}
          placeholder="Module Name"
        />
        <button onClick={() => deleteModule(moduleIndex)}>Delete Module</button>
        {module.submodules.map((submodule, submoduleIndex) => (
          <div key={`submodule-${moduleIndex}-${submoduleIndex}`} style={{ marginLeft: '20px' }}>
            <input
              type="text"
              value={submodule.submoduleName}
              onChange={(e) => handleSubmoduleChange(e, moduleIndex, submoduleIndex)}
              placeholder="Submodule Name"
            />
            <button onClick={() => deleteSubmodule(moduleIndex, submoduleIndex)}>Delete Submodule</button>
            {submodule.topics.map((topic, topicIndex) => (
              <div key={`topic-${moduleIndex}-${submoduleIndex}-${topicIndex}`} style={{ marginLeft: '40px' }}>
                <input
                  type="text"
                  value={topic.topicName}
                  onChange={(e) => handleTopicChange(e, moduleIndex, submoduleIndex, topicIndex)}
                  placeholder="Topic Name"
                />
                <input
                  type="number"
                  value={topic.sessions}
                  onChange={(e) => handleSessionsChange(e, moduleIndex, submoduleIndex, topicIndex)}
                  placeholder="Number of Sessions"
                />
                <button onClick={() => deleteTopic(moduleIndex, submoduleIndex, topicIndex)}>Delete Topic</button>
              </div>
            ))}
            <button onClick={() => addTopic(moduleIndex, submoduleIndex)}>Add Topic</button>
          </div>
        ))}
        <button onClick={() => addSubmodule(moduleIndex)}>Add Submodule</button>
      </div>
</div>

    ));
  };

  return (
    <div>
      {renderCurriculum()}
      <button onClick={addModule}>Add Module</button>
      <button onClick={handleSubmit}>Submit Curriculum</button>
    </div>
  );
};

export default CurriculumManager;





// import React, { useState } from 'react';
// import axios from 'axios';
// import { MdDelete } from 'react-icons/md';

// const CurriculumManager = () => {
//     const [curriculum, setCurriculum] = useState({
//         modules: [],
//     });

//     const [newModuleName, setNewModuleName] = useState('');
//     const [newSubmoduleName, setNewSubmoduleName] = useState('');
//     const [newTopicName, setNewTopicName] = useState('');
//     const [newSessionCount, setNewSessionCount] = useState('');

//     const addModule = () => {
//         const newModule = {
//             name: newModuleName,
//             submodules: [],
//         };
//         setCurriculum(prev => ({
//             ...prev,
//             modules: [...prev.modules, newModule],
//         }));
//         setNewModuleName('');
//     };

//     const updateModule = (moduleIndex, newName) => {
//         setCurriculum(prev => ({
//             ...prev,
//             modules: prev.modules.map((module, index) =>
//                 index === moduleIndex ? { ...module, name: newName } : module
//             ),
//         }));
//     };

//     const deleteModule = (moduleIndex) => {
//         setCurriculum(prev => ({
//             ...prev,
//             modules: prev.modules.filter((module, index) => index !== moduleIndex),
//         }));
//     };

//     const addSubmodule = (moduleIndex) => {
//         const newSubmodule = {
//             name: newSubmoduleName,
//             topics: [],
//         };
//         setCurriculum(prev => ({
//             ...prev,
//             modules: prev.modules.map((module, index) =>
//                 index === moduleIndex
//                     ? { ...module, submodules: [...module.submodules, newSubmodule] }
//                     : module
//             ),
//         }));
//         setNewSubmoduleName('');
//     };

//     const updateSubmodule = (moduleIndex, submoduleIndex, newName) => {
//         setCurriculum(prev => ({
//             ...prev,
//             modules: prev.modules.map((module, mIndex) =>
//                 mIndex === moduleIndex
//                     ? {
//                         ...module,
//                         submodules: module.submodules.map((submodule, sIndex) =>
//                             sIndex === submoduleIndex ? { ...submodule, name: newName } : submodule
//                         ),
//                     }
//                     : module
//             ),
//         }));
//     };

//     const deleteSubmodule = (moduleIndex, submoduleIndex) => {
//         setCurriculum(prev => ({
//             ...prev,
//             modules: prev.modules.map((module, mIndex) =>
//                 mIndex === moduleIndex
//                     ? {
//                         ...module,
//                         submodules: module.submodules.filter((submodule, sIndex) => sIndex !== submoduleIndex),
//                     }
//                     : module
//             ),
//         }));
//     };

//     const addTopic = (moduleIndex, submoduleIndex) => {
//         const newTopic = {
//             name: newTopicName,
//             sessions: parseInt(newSessionCount, 10),
//         };

//         setCurriculum(prev => ({
//             ...prev,
//             modules: prev.modules.map((module, mIndex) =>
//                 mIndex === moduleIndex
//                     ? {
//                         ...module,
//                         submodules: module.submodules.map((submodule, sIndex) =>
//                             sIndex === submoduleIndex ? { ...submodule, topics: [...submodule.topics, newTopic] } : submodule
//                         ),
//                     }
//                     : module
//             ),
//         }));

//         setNewTopicName('');
//         setNewSessionCount('');
//     };

//     const updateTopic = (moduleIndex, submoduleIndex, topicIndex, newName, newSessions) => {
//         setCurriculum(prev => ({
//             ...prev,
//             modules: prev.modules.map((module, mIndex) =>
//                 mIndex === moduleIndex
//                     ? {
//                         ...module,
//                         submodules: module.submodules.map((submodule, sIndex) =>
//                             sIndex === submoduleIndex
//                                 ? {
//                                     ...submodule,
//                                     topics: submodule.topics.map((topic, tIndex) =>
//                                         tIndex === topicIndex ? { ...topic, name: newName, sessions: newSessions } : topic
//                                     ),
//                                 }
//                                 : submodule
//                         ),
//                     }
//                     : module
//             ),
//         }));
//     };

//     const deleteTopic = (moduleIndex, submoduleIndex, topicIndex) => {
//         setCurriculum(prev => ({
//             ...prev,
//             modules: prev.modules.map((module, mIndex) =>
//                 mIndex === moduleIndex
//                     ? {
//                         ...module,
//                         submodules: module.submodules.map((submodule, sIndex) =>
//                             sIndex === submoduleIndex
//                                 ? {
//                                     ...submodule,
//                                     topics: submodule.topics.filter((topic, tIndex) => tIndex !== topicIndex),
//                                 }
//                                 : submodule
//                         ),
//                     }
//                     : module
//             ),
//         }));
//     };

//     const validateCurriculum = () => {
//         for (const module of curriculum.modules) {
//             if (!module.name) {
//                 return 'All module names must be filled';
//             }
//             for (const submodule of module.submodules) {
//                 if (!submodule.name) {
//                     return 'All submodule names must be filled';
//                 }
//                 for (const topic of submodule.topics) {
//                     if (!topic.name || !topic.sessions) {
//                         return 'All topic names and session counts must be filled';
//                     }
//                     // if (submodule.topics.length !== submodule.topics.reduce((sum, t) => sum + t.sessions, 0)) {
//                     //     return 'The number of topics must match the number of sessions';
//                     // }
//                 }
//             }
//         }
//         return '';
//     };

//     const submitCurriculum = async () => {
//         const validationError = validateCurriculum();
//         if (validationError) {
//             alert(validationError);
//             return;
//         }

//         try {
//             await axios.post('/api/curriculum', curriculum);
//             alert('Curriculum submitted successfully');
//         } catch (error) {
//             console.error("API call error:", error);
//             alert('Failed to submit curriculum');
//         }
//     };

//     return (
//         <div>
//             <h1>Curriculum Manager</h1>
//             <div className="card-body">
//                 <div className="table-responsive table-card border-0">
//                     <div className="table-container table-scroll">
//                         <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
//                             <thead>
//                                 <tr>
//                                     <th scope="col" >Module</th>
//                                     <th scope="col" >Submodule</th>
//                                     <th scope="col" >Topic</th>
//                                     <th scope="col" >Sessions</th>

//                                 </tr>
//                             </thead>
//                             <tbody className="scrollable-body">

                              
//                                     {curriculum.modules.map((module, moduleIndex) => (
//                                         <React.Fragment key={moduleIndex}>
//                                             <tr>
//                                                 <td className="fs-13 black_300 fw_500 lh-xs bg_light" >
//                                                     <input
//                                                         className="form-control fs-s bg-form text_color input_bg_color text-capitalize"
//                                                         placeholder="Enter the Module Name"
//                                                         type="text"
//                                                         value={module?.name}
//                                                         onChange={(e) => updateModule(moduleIndex, e.target.value)}
//                                                     />
//                                                     <button onClick={() => deleteModule(moduleIndex)}>🗑️</button>
//                                                 </td>
//                                                 <td className="fs-13 black_300 fw_500 lh-xs bg_light" >
//                                                     <button onClick={() => addSubmodule(moduleIndex)}>➕ Add Submodule</button>
//                                                 </td>
//                                             </tr>
//                                             {module.submodules.map((submodule, submoduleIndex) => (
//                                                 <React.Fragment key={submoduleIndex}>
//                                                     <tr>
//                                                         <td className="fs-13 black_300 fw_500 lh-xs bg_light" ></td>
//                                                         <td className="fs-13 black_300 fw_500 lh-xs bg_light" >
//                                                             <input
//                                                                 type="text"
//                                                                 placeholder="Enter the SubModule Name"
//                                                                 className="form-control fs-s bg-form text_color input_bg_color text-capitalize"
//                                                                 value={submodule.name}
//                                                                 onChange={(e) => updateSubmodule(moduleIndex, submoduleIndex, e.target.value)}
//                                                             />
//                                                             <button onClick={() => deleteSubmodule(moduleIndex, submoduleIndex)}>🗑️</button>
//                                                         </td>
//                                                         <td className="fs-13 black_300 fw_500 lh-xs bg_light" >
//                                                             <button onClick={() => addTopic(moduleIndex, submoduleIndex)}>➕ Add Topic</button>
//                                                         </td>
//                                                     </tr>
//                                                     {submodule.topics.map((topic, topicIndex) => (
//                                                         <tr key={topicIndex}>
//                                                             <td className="fs-13 black_300 fw_500 lh-xs bg_light" ></td>
//                                                             <td className="fs-13 black_300 fw_500 lh-xs bg_light"></td>
//                                                             <td className="fs-13 black_300 fw_500 lh-xs bg_light" >
//                                                                 <input
//                                                                     className="form-control fs-s bg-form text_color input_bg_color text-capitalize"
//                                                                     type="text"
//                                                                     placeholder="Enter the Topic"
//                                                                     value={topic.name}
//                                                                     onChange={(e) => updateTopic(moduleIndex, submoduleIndex, topicIndex, e.target.value, topic.sessions)}
//                                                                 />
//                                                             </td>
//                                                             <td className="fs-13 black_300 fw_500 lh-xs bg_light" >
//                                                                 <input
//                                                                     className="form-control fs-s bg-form text_color input_bg_color text-capitalize"
//                                                                     type="number"
//                                                                     value={topic.sessions}
//                                                                     onChange={(e) => updateTopic(moduleIndex, submoduleIndex, topicIndex, topic.name, parseInt(e.target.value, 10))}
//                                                                     placeholder="Number of Sessions"
//                                                                 />
//                                                                 <button onClick={() => deleteTopic(moduleIndex, submoduleIndex, topicIndex)}>🗑️</button>
//                                                             </td>
//                                                         </tr>
//                                                     ))}
//                                                 </React.Fragment>
//                                             ))}

//                                         </React.Fragment>
//                                     ))}
                              
//                                 <tr>
//                                     <td className="fs-13 black_300 fw_500 lh-xs bg_light">
//                                         <input
//                                             className="form-control fs-s bg-form text_color input_bg_color text-capitalize"
//                                             type="text"
//                                             value={newModuleName}
//                                             onChange={(e) => setNewModuleName(e.target.value)}
//                                             placeholder="New Module Name"
//                                         />
//                                         <button onClick={addModule}>➕ Add Module</button>
//                                     </td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                         <button onClick={submitCurriculum}>Submit Curriculum</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );


// };

// export default CurriculumManager;
