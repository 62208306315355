import React from 'react'
import { useNavigate } from 'react-router-dom';


function InternalServer() {

    const navigate = useNavigate();
    const handleReload = (e) => {
        navigate("/");
        window.location.reload();
    }


    return (
        <div>
            <div class="auth-page-wrapperr py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div class="auth-page-contentt overflow-hidden p-0">
                    <div class="container-fluid">
                        <div class="row justify-content-center">
                            <div class="col-xl-4 text-center">
                                <div class="error-500 position-relativee">
                                    <img src="https://themesbrand.com/velzon/html/master/assets/images/error500.png" alt="" class="img-fluid error-500-img error-img" />
                                    <h1 class="title text-muted">500</h1>
                                </div>
                                <div>
                                    <h5>Internal Server Error!</h5>
                                    <p class="text-mutedd w-75 mx-auto">Server Error 500. We're not exactly sure what happened, but our servers say something is wrong.</p>
                                    <button class="btn btn-success" onClick={(e) => handleReload(e)}>Reload</button>
                                   
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default InternalServer