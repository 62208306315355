import { useEffect, useReducer } from "react";
import WebsiteLeadsReducer from "./WebsiteLeadsReducer";
import { intialState } from "./utils/WebsiteLeadsConfi";


import * as Actions from "./utils/WebsiteLeadsActions";
import * as Api from "./utils/WebsiteLeadsAPI";

const WebsiteLeadProvider = () => {
    const [websiteLeadsState, DispatchwebsiteLeads] = useReducer(WebsiteLeadsReducer, intialState);
    console.log(websiteLeadsState, "websiteLeadsState")

    //webinar leads
    const getWebinarLeads = async () => {
        DispatchwebsiteLeads(Actions.setLoading("WEBINAR_LEADS"));
        const { filter, search, pageSize, page } = websiteLeadsState?.Webinar;

        const searchobj = { search };
        const perPageObj = { pageSize };
        const currentPageObj = { page };
        const filterobj = { filter }
        try {
            const { data, status } = await Api.getWebinarLeads(filterobj, searchobj, perPageObj, currentPageObj)
            if (status === 200) {
                DispatchwebsiteLeads(Actions.setWebinarLeads(data, "WEBINAR_LEADS"))
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            DispatchwebsiteLeads(Actions.setLoading("WEBINAR_LEADS"));
        }
    }

    // whatsAPP

    const getwhatsAppLeads = async () => {
        DispatchwebsiteLeads(Actions.setLoading("WHATSAPP_LEADS"));
        const { filter, search, pageSize, page } = websiteLeadsState?.whatsApp;
        console.log(filter, search, pageSize, page, "fhfhth")
        const searchobj = { search };
        const perPageObj = { pageSize };
        const currentPageObj = { page };
        const filterobj = { filter }
        try {
            const { data, status } = await Api.getwhatsAppLeads(filterobj, searchobj, perPageObj, currentPageObj)
            if (status === 200) {
                DispatchwebsiteLeads(Actions.setwhatsAppLeads(data, "WHATSAPP_LEADS"))
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            DispatchwebsiteLeads(Actions.setLoading("WHATSAPP_LEADS"));
        }
    }





    //Download Syllabus
    const getDownloadSyllabusLeads = async () => {
        DispatchwebsiteLeads(Actions.setLoading("DOWNLOAD_SYLLABUS_LEADS"));
        const { filter, search, pageSize, page } = websiteLeadsState?.DownloadSyllabus;
        console.log(filter, search, pageSize, page, "fhfhth")
        const searchobj = { search };
        const perPageObj = { pageSize };
        const currentPageObj = { page };
        const filterobj = { filter }
        try {
            const { data, status } = await Api.getDownloadSyllabusLeads(filterobj, searchobj, perPageObj, currentPageObj)
            console.log(data, status, "djhfgduyf")
            if (status === 200) {
                DispatchwebsiteLeads(Actions.setDownloadSyllabusLeads(data, "DOWNLOAD_SYLLABUS_LEADS"))
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            DispatchwebsiteLeads(Actions.setLoading("DOWNLOAD_SYLLABUS_LEADS"));
        }
    }
    //viewcourse

    const getViewCourseLeads = async () => {
        DispatchwebsiteLeads(Actions.setLoading("VIEWCOURSE_LEADS"));
        const { filter, search, pageSize, page } = websiteLeadsState?.ViewCourse;
        console.log(filter, search, pageSize, page, "fhfhth")
        const searchobj = { search };
        const perPageObj = { pageSize };
        const currentPageObj = { page };
        const filterobj = { filter }
        try {
            const { data, status } = await Api.getViewCourseLeads(filterobj, searchobj, perPageObj, currentPageObj)
            console.log(data, status, "djhfgduyf")
            if (status === 200) {
                DispatchwebsiteLeads(Actions.setViewCourseLeads(data, "VIEWCOURSE_LEADS"))
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            DispatchwebsiteLeads(Actions.setLoading("VIEWCOURSE_LEADS"));
        }
    }

    //contactus
    const getContactUsLeads = async () => {
        DispatchwebsiteLeads(Actions.setLoading("CONTACTUS_LEADS"));
        const { filter, search, pageSize, page } = websiteLeadsState?.ContactUs;
        console.log(filter, search, pageSize, page, "fhfhth")
        const searchobj = { search };
        const perPageObj = { pageSize };
        const currentPageObj = { page };
        const filterobj = { filter }
        try {
            const { data, status } = await Api.getContactUsLeads(filterobj, searchobj, perPageObj, currentPageObj)
            console.log(data, status, "djhfgduyf")
            if (status === 200) {
                DispatchwebsiteLeads(Actions.setContactUsLeads(data, "CONTACTUS_LEADS"))
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            DispatchwebsiteLeads(Actions.setLoading("CONTACTUS_LEADS"));
        }
    }

    //hlp
    const getHLPEnquireLeads = async () => {
        DispatchwebsiteLeads(Actions.setLoading("HLPENQUIRE_LEADS"));
        const { filter, search, pageSize, page } = websiteLeadsState?.HLPEnquireLeads;
        console.log(filter, search, pageSize, page, "fhfhth")
        const searchobj = { search };
        const perPageObj = { pageSize };
        const currentPageObj = { page };
        const filterobj = { filter }
        try {
            const { data, status } = await Api.getHLPEnquireLeads(filterobj, searchobj, perPageObj, currentPageObj)
            console.log(data, status, "djhfgduyf")
            if (status === 200) {
                DispatchwebsiteLeads(Actions.setHLPEnquireLeads(data, "HLPENQUIRE_LEADS"))
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            DispatchwebsiteLeads(Actions.setLoading("HLPENQUIRE_LEADS"));
        }
    }
    //SLP
   
    const getSLPEnquireLeads = async () => {
        DispatchwebsiteLeads(Actions.setLoading("SLPENQUIRE_LEADS"));
        const { filter, search, pageSize, page } = websiteLeadsState?.SLPEnquireLeads;
        console.log(filter, search, pageSize, page, "fhfhth")
        const searchobj = { search };
        const perPageObj = { pageSize };
        const currentPageObj = { page };
        const filterobj = { filter }
        try {
            const { data, status } = await Api.getSLPEnquireLeads(filterobj, searchobj, perPageObj, currentPageObj)
            console.log(data, status, "djhfgduyf")
            if (status === 200) {
                DispatchwebsiteLeads(Actions.setSLPEnquireLeads(data, "SLPENQUIRE_LEADS"))
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            DispatchwebsiteLeads(Actions.setLoading("SLPENQUIRE_LEADS"));
        }
    }








    useEffect(() => {
        getDownloadSyllabusLeads();
        getWebinarLeads();
        getwhatsAppLeads();
        getViewCourseLeads();
        getContactUsLeads();
        getHLPEnquireLeads();
        getSLPEnquireLeads();
    }, [])

    // download syllabus
    useEffect(() => {
        getDownloadSyllabusLeads();
    }, [websiteLeadsState?.DownloadSyllabus?.search,
    websiteLeadsState?.DownloadSyllabus?.pageSize,
    websiteLeadsState?.DownloadSyllabus?.filter,
    websiteLeadsState?.DownloadSyllabus?.page])

    // webinar
    useEffect(() => {
        getWebinarLeads();
    }, [websiteLeadsState.Webinar?.search,
    websiteLeadsState.Webinar?.pageSize,
    websiteLeadsState.Webinar?.filter,
    websiteLeadsState.Webinar?.page])
    //VIEWCOURSE
    useEffect(() => {
        getViewCourseLeads();
    }, [websiteLeadsState?.ViewCourse?.search,
    websiteLeadsState?.ViewCourse?.pageSize,
    websiteLeadsState?.ViewCourse?.filter,
    websiteLeadsState?.ViewCourse?.page])
    //CONTACTUS
    useEffect(() => {
        getContactUsLeads();
    }, [websiteLeadsState?.ContactUs?.search,
    websiteLeadsState?.ContactUs?.pageSize,
    websiteLeadsState?.ContactUs?.filter,
    websiteLeadsState?.ContactUs?.page])
    //whatsapp
    useEffect(() => {
        getwhatsAppLeads();
    }, [websiteLeadsState.whatsApp?.search,
    websiteLeadsState.whatsApp?.pageSize,
    websiteLeadsState.whatsApp?.filter,
    websiteLeadsState.whatsApp?.page])
    //hlp
    useEffect(() => {
        getHLPEnquireLeads();
    }, [websiteLeadsState.HLPEnquireLeads?.search,
    websiteLeadsState.HLPEnquireLeads?.pageSize,
    websiteLeadsState.HLPEnquireLeads?.filter,
    websiteLeadsState.HLPEnquireLeads?.page])
    //slp
    useEffect(() => {
        getSLPEnquireLeads();
    }, [websiteLeadsState.SLPEnquireLeads?.search,
    websiteLeadsState.SLPEnquireLeads?.pageSize,
    websiteLeadsState.SLPEnquireLeads?.filter,
    websiteLeadsState.SLPEnquireLeads?.page])

    return {
        websiteLeadsState,
        DispatchwebsiteLeads,
        getwhatsAppLeads,
        getDownloadSyllabusLeads,
        getWebinarLeads,
        getViewCourseLeads,
        getContactUsLeads,
        getHLPEnquireLeads,
        getSLPEnquireLeads,


    }
}
export default WebsiteLeadProvider;