import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useState } from 'react';

const GraphOne = () => {
    const dataColors = ['#405189', '#eb6329'];
    const getChartColorsArray = (dataColors) => {
        return dataColors;
    };
    const chartGroupbarColors = getChartColorsArray(dataColors);

    const [chart, setChart] = useState({
        series: [
            {
                name: 'Branch Wise Active Batches',
                data: [44, 55, 41, 64, 22],
            },
            {
                name: 'Total Active Students',
                data: [53, 32, 33, 52, 13],
            },
        ],
        options: {
            chart: {
                type: 'bar',
                height: 410,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false, // Set this to false for vertical bars
                    barHeight: '100%', 
                    dataLabels: {
                        position: 'top', // Position inside the bar
                    },
                    columnWidth: '60%',
                },
            },
            dataLabels: {
                enabled: true,
                offsetX: 0,
                offsetY: -8,
                style: {
                    fontSize: '6px', // Decrease font size here
                    colors: ['#fff'],
                },
                formatter: function (val) {
                    return val; // Optional: format the data label value if needed
                },
            },
            
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff'],
            },
            tooltip: {
                shared: true,
                intersect: false,
            },
            xaxis: {
                categories: ['Ammerpet', 'kukatpally', 'secunderabad', 'Dilsukhnagar', 'Hi-tech city'], // Branch names on the x-axis
                labels: {
                    style: {
                        fontSize: '8px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: '8px',
                    },
                },
            },
            legend: {
                fontSize: '12px',
            },
            colors: ['#e96228', '#405189'], // Update with your specific colors if needed
        },
    });


    return (
        <div>
            <React.Fragment>
                <ReactApexChart
                    dir="ltr"
                    className="apex-charts"
                    options={chart.options}
                    series={chart.series}
                    type="bar"
                    height={400}
                />
            </React.Fragment>
        </div>
    );
};

export default GraphOne;
