import React, { useEffect, useMemo, useState } from "react";
import "../../../../assets/css/Table.css";
import "../../../../assets/css/FeeDetails.css";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdFilterList } from "react-icons/md";
import Usedebounce from "../../../../dataLayer/hooks/useDebounce/Usedebounce";
import { useStudentsContext } from "../../../../dataLayer/hooks/useStudentsContext";
import { useBranchContext } from "../../../../dataLayer/hooks/useBranchContext";
import feerecordimg from "../../../../assets/images/feedetails/fee_records.png";
import noduerecordimg from "../../../../assets/images/feedetails/No_due_records.png";
import feefollowupimg from "../../../../assets/images/feedetails/Follow_Ups.png";
import BackButton from "../../../components/backbutton/BackButton";
import { toast } from "react-toastify";
import "flatpickr/dist/themes/material_green.css";
import { Offcanvas } from "bootstrap";
import GateKeeper from "../../../../rbac/GateKeeper";
import Pagination from "../../../../utils/Pagination";
import PaginationInfo from "../../../../utils/PaginationInfo";
import { useUserContext } from "../../../../dataLayer/hooks/useUserContext";
import { useLeadSourceContext } from "../../../../dataLayer/hooks/useLeadSourceContext";
import Filter from "../../../../utils/Filter";


function FeeDetailsPage() {
  const { studentState: { FeeDetailsStudents, NoDueFeeRecords_Students }, Dispatchstudents, getPaginatedFeeDetailsStudents,
    getNoDueFeeRecordStudents, } = useStudentsContext();
  const { BranchState } = useBranchContext();
  const { debouncesetSearch, debouncesetPage } = Usedebounce(Dispatchstudents);
  const { UsersState: { TotalUsersWithCountellers } } = useUserContext();
  const { leadSourceState } = useLeadSourceContext();

  const branchData = useMemo(() =>
    BranchState?.branches?.map((item) => ({ label: item?.branch_name, value: item?.branch_name })) || [],
    [BranchState?.branches]
  );

  const EnquirytakenByData = useMemo(() =>
    TotalUsersWithCountellers?.map((item) => ({ label: item?.fullname, value: item?.fullname })) || [],
    [TotalUsersWithCountellers]
  );

  const leadSourceData = useMemo(() =>
    leadSourceState?.leadSources?.map((item) => ({ label: item?.leadsource, value: item?.leadsource })) || [],
    [leadSourceState?.leadSources]
  );


  useEffect(() => {
    setFilterData(prevState => prevState.map(item => {
      if (item.inputname === "lead") {
        return { ...item, options: leadSourceData };
      }
      if (item.inputname === "enquiry") {
        return { ...item, options: EnquirytakenByData };
      }
      if (item.inputname === "branch") {
        return { ...item, options: branchData };
      }
      return item;
    }));
  }, [branchData, EnquirytakenByData, leadSourceData])

  const initialFilterData = [
    { label: "From Date", type: "date", inputname: "fromDate", value: "" },
    { label: "TO Date", type: "date", inputname: "toDate", value: "" },
    { label: "Branch", type: "select", inputname: "branch", value: "", options: [] },
    { label: "Counsellor", type: "select", inputname: "enquiry", value: "", options: [] },
    { label: "Lead Source", type: "select", inputname: "lead", value: "", options: [] },
    {
      label: "Mode of Training", type: "select", inputname: "modeOfTraining", value: "", options: [
        { label: "online", value: "online" },
        { label: "offline", value: "offline" }
      ]
    },
  ];

  const [filterData, setFilterData] = useState(initialFilterData);


  const handleFilters = (index, name, value) => {
    let updatedFilterData = [...filterData]
    updatedFilterData[index].value = value;
    setFilterData(updatedFilterData)
  }

  const handleSearch = (e) => {
    debouncesetSearch({
      context: "FEE_DETAILS_STUDENTS",
      data: e.target.value,
    });
  };

  const handlePerPage = (e) => {
    const selectedvalue = parseInt(e.target.value, 10);
    Dispatchstudents({
      type: "SET_PER_PAGE",
      payload: {
        context: "FEE_DETAILS_STUDENTS",
        data: selectedvalue,
      },
    });
  };

  const FilterReset = () => {
    const resetFilterData = filterData?.map((item) => ({
      ...item,
      value: "",
    }))
    setFilterData(resetFilterData);
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "FEE_DETAILS_STUDENTS",
        data: {
          fromDate: "",
          toDate: "",
          branch: "",
          modeOfTraining: "",
          enquiry: "",
          lead: "",
        },
      },
    });

  };

  const filterSubmit = () => {
    const filter = filterData.reduce((acc, item) => {
      acc[item.inputname] = item.value;
      return acc;
    }, {});

    if (!filter.fromDate && !filter.toDate && !filter.branch && !filter.modeOfTraining && !filter?.enquiry && !filter?.lead) {
      toast.error("Please select atleast one Filter");
      return;
    }
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "FEE_DETAILS_STUDENTS",
        data: {
          fromDate: filter.fromDate,
          toDate: filter.toDate,
          branch: filter.branch,
          modeOfTraining: filter.modeOfTraining,
          enquiry: filter.enquiry,
          lead: filter.lead,
        },
      },
    });
    const offcanvasElement = document.getElementById('offcanvasRight');
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();
  };


  const handlePageChangeFeeDetails = (page) => {
    debouncesetPage({ context: "FEE_DETAILS_STUDENTS", data: page });
  }


  useEffect(() => {
    debouncesetPage({ context: "FEE_DETAILS_STUDENTS", data: 1 });
    debouncesetSearch({
      context: "FEE_DETAILS_STUDENTS",
      data: "",
    });

    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "FEE_DETAILS_STUDENTS",
        data: {
          fromDate: "",
          toDate: "",
          branch: "",
          modeOfTraining: "",
          enquiry: "",
          lead: "",
        },
      },
    });

    Dispatchstudents({
      type: "SET_PER_PAGE",
      payload: {
        context: "FEE_DETAILS_STUDENTS",
        data: 10,
      },
    });
  }, []);

  //---------------------------------------HERE THE NO DUE FEE RECORDS FILTERS---------------------------------------------------


  const InitialStateNoDueRecords = [
    { label: "From Date", type: "date", inputname: "fromDate", value: "" },
    { label: "TO Date", type: "date", inputname: "toDate", value: "" },
    { label: "Branch", type: "select", inputname: "branch", value: "", options: [] },
    { label: "Counsellor", type: "select", inputname: "enquiry", value: "", options: [] },
    { label: "Lead Source", type: "select", inputname: "lead", value: "", options: [] },
    {
      label: "Mode of Training", type: "select", inputname: "modeOfTraining", value: "", options: [
        { label: "online", value: "online" },
        { label: "offline", value: "offline" }]
    },
  ]

  const [noDueFilterData, setNoDueFilterData] = useState(InitialStateNoDueRecords);

  useEffect(() => {
    setNoDueFilterData(prevState => prevState.map(item => {
      if (item.inputname === "lead") {
        return { ...item, options: leadSourceData };
      }
      if (item.inputname === "enquiry") {
        return { ...item, options: EnquirytakenByData };
      }
      if (item.inputname === "branch") {
        return { ...item, options: branchData };
      }
      return item;
    }));
  }, [branchData, EnquirytakenByData, leadSourceData])

  const handleFiltersNoDue = (index, name, value) => {
    let updatedFilterData = [...noDueFilterData]
    updatedFilterData[index].value = value;
    setNoDueFilterData(updatedFilterData)
  }

  const handleSearchDueFee = (e) => {
    debouncesetSearch({
      context: "NO_DUE_FEE_RECORDS_STUDENTS",
      data: e.target.value,
    });
  };

  const handlePerPageDueFee = (e) => {
    const selectedvalue = parseInt(e.target.value, 10);
    Dispatchstudents({
      type: "SET_PER_PAGE",
      payload: {
        context: "NO_DUE_FEE_RECORDS_STUDENTS",
        data: selectedvalue,
      },
    });
  };

  const FilterResetDueFee = () => {
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "NO_DUE_FEE_RECORDS_STUDENTS",
        data: {
          fromDate: "",
          toDate: "",
          branch: "",
          modeOfTraining: "",
          enquiry: "",
          lead: "",
        },
      },
    });
  };

  const filterSubmitDueFee = () => {
    const filter = noDueFilterData?.reduce((acc, item) => {
      acc[item.inputname] = item.value;
      return acc;
    }, {});

    if (!filter.fromDate && !filter.toDate && !filter.branch && !filter.modeOfTraining && !filter.enquiry && !filter.lead) {
      toast.error("Please fill in at least one filter criteria.");
      return;
    }
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "NO_DUE_FEE_RECORDS_STUDENTS",
        data: {
          fromDate: filter.fromDate,
          toDate: filter.toDate,
          branch: filter.branch,
          modeOfTraining: filter.modeOfTraining,
          enquiry: filter.enquiry,
          lead: filter.lead,
        },
      },
    });


    const offcanvasElement = document.getElementById('offcanvasRightSide');
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();
  };

  const handlePageChangeNoDueFeeRecords = (page) => {
    debouncesetPage({ context: "NO_DUE_FEE_RECORDS_STUDENTS", data: page, });
  }



  // handle the Tabs
  const [activeTabs, setActiveTable] = useState({
    FeeDetails: true,
    NoDueFeeDetails: false,
    FeeFollowUps: false,
  });

  const handleTabs = (tab) => {
    setActiveTable(prevState => ({
      ...prevState,  // Spread the previous state
      FeeDetails: tab === "FeeDetails",
      NoDueFeeDetails: tab === "NoDueFeeDetails",
      FeeFollowUps: tab === "FeeFollowUps",
    }));
  };

  useEffect(() => {
    const { FeeDetails, NoDueFeeDetails, FeeFollowUps } = activeTabs;
    if (FeeDetails) {
      getPaginatedFeeDetailsStudents();
    } else if (NoDueFeeDetails) {
      getNoDueFeeRecordStudents();
    } else if (FeeFollowUps) {
      return null;
    }
  }, [activeTabs]);



  return (
    <div>
      <BackButton heading="Fee Details" content="Back" to="/" />
      <div className="overflow-autogtd container-fluid d-flex justify-content-center ">
        <ul className="nav gap-3 nav-tabs" id="pills-tab" role="tablist">

          {/* Fee Records */}
          <li className="nav-item w_100" role="presentation">
            <button
              className={`bg_white nav-link ${activeTabs.FeeDetails ? 'active' : ''} card card_animate w_100`}
              id="pills-feedetails-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-feedetails"
              type="button"
              role="tab"
              aria-controls="pills-feedetails"
              aria-selected={activeTabs.FeeDetails}
              onClick={() => handleTabs("FeeDetails")}
            >
              <div className="text-start ">
                <div className="d-flex justify-content-between align-items-center ">
                  <div>
                    <span className="fs_20 fw-500 me-2"> Fee Records</span>
                    {/* <div className='mt-3 fs-14 lh-100 text_underline black_300'>
                      <p>12,000 <FaArrowRight /></p>
                    </div> */}
                  </div>
                  <div className="flex-shrink-0">
                    <div className="avatar-sm">
                      <span className="avatar-title bg-danger-subtle rounded-circle fs-1">
                        <img
                          src={feerecordimg}
                          className="img-fluid"
                          width="100px"
                          height="100px"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>
          {/* No Due FeeRecords */}
          <li className="nav-item w_100" role="presentation">
            <button
              className={`nav-link card card_animate w_100 ${activeTabs.NoDueFeeDetails ? 'active' : ''}`}
              id="pills-duerecords-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-duerecords"
              type="button"
              role="tab"
              aria-controls="pills-duerecords"
              aria-selected={activeTabs.NoDueFeeDetails}
              onClick={() => handleTabs("NoDueFeeDetails")}
            >
              <div className="text-start">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span className="fs_20 fw-500 me-2">No Due Records</span>
                    {/* <div className='mt-3 fs-14 lh-100 text_underline black_300'>
                      <p>5000 <FaArrowRight /></p>
                    </div> */}
                  </div>
                  <div className="flex-shrink-0">
                    <div className="avatar-sm">
                      <span className="avatar-title bg-danger-subtle rounded-circle fs-1">
                        <img
                          src={noduerecordimg}
                          className="img-fluid"
                          width="100px"
                          height="100px"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>
          {/* Fee FollowUps */}
          <li className="nav-item w_100" role="presentation">
            <Link to="/student/feefollowups">
              <button
                className={`bg_white nav-link ${activeTabs.FeeFollowUps ? 'active' : ''} card card_animate w_100`}
                id="pills-feerecords-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-feerecords"
                type="button"
                role="tab"
                aria-controls="pills-feerecords"
                aria-selected={activeTabs.FeeFollowUps}
                onClick={() => handleTabs("FeeFollowUps")}
              >
                <div className="text-start">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <span className="fs_20 fw-500 me-2">Fee FollowUps</span>
                      {/* <div className='mt-3 fs-14 lh-100 text_underline black_300'>
                        <p>1100 <FaArrowRight /></p>
                      </div> */}
                    </div>
                    <div className="flex-shrink-0">
                      <div className="avatar-sm">
                        <span className="avatar-title bg-danger-subtle rounded-circle fs-1">
                          <img
                            src={feefollowupimg}
                            className="img-fluid"
                            width="100px"
                            height="100px"
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </Link>
          </li>
        </ul>
      </div>
      <div className="tab-content mt-3" id="pills-tabContent">
        {/* FEE RECORDS */}
        {
          activeTabs.FeeDetails && (
            <div
              className={`tab-pane fade ${activeTabs.FeeDetails ? 'show active' : ''} w_100`}
              id="pills-feedetails"
              role="tabpanel"
              aria-labelledby="pills-feedetails-tab"
              tabIndex="0"
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card border-0">
                      <div className="card-header">
                        <div className="row justify-content-between">
                          <div className="col-sm-4">
                            <div className="search-box">
                              <input
                                type="text"
                                className="form-control search text_color input_bg_color select"
                                placeholder="Search for..."
                                name="search"
                                required
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="buttons_alignment">
                              <div className="fs-13 me-3 mt-2 text_color">
                              </div>
                              <button
                                className="btn btn-sm btn_primary fs-13 me-1"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                              >
                                <MdFilterList className="me-1 mb-1" />
                                Filters
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="offcanvas offcanvas-end bg_white text_color"
                          id="offcanvasRight"
                          aria-labelledby="offcanvasRightLabel"
                        >
                          <div className="offcanvas-header">
                            <h5
                              className="offcanvas-title"
                              id="offcanvasRightLabel"
                            >
                              Filters
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="offcanvas-body p-2">
                            <Filter filterData={filterData} HandleFilters={handleFilters} filterReset={FilterReset} filterSubmit={filterSubmit} />
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive table-container table-scroll table-card  border-0">

                          <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                            <thead className="">
                              <tr className="">
                                <th scope="col" className="fs-13 lh-xs fw-600  ">
                                  S.No
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                  Name
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                  Branch
                                </th>
                                <th scope="col" className="fs-13 lh-xs fw-600  ">
                                  Counsellor
                                </th>
                                <th scope="col" className="fs-13 lh-xs fw-600  ">
                                  Contact
                                </th>
                                <th scope="col" className="fs-13 lh-xs fw-600  ">
                                  Course
                                </th>

                                <th scope="col" className="fs-13 lh-xs fw-600  ">
                                  Lead Source
                                </th>

                                <th scope="col" className="fs-13 lh-xs fw-600  ">
                                  Date&nbsp;of&nbsp;Joining
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                  Total&nbsp;Fee
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600 ">
                                  Fee&nbsp;Paid
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600 ">
                                  Due&nbsp;Date
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600 ">
                                  Due&nbsp;Amount
                                </th>

                                <th scope="col" className="fs-13 lh-xs  fw-600 ">
                                  Installments
                                </th>
                                <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                  <th scope="col" className="fs-13 lh-xs  fw-600 ">
                                    Action
                                  </th>
                                </GateKeeper>
                              </tr>
                            </thead>
                            <tbody className="">
                              {FeeDetailsStudents?.PaginatedFeeDetailsStudents &&
                                FeeDetailsStudents?.PaginatedFeeDetailsStudents
                                  .length > 0 ? (
                                FeeDetailsStudents?.loading ? (
                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                    loading...
                                  </td>
                                ) : (
                                  FeeDetailsStudents?.PaginatedFeeDetailsStudents.map(


                                    (item, index) => {
                                      console.log(item, "djkhfkjdshfkdshf")
                                      return (
                                        <tr>
                                          <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                            {(FeeDetailsStudents?.currentPage - 1) *
                                              FeeDetailsStudents?.perPage +
                                              index +
                                              1}
                                          </td>
                                          <td
                                            className="fs-13 black_300  lh-xs bg_light text-truncate"
                                            style={{ maxWidth: "150px" }}
                                            title={item.name}
                                          >
                                            <Link
                                              to={`/student/feeview/${item?.id}`}>
                                              {item?.name}
                                            </Link>

                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.branch}
                                          </td>
                                          <td
                                            className="fs-13 black_300  lh-xs bg_light text-truncate"
                                            style={{ maxWidth: "100px" }}
                                            title={item?.enquirytakenby}
                                          >
                                            {item?.enquirytakenby}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.mobilenumber}
                                          </td>
                                          <td
                                            className="fs-13 black_300  lh-xs bg_light text-truncate"
                                            style={{ maxWidth: "150px" }}
                                            title={item?.courses}
                                          >
                                            {item?.courses}
                                          </td>

                                          <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }}
                                            title={item?.leadsource[0]?.source}
                                          >
                                            {item?.leadsource[0]?.source}
                                          </td>


                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.admissiondate}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.finaltotal?.toLocaleString("en-IN")}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.totalpaidamount?.toLocaleString("en-IN")}
                                          </td>
                                          <td className="fs-13 black_300 lh-xs bg_light">
                                            {item?.dueamount && item?.dueamount === 0 ? "None" : (item?.nextduedate ? item?.nextduedate : "-")}
                                          </td>

                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.dueamount?.toLocaleString("en-IN")}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light ">
                                            {item?.totalinstallments && item?.totalinstallments.length > 0 ?
                                              `${item?.totalinstallments[0].totalinstallmentspaid}/${item?.totalinstallments[0]?.totalinstallments}` :
                                              '-'
                                            }
                                          </td>
                                          <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                            <td className="fs-14 text_mute bg_light   lh-xs">
                                              <Link
                                                to={`/student/feeview/${item?.id}`}
                                              >
                                                <AiFillEye className=" eye_icon me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                              </Link>
                                            </td>
                                          </GateKeeper>

                                        </tr>
                                      );
                                    }
                                  )
                                )
                              ) : (
                                <tr>
                                  <td className="fs-13 black_300  lh-xs bg_light ">
                                    No Data
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {/* pagination */}
                        <div className=" mt-3 align-items-center d-flex justify-content-between row text-center text-sm-start">
                          <div className="col-sm">
                            <PaginationInfo
                              data={{
                                length: FeeDetailsStudents?.PaginatedFeeDetailsStudents?.length,
                                start: FeeDetailsStudents?.startStudent,
                                end: FeeDetailsStudents?.endStudent,
                                total: FeeDetailsStudents?.searchResultStudents,
                              }}
                              loading={FeeDetailsStudents?.loading}
                            />
                          </div>
                          <div className="col-sm-auto mt-3 mt-sm-0 d-flex">
                            <div className="mt-3">
                              <select
                                className="form-select form-control me-3 text_color input_bg_color pagination-select"
                                aria-label="Default select example"
                                placeholder="Branch*"
                                name="branch"
                                id="branch"
                                required
                                onChange={handlePerPage}
                                value={FeeDetailsStudents?.perPage}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                              </select>
                            </div>

                            <div className="">
                              <Pagination
                                currentPage={FeeDetailsStudents?.currentPage}
                                totalPages={FeeDetailsStudents?.totalPages}
                                loading={FeeDetailsStudents?.loading}
                                onPageChange={handlePageChangeFeeDetails}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {/* No Due Fee Details */}
        {
          activeTabs.NoDueFeeDetails && (
            <div
              className={`tab-pane fade ${activeTabs.NoDueFeeDetails ? 'show active' : ''}`}
              id="pills-duerecords"
              role="tabpanel"
              aria-labelledby="pills-duerecords-tab"
              tabIndex="0"
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card border-0">
                      <div className="card-header">
                        <div className="row justify-content-between">
                          <div className="col-sm-4">
                            <div className="search-box">
                              <input
                                type="text"
                                className="form-control search text_color input_bg_color"
                                placeholder="Search for..."
                                name="search"
                                required
                                onChange={handleSearchDueFee}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="buttons_alignment">
                              <div className="fs-13 me-3 mt-2 text_color">
                                {/* 10/40 */}
                              </div>

                              <button
                                className="btn btn-sm btn_primary fs-13 me-2"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRightSide"
                                aria-controls="offcanvasRightSide"
                              >
                                <MdFilterList className="me-1 mb-1" />
                                Filters
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="offcanvas offcanvas-end  bg_white text_color"
                          id="offcanvasRightSide"
                          aria-labelledby="offcanvasRightLabel"
                        >
                          <div className="offcanvas-header">
                            <h5
                              className="offcanvas-title"
                              id="offcanvasRightLabel"
                            >
                              Filters
                            </h5>
                            <button
                              type="button"
                              className="btn-close "
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="offcanvas-body p-2">
                            <Filter filterData={noDueFilterData} HandleFilters={handleFiltersNoDue} filterReset={FilterResetDueFee} filterSubmit={filterSubmitDueFee} />
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive table-container table-scroll table-card  border-0">

                          <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                            <thead className="">
                              <tr className="">
                                <th scope="col" className="fs-13 lh-xs fw-600  ">
                                  S.No
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                  Name
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                  Branch
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                  Counsellor
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                  Contact
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                  Course
                                </th>
                                <th scope="col" className="fs-13 lh-xs fw-600  ">
                                  Lead Source
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                  Date&nbsp;of&nbsp;Joining
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600  ">
                                  Total&nbsp;Fee
                                </th>
                                <th scope="col" className="fs-13 lh-xs fw-600 ">
                                  Fee&nbsp;Paid
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600 ">
                                  Due&nbsp;Date
                                </th>
                                <th scope="col" className="fs-13 lh-xs  fw-600 ">
                                  Due&nbsp;Amount
                                </th>

                                <th scope="col" className="fs-13 lh-xs  fw-600 ">
                                  Installments
                                </th>
                                <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                  <th scope="col" className="fs-13 lh-xs fw-600 ">
                                    Action
                                  </th>
                                </GateKeeper>
                              </tr>
                            </thead>
                            <tbody className="">
                              {NoDueFeeRecords_Students?.PaginatedNoDueFeeRecords_Students &&
                                NoDueFeeRecords_Students
                                  .PaginatedNoDueFeeRecords_Students.length > 0 ? (
                                NoDueFeeRecords_Students?.loading ? (
                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                    loading...
                                  </td>
                                ) : (
                                  NoDueFeeRecords_Students?.PaginatedNoDueFeeRecords_Students.map(
                                    (item, index) => {
                                      return (
                                        <tr>
                                          <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                            {(NoDueFeeRecords_Students?.currentPage - 1) *
                                              NoDueFeeRecords_Students?.perPage +
                                              index +
                                              1}

                                          </td>
                                          <td
                                            className="fs-13 black_300  lh-xs bg_light text-truncate"
                                            style={{ maxWidth: "150px" }}
                                            title={item?.name}
                                          >
                                            {item?.name}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.branch}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.enquirytakenby}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.mobilenumber}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light  text-truncate" style={{ maxWidth: "120px" }} title={item.courses} >
                                            {item?.courses}
                                          </td>

                                          <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }}
                                            title={item?.leadsource[0]?.source}
                                          >
                                            {item?.leadsource[0]?.source}
                                          </td>

                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.admissiondate}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.finaltotal?.toLocaleString("en-IN")}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.totalpaidamount?.toLocaleString("en-IN")}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.nextduedate}
                                          </td>
                                          <td className="fs-13 black_300  lh-xs bg_light">
                                            {item?.dueamount?.toLocaleString("en-IN")}
                                          </td>

                                          <td className="fs-13 black_300  lh-xs bg_light ">
                                            {
                                              item?.totalinstallments[0]
                                                ?.totalinstallmentspaid
                                            }{" "}
                                            /{" "}
                                            {
                                              item?.totalinstallments[0]
                                                ?.totalinstallments
                                            }
                                          </td>


                                          <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                            <td className="fs-14 text_mute bg_light   lh-xs">
                                              <Link to={`/student/feeview/${item?.id}`}>
                                                <AiFillEye className=" eye_icon me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                              </Link>
                                            </td>
                                          </GateKeeper>
                                        </tr>
                                      );
                                    }
                                  )
                                )
                              ) : (
                                <tr>
                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                    No Data
                                  </td>
                                </tr>
                              )}


                            </tbody>
                          </table>
                        </div>
                        <div className=" mt-4 align-items-center d-flex justify-content-between row text-center text-sm-start">
                          <div className="col-sm">

                            <PaginationInfo
                              data={{
                                length: NoDueFeeRecords_Students?.PaginatedNoDueFeeRecords_Students?.length,
                                start: NoDueFeeRecords_Students?.startStudent,
                                end: NoDueFeeRecords_Students?.endStudent,
                                total: NoDueFeeRecords_Students?.searchResultStudents,
                              }}
                              loading={NoDueFeeRecords_Students?.loading}
                            />

                          </div>
                          <div className="col-sm-auto mt-3 mt-sm-0 d-flex">
                            <div className="mt-2">
                              <select
                                className="form-select form-control me-3 text_color input_bg_color pagination-select"
                                aria-label="Default select example"
                                placeholder="Branch*"
                                name="branch"
                                id="branch"
                                required
                                onChange={handlePerPageDueFee}
                                value={NoDueFeeRecords_Students?.perPage}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <div className="">
                              <Pagination
                                currentPage={NoDueFeeRecords_Students?.currentPage}
                                totalPages={NoDueFeeRecords_Students?.totalPages}
                                loading={NoDueFeeRecords_Students?.loading}
                                onPageChange={handlePageChangeNoDueFeeRecords}
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default FeeDetailsPage;






