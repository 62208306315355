export const SET_SEARCH= "SET_SEARCH";
export const SET_ALL_USERS ="SET_ALL_USERS"
export const SET_LOADING ="SET_LOADING"
export const SET_PAGINATED_USERS="SET_PAGINATED_USERS"
export const SET_PER_PAGE="SET_PER_PAGE";
export const SET_PROFILE ="SET_PROFILE";
export const SET_DEPARTMENT ="SET_DEPARTMENT";
export const SET_BRANCH="SET_BRANCH";
export const CREATE_USER="CREATE_USER";
export const SET_FILTERS ="SET_FILTERS";
export const SET_CUSTOM_PAGE ="SET_CUSTOM_PAGE"
export const SET_ALL_COUNCELLERS= "SET_ALL_COUNCELLERS"
export const SET_ALL_USERS_WITHOUT_COUNCELLERS = "SET_ALL_USERS_WITHOUT_COUNCELLERS"
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const UPDATE_USER_REMARKS_HISTORY ="UPDATE_USER_REMARKS_HISTORY";
