import React, { useContext, useEffect, useState } from 'react'
import BackButton from '../../../components/backbutton/BackButton';
import Button from '../../../components/button/Button';
import { MdDelete, MdFilterList } from "react-icons/md";
import { MdFileDownload } from "react-icons/md";
import { HiMiniPlus } from "react-icons/hi2";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import JoblistProvider from './JoblistProvider';
import Usedebounce from '../../../../dataLayer/hooks/useDebounce/Usedebounce';
import { RiEdit2Line } from 'react-icons/ri';
import { ERPApi } from '../../../../serviceLayer/interceptor';
import Swal from 'sweetalert2';
import { usePermissionsProvider } from '../../../../dataLayer/hooks/usePermissionsProvider';
import GateKeeper from '../../../../rbac/GateKeeper';
import { toast } from 'react-toastify';
import { FaShareAlt } from 'react-icons/fa';
import SharePopup from './SharePopup';
import Pagination from '../../../../utils/Pagination';
import PaginationInfo from '../../../../utils/PaginationInfo';

function JobList() {

  const { JobsState, JobsState: { JobsList }, DispatchJobs, getPaginatedJobsList } = JoblistProvider();
  const { permission } = usePermissionsProvider();

  console.log(JobsState, "JobsState");

  const { debouncesetSearch, debouncesetPage } = Usedebounce(DispatchJobs);
  // search
  const handleSearch = (e) => {
    debouncesetSearch({ data: e.target.value });
  };

  // pageSize
  const handlePerPage = (e) => {
    const selectedvalue = parseInt(e.target.value, 10);
    console.log(selectedvalue, "selectedvalue")
    DispatchJobs({
      type: "SET_PER_PAGE",
      payload: {
        data: selectedvalue,
      },
    });
  };


  const [filters, setFilters] = useState({
    jobType: "",
    workPlaceType: "",
    experience: "",
    // companyName: "",
    // jobTitle: "",
    // location: "",
    // location: "",
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }))
  };

  const handleReset = () => {
    DispatchJobs({
      type: "SET_FILTERS",
      payload: {
        data: {
          jobType: "",
          workPlaceType: "",
          experience: "",
          // companyName: "",
          // jobTitle: "",
          // location: "",

        },
      },
    });
    setFilters({
      jobType: "",
      workPlaceType: "",
      experience: "",
      // companyName: "",
      // jobTitle: "",
      // location: "",

    })
  };

  const handleSubmit = () => {
    DispatchJobs({
      type: "SET_FILTERS",
      payload: {
        data: {
          jobType: filters.jobType,
          workPlaceType: filters.workPlaceType,
          experience: filters.experience,
          // companyName: filters.companyName,
          // jobTitle: filters.jobTitle,
          // location: filters.location,
        },
      },
    });
  }

  // Pagination 

  const handlePageChange = (page) => {
    debouncesetPage({ data: page });
  }



  // Delete the Job Position
  const handleDeleteJobPosition = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this Job Position",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        let jobId = { id: id };
        try {
          const { data, status } = await ERPApi.delete(`${process.env.REACT_APP_API_URL}/jobs/job-posting/${id}`, id)
          console.log(data, status, "seethdsvf");
          if (status === 200) {
            console.log(data, "hellobb");
            DispatchJobs({
              type: "DELETE_JOB_POSITION",
              payload: jobId,
            });

            Swal.fire({
              title: "Deleted!",
              text: "Job Position deleted Successfully.",
              icon: "success"
            });
          }
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 500) {
            toast.error(error?.response?.message);
          }
        }
      }
    });
  };

  const [selectedJob, setSelectedJob] = useState(null);


  const handleShareButtonClick = (job) => {
    console.log("Selected job for sharing:", job);
    setSelectedJob(job);
  };

  const handleClosePopup = () => {
    setSelectedJob(null);
  };



  return (
    <div>
      <BackButton heading="Job List" content="Back" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card border-0">
              <div className="card-header">
                <div className="row justify-content-between">
                  <div className="col-sm-4">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search input_bg_color select"
                        placeholder="Search for..."
                        name="search"
                        required
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="buttons_alignment">
                      <div className="fs-13 me-3 mt-2">

                      </div>
                      <button
                        className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        <MdFilterList className="me-1 mb-1 text_white" />
                        Filters
                      </button>
                      <GateKeeper requiredModule="HR Management" requiredPermission="all" submenumodule="Job Openings" submenuReqiredPermission="canCreate">
                        <Link to="/hrmanagement/addjobpost">
                          <button className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white">
                            Post a Job
                          </button>
                        </Link>
                      </GateKeeper>
                    </div>
                  </div>
                </div>
                <div
                  className="offcanvas offcanvas-end bg_light"
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                >
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                      Filters
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="offcanvas-body p-2">


                    {/* JOB type */}
                    <div className="">
                      <label className="form-label fs-s fw-medium text_color">
                        Job Type
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder="Select the Job Type"
                        name="jobType"
                        id="jobType"
                        required
                        onChange={(e) => handleFilterChange(e)}
                        value={filters.jobType}
                      >
                        <option value="" disabled selected className="">Select the Job Type</option>
                        <option value="full-time">Full-time</option>
                        <option value="part-time">Part-time</option>
                        <option value="contract">Contract</option>
                        <option value="temporary">Temporary</option>
                        <option value="other">Other</option>
                        <option value="volunteer">Volunteer</option>
                        <option value="internship">Internship</option>
                      </select>
                    </div>
                    {/* WOrk Place Type */}
                    <div className="mt-2">
                      <label className="form-label fs-s fw-medium black_300">
                        Work Mode
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder="Select the WorkPlace Type"
                        name="workPlaceType"
                        id="workPlaceType"
                        required
                        onChange={(e) => handleFilterChange(e)}
                        value={filters?.workPlaceType}
                      >
                        <option value="" disabled selected className="">Select the WorkPlace Type</option>
                        <option value="on-site">On-Site</option>
                        <option value="hybrid">Hybrid</option>
                        <option value="remote">Remote</option>
                      </select>
                    </div>
                    <div className="">
                      <label className="form-label fs-s fw-medium text_color">
                        Experience
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder="Select the Job Type"
                        name="experience"
                        id="experience"
                        required
                        onChange={(e) => handleFilterChange(e)}
                        value={filters.experience}
                      >
                        <option value="" disabled selected>Select the Experience</option>
                        <option value="fresher">Fresher</option>
                        <option value="0-1 year">0-1 year</option>
                        <option value="1-3 years">1-3 years</option>
                        <option value="3-5 years">3-5 years</option>
                        <option value="5-7 years">5-7 years</option>
                        <option value="7-10 years">7-10 years</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                    {/* <div className="">
                      <label className="form-label fs-s fw-medium text_color">
                        Company Name
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder="Select the Company Name"
                        name="companyName"
                        id="companyName"
                        required
                        onChange={(e) => handleFilterChange(e)}
                        value={filters.companyName}
                      >
                        <option value="" disabled selected className="">Select the Company Name</option>
                        <option value="infozit">Infozit</option>
                        <option value="teksacademy">Teksacademy</option>
                        <option value="kapilgrouup">kapilgrouup</option>

                      </select>
                    </div>
                    <div className="">
                      <label className="form-label fs-s fw-medium text_color">
                        job Title
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder="Select the job Title"
                        name="jobTitle"
                        id="jobTitle"
                        required
                        onChange={(e) => handleFilterChange(e)}
                        value={filters.jobTitle}
                      >
                        <option value="" disabled selected className="">Select the job Title</option>
                        <option value="software developer">Software Developer</option>
                        <option value="node js developer">Node js Developer</option>
                        <option value="react js developer">React js Developer</option>

                      </select>
                    </div>
                    <div className="">
                      <label className="form-label fs-s fw-medium text_color">
                        Location
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder="Select the Location"
                        name="location"
                        id="location"
                        required
                        onChange={(e) => handleFilterChange(e)}
                        value={filters.location}
                      >
                        <option value="" disabled selected className="">Select the Location</option>
                        <option value="hyderbad">Hyderbad</option>
                        <option value="pune">Pune</option>
                        <option value="mumbai">Mumbai</option>

                      </select>
                    </div> */}

                    {/* Location */}

                    {/* <div className="mt-2">
                      <label className="form-label fs-s fw-medium text_color">Location</label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        id="location"
                        name="location"
                        placeholder="Select the Location"
                        required
                        onChange={(e) => handleFilterChange(e)}
                        value={filters?.location}
                      >
                        <option value="" disabled selected>Select the location</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Bengulore">Bengulore</option>
                        <option value="Pune">Pune</option>
                        <option value="Kolkata">Kolkata</option>
                      </select>
                    </div> */}
                    {/* Extra filters */}
                    {/* <div className='mt-2'>
                      <label className="form-label fs-s fw-medium text_color">
                        From Date
                      </label>
                      <input
                        className="  form-control input_bg_color  date_input_color "
                        id="rdob"
                        name="fromDate"
                        type="date"

                      />
                    </div>
                    <div className="mt-2">
                      <label className="form-label fs-s fw-medium text_color">
                        To Date
                      </label>
                      <input

                        className=" form-control input_bg_color  date_input_color "
                        id="rdob"
                        name="toDate"
                        type="date"

                      />
                    </div>
                    <div className="mt-2">
                      <label
                        for="firstNameinput"
                        className="form-label fs-s fw-medium black_300"
                      >Batch Timing
                      </label>
                      <input
                        type="time"
                        className="form-control fs-s bg-form text_color input_bg_color"
                        id=""
                        name=""
                      />
                    </div> */}

                    <div className="position-absolute bottom-0 start-0 ms-2 mb-2">
                      <button className="btn btn_primary"
                        type="button"
                        // className="btn-close"
                        // data-bs-dismiss="offcanvas"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => handleReset()}
                      >
                        Clear
                      </button>
                    </div>
                    <div className="position-absolute bottom-0 end-0 me-2 mb-2">
                      <button className="btn btn_primary" data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => handleSubmit()}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-card table-container table-scroll border-0">
                  <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                    <thead>
                      <tr className="">

                        <th
                          scope="col"
                          className="fs-13 lh-xs fw-600 "
                        >
                          S.No
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Job Title
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Company Name
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Work Mode
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs fw-600  "
                        >
                          Location
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600 " >

                          Job Type
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600 " >

                          Experience
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600 " >

                          Number Of Position
                        </th>
                        {/* <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600 "  >
                          Action
                        </th> */}

                        {permission?.permissions.map((perm) => {
                          if (perm.module === "HR Management") {
                            return perm?.submenus?.map((submenu) => {
                              if (submenu?.module === "Job Openings" && (submenu?.canUpdate === true || submenu?.canDelete === true || submenu?.canRead === true)) {
                                return (
                                  <th scope="col" className="fs-13 lh-xs fw-600" key={`${submenu.module}-${submenu.id}`}>
                                    Actions
                                  </th>
                                );
                              }
                              return null;
                            });
                          }
                          return null;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        JobsList?.PaginatedJobs && JobsList?.PaginatedJobs.length > 0 ? JobsList.loading ?
                          (
                            <tr>
                              <td className="fs-13 black_300 fw-500 lh-xs bg_light">loading...</td>
                            </tr>
                          ) :
                          JobsList?.PaginatedJobs?.map((item, index) => {
                            const JobID = item?.id;
                            return (
                              <tr key={JobID}>
                                <td className="fs-13 black_300 fw-500 lh-xs bg_light">
                                  {(JobsList?.currentPage - 1) * JobsList.perPage + index + 1}
                                </td>
                                <td className="fs-13 black_300 lh-xs bg_light">{item?.title}</td>
                                <td className="fs-13 black_300 lh-xs bg_light">{item?.company_name}</td>
                                <td className="fs-13 black_300 lh-xs bg_light">{item?.workplace_type}</td>
                                <td className="fs-13 black_300 lh-xs bg_light">{item?.location}</td>
                                <td className="fs-13 black_300 lh-xs bg_light">{item?.job_type}</td>
                                <td className="fs-13 black_300 lh-xs bg_light">{item?.experience}</td>
                                <td className="fs-13 black_300 lh-xs bg_light">{item?.number_of_positions}</td>
                                {permission?.permissions.map((perm) => {
                                  if (perm.module === "HR Management") {
                                    return perm?.submenus?.map((submenu) => {
                                      if (submenu?.module === "Job Openings" && (submenu?.canUpdate === true || submenu?.canDelete === true || submenu?.canRead === true)) {
                                        return (
                                          <td key={`${submenu.module}-${submenu.id}`} className="fs-13 black_300 lh-xs bg_light cursor-pointer">
                                            <GateKeeper requiredModule="HR Management" requiredPermission="all" submenumodule="Job Openings" submenuReqiredPermission="canRead">
                                              <Link to={`/hrmanagement/jobpostview/${JobID}`}>
                                                <AiFillEye className="me-3 eye_icon" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                              </Link>
                                            </GateKeeper>
                                            <GateKeeper requiredModule="HR Management" requiredPermission="all" submenumodule="Job Openings" submenuReqiredPermission="canUpdate">
                                              <Link to={`/hrmanagement/addjobpost/${JobID}`}>
                                                <RiEdit2Line className="edit_icon me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" />
                                              </Link>
                                            </GateKeeper>

                                            <GateKeeper requiredModule="HR Management" requiredPermission="all" submenumodule="Job Openings" submenuReqiredPermission="canDelete">
                                              <MdDelete className="delete_icon me-3" onClick={() => handleDeleteJobPosition(JobID)} />
                                            </GateKeeper>

                                            <FaShareAlt className="me-3 eye_icon" onClick={() => handleShareButtonClick(item)} title="Share" />

                                          </td>
                                        );
                                      }
                                      return null; // Return null when the conditions are not met
                                    });
                                  }
                                  return null;
                                })}


                                {/* <Link to={`/hrmanagement/jobpostview/${JobID}`}>
                                    <AiFillEye className="me-3 eye_icon" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                  </Link>

                                  <Link to={`/hrmanagement/addjobpost/${JobID}`}>
                                    <RiEdit2Line className="edit_icon me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" />
                                  </Link>

                                  <MdDelete
                                    className="delete_icon me-3"
                                    onClick={() => handleDeleteJobPosition(JobID)}
                                  /> */}

                              </tr>
                            )
                          })
                          :
                          <tr>
                            <td className="fs-13 black_300  lh-xs bg_light ">
                              No data
                            </td>
                          </tr>
                      }

                    </tbody>
                  </table>
                  {selectedJob && (
                    <SharePopup job={selectedJob} onClose={handleClosePopup} />
                  )}
                </div>
                <div className=" mt-3 align-items-center d-flex justify-content-between row text-center text-sm-start">
                  <div className="col-sm">

                    <PaginationInfo
                      data={{
                        length: JobsList?.PaginatedJobs?.length,
                        start: JobsList?.startJob,
                        end: JobsList?.endJob,
                        total: JobsList?.searchResultJobs,
                      }}
                      loading={JobsList?.loading}
                    />

                  </div>
                  <div className="col-sm-auto mt-3 mt-sm-0 d-flex pagination-res">
                    <div className="mt-2">
                      <select

                        className="form-select form-control me-3 input_bg_color pagination-select "
                        aria-label="Default select example"
                        required
                        onChange={handlePerPage}
                        value={JobsList?.perPage}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>

                    <div className=''>
                      <Pagination
                        currentPage={JobsList?.currentPage}
                        totalPages={JobsList?.totalPages}
                        loading={JobsList?.loading}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}




export default JobList;



