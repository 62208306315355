import { Route, Routes } from "react-router-dom";
import HRdashboard from "../../componentLayer/pages/hrManagement/dashboard/HRdashboard";
import JobList from "../../componentLayer/pages/hrManagement/jobOpenings/JobList";
import Applicants from "../../componentLayer/pages/hrManagement/applicants/Applicants";
import JobPostView from "../../componentLayer/pages/hrManagement/jobOpenings/JobPostView";
import AddjobPost from "../../componentLayer/pages/hrManagement/jobOpenings/AddjobPost";
import RouteBlocker from "../../rbac/RouteBlocker";
import Error from "../../componentLayer/pages/Error/Error";


function HRManagementRoutes() {

  return (
    <Routes>
       <Route path='*' element={<Error />} />
      <Route path="/hrdashboard" element={< HRdashboard />} />
      
      <Route path="/joblist" element={
        <RouteBlocker
          requiredModule="HR Management"
          requiredPermission="all"
          submenumodule="Job Openings"
          submenuReqiredPermission="canRead"
        >
          <JobList />
        </RouteBlocker>
      } />

      <Route path="/addjobpost" element={
        <RouteBlocker
          requiredModule="HR Management"
          requiredPermission="all"
          submenumodule="Job Openings"
          submenuReqiredPermission="canCreate"
        >
          < AddjobPost />
        </RouteBlocker>
      } />

      <Route path="/addjobpost/:id" element={
        <RouteBlocker
          requiredModule="HR Management"
          requiredPermission="all"
          submenumodule="Job Openings"
          submenuReqiredPermission="canUpdate"
        >
          < AddjobPost />
        </RouteBlocker>
      } />

      <Route path="/jobpostview/:id" element={
        <RouteBlocker
          requiredModule="HR Management"
          requiredPermission="all"
          submenumodule="Job Openings"
          submenuReqiredPermission="canRead"
        >
          < JobPostView />
        </RouteBlocker>
      } />


      <Route path="/applicants" element={
        <RouteBlocker
          requiredModule="HR Management"
          requiredPermission="all"
          submenumodule="Applicants"
          submenuReqiredPermission="canRead"
        >
          <Applicants />
        </RouteBlocker>
      } />

    </Routes>
  );
}
export default HRManagementRoutes;