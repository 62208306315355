
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barColors: {
    0: '#405189',
    '1.0': '#405189',
  },
  barThickness: 5,
  shadowBlur: 4,
});

const TopBarloading=()=>{
    return <TopBarProgress/>;
}

export default TopBarloading;

