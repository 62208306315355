import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const GraphThree = () => {
    // Define MultipleHeatmap function inside GraphThree component
    const MultipleHeatmap = () => {
        // Move generateData function inside MultipleHeatmap function
        function generateData(count, yrange) {
            var i = 0;
            var series = [];
            var times = ['10:00', '10:30', '11:00', '11:30', '1:00', '1:30', '2:00', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30'];
            while (i < count) {
                var x = times[Math.floor(Math.random() * times.length)];
                var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

                series.push({
                    x: x,
                    y: y
                });
                i++;
            }
            return series;
        }

        // Generate data array using generateData function
        const data = [
            { name: '10:00-10:30', data: generateData(7, { min: 0, max: 120 }) },
            { name: '10:30-11:00', data: generateData(7, { min: 0, max: 120 }) },
            { name: '11:00-11:30', data: generateData(7, { min: 0, max: 120 }) },
            { name: '1:00-1:30', data: generateData(7, { min: 0, max: 120 }) },
            { name: '2:00-3:00', data: generateData(7, { min: 0, max: 120 }) },
            { name: '3:00-3:30', data: generateData(7, { min: 0, max: 120 }) },
            { name: '4:00-4:30', data: generateData(7, { min: 0, max: 120 }) },
            { name: '5:00-5:30', data: generateData(7, { min: 0, max: 120 }) },
            { name: '5:30-6:00', data: generateData(7, { min: 0, max: 120 }) }
        ];

        return data;
    }

    // Initialize state for multiChart
    const [multiChart, setMultiChart] = useState({
        series: MultipleHeatmap(), // Call MultipleHeatmap function to get initial series data
        options: {
            chart: {
                height: 450,
                type: 'heatmap',
                toolbar: { show: false }
            },
            plotOptions: {
                heatmap: {
                    colorScale: {
                        ranges: [
                            { from: 0, to: 10, color: '#808080' }, // Gray
                            { from: 11, to: 40, color: '#405189' }, // Blue
                            { from: 41, to: 70, color: '#ffc107' },  // Yellow
                            { from: 71, to: 120, color: '#eb6329' } // Orange
                        ]
                    },
                    distributed: true, // Distributes the boxes
                    columnWidth: '100%', // Increase the column width to make boxes wider
                    rowHeight: 60 // Increase the row height to make boxes taller
                }
            },
            dataLabels: { 
                enabled: true, 
                style: {
                    colors: ['#fff'],
                    fontSize: '10px'
                } 
            },
            xaxis: {
                type: 'category',
                categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
            },
            yaxis: {
                type: 'category',
                categories: ['10:00-10:30', '10:30-11:00', '11:00-11:30', '1:00-1:30', '2:00-3:00', '3:00-3:30', '4:00-4:30', '5:00-5:30', '5:30-6:00'],
                labels: {
                    offsetX: -60 // Adjust this value to move the labels to the left
                }
            },
            grid: {
                padding: {
                    left: -50 // Adjust this value to move the graph to the left
                }
            }
        }
    });

    return (
        <div>
            <ReactApexChart
                dir="ltr"
                id="chart"
                className="apex-charts responsive-chart"
                series={multiChart.series}
                options={multiChart.options}
                type="heatmap"
                height={400}
            />
        </div>
    );
};

export default GraphThree;
