import { createContext, useEffect, useReducer } from "react";

import axios from "axios";
import BranchReducer from "./BranchReducer";
import {ERPApi} from "../../../serviceLayer/interceptor";

export const BranchContext = createContext();

const BranchContextProvider = ({ children }) => {
  const intialState = {
    branches: [],
  };

  const [BranchState, DispatchBranch] = useReducer(BranchReducer, intialState);

  const getAllBranches = async () => {
    try {
      const { status, data } = await ERPApi.get(`${process.env.REACT_APP_API_URL}/settings/getbranch`);
      console.log(data,status, "dkhbfjsvbj");
      if (status === 200) {
        DispatchBranch({ type: "SET_BRANCHES", payload: data });
      }
    } catch (error) {
      console.log(error);
    }
  };

 

  useEffect(() => {
    getAllBranches();
  }, []);


  return (
    <BranchContext.Provider
      value={{ BranchState, DispatchBranch, getAllBranches }}
    >
      {children}
    </BranchContext.Provider>
  );
};

export default BranchContextProvider;
