import React from 'react'
import { useState } from 'react';
import GateKeeper from "../../../../rbac/GateKeeper";
import BackButton from "../../../components/backbutton/BackButton";
import { BsPersonCheck } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { PiStudent } from "react-icons/pi";
const Trainers = () => {
  const [selectedBatch, setSelectedBatch] = useState(null);

  // Function to handle click on batch time
  const handleBatchClick = (batchTime) => {
    setSelectedBatch(batchTime);
  };
  
  return (
    <div>
      <BackButton heading=" Trainers" content="Back" />
      <div className="container-fluid">
        <div className="card-body">
          <ul
            className="row nav nav-justified nav-fill"
            id="pills-tab"
            role="tablist"
          >
            <GateKeeper
              requiredModule="Student Management"
              submenumodule="Enrolled Students"
              submenuReqiredPermission="canRead"
            >
              <li
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 nav-item"
                role="presentation"
              >
                <button
                  className="card card_animate nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"

                >
                  <div className="d-flex align-items-center w-100 justify-content-between">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-start text-uppercase fw-medium text-mute text-truncate mt-1 fs-14">
                        Active Batch
                      </p>
                    </div>

                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-2 mb-2 w-100">
                    <div className="text-start">
                      <h4 className="fs-22 fw-semibold ff-secondary mb-3 display_no">
                        <span className="counter-value" data-target="559.25">
                          100
                        </span>{" "}
                      </h4>

                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-success-subtle rounded fs-3">
                        <BsPersonCheck className="light-blue-color fs-20" />
                      </span>
                    </div>
                  </div>
                </button>
              </li>
            </GateKeeper>

            <GateKeeper
              requiredModule="Student Management"
              submenumodule="Fee Details"
              submenuReqiredPermission="canRead"
            >
              <li
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 nav-item"
                role="presentation"
              >
                <button
                  className="card nav-link card_animate"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"

                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-start text-uppercase fw-medium text-mute text-truncate mt-1 fs-14">
                        Active Students
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      <h5 className="text-danger fs-14 mb-0">
                        {/* <FiArrowDownRight className="text-danger" />
                    -3.57 % */}
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex align-items-end mt-2 mb-2 justify-content-between w-100">
                    <div className="text-start">
                      <h4 className="fs-22 fw-semibold ff-secondary mb-3 display_no">
                        <span className="counter-value" data-target="36894">
                          200



                        </span>
                      </h4>

                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-info-subtle rounded fs-3">
                        <PiStudent className="text-success fs-20" />
                      </span>
                    </div>
                  </div>
                </button>
              </li>
            </GateKeeper>

            <GateKeeper
              requiredModule="Student Management"
              submenumodule="Fee Details"
              submenuReqiredPermission="canRead"
            >
              <li
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 nav-item"
                role="presentation"
              >

                <button
                  className="card nav-link card_animate"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  <div className="d-flex align-items-center w-100 justify-content-between">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-start text-uppercase fw-medium text-mute text-truncate mt-1 fs-14">
                        Upcoming Batches
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      <h5 className="text-success fs-14 mb-0">
                        <i className="ri-arrow-right-up-line fs-14 align-middle"></i>{" "}
                        {/* +29.08 % */}
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-2 mb-2 w-100">
                    <div className="text-start">
                      <h4 className="fs-22 fw-semibold ff-secondary mb-3 display_no">
                        <span className="counter-value" data-target="183.35">
                          80
                        </span>
                      </h4>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-warning-subtle rounded fs-3">
                        <IoIosPeople className="text_yellow fs-20" />
                      </span>
                    </div>
                  </div>
                </button>
              </li>
            </GateKeeper>
          </ul>
        </div>

        <div className="card">
          <div className="card-header">
            <div className="card-body">
              <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                  <thead>
                    <tr className="">
                      <th
                        scope="col"
                        className="fs-13 lh-xs fw-600  "
                      >
                        S.No
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600  "
                      >
                        Batch Time
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600  "
                      >
                        Training Mode
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600 text-truncate"
                      >
                        Course
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600 text-truncate"
                      >
                        Start Date
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs fw-600  text-truncate"
                      > Total Duration Batch(in Session)
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600 text-truncate"
                      >
                        Current Duration
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600 text-truncate"
                      >
                        Total Students
                      </th>
                    </tr>
                  </thead>
                  <tr onClick={() => handleBatchClick("10:30")}>
                    <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                      01
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light">
                      10:30
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light">
                      Offline
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light ">
                      Python
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light ">
                      20-05-2024
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light ">
                      100
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light ">
                      50
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light ">
                      110
                    </td>
                  </tr>
                  <tbody>
                    <tr>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {selectedBatch && (
        <div className="card">
          <div className="card-header">
            <div className="card-body">
              <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                  <thead>
                    <tr className="">
                      <th
                        scope="col"
                        className="fs-13 lh-xs fw-600  "
                      >
                        S.No
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600  "
                      >
                        Student Name
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600  "
                      >
                        Enrollement Id
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs fw-600  text-truncate"
                      >Course
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs fw-600  text-truncate"
                      >Training Mode
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600 text-truncate"
                      >
                        Admission Date
                      </th>
                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600 text-truncate"
                      >
                        Batch Start Date
                      </th>


                      <th
                        scope="col"
                        className="fs-13 lh-xs  fw-600 text-truncate"
                      >
                        Attendance
                      </th>
                    </tr>
                  </thead>
                  <tr>
                    <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                      01
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light">
                      Krishna
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light ">
                      TA85485755
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light">
                      Testing
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light">
                      Offline
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light ">
                      12-05-2024
                    </td>
                    <td className="fs-13 black_300  lh-xs bg_light ">
                      20-05-2024
                    </td>


                    <td className="fs-13 black_300  lh-xs bg_light ">
                      10
                    </td>
                  </tr>
                  <tbody>
                    <tr>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
          )}
      </div>
    </div>
  )
}

export default Trainers