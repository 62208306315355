
import * as ActionTypes from "./utils/BatchesActionTypes"


const BatchesReducer = (state, action) => {

    switch (action.type) {
        
        case ActionTypes.SET_PAGINATED_BATCHES:

            if (action?.payload?.context === "ACTIVE_BATCHES_LIST") {

                const reducerData = action?.payload?.data;
                

                console.log(reducerData, "reducerDataghfjf")
                return {
                    ...state,
                    ActiveBatchesList: {
                        ...state.ActiveBatchesList,
                        PaginatedActiveBatches: reducerData?.students,
                        searchResultBatches: reducerData?.searchResultStudents,
                        perPage: reducerData?.pageSize,
                        totalBatches: reducerData?.totalStudents,
                        totalPages: reducerData?.totalPages,
                        startBatch: reducerData?.startStudent,
                        endBatch: reducerData?.endStudent,
                        currentPage: reducerData?.currentPage,
                    }
                }
            }

            else if (action?.payload?.context === "UPCOMING_BATCHES_LIST") {
                const reducerData = action?.payload?.data;

                console.log(reducerData, "reducerDataupcoming")
                return {
                    ...state,
                    UpcomingBatchesList: {
                        ...state.UpcomingBatchesList,
                        PaginatedUpcomingBatches: reducerData?.reversedjobs,
                        searchResultBatches: reducerData?.searchResultJobs,
                        perPage: reducerData?.pageSize,
                        totalBatches: reducerData?.totalJobs,
                        totalPages: reducerData?.totalPages,
                        startBatch: reducerData?.startJob,
                        endBatch: reducerData?.endJob,
                        currentPage: reducerData?.currentPage,
                    }
                }
            }
            else if (action?.payload?.context === "COMPLETED_BATCHES_LIST") {
                const reducerData = action?.payload?.data;
                console.log(reducerData, "reducerDatacompleted")
                return {
                    ...state,
                    CompletedBatchesList: {
                        ...state.CompletedBatchesList,
                        PaginatedCompletedBatches: reducerData?.applications,
                        searchResultBatches: reducerData?.searchResultApplications,
                        perPage: reducerData?.pageSize,
                        totalBatches: reducerData?.totalApplications,
                        totalPages: reducerData?.totalPages,
                        startBatch: reducerData?.startApplication,
                        endBatch: reducerData?.endApplication,
                        currentPage: reducerData?.currentPage,
                    }
                }
            }
            break;

        case ActionTypes.SET_LOADING:
            if (action?.payload?.context === "ACTIVE_BATCHES_LIST") {

                console.log(action?.payload?.context, "jhdgfhjdjf")
                return {
                    ...state,
                    ActiveBatchesList: {
                        ...state.ActiveBatchesList,
                        loading: !state.ActiveBatchesList.loading
                    }
                }
            }

            else if (action?.payload?.context === "UPCOMING_BATCHES_LIST") {
                return {
                    ...state,
                    UpcomingBatchesList: {
                        ...state?.UpcomingBatchesList,
                        loading: !state.UpcomingBatchesList.loading
                    }
                }
            }

            else if (action?.payload?.context === "COMPLETED_BATCHES_LIST") {
                return {
                    ...state,
                    CompletedBatchesList: {
                        ...state.CompletedBatchesList,
                        loading: !state.CompletedBatchesList.loading
                    }
                }
            }

            break;


        case ActionTypes.SET_SEARCH:
            if (action.payload.context === "ACTIVE_BATCHES_LIST") {
                return {
                    ...state,
                    ActiveBatchesList: {
                        ...state.ActiveBatchesList,
                        search: action.payload.data,
                        currentPage: 1,
                    }
                }
            }

            else if (action?.payload?.context === "UPCOMING_BATCHES_LIST") {
                return {
                    ...state,
                    UpcomingBatchesList: {
                        ...state?.UpcomingBatchesList,
                        search: action?.payload?.data,
                        currentPage: 1,
                    }
                }
            }
            else if (action?.payload?.context === "COMPLETED_BATCHES_LIST") {
                return {
                    ...state,
                    CompletedBatchesList: {
                        ...state.CompletedBatchesList,
                        search: action.payload.data,
                        currentPage: 1,
                    }
                }
            }

            break;






        // here the Handle PerPage

        case ActionTypes?.SET_PER_PAGE:
            if (action?.payload?.context === "ACTIVE_BATCHES_LIST") {
                return {
                    ...state,
                    ActiveBatchesList: {
                        ...state.ActiveBatchesList,
                        perPage: action.payload.data,
                        currentPage: 1,
                    }
                }
            }

            else if (action?.payload?.context === "UPCOMING_BATCHES_LIST") {
                return {
                    ...state,
                    UpcomingBatchesList: {
                        ...state?.UpcomingBatchesList,
                        perPage: action?.payload?.data,
                        currentPage: 1,
                    }
                }
            }

            else if (action?.payload?.context === "COMPLETED_BATCHES_LIST") {
                return {
                    ...state,
                    CompletedBatchesList: {
                        ...state?.CompletedBatchesList,
                        perPage: action?.payload?.data,
                        currentPage: 1,
                    }
                }
            }

            break;


        case ActionTypes.SET_CUSTOM_PAGE:

            if (action.payload?.context === "ACTIVE_BATCHES_LIST") {
                return {
                    ...state,
                    ActiveBatchesList: {
                        ...state.ActiveBatchesList,
                        currentPage: action.payload.data,
                    }
                }
            }

            else if (action?.payload?.context === "UPCOMING_BATCHES_LIST") {
                console.log(action?.payload,  "showthecustojhdf")
                return {
                    ...state,
                    UpcomingBatchesList: {
                        ...state?.UpcomingBatchesList,
                        currentPage: action?.payload?.data
                    }
                }
            }

            else if (action?.payload.context === "COMPLETED_BATCHES_LIST") {
                return {
                    ...state,
                    CompletedBatchesList: {
                        ...state?.CompletedBatchesList,
                        currentPage: action.payload.data
                    }
                }
            }
            break;

        case ActionTypes.SET_FILTERS:

            if (action?.payload?.context === "ACTIVE_BATCHES_LIST") {

                return {
                    ...state,
                    ActiveBatchesList: {
                        ...state?.ActiveBatchesList,
                        filters: action?.payload?.data,
                        currentPage: 1,
                        perPage: 10,
                    }
                }
            }

            else if (action?.payload?.context === "UPCOMING_BATCHES_LIST") {
                return {
                    ...state,
                    UpcomingBatchesList: {
                        ...state?.UpcomingBatchesList,
                        filters: action?.payload?.data,
                        currentPage: 1,
                        perPage: 10,
                    }
                }
            }

            else if (action?.payload?.context === "COMPLETED_BATCHES_LIST") {
                return {
                    ...state,
                    CompletedBatchesList: {
                        ...state.CompletedBatchesList,
                        filters: action.payload.data,
                        currentPage: 1,
                        perPage: 10,
                    }
                }
            }
            break;


        default:
            return state;
    }

}
export default BatchesReducer;