import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PanelLayout from "./PanelLayout";
import { useAuthContext } from "../../../dataLayer/hooks/useAuthContext";
import { toast } from "react-toastify";



const isTokenExpired = (token) => {
 
  try {
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
      return true; // Invalid token format
    }
    const payload = JSON.parse(atob(tokenParts[1])); // Decoding the payload
    const exp = payload.exp * 1000; // Expiration time in milliseconds
    if( exp < Date.now()){
      // localStorage.removeItem("data")
      // localStorage.removeItem("password")
      // DispatchAuth({ type: "SET_USER", payload: {} })
      // DispatchAuth({ type: "SET_TOKEN", payload: "" })
      // DispatchAuth({ type: "SET_ROLE", payload: {} })
      // DispatchAuth({ type: "SET_PASSWORD_LOCALSTORAGE", payload: "" })
      // toast.error("Your Session is Expired"); 
      return exp < Date.now()  // Check if the token is expired
    }
  }
   catch (e) {
    return true; // Error decoding token
  }
};



const RequireAuth = () => {
  const { AuthState, DispatchAuth } = useAuthContext();
  const location = useLocation();

  // Check if the user is authenticated and the token is not expired

  const isAuthenticated = AuthState.token && AuthState.password && !isTokenExpired(AuthState.token );

  if (!isAuthenticated) {
    return (
      <Navigate
        to={"/auth/login"}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <div className="app">
      <PanelLayout>
        <Outlet />
      </PanelLayout>
    </div>
  );
};

export default RequireAuth;
