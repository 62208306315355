
import React, { useCallback, useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { useBranchContext } from "../../../../dataLayer/hooks/useBranchContext";
import { useCourseContext } from "../../../../dataLayer/hooks/useCourseContext";
import { ERPApi } from "../../../../serviceLayer/interceptor";

const AddBatch = ({ show, handleClose }) => {

  const { BranchState } = useBranchContext();
  const { courseState } = useCourseContext();



  const [errors, setErrors] = useState({});

  const [userData, setUserData] = useState(() => {
    const data = JSON.parse(localStorage.getItem("data"));
    return data || "";
  });

  const isBranchListDisabled = ["Trainer", "Branch Manager", "Counsellor"].includes(userData?.user?.profile) || userData?.user?.profile !== "Admin";
  const isTrainerListDisabled = userData?.user?.profile === "Trainer" || (userData?.user?.profile !== "Branch Manager" &&
    userData?.user?.profile !== "Counsellor" &&
    userData?.user?.profile !== "Admin");

  const currentDate = new Date().toISOString().split("T")[0];


  const [TrainersList, setTrainersList] = useState([]);
  const [coursesList, setCourseList] = useState([]);
  const [TrainerData, setTrainerData] = useState({});
  const [fetchError, setFetchError] = useState({
    trainersList: false,
    coursesList: false,
  });

  console.log(TrainerData, "TrainerData")


  const [BatchState, setBatchState] = useState({
    branchId: null,
    branchName: "",
    trainerId: null,
    trainerName: "",
    courseId: null,
    courseName: "",
    fromTime: "",
    toTime: "",
    batchStartDate: "",
    batchEndDate: "",
    trainingMode: "",
  });

  useEffect(() => {
    if (show && userData?.user?.profile === "Trainer") {
      console.log("Before updating state:", BatchState);
      setBatchState((prev) => ({
        ...prev,
        branchName: "Secunderabad",
        branchId: 64,
        trainerId: 173,
        trainerName: "Ramu",
      }));

      //  set the trainersList, courseList
      setTrainersList([{ id: 173, fullname: "Ramu" }])
      setCourseList([{ id: 67, course_name: "Java" }, { id: 37, course_name: "React js" },])

      //  hit the trainerApi i want all information
      if (userData?.user?.id) {
        FetchtheSingleTrainerData(userData?.user?.id)
      }
    }

    else if (show && userData?.user?.profile === "Branch Manager" || userData?.user?.profile === "Counsellor") {
      setBatchState((prev) => ({
        ...prev,
        branchName: "Secunderabad",
        branchId: 64,
      }));
      //  call the Triners Api, set in TrainersList
      if (userData?.user?.id) {
        FetchtheTainerList(userData?.user?.id)
      }
    }
  }, [show, userData, setBatchState]);

  console.log(BatchState, "agdjfshdfshfd");

  const handleBranchChange = useCallback(async (e) => {
    const branchId = parseInt(e.target.value);
    setBatchState((prev) => ({
      ...prev,
      branchId: branchId,
      branchName: e.target.options[e.target.selectedIndex].text,
      trainerName: "",
      trainerId: "",
      courseName: "",
      courseId: "",
      trainingMode: "",
      fromTime: "",
      toTime: "",
      batchStartDate: "",
      batchEndDate: "",
    }));

    setErrors((prev) => ({
      ...prev,
      branchId: "",
      branchName: "",
    }));
    if (branchId) {
      FetchtheTainerList(branchId);
    }
  }, [setBatchState, setErrors]);


  const handleTrainerChange = async (e) => {
    const trainerId = e.target.value;
    setBatchState((prev) => ({
      ...prev,
      trainerId: parseInt(trainerId),
      trainerName: e.target.options[e.target.selectedIndex].text,
      courseName: "",
      courseId: "",
      trainingMode: "",
      fromTime: "",
      toTime: "",
      batchStartDate: "",
      batchEndDate: "",
    }))

    setErrors((prev) => ({
      ...prev,
      trainerName: "",
    }));

    if (trainerId) {
      FetchtheSingleTrainerData(trainerId)
    }
  }



  // Fetching
  const FetchtheTainerList = async (branchId) => {
    try {
      const { data, status } = await ERPApi.get(`${process.env.REACT_APP_API_URL}/student/viewstudentdata/${branchId}`);
      if (status === 200) {
        const trainersData = [{ id: 2, fullname: "somu" }, { id: 173, fullname: "Ramu" }, { id: 170, fullname: "Raghu" }, { id: 104, fullname: "Admin" }, { id: 172, fullname: "Krishna" }]
        setTrainersList(trainersData)
      }
    }
    catch (error) {
      console.log(error);
      setTrainersList([]);
      setFetchError({
        trainersList: true
      });
    }
  }
  const FetchtheSingleTrainerData = async (trainerId) => {
    try {
      const { data, status } = await ERPApi.get(`${process.env.REACT_APP_API_URL}/user/viewuser/${trainerId}`);
      if (status === 200) {
        console.log(data.user, status, "hdhgjgjdagsd")
        setTrainerData(data?.user)
        const courseData = [{ id: 37, course_name: "MERN STACK" }, { id: 37, course_name: "Python full Stack" }, { id: 37, course_name: "Data Science" }]
        setCourseList(data?.user?.courses.length > 0 ? data?.user?.courses : [])
      }

    }
    catch (error) {
      console.log(error)
      setFetchError({
        coursesList: true
      });
    }
  }

  const handleSubmitBatch = async (e) => {
    e.preventDefault();

    if (!BatchState?.branchName || BatchState?.branchName.trim() === "") {
      setErrors((prev) => ({
        ...prev,
        branchName: "Branch Name is required",
      }));
      return;
    }
    else if (!BatchState?.trainerName || BatchState?.trainerName.trim() === "") {
      setErrors((prev) => ({
        ...prev,
        trainerName: "Trainer Name is required",
      }));
      return;
    }
    else if (!BatchState?.courseName || BatchState?.courseName.trim() === "") {
      setErrors((prev) => ({
        ...prev,
        courseName: "Course is required",
      }));
      return;
    }

    else if (!BatchState?.trainingMode || BatchState?.trainingMode.trim() === "") {
      setErrors((prev) => ({
        ...prev,
        trainingMode: "Training Mode is required",
      }));
      return;
    }

    else if (!BatchState?.batchStartDate || BatchState?.batchStartDate.trim() === "") {
      setErrors((prev) => ({
        ...prev,
        batchStartDate: "Batch Start Date is required",
      }));
      return;
    }

    else if (!BatchState?.batchEndDate || BatchState?.batchEndDate.trim() === "") {
      setErrors((prev) => ({
        ...prev,
        batchEndDate: "Batch End Date is required",
      }));
      return;
    }

    console.log(BatchState, "BatchStateddf")
    try {
      const { data, status } = await ERPApi.post(`${process.env.REACT_APP_API_URL}/student/addbatch`, BatchState);
      if (status === 200) {
        alert("Batch Added Successfully")
        handleClose();
      }
    }
    catch (error) {
      console.log(error);
    }
  }



  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add Batch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form // onSubmit={handleSubmit}
        >
          <div className="row">
            <div className="col-lg-6">

              {/* branch */}
              <div className="mb-3">
                <label className="form-label fs-s fw-medium black_300">
                  Branch <span className="text-danger">*</span>
                </label>
                <select
                  className={`form-control input_bg_color ${errors.branchName ? 'error-input' : ''}`}
                  id="branchName"
                  name="branchName"
                  value={BatchState?.branchId}
                  onChange={(e) => handleBranchChange(e)}
                  required
                  disabled={isBranchListDisabled}
                  style={{ cursor: isBranchListDisabled ? "not-allowed" : "pointer" }}
                >

                  {BranchState?.branches?.length > 0 ? (
                    <>
                      <option value="" selected disabled>Select the Branch</option>
                      {BranchState?.branches?.map((item, index) => (
                        <option key={index} value={item.id}>{item.branch_name}</option>
                      ))}
                    </>
                  ) : (
                    <option value="" disabled selected>No Branches are Found</option>
                  )}

                </select>
                <div className="response" style={{ height: "8px" }}>
                  {errors.branchName && (
                    <p className="text-danger m-0 fs-xs">
                      {errors.branchName}
                    </p>
                  )}
                </div>
              </div>

              {/* course */}
              <div className="mb-3">
                <label className="form-label fs-s fw-medium black_300" htmlFor="courseName">
                  Course <span className="text-danger">*</span>
                </label>
                <select
                  className={`form-control input_bg_color ${errors.courseName ? 'error-input' : ''}`}
                  name="courseName"
                  id="courseName"
                  value={BatchState?.courseId}
                  onChange={(e) => {
                    const selectedCourseName = e.target.options[e.target.selectedIndex].text;
                    const selectedCourseId = parseInt(e.target.value, 10);
                    setBatchState((prev) => ({
                      ...prev,
                      courseName: selectedCourseName,
                      courseId: selectedCourseId,
                      fromTime: "",
                      toTime: "",
                      batchStartDate: "",
                      batchEndDate: "",
                      trainingMode: "",
                    }));
                    setErrors((prev) => ({
                      ...prev,
                      courseName: ""
                    }));
                  }}
                  required
                  disabled={(!BatchState?.trainerName)}
                  style={{ cursor: (!BatchState?.trainerName) ? "not-allowed" : "pointer" }}
                >
                  {
                    fetchError?.coursesList === true ? (
                      <option value="" disabled selected>Unable to Get data</option>
                    ) : coursesList.length > 0 ? (
                      <>
                        <option value="" disabled selected>Select the Course</option>
                        {coursesList.map((item, index) => (
                          <option key={index} value={item.id}>{item.course_name}</option>
                        ))}
                      </>
                    ) : (
                      <option value="" disabled selected>No Courses are Found</option>
                    )
                  }
                </select>
                <div className="response" style={{ height: "8px" }}>
                  {errors.courseName && (
                    <p className="text-danger m-0 fs-xs">
                      {errors.courseName}
                    </p>
                  )}
                </div>
              </div>

              {/* Batch startDate */}
              <div className="mb-3">
                <label htmlFor="batchStartDate" className="form-label fs-s fw-medium black_300">
                  Batch Start Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className={`form-control input_bg_color ${errors.batchStartDate ? `error-input` : ``} `}
                  name="batchStartDate"
                  id="batchStartDate"
                  value={BatchState?.batchStartDate}
                  min={currentDate}
                  onChange={(e) => {
                    setBatchState((prev) => ({
                      ...prev,
                      batchStartDate: e.target.value,
                      fromTime: "",
                      toTime: "",
                      batchEndDate: "",
                    }));
                    setErrors((prev) => ({
                      ...prev,
                      batchStartDate: ""
                    }));
                  }}
                  required
                  disabled={!BatchState?.trainingMode}
                  style={{ cursor: !BatchState?.trainingMode ? "not-allowed" : "pointer" }}
                />
                <div className="response" style={{ height: "8px" }}>
                  {errors.batchStartDate && (
                    <p className="text-danger m-0 fs-xs">
                      {errors.batchStartDate}
                    </p>
                  )}

                </div>
              </div>

              {/* batch Timing */}
              <div className="mb-3">
                <label htmlFor="fromTime" className="form-label fs-s fw-medium black_300">
                  Batch Time <span className="text-danger">*</span>
                </label>
                <div className="d-flex">
                  <input
                    type="time"
                    className={`form-control input_bg_color ${errors.fromTime ? "error-input" : ""}`}
                    id="fromTime"
                    name="fromTime"
                    // value={batchData.fromTime}
                    // onChange={handleInputChange}
                    required
                  />
                  <span className="mx-0">to</span>
                  <input
                    type="time"
                    className={`form-control input_bg_color ${errors.toTime ? "error-input" : ""}`}
                    id="toTime"
                    name="toTime"
                    // value={batchData.toTime}
                    // onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="response" style={{ height: "8px" }}>
                  {errors.fromTime && (
                    <p className="text-danger m-0 fs-xs ">
                      {errors.fromTime}
                    </p>
                  )}
                  {errors.toTime && (
                    <p className="text-danger m-0 fs-xs text-end">
                      {errors.toTime}
                    </p>
                  )}
                </div>
              </div>


            </div>

            <div className="col-lg-6">

              {/* Trainer  */}
              <div className="mb-3">
                <label htmlFor="trainerName" className="form-label fs-s fw-medium black_300">
                  Trainer Name <span className="text-danger">*</span>
                </label>
                <select
                  className={`form-control input_bg_color ${errors.trainerName ? "error-input" : ""}`}
                  name="trainerName"
                  id="trainerName"
                  // value={BatchState?.trainerId}
                  value={BatchState?.trainerId}
                  onChange={(e) => handleTrainerChange(e)}
                  required
                  disabled={(!BatchState?.branchName || isTrainerListDisabled)}
                  style={{ cursor: (!BatchState?.branchName || isTrainerListDisabled) ? "not-allowed" : "pointer" }}
                >
                  {fetchError?.trainersList === true ? (
                    <option value="" disabled selected>Unable to Get data</option>
                  ) : TrainersList.length > 0 ? (
                    <>
                      <option value="" selected disabled>Select the Trainer</option>
                      {TrainersList.map((item, index) => (
                        <option key={index} value={item?.id}>{item?.fullname}</option>
                      ))}
                    </>
                  ) : (
                    <option value="" disabled selected>No Trainers are Found</option>
                  )}

                </select>
                <div className="response" style={{ height: "8px" }}>
                  {errors.trainerName && (
                    <p className="text-danger m-0 fs-xs">
                      {errors.trainerName}
                    </p>
                  )}
                </div>
              </div>

              {/* mode of Training */}
              <div className="mb-3">
                <label htmlFor="trainingMode" className="form-label fs-s fw-medium black_300">
                  Training Mode <span className="text-danger">*</span>
                </label>
                <select
                  className={`form-control input_bg_color ${errors.trainingMode ? "error-input" : ""}`}
                  name="trainingMode"
                  id="trainingMode"
                  value={BatchState?.trainingMode}
                  onChange={(e) => {
                    setBatchState((prev) => ({
                      ...prev,
                      trainingMode: e.target.value,
                      fromTime: "",
                      toTime: "",
                      batchStartDate: "",
                      batchEndDate: "",
                    }));
                    setErrors((prev) => ({
                      ...prev,
                      trainingMode: ""
                    }));
                  }}
                  required
                  disabled={!BatchState?.courseName}
                  style={{ cursor: !BatchState?.courseName ? "not-allowed" : "pointer" }}
                >
                  <option value="" disabled>Select the Training Mode </option>
                  <option value="online">Online</option>
                  <option value="offline">Offline</option>
                  <option value="hybrid">Hybrid</option>
                </select>
                <div className="response" style={{ height: "8px" }}>
                  {errors.trainingMode && (
                    <p className="text-danger m-0 fs-xs">
                      {errors.trainingMode}
                    </p>
                  )}
                </div>
              </div>

              {/* Batch EndDate */}
              <div className="mb-3">
                <label htmlFor="batchEndDate" className="form-label fs-s fw-medium black_300">
                  Batch End Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className={`form-control input_bg_color ${errors.batchEndDate ? "error-input" : ""}`}
                  name="batchEndDate"
                  id="batchEndDate"
                  min={BatchState?.batchStartDate}
                  value={BatchState?.batchEndDate}
                  onChange={(e) => {
                    setBatchState((prev) => ({
                      ...prev,
                      batchEndDate: e.target.value,
                      fromTime: "",
                      toTime: "",
                    }));
                    setErrors((prev) => ({
                      ...prev,
                      batchEndDate: ""
                    }));
                  }}
                  required
                  disabled={!BatchState?.batchStartDate}
                  style={{ cursor: !BatchState?.batchStartDate ? "not-allowed" : "pointer" }}
                />
                <div className="response" style={{ height: "8px" }}>
                  {errors.batchEndDate && (
                    <p className="text-danger m-0 fs-xs">
                      {errors.batchEndDate}
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-5 text-end">
                <button className="btn btn-sm btn-md btn_primary fs-13"
                  onClick={(e) => handleSubmitBatch(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

        </form>
      </Modal.Body>
    </Modal >
  );
};

export default AddBatch;



// import React, { useEffect, useState } from "react";
// import { Modal } from 'react-bootstrap';
// import axios from "axios";
// import { useBranchContext } from "../../../../dataLayer/hooks/useBranchContext";

// const AddBatch = ({ show, handleClose }) => {

//   const { BranchState } = useBranchContext();

//   const [branches, setBranches] = useState([]);
//   const [trainers, setTrainers] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [courses, setCourses] = useState([]);


//   console.log(users, branches, "usersusershgsd")

//   const [batchData, setBatchData] = useState({
//     branch: "",
//     trainer: "",
//     course: "",
//     fromTime: "",
//     toTime: "",
//     trainingMode: "",
//     batchStartDate: "",
//     batchEndDate: ""
//   });
//   const [errors, setErrors] = useState({});
//   const [isTrainer, setIsTrainer] = useState(false);

//   // Fetch user data and initialize component
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/userdata`);
//         const usersData = response.data;

//         const savedUserData = JSON.parse(localStorage.getItem("data"));
//         if (savedUserData && savedUserData.user.profile === "Trainer") {
//           setIsTrainer(true);
//           const filteredUsers = usersData.filter(user => user.branch === savedUserData.user.branch && user.profile === "Trainer");
//           setUsers(filteredUsers);
//           setBranches([savedUserData.user.branch]);
//           setTrainers([savedUserData.user]);
//           setBatchData({
//             ...batchData,
//             branch: savedUserData.user.branch,
//             trainer: savedUserData.user.fullname,
//             course: savedUserData.user.phonenumber,
//           });
//           setCourses([{ phone: savedUserData.user.phonenumber, name: savedUserData.user.fullname }]);
//         }

//         else if (savedUserData && savedUserData.user.profile === "Branch Manager") {
//           const branchManagerBranch = savedUserData.user.branch;
//           const filteredTrainers = usersData.filter(user => user.branch === branchManagerBranch && user.profile === "Trainer");
//           setUsers(usersData);
//           setBranches([branchManagerBranch]);
//           setTrainers(filteredTrainers);
//           // Only set branch in batchData if it's not already set
//           if (!batchData.branch) {
//             setBatchData(prevData => ({
//               ...prevData,
//               branch: branchManagerBranch,
//             }));
//           }
//         }

//         else {
//           const uniqueBranches = [...new Set(usersData.map(user => user.branch))];
//           setUsers(usersData);
//           setBranches(uniqueBranches);
//         }
//       }

//       catch (error) {
//         console.error('Error fetching users:', error);
//       }
//     };

//     fetchData();
//   }, []); // Only fetch once on component mount



//   const handleBranchChange = (e) => {
//     const selectedBranch = e.target.value;
//     setBatchData({ ...batchData, branch: selectedBranch });

//     const filteredTrainers = users.filter(user => user.branch === selectedBranch && user.profile === "Trainer");
//     setTrainers(filteredTrainers);

//     setBatchData(prevBatchData => ({
//       ...prevBatchData,
//       trainer: "",
//       course: "",
//     }));
//     setCourses([]);
//     setErrors({});
//   };

//   const handleTrainerChange = (e) => {
//     const trainerFullName = e.target.value;
//     const selectedTrainer = users.find(user => user.fullname === trainerFullName);

//     if (selectedTrainer) {
//       setBatchData({
//         ...batchData,
//         trainer: trainerFullName,
//         course: selectedTrainer.phonenumber,
//       });
//       setCourses([{ phone: selectedTrainer.phonenumber, name: trainerFullName }]);
//     } else {
//       setBatchData({
//         ...batchData,
//         trainer: trainerFullName,
//         course: "",
//       });
//       setCourses([]);
//     }
//     setErrors({ ...errors, trainer: '' });
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setBatchData({ ...batchData, [name]: value });
//     setErrors({ ...errors, [name]: '' });
//   };



//   const validate = () => {
//     let tempErrors = {};
//     const { trainer, branch, course, trainingMode, fromTime, toTime, batchStartDate, batchEndDate } = batchData;
//     if (!branch) {
//       tempErrors.branch = "Branch is required";
//     }
//     if (!trainer) {
//       tempErrors.trainer = "Trainer name is required";
//     }
//     if (!course) {
//       tempErrors.course = "Course is required";
//     }
//     if (!fromTime) {
//       tempErrors.fromTime = "Time is required";
//     }
//     if (!toTime) {
//       tempErrors.toTime = "Time is required";
//     }
//     if (!batchStartDate) {
//       tempErrors.batchStartDate = "Batch start date is required";
//     }
//     if (!batchEndDate) {
//       tempErrors.batchEndDate = "Batch end date is required";
//     }
//     if (!trainingMode) {
//       tempErrors.trainingMode = "Training mode is required";
//     }
//     return tempErrors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const tempErrors = validate();

//     if (Object.keys(tempErrors).length === 0) {
//       try {
//         const response = await axios.post(``, batchData);
//         // Handle success, update state or close modal
//       } catch (error) {
//         console.error('Error adding batch', error);
//         // Handle error, show toast or error message
//       }
//     } else {
//       const firstErrorKey = Object.keys(tempErrors)[0];
//       setErrors({ [firstErrorKey]: tempErrors[firstErrorKey] });
//     }
//   };

//   const resetForm = () => {
//     if (localStorage.getItem("data")) {
//       const savedUserData = JSON.parse(localStorage.getItem("data"));
//       if (savedUserData.user.profile === "Admin") {
//         setBatchData({
//           ...batchData,
//           branch: "",
//           trainer: "",
//           course: "",
//           fromTime: "",
//           toTime: "",
//           trainingMode: "",
//           batchStartDate: "",
//           batchEndDate: ""
//         });

//       } else if (savedUserData.user.profile === "Trainer") {
//         setBatchData(prevData => ({
//           ...prevData,
//           fromTime: "",
//           toTime: "",
//           trainingMode: "",
//           batchStartDate: "",
//           batchEndDate: ""
//         }));
//       } else if (savedUserData.user.profile === "Branch Manager") {
//         // Only reset non-branch fields
//         setBatchData(prevData => ({
//           ...prevData,
//           trainer: "",
//           course: "",
//           fromTime: "",
//           toTime: "",
//           trainingMode: "",
//           batchStartDate: "",
//           batchEndDate: ""
//         }));
//       }
//     } else {
//       setBatchData({
//         ...batchData,
//         branch: "",
//         trainer: "",
//         course: "",
//         fromTime: "",
//         toTime: "",
//         trainingMode: "",
//         batchStartDate: "",
//         batchEndDate: ""
//       });
//     }
//     setErrors({});
//   };


//   useEffect(() => {
//     if (!show) {
//       resetForm();
//     }
//   }, [show]);


//   return (
//     <Modal show={show} onHide={handleClose} backdrop="static">
//       <Modal.Header closeButton>
//         <Modal.Title>Add Batch</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <form onSubmit={handleSubmit}>
//           <div className="row">
//             <div className="col-lg-6">
//               <div className="mb-3">
//                 <label className="form-label fs-s fw-medium black_300">
//                   Branch <span className="text-danger">*</span>
//                 </label>
//                 <select
//                   className={
//                     errors.branch
//                       ? "form-control input_bg_color error-input"
//                       : "form-control input_bg_color"
//                   }
//                   name="branch"
//                   value={batchData.branch}
//                   onChange={handleBranchChange}
//                   required
//                   disabled={isTrainer || (localStorage.getItem("data") && JSON.parse(localStorage.getItem("data")).user.profile === "Branch Manager")}
//                 >
//                   <option value="" disabled>Select the branch</option>
//                   {BranchState?.branches?.map((item, index) => (
//                     <option key={index} value={item.branch_name}>{item.branch_name}</option>
//                   ))}
//                 </select>
//                 <div className="response" style={{ height: "8px" }}>
//                   {errors.branch && (
//                     <p className="text-danger m-0 fs-xs">
//                       {errors.branch}
//                     </p>
//                   )}
//                 </div>
//               </div>

//               <div className="mb-3">
//                 <label className="form-label fs-s fw-medium black_300">
//                   Course <span className="text-danger">*</span>
//                 </label>
//                 <select
//                   className={
//                     errors.course
//                       ? "form-control input_bg_color error-input"
//                       : "form-control input_bg_color"
//                   }
//                   name="course"
//                   value={batchData.course}
//                   onChange={handleInputChange}
//                   required
//                   disabled={isTrainer}
//                 >
//                   <option value="" disabled>Select</option>

//                   {courses.map((course, index) => (
//                     <option key={index} value={course.phone}>
//                       {`${course.phone}`}
//                     </option>
//                   ))}

//                 </select>
//                 <div className="response" style={{ height: "8px" }}>
//                   {errors.course && (
//                     <p className="text-danger m-0 fs-xs">
//                       {errors.course}
//                     </p>
//                   )}
//                 </div>
//               </div>

//               <div className="mb-3">
//                 <label className="form-label fs-s fw-medium black_300">
//                   Batch Start Date <span className="text-danger">*</span>
//                 </label>
//                 <input
//                   type="date"
//                   className={
//                     errors.batchStartDate
//                       ? "form-control input_bg_color error-input"
//                       : "form-control input_bg_color"
//                   }
//                   name="batchStartDate"
//                   value={batchData.batchStartDate}
//                   onChange={handleInputChange}
//                   required
//                 />
//                 <div className="response" style={{ height: "8px" }}>

//                   {errors.batchStartDate && (
//                     <p className="text-danger m-0 fs-xs">
//                       {errors.batchStartDate}
//                     </p>
//                   )}

//                 </div>
//               </div>
//               <div className="mb-3">
//                 <label className="form-label fs-s fw-medium black_300">
//                   Training Mode <span className="text-danger">*</span>
//                 </label>
//                 <select
//                   className={
//                     errors.trainingMode
//                       ? "form-control input_bg_color error-input"
//                       : "form-control input_bg_color"
//                   }
//                   name="trainingMode"
//                   value={batchData.trainingMode}
//                   onChange={handleInputChange}
//                   required
//                 >
//                   <option value="" disabled>Select</option>
//                   <option value="Online">Online</option>
//                   <option value="Offline">Offline</option>
//                   <option value="Hybrid">Hybrid</option>
//                 </select>
//                 <div className="response" style={{ height: "8px" }}>
//                   {errors.trainingMode && (
//                     <p className="text-danger m-0 fs-xs">
//                       {errors.trainingMode}
//                     </p>
//                   )}
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-6">
//               <div className="mb-3">
//                 <label className="form-label fs-s fw-medium black_300">
//                   Trainer Name <span className="text-danger">*</span>
//                 </label>
//                 <select
//                   className={
//                     errors.trainer
//                       ? "form-control input_bg_color error-input"
//                       : "form-control input_bg_color"
//                   }
//                   name="trainer"
//                   value={batchData.trainer}
//                   onChange={handleTrainerChange}
//                   required
//                   disabled={isTrainer}
//                 >
//                   <option value="" disabled>Select</option>
//                   {trainers.map((trainer, index) => (
//                     <option key={index} value={trainer.fullname}>
//                       {`${trainer.fullname}`}
//                     </option>
//                   ))}
//                 </select>
//                 <div className="response" style={{ height: "8px" }}>
//                   {errors.trainer && (
//                     <p className="text-danger m-0 fs-xs">
//                       {errors.trainer}
//                     </p>
//                   )}
//                 </div>
//               </div>

//               <div className="mb-3">
//                 <label className="form-label fs-s fw-medium black_300">
//                   Batch Time <span className="text-danger">*</span>
//                 </label>
//                 <div className="d-flex">
//                   <input
//                     type="time"
//                     className={
//                       errors.fromTime
//                         ? "form-control input_bg_color error-input"
//                         : "form-control input_bg_color"
//                     }
//                     name="fromTime"
//                     value={batchData.fromTime}
//                     onChange={handleInputChange}
//                     required
//                   />
//                   <span className="mx-0">to</span>
//                   <input
//                     type="time"
//                     className={
//                       errors.toTime
//                         ? "form-control input_bg_color error-input"
//                         : "form-control input_bg_color"
//                     }
//                     name="toTime"
//                     value={batchData.toTime}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="response" style={{ height: "8px" }}>
//                   {errors.fromTime && (
//                     <p className="text-danger m-0 fs-xs ">
//                       {errors.fromTime}
//                     </p>
//                   )}
//                   {errors.toTime && (
//                     <p className="text-danger m-0 fs-xs text-end">
//                       {errors.toTime}
//                     </p>
//                   )}
//                 </div>
//               </div>

//               <div className="mb-3">
//                 <label className="form-label fs-s fw-medium black_300">
//                   Batch End Date <span className="text-danger">*</span>
//                 </label>
//                 <input
//                   type="date"
//                   className={
//                     errors.batchEndDate
//                       ? "form-control input_bg_color error-input"
//                       : "form-control input_bg_color"
//                   }
//                   name="batchEndDate"
//                   value={batchData.batchEndDate}
//                   onChange={handleInputChange}
//                   required
//                 />
//                 <div className="response" style={{ height: "8px" }}>
//                   {errors.batchEndDate && (
//                     <p className="text-danger m-0 fs-xs">
//                       {errors.batchEndDate}
//                     </p>
//                   )}
//                 </div>
//               </div>
//               <div className="mt-5 text-end">
//                 <button onClick={handleSubmit} className="btn btn-sm btn-md btn_primary fs-13">
//                   Submit
//                 </button>
//               </div>
//             </div>
//           </div>

//         </form>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default AddBatch;
