import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../../../assets/css/Table.css";
import { AiFillEye } from "react-icons/ai";
import todayimg from "../../../../assets/images/feedetails/Today.png";
import upcommingimg from "../../../../assets/images/feedetails/Upcoming.png";
import overdueimg from "../../../../assets/images/feedetails/Overdue.png";
import Button from "../../../components/button/Button";
import Usedebounce from "../../../../dataLayer/hooks/useDebounce/Usedebounce";
import { useStudentsContext } from "../../../../dataLayer/hooks/useStudentsContext";
import { useCourseContext } from "../../../../dataLayer/hooks/useCourseContext";
import BackButton from "../../../components/backbutton/BackButton";
import { useBranchContext } from "../../../../dataLayer/hooks/useBranchContext";
import { MdFilterList } from "react-icons/md";
import GateKeeper from "../../../../rbac/GateKeeper";
import { toast } from "react-toastify";
import { Offcanvas } from "bootstrap";
import { LiaRupeeSignSolid } from "react-icons/lia";
import Pagination from "../../../../utils/Pagination";
import PaginationInfo from "../../../../utils/PaginationInfo";
import { useUserContext } from "../../../../dataLayer/hooks/useUserContext";
import { useLeadSourceContext } from "../../../../dataLayer/hooks/useLeadSourceContext";


const FeeFollowUps = () => {
  const { courseState } = useCourseContext();
  const { studentState: { TodayFeeDetailsStudents, OverDueFeeDetailsStudents, UpComingFeeDetailsStudents }, Dispatchstudents, getTodayFeeDetailsStudents, getOverDueFeeDetailsStudents, getUpComingFeeDetailsStudents, } = useStudentsContext();
  const { UsersState: { TotalUsersWithCountellers } } = useUserContext();
  const { leadSourceState } = useLeadSourceContext();
  const { BranchState } = useBranchContext();
  const { debouncesetSearch, debouncesetPage } = Usedebounce(Dispatchstudents);

  // Today FeeDetails 
  const handleSearch = (e) => {
    debouncesetSearch({
      context: "TODAY_FEE_DETAILS_STUDENTS",
      data: e.target.value,
    });
  };

  const handlePerPage = (e) => {
    const selectedvalue = parseInt(e.target.value, 10);
    Dispatchstudents({
      type: "SET_PER_PAGE",
      payload: {
        context: "TODAY_FEE_DETAILS_STUDENTS",
        data: selectedvalue,
      },
    });
  };

  const [filterCriteria, setfilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    branch: "",
    course: "",
    enquiry: "",
    lead: ""
  });

  const HandleFilterCertria = (e) => {
    const { name, value } = e.target;
    setfilterCriteria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const FilterReset = () => {
    setfilterCriteria({
      fromDate: "",
      toDate: "",
      branch: "",
      course: "",
      enquiry: "",
      lead: ""
    });
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "TODAY_FEE_DETAILS_STUDENTS",
        data: {
          fromDate: "",
          toDate: "",
          branch: "",
          course: "",
          enquiry: "",
          lead: "",
        },
      },
    });
  };

  const filterSubmit = () => {
    if (!filterCriteria.fromDate && !filterCriteria.toDate && !filterCriteria.branch && !filterCriteria.course && !filterCriteria.enquiry && !filterCriteria.lead) {
      toast.error("Please fill in at least one filter criteria.");
      return;
    }
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "TODAY_FEE_DETAILS_STUDENTS",
        data: {
          fromDate: filterCriteria.fromDate,
          toDate: filterCriteria.toDate,
          branch: filterCriteria.branch,
          course: filterCriteria.course,
          enquiry: filterCriteria.enquiry,
          lead: filterCriteria.lead,
        },
      },
    });
    const offcanvasElement = document.getElementById('offcanvasRightToday');
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();
  };
  const handlePageChangeTodayFee = (page) => {
    debouncesetPage({ context: "TODAY_FEE_DETAILS_STUDENTS", data: page });
  }



  // Upcoming Fee Details
  const handleSearchUpcoming = (e) => {
    debouncesetSearch({
      context: "UPCOMING_FEE_DETAILS_STUDENTS",
      data: e.target.value,
    });
  };

  const handlePerPageUpcoming = (e) => {
    const selectedvalue = parseInt(e.target.value, 10);
    Dispatchstudents({
      type: "SET_PER_PAGE",
      payload: {
        context: "UPCOMING_FEE_DETAILS_STUDENTS",
        data: selectedvalue,
      },
    });
  };

  const [filterCriteriaUpcoming, setfilterCriteriaUpcoming] = useState({
    fromDate: "",
    toDate: "",
    branch: "",
    course: "",
    enquiry: "",
    lead: "",
    admissiontoDate: "",
    admissionfromDate: "",
  });



  const HandleFilterCertriaUpcoming = (e) => {
    const { name, value } = e.target;
    setfilterCriteriaUpcoming((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const FilterResetUpcoming = () => {
    setfilterCriteriaUpcoming({
      fromDate: "",
      toDate: "",
      branch: "",
      course: "",
      enquiry: "",
      lead: "",
      admissiontoDate: "",
      admissionfromDate: "",
    });
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "UPCOMING_FEE_DETAILS_STUDENTS",
        data: {
          fromDate: "",
          toDate: "",
          branch: "",
          course: "",
          enquiry: "",
          lead: "",
          admissiontoDate: "",
          admissionfromDate: "",
        },
      },
    });
    const offcanvasElement = document.getElementById('offcanvasRightUpcoming');
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();

  };

  const filterSubmitUpcoming = () => {
    if (!filterCriteriaUpcoming?.fromDate && !filterCriteriaUpcoming?.toDate && !filterCriteriaUpcoming?.branch && !filterCriteriaUpcoming?.course && !filterCriteriaUpcoming?.enquiry && !filterCriteriaUpcoming?.lead &&
      !filterCriteriaUpcoming?.admissiontoDate && filterCriteriaUpcoming?.admissionfromDate) {
      toast.error("Please fill in at least one filter criteria.");
      return;
    }
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "UPCOMING_FEE_DETAILS_STUDENTS",
        data: {
          fromDate: filterCriteriaUpcoming?.fromDate,
          toDate: filterCriteriaUpcoming?.toDate,
          branch: filterCriteriaUpcoming?.branch,
          course: filterCriteriaUpcoming?.course,
          enquiry: filterCriteriaUpcoming?.enquiry,
          lead: filterCriteriaUpcoming?.lead,
          admissiontoDate: filterCriteriaUpcoming?.admissiontoDate,
          admissionfromDate: filterCriteriaUpcoming?.admissionfromDate,
        },
      },
    });
    const offcanvasElement = document.getElementById('offcanvasRightUpcoming');
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();

  };


  const handlePageChangeUpcomingFee = (page) => {
    debouncesetPage({ context: "UPCOMING_FEE_DETAILS_STUDENTS", data: page });
  }


  // OVERDUE FILTERS AND PAGINATION
  const handleSearchOverDue = (e) => {
    debouncesetSearch({
      context: "OVER_DUE_FEE_DETAILS_STUDENTS",
      data: e.target.value,
    });
  };

  const handlePerPageOverDue = (e) => {
    const selectedvalue = parseInt(e.target.value, 10);
    Dispatchstudents({
      type: "SET_PER_PAGE",
      payload: {
        context: "OVER_DUE_FEE_DETAILS_STUDENTS",
        data: selectedvalue,
      },
    });
  };

  const [filterCriteriaOverDue, setfilterCriteriaOverDue] = useState({
    fromDate: "",
    toDate: "",
    branch: "",
    course: "",
    enquiry: "",
    lead: "",
    admissiontoDate: "",
    admissionfromDate: "",
  });

  const HandleFilterCertriaOverDue = (e) => {
    const { name, value } = e.target;
    setfilterCriteriaOverDue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const FilterResetOverDue = () => {
    setfilterCriteriaOverDue({
      fromDate: "",
      toDate: "",
      branch: "",
      course: "",
      enquiry: "",
      lead: "",
      admissiontoDate: "",
      admissionfromDate: "",
    });
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "OVER_DUE_FEE_DETAILS_STUDENTS",
        data: {
          fromDate: "",
          toDate: "",
          branch: "",
          course: "",
          enquiry: "",
          lead: "",
          admissiontoDate: "",
          admissionfromDate: "",
        },
      },
    });


  };

  const filterSubmitOverDue = () => {
    if (!filterCriteriaOverDue?.fromDate && !filterCriteriaOverDue?.toDate && !filterCriteriaOverDue?.branch && !filterCriteriaOverDue?.course && !filterCriteriaOverDue?.enquiry && !filterCriteriaOverDue?.lead && !filterCriteriaOverDue?.admissionfromDate && !filterCriteriaOverDue?.admissiontoDate) {
      toast.error("Please fill in at least one filter criteria.");
      return;
    }
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "OVER_DUE_FEE_DETAILS_STUDENTS",
        data: {
          fromDate: filterCriteriaOverDue?.fromDate,
          toDate: filterCriteriaOverDue?.toDate,
          branch: filterCriteriaOverDue?.branch,
          course: filterCriteriaOverDue?.course,
          enquiry: filterCriteriaOverDue?.enquiry,
          lead: filterCriteriaOverDue?.lead,
          admissiontoDate: filterCriteriaOverDue?.admissiontoDate,
          admissionfromDate: filterCriteriaOverDue?.admissionfromDate,
        },
      },
    });
    const offcanvasElement = document.getElementById('offcanvasRightOverdue');
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();
  };

  const handlePageChangeOverDueFee = (page) => {
    debouncesetPage({ context: "OVER_DUE_FEE_DETAILS_STUDENTS", data: page });
  }


  //  Filters Validations
  const [formIsValid, setFormIsValid] = useState(false);
  const checkFormValidity = () => {
    const { fromDate, toDate, branch, course } = filterCriteria;
    setFormIsValid(fromDate !== '' || toDate !== '' || branch !== '' || course !== '');
  };

  useEffect(() => {
    checkFormValidity();
  }, [filterCriteria]);




  // handle the Tabs
  const [activeTabs, setActiveTable] = useState({
    TodayFeeDetails: true,
    UpcomingFeeDetails: false,
    OverDueFeeDetails: false,
  });

  console.log(activeTabs, "activeTabsjdhfdgf")

  const handleTabs = (tab) => {
    setActiveTable(prevState => ({
      ...prevState,  // Spread the previous state
      TodayFeeDetails: tab === "TodayFeeDetails",
      UpcomingFeeDetails: tab === "UpcomingFeeDetails",
      OverDueFeeDetails: tab === "OverDueFeeDetails",
    }));
  };

  useEffect(() => {
    const { TodayFeeDetails, UpcomingFeeDetails, OverDueFeeDetails } = activeTabs;
    if (TodayFeeDetails) {
      getTodayFeeDetailsStudents();
    } else if (UpcomingFeeDetails) {
      getUpComingFeeDetailsStudents();
    } else if (OverDueFeeDetails) {
      getOverDueFeeDetailsStudents();
    }
  }, [activeTabs]);



  return (
    <div>
      <BackButton heading="Fee Details" content="Back" />
      <div className="container d-flex justify-content-center">
        <ul className="nav gap-3 nav-tabs" id="pills-tab" role="tablist">
          {/* Today */}
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link card card_animate ${activeTabs.TodayFeeDetails ? 'active' : ''}`}
              id="pills-today-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-today"
              type="button"
              role="tab"
              aria-controls="pills-today"
              aria-selected={activeTabs.TodayFeeDetails}
              onClick={() => handleTabs("TodayFeeDetails")}
            >
              <div className="d-flex justify-content-between">
                <div className="text-start me-5">
                  <p className="fs_20 fw-500">Today</p>
                  <p className="mt-3 fs_14 lh-100 black_300">
                    <LiaRupeeSignSolid />
                    {Number(
                      parseFloat(
                        TodayFeeDetailsStudents?.totalDueAmount
                      ).toFixed(2)
                    ).toLocaleString("en-IN")}{"/-"}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <span className="avatar-title bg-danger-subtle rounded-circle fs-1">
                      <img
                        src={todayimg}
                        className="img-fluid"
                        width="100px"
                        height="100px"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </div>
            </button>
          </li>

          {/* Upcoming */}
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link card card_animate ${activeTabs.UpcomingFeeDetails ? 'active' : ''}`}
              id="pills-upcoming-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-upcoming"
              type="button"
              role="tab"
              aria-controls="pills-upcoming"
              aria-selected={activeTabs.UpcomingFeeDetails}
              onClick={() => handleTabs("UpcomingFeeDetails")}
            >
              <div className="d-flex justify-content-between">
                <div className="text-start me-4">
                  <p className="fs_20 fw-500">Upcoming</p>
                  <p className="mt-3 fs_14 lh-100 black_300">

                    <LiaRupeeSignSolid />
                    {Number(
                      parseFloat(
                        UpComingFeeDetailsStudents?.totalDueAmount
                      ).toFixed(2)
                    ).toLocaleString("en-IN")}{"/-"}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <span className="avatar-title bg-danger-subtle rounded-circle fs-1">
                      <img
                        src={upcommingimg}
                        className="img-fluid"
                        width="100px"
                        height="100px"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </div>
            </button>
          </li>

          {/* OverDue */}
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link card card_animate ${activeTabs.OverDueFeeDetails ? 'active' : ''}`}
              id="pills-overdue-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-overdue"
              type="button"
              role="tab"
              aria-controls="pills-overdue"
              aria-selected={activeTabs.OverDueFeeDetails}
              onClick={() => handleTabs("OverDueFeeDetails")}
            >
              <div className="d-flex justify-content-between">
                <div className="text-start me-5">
                  <p className="fs_20 fw-500">Over Due</p>
                  <p className="mt-3 fs_14 lh-100 black_300">
                    <LiaRupeeSignSolid />
                    {Number(
                      parseFloat(
                        OverDueFeeDetailsStudents?.totalDueAmount
                      ).toFixed(2)
                    ).toLocaleString("en-IN")}{"/-"}
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <span className="avatar-title bg-danger-subtle rounded-circle fs-1">
                      <img
                        src={overdueimg}
                        className="img-fluid"
                        width="100px"
                        height="100px"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </div>

      <div className="tab-content mt-4" id="pills-tabContent">

        {/*--------------------------------Today Fee Details-------------------------------------- */}
        {
          activeTabs?.TodayFeeDetails && (
            <div
              className={`tab-pane fade mt-4 ${activeTabs.TodayFeeDetails ? 'show active' : ''}`}
              id="pills-today"
              role="tabpanel"
              aria-labelledby="pills-today-tab"
              tabIndex="0"
            >
              <div className="container-fluid mt-4">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card border-0">
                      <div className="card-header">
                        <div className="row justify-content-between">
                          <div className="col-sm-4">
                            <div className="search-box">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Search for..."
                                name="search"
                                required
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="d-flex justify-content-end">
                              <div className="fs-13 me-3 mt-2">
                                {/* 1/4 */}

                              </div>

                              <button
                                className="btn btn_primary fs-13 me-2"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRightToday"
                                aria-controls="offcanvasRight"
                              >
                                <MdFilterList className="me-1 mb-1" />
                                Filters
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="offcanvas offcanvas-end  bg_light"
                          id="offcanvasRightToday"
                          aria-labelledby="offcanvasRightLabel"
                        >
                          <div className="offcanvas-header">
                            <h5
                              className="offcanvas-title text_color"
                              id="offcanvasRightLabel"
                            >
                              Filters
                            </h5>
                            <button
                              type="button"
                              className="btn-close text_color"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="offcanvas-body p-2 ">
                            {/* from date */}
                            {/* <div className="form-group text-start">
                          <label
                            className="form-label fs-s text_color"
                            for="example-text-input "
                          >
                            From Date
                          </label>
                          <input
                            className="form-control fs-s bg-form text_color input_bg_color date_input_color"
                            type="date"
                            id="exampleInputdate"
                            name="fromDate"
                            value={filterCriteria?.fromDate}
                            onChange={HandleFilterCertria}
                            required
                          />
                        </div> */}
                            {/* to date */}
                            {/* <div className="form-group text-start mt-2">
                          <label
                            className="form-label fs-s text_color"
                            for="example-text-input "
                          >
                            To Date
                          </label>
                          <input
                            className="form-control fs-s bg-form text_color input_bg_color date_input_color"
                            type="date"
                            id="exampleInputdate"
                            name="toDate"
                            value={filterCriteria?.toDate}
                            onChange={HandleFilterCertria}
                            required
                          />
                        </div> */}
                            {/* branch */}
                            <div className="mt-2">
                              <label className="form-label fs-s fw-medium txt-color">
                                Branch
                              </label>
                              <select
                                className="form-select input_bg_color text_color form-control select "
                                aria-label="Default select example"
                                placeholder="Branch*"
                                id="branch"
                                name="branch"
                                value={filterCriteria?.branch}
                                onChange={HandleFilterCertria}
                                required
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  Select the Branch{" "}
                                </option>
                                {BranchState?.branches && BranchState?.branches.length > 0
                                  ? BranchState?.branches?.map((item, index) => (
                                    <option key={index} value={item.fullname}>
                                      {item?.branch_name}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>
                            {/* course */}
                            <div className="">
                              <label className="form-label fs-s fw-medium text_color">
                                Course
                              </label>
                              <select
                                className="form-select form-control input_bg_color text_color select"
                                aria-label="Default select example"
                                placeholder="course*"
                                name="course"
                                id="course"
                                value={filterCriteria?.course}
                                onChange={HandleFilterCertria}
                                required
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  Select the Course{" "}
                                </option>
                                {courseState?.courses && courseState?.courses.length > 0
                                  ? courseState.courses.map((item, index) => (
                                    <option key={index} value={item?.course_name}>
                                      {item?.course_name}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>

                            {/* leadSource */}
                            <div className="mt-2">
                              <label className="form-label fs-s fw-medium text_color">
                                Lead Source
                              </label>
                              <select
                                className="form-select form-control input_bg_color text_color select"
                                aria-label="Default select example"
                                placeholder="leadSource*"
                                id="lead"
                                name="lead"
                                value={filterCriteria.lead}
                                onChange={HandleFilterCertria}
                                required
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  Select the Lead Source{" "}
                                </option>
                                {leadSourceState?.leadSources && leadSourceState?.leadSources.length > 0
                                  ? leadSourceState?.leadSources.map((item, index) => (
                                    <option key={index} value={item?.leadsource}>
                                      {item?.leadsource}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>
                            {/* councellor*/}
                            <div className="">
                              <label className="form-label fs-s fw-medium text_color">
                                Counsellors
                              </label>
                              <select
                                className="form-select form-control input_bg_color text_color select"
                                aria-label="Default select example"
                                placeholder="enquiryTakenBy*"
                                id="enquiry"
                                name="enquiry"
                                value={filterCriteria?.enquiry}
                                onChange={HandleFilterCertria}
                                required
                              >
                                <option value="" disabled selected >
                                  {" "}
                                  Select the Counsellor{" "}
                                </option>
                                {TotalUsersWithCountellers &&
                                  TotalUsersWithCountellers.length > 0
                                  ? TotalUsersWithCountellers.map((item, index) => {
                                    return (
                                      <option key={index} value={item?.fullname}>
                                        {item?.fullname}
                                      </option>
                                    );
                                  })
                                  : null}
                              </select>
                            </div>

                            <div>
                              <div className="position-absolute bottom-0 start-0 ms-2 mb-2">
                                <button
                                  className="btn btn_primary"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                  onClick={
                                    FilterReset
                                  }
                                >
                                  Clear
                                </button>
                              </div>
                              <div className="position-absolute bottom-0 end-0 me-2 mb-2">

                                <Button
                                  className="btn btn_primary"
                                  onClick={
                                    filterSubmit
                                  }
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="card-body">
                        <div className="table-responsive table-card  border-0">
                          <div className="table-container table-scroll">
                            <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                              <thead>
                                <tr className="">
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs fw-600 "
                                  >
                                    S.No
                                  </th>
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs fw-600  "
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs  fw-600  "
                                  >
                                    Branch
                                  </th>
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs fw-600  "
                                  >
                                    Counsellor
                                  </th>
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs  fw-600  "
                                  >
                                    Contact
                                  </th>
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs  fw-600 "
                                  >
                                    Course
                                  </th>

                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs  fw-600 "
                                  >
                                    Lead Source
                                  </th>
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs  fw-600 "
                                  >
                                    Due&nbsp;Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs  fw-600 "
                                  >
                                    Due&nbsp;Amount
                                  </th>
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs  fw-600 "
                                  >
                                    Paid&nbsp;Status
                                  </th>
                                  <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                    <th
                                      scope="col"
                                      className="fs-13 lh-xs  fw-600 "
                                    >
                                      Action
                                    </th>
                                  </GateKeeper>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  TodayFeeDetailsStudents?.PaginatedTodayFeeDetails && TodayFeeDetailsStudents?.PaginatedTodayFeeDetails?.length > 0 ?
                                    TodayFeeDetailsStudents?.loading ?
                                      <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                        Loading...
                                      </td>
                                      :
                                      TodayFeeDetailsStudents?.PaginatedTodayFeeDetails.map((item, index) => {

                                        return (
                                          <tr>
                                            <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                              {(TodayFeeDetailsStudents?.currentPage - 1) * TodayFeeDetailsStudents.perPage + index + 1}
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }}>
                                              {item?.name}
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light">
                                              {item?.branch}
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }} title={item?.enquirytakenby}>
                                              {item?.enquirytakenby}
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light">
                                              {item?.mobilenumber}
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }} title={item?.courses} >
                                              {item?.courses}
                                            </td>

                                            <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }} title={item?.leadsource[0]?.source}>
                                              {item?.leadsource[0]?.source}
                                            </td>



                                            <td className="fs-13 black_300  lh-xs bg_light ">
                                              {item?.nextduedate}
                                            </td>
                                            <td className="fs-13 black_300  lh-xs  bg_light">
                                              {Number(
                                                parseFloat(item.dueamount).toFixed(2)
                                              ).toLocaleString("en-IN")}
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light ">
                                              {item?.totalinstallments && item?.totalinstallments.length > 0 ?
                                                `${item?.totalinstallments[0].totalinstallmentspaid}/${item?.totalinstallments[0]?.totalinstallments}` :
                                                '-'
                                              }
                                            </td>
                                            <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                              <td className="fs_14 text_mute bg_light lh-xs ">
                                                <Link to={`/student/feeview/${item.id}`}>
                                                  <AiFillEye className=" me-3" />
                                                </Link>
                                              </td>
                                            </GateKeeper>
                                          </tr>

                                        )
                                      })
                                    :
                                    <tr>
                                      <td className="fs-13 black_300  lh-xs bg_light">
                                        no data
                                      </td>
                                    </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className=" mt-4 align-items-center d-flex justify-content-between row text-center text-sm-start   ">
                          <div className="col-sm">
                            <PaginationInfo
                              data={{
                                length: TodayFeeDetailsStudents?.PaginatedTodayFeeDetails?.length,
                                start: TodayFeeDetailsStudents?.startStudent,
                                end: TodayFeeDetailsStudents?.endStudent,
                                total: TodayFeeDetailsStudents?.searchResultStudents,
                              }}
                              loading={TodayFeeDetailsStudents?.loading}
                            />
                          </div>
                          <div className="col-sm-auto mt-3 mt-sm-0 d-flex">
                            <div className="mt-2">
                              <select
                                className="form-select form-control me-3 pagination-select"
                                aria-label="Default select example"
                                required
                                onChange={handlePerPage}
                                value={TodayFeeDetailsStudents?.perPage}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                              </select>
                            </div>


                            <div className=''>
                              <Pagination
                                currentPage={TodayFeeDetailsStudents?.currentPage}
                                totalPages={TodayFeeDetailsStudents?.totalPages}
                                loading={TodayFeeDetailsStudents?.loading}
                                onPageChange={handlePageChangeTodayFee}
                              />
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }



        {/* ------------------------UpComing Fee Details--------------------------------------- */}
        {
          activeTabs?.UpcomingFeeDetails && (
            <div
              className={`tab-pane fade mt-4 ${activeTabs.UpcomingFeeDetails ? 'show active' : ''}`}
              id="pills-upcoming"
              role="tabpanel"
              aria-labelledby="pills-upcoming-tab"
              tabIndex="0"
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card border-0">
                      <div className="card-header">
                        <div className="row justify-content-between">
                          <div className="col-sm-4">
                            <div className="search-box">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Search for..."
                                name="search"
                                required
                                onChange={handleSearchUpcoming}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="d-flex justify-content-end">
                              <div className="fs-13 me-3 mt-2">


                              </div>

                              <button
                                className="btn btn_primary fs-13 me-2"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRightUpcoming"
                                aria-controls="offcanvasRight"
                              >
                                <MdFilterList className="me-1 mb-1" />
                                Filters
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="offcanvas offcanvas-end text_color bg_light"
                          id="offcanvasRightUpcoming"
                          aria-labelledby="offcanvasRightLabel"
                        >
                          <div className="offcanvas-header">
                            <h5
                              className="offcanvas-title text_color"
                              id="offcanvasRightLabel"
                            >
                              Filters
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="offcanvas-body p-2">

                            {/* From Date */}
                            <div className="form-group text-start">
                              <label
                                className="form-label fs-s text_color"
                                for="example-text-input "
                              >
                                From Date
                              </label>
                              <input
                                className="form-control fs-s bg-form text_color input_bg_color date_input_color"
                                type="date"
                                id="exampleInputdate"
                                name="fromDate"
                                value={filterCriteriaUpcoming.fromDate}
                                onChange={HandleFilterCertriaUpcoming}
                                min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]}
                                required
                              />
                            </div>
                            {/*   To Date */}
                            <div className="form-group text-start mt-2">
                              <label
                                className="form-label fs-s text_color"
                                for="example-text-input "
                              >
                                To Date
                              </label>
                              <input
                                className="form-control fs-s bg-form text_color input_bg_color  date_input_color"
                                type="date"
                                id="exampleInputdate"
                                name="toDate"
                                value={filterCriteriaUpcoming.toDate}
                                onChange={HandleFilterCertriaUpcoming}
                                min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]}
                                required
                              />
                            </div>

                            {/* AdmissionFrom  */}
                            <div className="form-group text-start">
                              <label
                                className="form-label fs-s text_color"
                                for="example-text-input "
                              >
                                Admission From Date
                              </label>
                              <input
                                className="form-control fs-s bg-form text_color input_bg_color date_input_color"
                                type="date"
                                id="admissionfromDate"
                                name="admissionfromDate"
                                value={filterCriteriaUpcoming.admissionfromDate}
                                onChange={HandleFilterCertriaUpcoming}
                                required
                              />
                            </div>
                            {/*   Admission ToDate */}
                            <div className="form-group text-start mt-2">
                              <label
                                className="form-label fs-s text_color"
                                for="example-text-input "
                              >
                                Admission To Date
                              </label>
                              <input
                                className="form-control fs-s bg-form text_color input_bg_color  date_input_color"
                                type="date"
                                id="admissiontoDate"
                                name="admissiontoDate"
                                value={filterCriteriaUpcoming.admissiontoDate}
                                onChange={HandleFilterCertriaUpcoming}
                                required
                              />
                            </div>

                            {/* branch */}
                            <div className="mt-2">
                              <label className="form-label fs-s fw-medium txt-color">
                                Branch
                              </label>
                              <select
                                className="form-select form-control input_bg_color select"
                                aria-label="Default select example"
                                placeholder="Branch*"
                                id="branch"
                                name="branch"
                                value={filterCriteriaUpcoming.branch}
                                onChange={HandleFilterCertriaUpcoming}
                                required
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  Select the Branch{" "}
                                </option>
                                {BranchState.branches && BranchState.branches.length > 0
                                  ? BranchState.branches.map((item, index) => (
                                    <option key={index} value={item.fullname}>
                                      {item.branch_name}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>

                            {/* course */}
                            <div className="">
                              <label className="form-label fs-s fw-medium text_color">
                                Course
                              </label>
                              <select
                                className="form-select form-control input_bg_color text_color select"
                                aria-label="Default select example"
                                placeholder="course*"
                                name="course"
                                id="course"


                                value={filterCriteriaUpcoming.course}
                                onChange={HandleFilterCertriaUpcoming}
                                required
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  Select the Course{" "}
                                </option>
                                {courseState?.courses && courseState?.courses.length > 0
                                  ? courseState?.courses.map((item, index) => (
                                    <option key={index} value={item?.course_name}>
                                      {item?.course_name}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>

                            {/* leadSource */}
                            <div className="mt-2">
                              <label className="form-label fs-s fw-medium text_color">
                                Lead Source
                              </label>
                              <select
                                className="form-select form-control input_bg_color text_color select"
                                aria-label="Default select example"
                                placeholder="leadSource*"
                                id="lead"
                                name="lead"
                                value={filterCriteriaUpcoming?.lead}
                                onChange={HandleFilterCertriaUpcoming}
                                required
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  Select the Lead Source{" "}
                                </option>
                                {leadSourceState?.leadSources && leadSourceState?.leadSources.length > 0
                                  ? leadSourceState?.leadSources.map((item, index) => (
                                    <option key={index} value={item?.leadsource}>
                                      {item?.leadsource}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>
                            {/* councellor*/}
                            <div className="">
                              <label className="form-label fs-s fw-medium text_color">
                                Counsellors
                              </label>
                              <select
                                className="form-select form-control input_bg_color text_color select"
                                aria-label="Default select example"
                                placeholder="enquiryTakenBy*"
                                id="enquiry"
                                name="enquiry"
                                value={filterCriteriaUpcoming?.enquiry}
                                onChange={HandleFilterCertriaUpcoming}
                                required
                              >
                                <option value="" disabled selected >
                                  {" "}
                                  Select the Counsellor{" "}
                                </option>
                                {TotalUsersWithCountellers &&
                                  TotalUsersWithCountellers.length > 0
                                  ? TotalUsersWithCountellers.map((item, index) => {
                                    return (
                                      <option key={index} value={item?.fullname}>
                                        {item?.fullname}
                                      </option>
                                    );
                                  })
                                  : null}
                              </select>
                            </div>
                            <div>
                              <div className="position-absolute bottom-0 start-0 ms-2 mb-2">

                                <button
                                  className="btn btn_primary"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                  onClick={
                                    FilterResetUpcoming
                                  }
                                >
                                  Clear
                                </button>
                              </div>
                              <div className="position-absolute bottom-0 end-0 me-2 mb-2">

                                <Button
                                  className="btn btn_primary"
                                  onClick={
                                    filterSubmitUpcoming
                                  }
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="card-body">
                        <div className="table-responsive table-container table-scroll table-card  border-0">

                          <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                            <thead>
                              <tr className="">
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs fw-600 "
                                >
                                  S.No
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600  "
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600  "
                                >
                                  Branch
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600  "
                                >
                                  Counsellor
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600  "
                                >
                                  Contact
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600 "
                                >
                                  Course
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600 "
                                >
                                  Lead Source
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600 "
                                >
                                  Due&nbsp;Date
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600 "
                                >
                                  Due&nbsp;Amount
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600 "
                                >
                                  Paid&nbsp;Status
                                </th>
                                <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs  fw-600 "
                                  >
                                    Action
                                  </th>
                                </GateKeeper>
                              </tr>
                            </thead>
                            <tbody>

                              {
                                UpComingFeeDetailsStudents?.PaginatedUpComingFeeDetails && UpComingFeeDetailsStudents?.PaginatedUpComingFeeDetails?.length > 0 ? UpComingFeeDetailsStudents?.loading ?
                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                    Loading...
                                  </td>
                                  :
                                  UpComingFeeDetailsStudents?.PaginatedUpComingFeeDetails?.map((item, index) => {

                                    console.log(item?.leadsource[0].source, "itemitemitemdsfdsf")

                                    return (
                                      <tr>
                                        <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                          {(UpComingFeeDetailsStudents?.currentPage - 1) * UpComingFeeDetailsStudents.perPage + index + 1}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }}>
                                          {item?.name}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light">
                                          {item?.branch}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }} title={item.enquirytakenby}>
                                          {item?.enquirytakenby}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light">
                                          {item?.mobilenumber}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }} title={item.courses}>
                                          {item?.courses}
                                        </td>

                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }} title={item?.leadsource[0]?.source}>
                                          {item?.leadsource[0]?.source}
                                        </td>

                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.nextduedate}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs  bg_light">
                                          {Number(
                                            parseFloat(item?.dueamount).toFixed(2)
                                          ).toLocaleString("en-IN")}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.totalinstallments && item?.totalinstallments.length > 0 ?
                                            `${item?.totalinstallments[0].totalinstallmentspaid}/${item?.totalinstallments[0]?.totalinstallments}` :
                                            '-'
                                          }
                                        </td>
                                        <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                          <td className="fs_14 text_mute bg_light lh-xs ">
                                            <Link to={`/student/feeview/${item.id}`}>
                                              <AiFillEye className="eye_icon me-3" />
                                            </Link>
                                          </td>
                                        </GateKeeper>
                                      </tr>
                                    )
                                  })
                                  :
                                  <tr>
                                    <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                      no data
                                    </td>
                                  </tr>
                              }



                            </tbody>
                          </table>
                        </div>

                        <div className=" mt-4 align-items-center d-flex justify-content-between row text-center text-sm-start   ">
                          <div className="col-sm">

                            <PaginationInfo
                              data={{
                                length: UpComingFeeDetailsStudents?.PaginatedUpComingFeeDetails?.length,
                                start: UpComingFeeDetailsStudents?.startStudent,
                                end: UpComingFeeDetailsStudents?.endStudent,
                                total: UpComingFeeDetailsStudents?.searchResultStudents,
                              }}
                              loading={UpComingFeeDetailsStudents?.loading}
                            />

                          </div>
                          <div className="col-sm-auto mt-3 mt-sm-0 d-flex">
                            <div className="mt-2">
                              <select
                                className="form-select form-control me-3 pagination-select"
                                aria-label="Default select example"
                                required
                                onChange={handlePerPageUpcoming}
                                value={UpComingFeeDetailsStudents?.perPage}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                              </select>
                            </div>

                            <div className=''>
                              <Pagination
                                currentPage={UpComingFeeDetailsStudents?.currentPage}
                                totalPages={UpComingFeeDetailsStudents?.totalPages}
                                loading={UpComingFeeDetailsStudents?.loading}
                                onPageChange={handlePageChangeUpcomingFee}
                              />
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )
        }

        {/*----------------------------Over Due Fee Details----------------------------------------- */}

        {
          activeTabs?.OverDueFeeDetails && (
            <div
              className={`tab-pane fade mt-4 ${activeTabs.OverDueFeeDetails ? 'show active' : ''}`}
              id="pills-overdue"
              role="tabpanel"
              aria-labelledby="pills-overdue-tab"
              tabIndex="0"
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card border-0">
                      <div className="card-header">
                        <div className="row justify-content-between">
                          <div className="col-sm-4">
                            <div className="search-box">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Search for..."
                                name="search"
                                required
                                onChange={handleSearchOverDue}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="d-flex justify-content-end">
                              <div className="fs-13 me-3 mt-2">


                              </div>

                              <button
                                className="btn btn_primary fs-13 me-2"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRightOverdue"
                                aria-controls="offcanvasRight"
                              >
                                <MdFilterList className="me-1 mb-1" />
                                Filters
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="offcanvas offcanvas-end bg_light"
                          id="offcanvasRightOverdue"
                          aria-labelledby="offcanvasRightLabel"
                        >
                          <div className="offcanvas-header">
                            <h5
                              className="offcanvas-title text_color"
                              id="offcanvasRightLabel"
                            >
                              Filters
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="offcanvas-body p-2">

                            {/* from date */}
                            <div className="form-group text-start">
                              <label
                                className="form-label fs-s text_color"
                                for="example-text-input "
                              >
                                From Date
                              </label>
                              <input
                                className="form-control fs-s bg-form text_color input_bg_color date_input_color"
                                type="date"
                                id="exampleInputdate"
                                name="fromDate"
                                value={filterCriteriaOverDue?.fromDate}
                                onChange={HandleFilterCertriaOverDue}
                                max={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}
                                required
                              />
                            </div>
                            {/* to date */}
                            <div className="form-group text-start mt-2">
                              <label
                                className="form-label fs-s text_color"
                                for="example-text-input "
                              >
                                To Date
                              </label>
                              <input
                                className="form-control fs-s bg-form text_color input_bg_color date_input_color"
                                type="date"
                                id="exampleInputdate"
                                name="toDate"
                                value={filterCriteriaOverDue?.toDate}
                                onChange={HandleFilterCertriaOverDue}
                                max={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}
                                required
                              />
                            </div>

                            {/* AdmissionFrom  */}
                            <div className="form-group text-start">
                              <label
                                className="form-label fs-s text_color"
                                for="example-text-input "
                              >
                                Admission From Date
                              </label>
                              <input
                                className="form-control fs-s bg-form text_color input_bg_color date_input_color"
                                type="date"
                                id="admissionfromDate"
                                name="admissionfromDate"
                                value={filterCriteriaOverDue.admissionfromDate}
                                onChange={HandleFilterCertriaOverDue}
                                required
                              />
                            </div>
                            {/*   Admission ToDate */}
                            <div className="form-group text-start mt-2">
                              <label
                                className="form-label fs-s text_color"
                                for="example-text-input "
                              >
                                Admission To Date
                              </label>
                              <input
                                className="form-control fs-s bg-form text_color input_bg_color  date_input_color"
                                type="date"
                                id="admissiontoDate"
                                name="admissiontoDate"
                                value={filterCriteriaOverDue.admissiontoDate}
                                onChange={HandleFilterCertriaOverDue}
                                required
                              />
                            </div>

                            {/* branch */}
                            <div className="mt-2">
                              <label className="form-label fs-s fw-medium txt-color">
                                Branch
                              </label>
                              <select
                                className="form-select form-control input_bg_color select"
                                aria-label="Default select example"
                                placeholder="Branch*"
                                id="branch"
                                name="branch"
                                value={filterCriteriaOverDue?.branch}
                                onChange={HandleFilterCertriaOverDue}
                                required
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  Select the Branch{" "}
                                </option>
                                {BranchState?.branches && BranchState?.branches.length > 0
                                  ? BranchState.branches.map((item, index) => (
                                    <option key={index} value={item?.fullname}>
                                      {item?.branch_name}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>
                            {/* course */}
                            <div className="">
                              <label className="form-label fs-s fw-medium text_color">
                                Course
                              </label>
                              <select
                                className="form-select form-control input_bg_color text_color select"
                                aria-label="Default select example"
                                placeholder="course*"
                                name="course"
                                id="course"
                                value={filterCriteriaOverDue?.course}
                                onChange={HandleFilterCertriaOverDue}
                                required
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  Select the Course{" "}
                                </option>
                                {courseState?.courses && courseState?.courses?.length > 0
                                  ? courseState.courses.map((item, index) => (
                                    <option key={index} value={item?.course_name}>
                                      {item?.course_name}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>

                            {/* leadSource */}
                            <div className="mt-2">
                              <label className="form-label fs-s fw-medium text_color">
                                Lead Source
                              </label>
                              <select
                                className="form-select form-control input_bg_color text_color select"
                                aria-label="Default select example"
                                placeholder="leadSource*"
                                id="lead"
                                name="lead"
                                value={filterCriteriaOverDue?.lead}
                                onChange={HandleFilterCertriaOverDue}
                                required
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  Select the Lead Source{" "}
                                </option>
                                {leadSourceState?.leadSources && leadSourceState?.leadSources.length > 0
                                  ? leadSourceState?.leadSources.map((item, index) => (
                                    <option key={index} value={item?.leadsource}>
                                      {item?.leadsource}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>



                            {/* councellor*/}
                            <div className="">
                              <label className="form-label fs-s fw-medium text_color">
                                Counsellors
                              </label>
                              <select
                                className="form-select form-control input_bg_color text_color select"
                                aria-label="Default select example"
                                placeholder="enquiryTakenBy*"
                                id="enquiry"
                                name="enquiry"
                                value={filterCriteriaOverDue?.enquiry}
                                onChange={HandleFilterCertriaOverDue}
                                required
                              >
                                <option value="" disabled selected >
                                  {" "}
                                  Select the Counsellor{" "}
                                </option>
                                {TotalUsersWithCountellers &&
                                  TotalUsersWithCountellers?.length > 0
                                  ? TotalUsersWithCountellers?.map((item, index) => {
                                    return (
                                      <option key={index} value={item?.fullname}>
                                        {item?.fullname}
                                      </option>
                                    );
                                  })
                                  : null}
                              </select>
                            </div>


                            <div>
                              <div className="position-absolute bottom-0 start-0 ms-2 mb-2">
                                <button
                                  className="btn btn_primary"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                  onClick={
                                    FilterResetOverDue
                                  }
                                >
                                  Clear
                                </button>
                              </div>
                              <div className="position-absolute bottom-0 end-0 me-2 mb-2">

                                <Button
                                  className="btn btn_primary"
                                  onClick={
                                    filterSubmitOverDue
                                  }
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="card-body">
                        <div className="table-responsive table-container table-scroll table-card  border-0">

                          <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                            <thead>
                              <tr className="">
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs fw-600  "
                                >
                                  S.No
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600  "
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs fw-600  "
                                >
                                  Branch
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600  "
                                >
                                  Counsellor
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600  "
                                >
                                  Contact
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600 "
                                >
                                  Course
                                </th>

                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600 "
                                >
                                  Lead Source
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600 "
                                >
                                  Due&nbsp;Date
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600 "
                                >
                                  Due&nbsp;Amount
                                </th>
                                <th
                                  scope="col"
                                  className="fs-13 lh-xs  fw-600 "
                                >
                                  Paid&nbsp;Status
                                </th>
                                <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                  <th
                                    scope="col"
                                    className="fs-13 lh-xs  fw-600 "
                                  >
                                    Action
                                  </th>
                                </GateKeeper>
                              </tr>
                            </thead>
                            <tbody>

                              {
                                OverDueFeeDetailsStudents?.PaginatedOverDueFeeDetails && OverDueFeeDetailsStudents?.PaginatedOverDueFeeDetails?.length > 0 ? OverDueFeeDetailsStudents?.loading ?
                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                    Loading...
                                  </td>
                                  : OverDueFeeDetailsStudents?.PaginatedOverDueFeeDetails?.map((item, index) => {
                                    return (
                                      <tr>
                                        <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                          {(OverDueFeeDetailsStudents?.currentPage - 1) * OverDueFeeDetailsStudents?.perPage + index + 1}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }} title={item.name}>
                                          {item?.name}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light">
                                          {item?.branch}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }} title={item?.enquirytakenby}>
                                          {item?.enquirytakenby}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light">
                                          {item?.mobilenumber}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }} title={item?.courses}>
                                          {item?.courses}
                                        </td>

                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "150px" }} title={item?.leadsource[0]?.source}>
                                          {item?.leadsource[0]?.source}
                                        </td>

                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.nextduedate}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs  bg_light">
                                          {Number(
                                            parseFloat(item?.dueamount).toFixed(2)
                                          ).toLocaleString("en-IN")}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.totalinstallments && item?.totalinstallments.length > 0 ?
                                            `${item?.totalinstallments[0].totalinstallmentspaid}/${item?.totalinstallments[0]?.totalinstallments}` :
                                            '-'
                                          }
                                        </td>
                                        <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                          <td className="fs_14 text_mute bg_light lh-xs ">
                                            <Link to={`/student/feeview/${item.id}`}>
                                              <AiFillEye className="eye_icon me-3" />
                                            </Link>
                                          </td>
                                        </GateKeeper>
                                      </tr>

                                    )
                                  }) :
                                  <tr>
                                    <td className="fs-13 black_300  lh-xs bg_light">
                                      no data
                                    </td>
                                  </tr>
                              }

                            </tbody>
                          </table>
                        </div>
                        <div className=" mt-4 align-items-center d-flex justify-content-between">
                          <div>
                            <PaginationInfo
                              data={{
                                length: OverDueFeeDetailsStudents?.PaginatedOverDueFeeDetails?.length,
                                start: OverDueFeeDetailsStudents?.startStudent,
                                end: OverDueFeeDetailsStudents?.endStudent,
                                total: OverDueFeeDetailsStudents?.searchResultStudents,
                              }}
                              loading={OverDueFeeDetailsStudents?.loading}
                            />
                          </div>
                          <div className="col-sm-auto mt-3 mt-sm-0 d-flex">
                            <div className="mt-2">
                              <select
                                className="form-select form-control me-3 pagination-select"
                                aria-label="Default select example"
                                required
                                onChange={handlePerPageOverDue}
                                value={OverDueFeeDetailsStudents?.perPage}
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <div className=''>
                              <Pagination
                                currentPage={OverDueFeeDetailsStudents?.currentPage}
                                totalPages={OverDueFeeDetailsStudents?.totalPages}
                                loading={OverDueFeeDetailsStudents?.loading}
                                onPageChange={handlePageChangeOverDueFee}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }


      </div>
    </div>
  );
};

export default FeeFollowUps;
