import React from "react";
import BackButton from "../../../components/backbutton/BackButton";
import { MdLocalPrintshop } from "react-icons/md";

function BulkFeeInvoice() {
    return (
        <div>
            <BackButton heading="Fee Details" content="Back" to="/" />
            <div className="mt-3 text-end me-3 ">
                <button className="btn btn_primary mb-3  end">
                    <MdLocalPrintshop /> Print
                </button>
            </div>
            <div className="container-fluid">
                <div className="card">
                    <div className="container p-3">
                        <div className=" d-flex justify-content-between mt-3">
                            <div className="">
                                <h3 className="mb-0">Fee Invoice</h3>
                                <p className="mt-0 mb-0">
                                    <span className="fw-500 fs-14">Registration No</span> :
                                    342435345465
                                </p>
                                <p className="mt-0 mb-0">
                                    <span className="fw-500 fs-14">
                                        Invoice No
                                        <span className="me-4">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </span>
                                    : 12345678
                                </p>
                                <p className="mt-0 mb-0">
                                    <span className="fw-500 fs-14">
                                        Date<span className="me-5 ms-4">&nbsp;&nbsp;</span>
                                    </span>
                                    : 10-2-2024
                                </p>
                            </div>
                            <img
                                className="w-25 h-25"
                                src="https://www.admin.teksacademy.com/static/media/Teks-Logo-with-Trade.07d75f2c54a71180af08.png"
                                alt="logo"
                            />
                        </div>
                        <div className="mt-5">
                            <div className="row">
                                <div className="col-lg-8">
                                    <h5> Billed To :</h5>
                                    <p className="mb-0 mt-0">
                                        <span className="fw-500 fs-14 me-4">
                                            Name&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                        : srilaskhmi
                                    </p>
                                    <p className="mb-0 mt-0">
                                        <span className="fw-500 fs-14">Contact No </span>:
                                        9966180999
                                    </p>
                                    <p className="mb-0 mt-0">
                                        <span className="fw-500 fs-14 me-4">
                                            Email&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                        : laskhmi@gmail.com
                                    </p>
                                    <p className="mb-0 mt-0">
                                        <span className="fw-500 fs-14 me-3">Address&nbsp; </span>:
                                        Viayawada
                                    </p>
                                    <p className="mb-0 mt-0">
                                        <span className="fw-500 fs-14 me-3">
                                            Course&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                        : Python
                                    </p>
                                </div>
                                <div className="col-lg-4">
                                    <h5 className="fw-600 mb-4">KAPIL KNOWLEDGE HUB PVT LTD </h5>
                                    <p className="mb-0 mt-0">
                                        <span className="fw-500 fs-14 me-4">CIN&nbsp;&nbsp; </span>:
                                        U80100TG2018PTC123853
                                    </p>
                                    <p className="mb-0 mt-0">
                                        <span className="fw-500 fs-14 me-3">GSTIN </span>:
                                        36AAHCK0599C1ZI
                                    </p>
                                    <p className="mb-0 mt-0">
                                        <span className="fw-500 fs-14">Branch&nbsp;&nbsp; </span>:
                                        Teks-Hitech City
                                    </p>
                                </div>
                            </div>
                        </div>
                        <table class="table mt-3">
                            <thead className="page_color">
                                <tr className="text-center">
                                    <th scope="col" className="fw-600 fs-14 text-center">
                                        Fee Type
                                    </th>
                                    <th scope="col" className="fw-600 fs-14 text-center">
                                        Course Type
                                    </th>
                                    <th scope="col" className="fw-600 fs-14 text-center">
                                        HSN Type
                                    </th>
                                    <th scope="col" className="fw-600 fs-14 text-center">
                                        Amount
                                    </th>
                                    <th scope="col" className="fw-600 fs-14 text-center">
                                        Tax
                                    </th>
                                    <th scope="col" className="fw-600 fs-14 text-center">
                                        Tax Amount
                                    </th>
                                    <th scope="col" className="fw-600 fs-14 text-center">
                                        Total Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row"  className="text-center">Admission Fee</td>
                                    <td className="text-center">Online</td>
                                    <td className="text-center">2345</td>
                                    <td className="text-center">34.44</td>
                                    <td className="text-center">15%</td>
                                    <td className="text-center">56.33</td>
                                    <td className="text-center">488</td>
                                </tr>

                                <tr className="text-center">
                                    <td colspan="3" className="text-center">Total</td>
                                    <td className="text-center">34.44</td>
                                    <td className="text-center"></td>
                                    <td className="text-center">56.33</td>
                                    <td className="text-center">488</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table mt-3">
                            {/* <thead className="btn_primary">
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th colspan="2">dfsdf</th>
                                    <th colspan="2">dsvds</th>
                                    <th></th>
                                   
                                </tr>
                                <tr>
                                    <th scope="col" className="fw-600 fs-14fw-600 fs-14">HSN/AC</th>
                                    <th scope="col" className="fw-600 fs-14">Taxable Value</th>
                                    <th scope="col" className="fw-600 fs-14">Rate</th>
                                    <th scope="col" className="fw-600 fs-14">Amount</th>
                                    <th scope="col" className="fw-600 fs-14">Rate</th>
                                    <th scope="col" className="fw-600 fs-14">Amount</th>
                                    <th scope="col" className="fw-600 fs-14">Total Tax Amount</th>
                                </tr>
                            </thead> */}
                            <thead className="page_color">

                                <tr>

                                    <th className="fw-600 fs-14 text-center" rowspan="2">

                                        HSN/AC
                                    </th>
                                    <th className="fw-600 fs-14 text-center" rowspan={3}>
                                        Taxable  Value
                                    </th>
                                    <th className="fw-600 fs-14 text-center" colSpan={2}>
                                        CGST
                                    </th>

                                    <th className="fw-600 fs-14 text-center" colSpan={2}>
                                        SGST
                                    </th>
                                    <th className="fw-600 fs-14 text-center" rowspan="3">
                                        Total Tax Amount
                                    </th>
                                </tr>
                                <tr>
                                    <th className="fw-600 fs-14 text-center">Rate</th>
                                    <th className="fw-600 fs-14 text-center">Amount</th>
                                    <th className="fw-600 fs-14 text-center">Rate</th>
                                    <th className="fw-600 fs-14 text-center">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">Online</td>
                                    <td className="text-center">422.55</td>
                                    <td className="text-center">9%</td>
                                    <td className="text-center">34.44</td>
                                    <td className="text-center">15%</td>
                                    <td className="text-center">34.55</td>
                                    <td className="text-center">56.12</td>
                                </tr>

                                <tr>
                                    <td colspan="3" className="text-center">Total</td>
                                    <td className="text-center">34.44</td>
                                    <td className="text-center"></td>
                                    <td className="text-center">56.33</td>
                                    <td className="text-center">488</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mt-5 d-flex justify-content-between">
                            <div>
                                <h5>Bank Details :</h5>
                                <p className="mt-0 mb-0 fs-m fw-400">
                                    GSTIN <span className="me-4"></span>&nbsp;&nbsp;&nbsp;&nbsp;:
                                </p>
                                <p className="mt-0 mb-0 fs-m fw-400">Account No :</p>
                                <p className="mt-0 mb-0 fs-m fw-400">
                                    IFSC Code<span className="me-2"></span>&nbsp;:
                                </p>
                                <p className="mt-0 mb-0 fs-m fw-400">
                                    Branch<span className="me-4"></span>&nbsp;&nbsp;:
                                </p>
                            </div>
                            <div className="text-center">
                                <h6 className="mb-0 mt-0 fw-500">
                                    KAPIL KNOWLEDGE HUB PVT LTD
                                </h6>
                                <p className="mb-0 mt-0 fs-s">
                                    (Formely Kapil Food Park Pvt Ltd)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BulkFeeInvoice;
