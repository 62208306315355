import React, { useState, useRef, useEffect } from "react";
import Button from "../../../components/button/Button";
import BackButton from "../../../components/backbutton/BackButton";

const LaunchButton = () => {
  const options = [
    "React Props.",
    "ReactJS State",
    "React JS Components",
    "React with Redux",
    "Features React JS"
  ];
  const [activeButton, setActiveButton] = useState("toggle-button2");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, []);

  const handleToggleClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = options.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setShowOptions(true);
  };

  const handleOptionClick = (option) => {
    setSearchTerm(option);
    setShowOptions(false);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      event.target.id !== "searchInput"
    ) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <BackButton heading="Launch" content="Back" to="/" />
      <div className="container-fluid mt-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="">
                  <label
                    htmlFor="firstNameinput"
                    className="form-label fs-s fw-medium black_300"
                  >
                    Date
                  </label>
                  <input
                    type="date"
                    className="form-control fs-s bg-form text_color input_bg_color"
                    id=""
                    name=""
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <label
                  htmlFor="firstNameinput"
                  className="form-label fs-s fw-medium black_300"
                >
                  Topic Name
                </label>
                <div
                  className="input-group"
                  style={{ width: "100%", position: "relative" }}
                >
                  <input
                    type="text"
                    id="searchInput" // Assign an ID to identify the input field
                    className="form-control fs-s bg-form text_color input_bg_color"
                    placeholder="Select Topic"
                    style={{ width: "100%" }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={() => setShowOptions(true)}
                  />
                  {showOptions && (
                    <div
                      ref={dropdownRef}
                      className="options-dropdown"
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        zIndex: 1000,
                        maxHeight: "200px",
                        overflowY: "auto"
                      }}
                    >
                      {filteredOptions.map((option) => (
                        <div
                          key={option}
                          onClick={() => handleOptionClick(option)}
                          className="option-item"
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4 pt-4 mt-2">
                <Button className="btn btn_primary">Mark Attendance</Button>
                <Button className="btn btn_primary ms-3">Launch</Button>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <div className="col-sm-8">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control search input_bg_color select"
                      placeholder="Search for..."
                      name="search"
                      required
                    // onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="card-body mt-3">
                  <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                    <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                      <thead>
                        <tr className="">
                          <th scope="col" className="fs-13 lh-xs fw-600 ">
                            S.No
                          </th>
                          <th scope="col" className="fs-13 lh-xs fw-600 ">
                            Student Name
                          </th>
                          <th
                            scope="col"
                            className="fs-13 lh-xs fw-600 text-truncate"
                          >
                            Attendance
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="fs-13 black_300 fw-500 lh-xs bg_light">
                            01
                          </td>
                          <td className="fs-13 black_300 lh-xs bg_light">
                            Krishna
                          </td>
                          <td className="fs-13 black_300 lh-xs bg_light ">
                            <div className="tri-state-toggle">
                              <button
                                className={`tri-state-toggle-button ${activeButton === "toggle-button1"
                                  ? "active red"
                                  : ""
                                  }`}
                                id="toggle-button1"
                                onClick={() => handleToggleClick("toggle-button1")}
                              >
                                A
                              </button>
                              <button
                                className={`tri-state-toggle-button ${activeButton === "toggle-button2"
                                  ? "active green"
                                  : ""
                                  }`}
                                id="toggle-button2"
                                onClick={() => handleToggleClick("toggle-button2")}
                              >p</button>
                              {/* <button
                                className={`tri-state-toggle-button ${activeButton === "toggle-button3"
                                  ? "active green"
                                  : ""
                                  }`}
                                id="toggle-button3"
                                onClick={() => handleToggleClick("toggle-button3")}
                              >
                                P
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchButton;
