import React, { useEffect, useMemo, useState } from "react";
import "../../../../assets/css/Table.css";
import { Link } from "react-router-dom";
import { FaFilePdf, FaRegFilePdf } from "react-icons/fa6";
import { useStudentsContext } from "../../../../dataLayer/hooks/useStudentsContext";
import Usedebounce from "../../../../dataLayer/hooks/useDebounce/Usedebounce";
import { toast } from "react-toastify";
import { useUserContext } from "../../../../dataLayer/hooks/useUserContext";
import { useBranchContext } from "../../../../dataLayer/hooks/useBranchContext";
import { useCourseContext } from "../../../../dataLayer/hooks/useCourseContext";
import BackButton from "../../../components/backbutton/BackButton";
import { Offcanvas } from "bootstrap";
import { MdFilterList } from "react-icons/md";
import GateKeeper from "../../../../rbac/GateKeeper";
import Pagination from "../../../../utils/Pagination";
import PaginationInfo from "../../../../utils/PaginationInfo";
import Filter from "../../../../utils/Filter";
import { HiMiniPlus } from "react-icons/hi2";



const Certificate = () => {
  const {
    studentState: { CertificateStudents },
    Dispatchstudents,
  } = useStudentsContext();
  const {
    UsersState: { TotalUsersWithCountellers },
  } = useUserContext();
  const { BranchState } = useBranchContext();
  const { courseState } = useCourseContext();
  const { debouncesetSearch, debouncesetPage } = Usedebounce(Dispatchstudents);
  const courseData = useMemo(() => courseState?.courses?.map((item) => ({ label: item?.course_name, value: item?.course_name, })) || [],
    [courseState?.courses]
  );
  const EnquirytakenByData = useMemo(() => TotalUsersWithCountellers?.map((item) => ({ label: item?.fullname, value: item?.fullname, })) || [], [TotalUsersWithCountellers]
  );

  const branchData = useMemo(() => BranchState?.branches?.map((item) => ({ label: item?.branch_name, value: item?.branch_name, })) || [],
    [BranchState?.branches]
  );
  useEffect(() => {
    setFilterData((prevState) =>
      prevState.map((item) => {
        if (item.inputname === "course") {
          return { ...item, options: courseData };
        }
        if (item.inputname === "enquiry") {
          return { ...item, options: EnquirytakenByData };
        }
        if (item.inputname === "branch") {
          return { ...item, options: branchData };
        }
        return item;
      })
    );
  }, [courseData, EnquirytakenByData, branchData]);

  const initialState = [
    { label: "From Date", type: "date", inputname: "fromDate", value: "" },
    { label: "TO Date", type: "date", inputname: "toDate", value: "" },
    { label: "Course", type: "select", inputname: "course", value: "", options: [] },
    { label: "Counsellor", type: "select", inputname: "enquiry", value: "", options: [] },
    { label: "Branch", type: "select", value: "", inputname: "branch", options: [] },
    {
      label: "Certificate Status", type: "select", inputname: "certificateStatus", value: "", options: [
        { label: "Issued", value: "issued" },
        // { label: "not Issued", value: " " },
        { label: "Pending", value: "request Submitted" },
      ],
    },

    {
      label: "Student's Type", type: "select", inputname: "studentType", value: "", options: [
        { label: "Existing Students", value: "newStudents" },
        { label: "Old Students", value: "oldStudents" },
      ],
    },
    // {
    //   label: "InternShip Status", type: "select", inputname: "internShipStatus", value: "", options: [
    //     { label: "Issued", value: "InternShip Certificate Issued" },
    //     { label: "Not Issued", value: "InternShip Not Issued" },
    //     { label: "Pending", value: "InternShip Request Submitted" },
    //     // { label: "Not At Issued", value: " " },

    //   ],

    // },
    // {
    //   label: "IEP Status", type: "select", inputname: "iepStatus", value: "", options: [
    //     { label: "Issued", value: "IEP Certificate Issued" },
    //     { label: "Not Issued", value: "IEP Not Issued" },
    //     { label: "Pending", value: "IEP Request Submitted" },
    //     // { label: "Not At Issued", value: " " },
    //   ],
    // },
  ];

  const [filterData, setFilterData] = useState(initialState);

  const HandleFilters = (index, name, value) => {
    let updatedFilterData = [...filterData];
    updatedFilterData[index].value = value;
    setFilterData(updatedFilterData);
  };

  const handleSearch = (e) => {
    debouncesetSearch({
      context: "CERTIFICATE_STUDENTS",
      data: e.target.value,
    });
  };

  const handlePerPage = (e) => {
    const selectedvalue = parseInt(e.target.value, 10);
    Dispatchstudents({
      type: "SET_PER_PAGE",
      payload: {
        context: "CERTIFICATE_STUDENTS",
        data: selectedvalue,
      },
    });
  };

  const FilterReset = () => {
    const resetFilterData = filterData?.map((item) => ({
      ...item,
      value: "",
    }));
    setFilterData(resetFilterData);
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "CERTIFICATE_STUDENTS",
        data: {
          fromDate: "",
          toDate: "",
          course: "",
          enquiry: "",
          branch: "",
          certificateStatus: "",
          internShipStatus: "",
          iepStatus: "",
          studentType: "",
        },
      },
    });
  };

  const filterSubmit = () => {
    const filter = filterData.reduce((acc, item) => {
      acc[item.inputname] = item.value;
      return acc;
    }, {});

    if (
      !filter.fromDate &&
      !filter.toDate &&
      !filter.course &&
      !filter.enquiry &&
      !filter.branch &&
      !filter.certificateStatus &&
      !filter.internShipStatus &&
      !filter.iepStatus &&
      !filter?.studentType
    ) {
      toast.error("Please fill in at least one filter criteria.");
      return;
    }
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "CERTIFICATE_STUDENTS",
        data: {
          fromDate: filter?.fromDate,
          toDate: filter?.toDate,
          course: filter?.course,
          enquiry: filter?.enquiry,
          branch: filter?.branch,
          certificateStatus: filter?.certificateStatus,
          internShipStatus: filter?.internShipStatus,
          iepStatus: filter?.iepStatus,
          studentType: filter?.studentType
        },
      },
    });
    const offcanvasElement = document.getElementById("offcanvasRight");
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();
  };

  const handlePageChange = (page) => {
    debouncesetPage({ context: "CERTIFICATE_STUDENTS", data: page });
  };

  useEffect(() => {
    debouncesetPage({ context: "CERTIFICATE_STUDENTS", data: 1 });
    debouncesetSearch({ context: "CERTIFICATE_STUDENTS", data: "" });
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "CERTIFICATE_STUDENTS",
        data: {
          fromDate: "",
          toDate: "",
          course: "",
          enquiry: "",
          branch: "",
          certificateStatus: "",
          internShipStatus: "",
          iepStatus: "",
          studentType: "",
        },
      },
    });
    Dispatchstudents({
      type: "SET_PER_PAGE",
      payload: {
        context: "CERTIFICATE_STUDENTS",
        data: 10,
      },
    });
  }, []);

  return (
    <div>
      <BackButton heading="Certificate" content="Back" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card border-0">
              <div className="card-header">
                <div className="row justify-content-between">
                  <div className="col-sm-4">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search input_bg_color text_color "
                        placeholder="Search for..."
                        name="search"
                        required
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="buttons_alignment">

                      <GateKeeper
                        requiredModule="Student Management"
                        submenumodule="Certificate"
                        submenuReqiredPermission="canCreate"
                      >

                        <Link to="/student/certificateissueform">
                          <button
                            className="btn btn-sm btn_primary fs-13 me-1 margin_top_12"
                            type="button"
                            required
                          >
                            {<HiMiniPlus />}Certificate For Old Students
                          </button>
                        </Link>
                      </GateKeeper>


                      <button
                        className="btn btn-sm btn_primary fs-13 me-1 margin_top_12"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        <MdFilterList className="me-1 mb-1" />
                        Filters
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="offcanvas offcanvas-end  bg_white"
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                >
                  <div className="offcanvas-header">
                    <h5
                      className="offcanvas-title text_color"
                      id="offcanvasRightLabel"
                    >
                      Filters
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="offcanvas-body p-2">
                    <Filter
                      filterData={filterData}
                      HandleFilters={HandleFilters}
                      filterReset={FilterReset}
                      filterSubmit={filterSubmit}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-card  border-0">
                  <div className="table-container table-scroll">
                    <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                      <thead>
                        <tr className="">
                          <th scope="col" className="fs-13 lh-xs fw-600  ">
                            S.No
                          </th>
                          <th scope="col" className="fs-13 lh-xs fw-600  ">
                            Name
                          </th>
                          <th scope="col" className="fs-13 lh-xs  fw-600  ">
                            Course
                          </th>
                          <th scope="col" className="fs-13 lh-xs  fw-600  ">
                            Registration ID
                          </th>

                          <th scope="col"
                            className="fs-13 lh-xs  fw-600 text-truncate"
                            title="Course Certificate Status"
                            style={{ maxWidth: "120px" }}
                          >
                            Course Certificate Status
                          </th>
                          <th scope="col"
                            className="fs-13 lh-xs  fw-600 text-truncate"
                            title="Internship Certificate Status"
                            style={{ maxWidth: "120px" }}
                          >
                            Internship Certificate Status
                          </th>
                          <th scope="col"
                            className="fs-13 lh-xs  fw-600 text-truncate"
                            title="IEP Certificate Status"
                            style={{ maxWidth: "120px" }}
                          >
                            IEP Certificate Status
                          </th>

                          <GateKeeper
                            requiredModule="Student Management"
                            submenumodule="Certificate"
                            submenuReqiredPermission="canUpdate"
                          >
                            <th scope="col" className="fs-13 lh-xs  fw-600 ">
                              Request Certificate
                            </th>
                          </GateKeeper>

                          <th scope="col" className="fs-13 lh-xs  fw-600 ">
                            PDF's
                          </th>

                        </tr>
                      </thead>
                      <tbody className="">
                        {CertificateStudents?.PaginatedCertificateStudents &&
                          CertificateStudents?.PaginatedCertificateStudents
                            .length > 0 ? (
                          CertificateStudents?.loading ? (
                            <tr>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                loading...
                              </td>
                            </tr>
                          ) : (
                            CertificateStudents?.PaginatedCertificateStudents?.map(
                              (item, index) => {

                                console.log(item, "jsgdjgjdgajgd")
                                const currentDate = new Date().toISOString().split("T")[0];
                                let certificateStatusObj = item.certificate_status;

                                if (typeof certificateStatusObj === "string") {
                                  certificateStatusObj =
                                    JSON.parse(certificateStatusObj);
                                }

                                // certificate Status
                                const certificateStatus = certificateStatusObj
                                  .map((item) => item.certificateStatus)
                                  .join(", ");

                                console.log(item?.certificate_status[0]?.iep?.iepCertificateStatus, "lshfidgfuf")

                                return (
                                  <tr>
                                    <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                      {(CertificateStudents?.currentPage - 1) *
                                        CertificateStudents.perPage +
                                        index +
                                        1}
                                    </td>
                                    <td
                                      className="fs-13 black_300  lh-xs bg_light text-truncate"
                                      style={{ maxWidth: "150px" }}
                                      title={item.name}
                                    >
                                      {item?.name}
                                    </td>
                                    <td
                                      className="fs-13 black_300  lh-xs bg_light text-truncate"
                                      style={{ maxWidth: "150px" }}
                                      title={item.courses}
                                    >
                                      {item?.courses}
                                    </td>
                                    <td className="fs-13 black_300  lh-xs bg_light">
                                      {item?.registrationnumber}
                                    </td>


                                    <td className="fs-13 black_300  lh-xs bg_light" >
                                      {certificateStatus === "" ? "Not At Issued" : certificateStatus === "request Submitted" ? "Pending" : certificateStatus === "issued" ? "Issued" : ""}
                                    </td>



                                    <td className="fs-13 black_300 lh-xs bg_light text-truncate" style={{ maxWidth: "120px" }}>
                                      {item?.certificate_status[0]?.internShip?.internShipCertificateStatus ? item?.certificate_status[0]?.internShip?.internShipCertificateStatus === "InternShip Not Issued" ? "Not Issued" : item?.certificate_status[0]?.internShip?.internShipCertificateStatus === "InternShip Request Submitted" ? "pending" : "Issued" : "Not At Issued"}
                                    </td>

                                    <td className="fs-13 black_300 lh-xs bg_light text-truncate" style={{ maxWidth: "120px" }}>
                                      {item?.certificate_status[0]?.iep?.iepCertificateStatus ? item?.certificate_status[0]?.iep?.iepCertificateStatus === "IEP Not Issued" ? "Not Issued" : item?.certificate_status[0]?.iep?.iepCertificateStatus === "IEP Request Submitted" ? "pending" : "Issued" : "Not At Issued"}
                                    </td>

                                    <GateKeeper
                                      requiredModule="Student Management"
                                      submenumodule="Certificate"
                                      submenuReqiredPermission="canUpdate"
                                    >
                                      <td className="fs-13 black_300  lh-xs bg_light" >
                                        {(certificateStatus === "") && (
                                          <Link
                                            to={`/student/certificateissueform/request/${item?.registrationnumber}`}
                                          >
                                            <div
                                              className={certificateStatus === "" ? "rounded btn_primary font-size-xxs fw-100 btn-block pt-1 text-center pb-1 ps-1 text-white" : " rounded  btn_pending font-size-xxs fw-100 btn-block text-center  pt-1 pb-1 text-white"}
                                              type="button"
                                              required
                                            >
                                              Request Certificate
                                            </div>
                                          </Link>
                                        )}

                                        {
                                          certificateStatus === "request Submitted" && (
                                            <div
                                              className=" rounded  btn_pending font-size-xxs fw-100 btn-block text-center  pt-1 pb-1 text-white"
                                              type="button"
                                              required
                                              style={{ cursor: "not-allowed" }}
                                              disable
                                            >
                                              Pending Request
                                            </div>
                                          )

                                        }

                                        {certificateStatus === "issued" && (
                                          <div
                                            className="  rounded font-size-xxs  btn_certificate_submit fw-100 text-center pt-1 pb-1 ps-1 text-white"
                                            type="button"
                                            disable
                                            style={{ cursor: "not-allowed" }}
                                          >
                                            Certificate Submitted
                                          </div>
                                        )}
                                      </td>
                                    </GateKeeper>




                                    <GateKeeper
                                      requiredModule="Student Management"
                                      submenumodule="Certificate"
                                      submenuReqiredPermission="canRead"
                                    >
                                      <td className="fs-13 black_300  lh-xs bg_light">
                                        {certificateStatus === "issued" ? (
                                          <Link className="m-2" title="Course"
                                            to={`/student/certificateprint/${item?.registrationnumber}`}
                                          >
                                            <FaFilePdf className="text_color fs-12" />
                                          </Link>
                                        ) :
                                          <span className="text_color fs-12 m-2">NA</span>
                                        }


                                        {item?.certificate_status[0]?.internShip?.internShipCertificateStatus === "InternShip Certificate Issued" ? (
                                          <Link className="m-2" title="InternShip"
                                            to={`/student/internshipcertificate/${item?.registrationnumber}`}
                                          >
                                            <FaFilePdf className="text_color fs-12" />
                                          </Link>
                                        ) :
                                          <span className="text_color fs-12 m-2"> NA</span>

                                        }

                                        {item?.certificate_status[0]?.iep?.iepCertificateStatus === "IEP Certificate Issued" ? (
                                          <Link className="m-2" title="IEP"
                                            to={`/student/iepcertificate/${item?.registrationnumber}`}
                                          >
                                            <FaFilePdf className="text_color fs-12" />
                                          </Link>
                                        ) :

                                          <span className="text_color fs-12 m-2"> NA</span>

                                        }
                                      </td>
                                    </GateKeeper>

                                  </tr>
                                );
                              }
                            )
                          )
                        ) : (
                          <tr>
                            <td className="fs-13 black_300  lh-xs bg_light">
                              No data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className=" mt-4 align-items-center d-flex justify-content-between row text-center text-sm-start">
                  <div className="col-sm">
                    <PaginationInfo
                      data={{
                        length:
                          CertificateStudents?.PaginatedCertificateStudents
                            ?.length,
                        start: CertificateStudents?.startStudent,
                        end: CertificateStudents?.endStudent,
                        total: CertificateStudents?.searchResultStudents,
                      }}
                      loading={CertificateStudents?.loading}
                    />
                  </div>
                  <div className="col-sm-auto mt-3 mt-sm-0 d-flex">
                    <div className="mt-2">
                      <select
                        className="form-select form-control me-3 input_bg_color text_color pagination-select"
                        aria-label="Default select example"
                        placeholder="Branch*"
                        name="branch"
                        id="branch"
                        required
                        onChange={handlePerPage}
                        value={CertificateStudents?.perPage}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="">
                      <Pagination
                        currentPage={CertificateStudents?.currentPage}
                        totalPages={CertificateStudents?.totalPages}
                        loading={CertificateStudents?.loading}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
export default Certificate;



