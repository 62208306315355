import { Route, Routes } from 'react-router-dom';
import Error from '../../componentLayer/pages/Error/Error';
import RouteBlocker from '../../rbac/RouteBlocker';
import Webinar from '../../componentLayer/pages/leads/websiteLeads/Webinar';
import ContactUs from '../../componentLayer/pages/leads/websiteLeads/ContactUs';
import WhatsApp from '../../componentLayer/pages/leads/websiteLeads/WhatsApp';
import DownloadSyllabus from '../../componentLayer/pages/leads/websiteLeads/DownloadSyllabus';
import ViewCourse from '../../componentLayer/pages/leads/websiteLeads/ViewCourse';
import HLPEnquireLeads from '../../componentLayer/pages/leads/websiteLeads/HLPEnquireLeads';
import SLPEnquireLeads from '../../componentLayer/pages/leads/websiteLeads/SLPEnquireLeads';

function LeadsRoutes() {
    return (
        <Routes>
            
            <Route path='*' element={<Error />} />

            <Route path="/webinarleads" element={
                <RouteBlocker requiredModule="Leads" requiredPermission="all" submenumodule="Website Leads" submenuReqiredPermission="canRead">
                    <Webinar />
                </RouteBlocker>
            } />

            <Route path="/contactusleads" element={
                <RouteBlocker requiredModule="Leads" requiredPermission="all" submenumodule="Website Leads" submenuReqiredPermission="canRead">
                    <ContactUs />
                </RouteBlocker>
            } />

            <Route path="/whatsappleads" element={
                <RouteBlocker requiredModule="Leads" requiredPermission="all" submenumodule="Website Leads" submenuReqiredPermission="canRead">
                    <WhatsApp />
                </RouteBlocker>
            } />

            <Route path="/downloadsyllabusleads" element={
                <RouteBlocker requiredModule="Leads" requiredPermission="all" submenumodule="Website Leads" submenuReqiredPermission="canRead">
                    <DownloadSyllabus />
                </RouteBlocker>
            } />

            <Route path="/viewcourseleads" element={
                <RouteBlocker requiredModule="Leads" requiredPermission="all" submenumodule="Website Leads" submenuReqiredPermission="canRead">
                    <ViewCourse />
                </RouteBlocker>
            } />

            <Route path="/hlpenquireleads" element={
                <RouteBlocker requiredModule="Leads" requiredPermission="all" submenumodule="Website Leads" submenuReqiredPermission="canRead">
                    <HLPEnquireLeads />
                </RouteBlocker>
            } />

            <Route path="/slpenquireleads" element={
                <RouteBlocker requiredModule="Leads" requiredPermission="all" submenumodule="Website Leads" submenuReqiredPermission="canRead">
                    <SLPEnquireLeads />
                </RouteBlocker>
            } />

        </Routes>
    );
}

export default LeadsRoutes;