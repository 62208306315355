import React from 'react'
import BackButton from '../../../components/backbutton/BackButton';
import girl_img from '../../../../assets/images/batch_management/girl_img.png'
import { HiMiniPlus } from "react-icons/hi2";
import { MdOutlinePeopleOutline } from "react-icons/md";
import '../../../../assets/css/batchmanagement.css';
import { FaPeopleLine } from "react-icons/fa6";
import { FaPeopleGroup } from "react-icons/fa6";
import { BsPersonVideo2 } from "react-icons/bs";
import GraphOne from './GraphOne';
import GraphThree from './GraphThree';
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import AddBatch from '../batches/AddBatch';


const BatchManagement = () => {

  const [showModal1, setShowModal1] = useState(false);
  const handleCloseModal1 = () => setShowModal1(false);


  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Active Batches',
        data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
      },
      {
        name: 'Students',
        data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '15%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          'pavan', 'sasi', 'krishna', 'bala', 'abhi', 'priya', 'mouni', 'anu', 'sri', 'shobha', 'ammu', 'ricky'
        ],
      },
      yaxis: {
        title: {

        }
      },
      fill: {
        opacity: 1
      },
      colors: ['#405189', '#A9A9A9'],
      title: {

        align: 'left',
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
    },
  });

  return (
    <div>
      <BackButton heading="Dashboard Batch System" content="Back" to="/" />
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-lg-6">
            {/* create batch */}
            <div className="card">
              <div className='row align-items-end'>
                <div className='col-lg-8 '>
                  <div className="card-body h-5 pt-5 mt-4">
               
                    <button
                      type="button"
                      className="btn btn-sm btn-md btn_primary fs-13" onClick={(e) => setShowModal1(true)}>
                      <HiMiniPlus className="text_white" /> Create Batch
                    </button>

                  </div>
                </div>
                <div className='col-lg-4 text-end'>
                  <img
                    src={girl_img}
                    className="img-fluid mx-3"
                    width="100px"
                    height="100px"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* 4 boxes */}
            <div className="row">
              <div className="col-lg-6">
                <div className="card card_animate">
                  <div className="card-body">
                    <div className="d-flex align-items-center w-100 justify-content-between">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-start fw-medium text-mute text-truncate mt-1 fs-14">
                          Trainers
                        </p>
                        <h5 className="text-success fs-14 mb-0">
                          10
                        </h5>
                        <p className='fs-12 text-mute'>Total No. of Trainers in Teks Academy</p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="avatar-md-batch me-3">
                          <span class="avatar-title-batch batch_bg rounded-circle fs-1">
                            <MdOutlinePeopleOutline className='fw-300  batch_icon_color' />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card_animate">
                  <div className="card-body">
                    <div className="d-flex align-items-center w-100 justify-content-between">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-start fw-medium text-mute text-truncate mt-1 fs-14">
                          Batches
                        </p>
                        <h5 className="text-success fs-14 mb-0">
                          100
                        </h5>
                        <p className='fs-12 text-mute'>Total No. of Active Batches in Teks Academy</p>
                      </div>
                      <div className="flex-shrink-0">
                        <div class="avatar-md-batch me-3">
                          <span class="avatar-title-batch batch_bg rounded-circle fs-1">
                            <FaPeopleGroup className='fw-300  batch_icon_color' />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card_animate">
                  <div className="card-body">
                    <div className="d-flex align-items-center w-100 justify-content-between">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-start fw-medium text-mute text-truncate mt-1 fs-14">
                          Students
                        </p>
                        <h5 className="text-success fs-14 mb-0">
                          100
                        </h5>
                        <p className='fs-12 text-mute'>Total No. of Active Students in Teks Academy</p>
                      </div>
                      <div className="flex-shrink-0">
                        <div class="avatar-md-batch me-3">
                          <span class="avatar-title-batch batch_bg rounded-circle fs-1">
                            <BsPersonVideo2 className='fw-300  batch_icon_color' />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card_animate">
                  <div className="card-body">
                    <div className="d-flex align-items-center w-100 justify-content-between">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-start fw-medium text-mute text-truncate mt-1 fs-14">
                          Upcoming Batches
                        </p>
                        <h5 className="text-success fs-14 mb-0">
                          20
                        </h5>
                        <p className='fs-12 text-mute'>Total No. of Upcoming Batches in Teks Academy</p>
                      </div>
                      <div className="flex-shrink-0">
                        <div class="avatar-md-batch me-3">
                          <span class="avatar-title-batch batch_bg rounded-circle fs-1">
                            <FaPeopleLine className='fw-300  batch_icon_color' />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <h6 className="">Overall Batches</h6>
                <GraphOne />
              </div>
            </div>
          </div>
        </div>
        {/* new section */}
        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6 className="">Trainer Wise Count</h6>
                  <select class="form-control fs-s w-50 bg-form text_color input_bg_color select form-select"
                    aria-label="" placeholder="" id="branch" name="branch" required>
                    <option>Select Branch</option>
                    <option>Ameerpet</option>
                    <option>Madhapur</option>
                    <option>Hi-tech City</option>
                  </select>
                </div>
                <div>
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height={405}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6 className="">Batch Wise</h6>
                  <select class="form-control fs-s w-50 bg-form text_color input_bg_color select form-select"
                    aria-label="" placeholder="" id="branch" name="branch" required>
                    <option>Select Trainer</option>
                    <option>Bala Krishn</option>
                    <option>Sasi</option>
                    <option>Krishna</option>
                  </select>
                </div>
                <GraphThree />
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showModal1 === true && (
          <AddBatch show={showModal1} handleClose={handleCloseModal1} />
        )
      }
    </div>
  )
}

export default BatchManagement;