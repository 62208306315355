import { Route, Routes } from "react-router-dom";
import Dashboard from "../../componentLayer/pages/dashboard/Dashboard";
import Error from "../../componentLayer/pages/Error/Error";
import LoadingScreen from "../../componentLayer/components/loadingScreen/LoadingScreen";
import { useAuthContext } from "../../dataLayer/hooks/useAuthContext";



function DashboardRoutes() {

  const { AuthState, DispatchAuth } = useAuthContext();
  console.log(AuthState?.user?.profile, "AuthStateAuthState")
  const profile = AuthState?.user?.profile;

  
  return (
    <Routes>
      <Route path='*' element={<Error />} />

      {
        (profile !== "Human Resource" && profile !== "Placement Partner") && (
          <Route path="/" element={<Dashboard />} />
        )
      }


      <Route path="/loading" element={<LoadingScreen />} />
    </Routes>
  );
}

export default DashboardRoutes;
