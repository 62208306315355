import React, { useEffect, useMemo, useState } from 'react'
import "../../../../assets/css/Table.css"
import { useStudentsContext } from '../../../../dataLayer/hooks/useStudentsContext';
import Usedebounce from '../../../../dataLayer/hooks/useDebounce/Usedebounce';
import { useBranchContext } from '../../../../dataLayer/hooks/useBranchContext';
import { useCourseContext } from '../../../../dataLayer/hooks/useCourseContext';
import BackButton from '../../../components/backbutton/BackButton';
import { MdFilterList } from "react-icons/md";
import { toast } from 'react-toastify';
import { Offcanvas } from "bootstrap";
import Pagination from '../../../../utils/Pagination';
import PaginationInfo from '../../../../utils/PaginationInfo';
import Filter from '../../../../utils/Filter';
import { FaRotate } from 'react-icons/fa6';
import { ERPApi } from '../../../../serviceLayer/interceptor';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import GateKeeper from '../../../../rbac/GateKeeper';



function IssuedCertificates() {

    const { courseState } = useCourseContext();
    const { BranchState } = useBranchContext();
    const { studentState: { Issued_CerificateStudents }, Dispatchstudents, get_Issued_CertificateStudents } = useStudentsContext();
    const { debouncesetSearch, debouncesetPage } = Usedebounce(Dispatchstudents);
    const navigate = useNavigate();


    const courseData = useMemo(() =>
        courseState?.courses?.map((item) => ({ label: item?.course_name, value: item?.course_name })) || [],
        [courseState?.courses]
    );
    const branchData = useMemo(() =>
        BranchState?.branches?.map((item) => ({ label: item?.branch_name, value: item?.branch_name })) || [],
        [BranchState?.branches]
    );

    useEffect(() => {
        setFilterData(prevState => prevState.map(item => {
            if (item.inputname === "course") {
                return { ...item, options: courseData };
            }
            if (item.inputname === "branch") {
                return { ...item, options: branchData };
            }
            return item;
        }));
    }, [courseData, branchData]);

    const initialFilterData = [
        { label: "From Date", type: "date", inputname: "fromDate", value: "" },
        { label: "TO Date", type: "date", inputname: "toDate", value: "" },
        { label: "Course", type: "select", inputname: "course", value: "", options: [] },
        { label: "Branch", type: "select", inputname: "branch", value: "", options: [] },
        {
            label: "Student's Type", type: "select", inputname: "studentType", value: "", options: [
                { label: "Existing Students", value: "newStudents" },
                { label: "Old Students", value: "oldStudents" },
            ],
        },
        // {
        //     label: "InternShip Status", type: "select", inputname: "internShipStatus", value: "", options: [
        //         { label: "Issued", value: "InternShip Certificate Issued" },
        //         { label: "Not Issued", value: "InternShip Not Issued" },
        //         // { label: "Not At Issued", value: " " },

        //     ],
        // },
        // {
        //     label: "IEP Status", type: "select", inputname: "iepStatus", value: "", options: [
        //         { label: "Issued", value: "IEP Certificate Issued" },
        //         { label: "Not Issued", value: "IEP Not Issued" },
        //         // { label: "Not At Issued", value: " " },
        //     ],
        // },
    ];

    const [filterData, setFilterData] = useState(initialFilterData);

    const handleFilters = (index, name, value) => {
        let updatedFilterData = [...filterData]
        updatedFilterData[index].value = value;
        setFilterData(updatedFilterData)
    }

    const handleSearch = (e) => {
        debouncesetSearch({ context: "ISSUED_CERTIFICATES_STUDENTS", data: e.target.value });
    };

    const handlePerPage = (e) => {
        const selectedvalue = parseInt(e.target.value, 10);
        Dispatchstudents({
            type: "SET_PER_PAGE",
            payload: {
                context: "ISSUED_CERTIFICATES_STUDENTS",
                data: selectedvalue,
            },
        });
    };

    const FilterReset = () => {
        const resetFilterData = filterData?.map((item) => ({
            ...item,
            value: "",
        }))
        setFilterData(resetFilterData);
        Dispatchstudents({
            type: "SET_FILTERS",
            payload: {
                context: "ISSUED_CERTIFICATES_STUDENTS",
                data: {
                    fromDate: "",
                    toDate: "",
                    branch: "",
                    course: "",
                    internShipStatus: "",
                    iepStatus: "",
                    studentType: "",
                },
            },
        });
    };



    const filterSubmit = () => {
        const filter = filterData.reduce((acc, item) => {
            acc[item.inputname] = item.value;
            return acc;
        }, {});

        if (!filter.fromDate && !filter.toDate && !filter.course && !filter.branch && !filter?.internShipStatus && !filter?.iepStatus && !filter?.studentType) {
            toast.error("Please fill in at least one filter criteria.");
            return;
        }
        Dispatchstudents({
            type: "SET_FILTERS",
            payload: {
                context: "ISSUED_CERTIFICATES_STUDENTS",
                data: {
                    fromDate: filter.fromDate,
                    toDate: filter.toDate,
                    branch: filter.branch,
                    course: filter.course,
                    internShipStatus: filter?.internShipStatus,
                    iepStatus: filter?.iepStatus,
                    studentType: filter?.studentType,
                },
            },
        });
        const offcanvasElement = document.getElementById('offcanvasRight');
        const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
        offcanvasInstance.hide();
    };

    const handlePageChange = (page) => {
        debouncesetPage({ context: "ISSUED_CERTIFICATES_STUDENTS", data: page });
    };

    useEffect(() => {
        debouncesetSearch({ context: "ISSUED_CERTIFICATES_STUDENTS", data: "" });
        debouncesetPage({ context: "ISSUED_CERTIFICATES_STUDENTS", data: 1 });
        Dispatchstudents({
            type: "SET_FILTERS",
            payload: {
                context: "ISSUED_CERTIFICATES_STUDENTS",
                data: {
                    fromDate: "",
                    toDate: "",
                    branch: "",
                    course: "",
                    internShipStatus: "",
                    iepStatus: "",
                    studentType: "",
                },
            },
        });
        Dispatchstudents({
            type: "SET_PER_PAGE",
            payload: {
                context: "ISSUED_CERTIFICATES_STUDENTS",
                data: 10,
            },
        });
    }, []);

    const handleReIssueCertificate = async (e, studentid) => {
        e.preventDefault();

        const updatedData = {
            certificate_status: []
        }
        Swal.fire({
            title: "Are you sure?",
            text: "You are about to re-issue this certificate. This action cannot be undone.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Re-Issue it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data, status } = await ERPApi.put(`${process.env.REACT_APP_API_URL}/sc/certificatestatus/${studentid}`, updatedData)

                    if (status === 200) {
                        // Display success Swal alert
                        Swal.fire({
                            title: "Re-Issued!",
                            text: "Certificate re-issued successfully.",
                            icon: "success"
                        });

                        get_Issued_CertificateStudents();

                    }
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }


    return (
        <div>
            <BackButton heading="Issued Certificate" content="Back" to="/student/requestedcertificate" />
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card border-0">
                            <div class="card-header">
                                <div className="row justify-content-between">
                                    <div className="col-sm-4">
                                        <div className="search-box">
                                            <input type="text"
                                                className="form-control search"
                                                placeholder="Search for..."
                                                name="search"
                                                required
                                                onChange={handleSearch}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="d-flex justify-content-end">
                                            <div className="fs-13 me-3 mt-2">

                                            </div>

                                            <button className="btn btn_primary fs-13 me-2 " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">   <MdFilterList className="me-1 mb-1" /> Filters</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="offcanvas offcanvas-end  " id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                                    <div className="offcanvas-header">
                                        <h5 className="offcanvas-title" id="offcanvasRightLabel">Filters</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                    </div>
                                    <div className="offcanvas-body p-2">
                                        <Filter filterData={filterData} HandleFilters={handleFilters} filterReset={FilterReset} filterSubmit={filterSubmit} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-container table-scroll table-responsive table-card  border-0">
                                    <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                                        <thead>
                                            <tr className=''>
                                                <th scope="col" className="fs-13 lh-xs fw-600 black_300 ">
                                                    S.No
                                                </th>
                                                <th scope="col" className="fs-13 lh-xs black_300 fw-600  ">
                                                    Name
                                                </th>
                                                <th scope="col" className="fs-13 lh-xs black_300 fw-600  ">
                                                    Course
                                                </th>
                                                <th scope="col" className="fs-13 lh-xs black_300 fw-600  ">
                                                    Registration ID
                                                </th>
                                                <th scope="col"
                                                    className="fs-13 lh-xs  fw-600 text-truncate"
                                                    title="Course Certificate Status"
                                                    style={{ maxWidth: "120px" }}
                                                >
                                                    Course Certificate Status
                                                </th>
                                                <th scope="col"
                                                    className="fs-13 lh-xs  fw-600 text-truncate"
                                                    title="Internship Certificate Status"
                                                    style={{ maxWidth: "120px" }}
                                                >
                                                    Internship Certificate Status
                                                </th>
                                                <th scope="col"
                                                    className="fs-13 lh-xs  fw-600 text-truncate"
                                                    title="IEP Certificate Status"
                                                    style={{ maxWidth: "120px" }}
                                                >
                                                    IEP Certificate Status
                                                </th>


                                                <th scope="col" className="fs-13 lh-xs black_300 fw-600 ">
                                                    Certificate&nbsp;Status
                                                </th>

                                                <GateKeeper requiredModule="Student Management" submenumodule="Issued Certificate" submenuReqiredPermission="canUpdate">
                                                    <th scope="col" className="fs-13 lh-xs black_300 fw-600 text-truncate" title=" Certificate Re-Issue"
                                                        style={{ maxWidth: "120px" }}>

                                                        Revoke Certificate
                                                    </th>
                                                </GateKeeper>


                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {
                                                Issued_CerificateStudents.Paginated_Issued_CerificateStudents && Issued_CerificateStudents.Paginated_Issued_CerificateStudents.length > 0 ? Issued_CerificateStudents.loading ?
                                                    <tr>
                                                        <td className='fs-13 black_300  lh-xs bg_light '>
                                                            loading...
                                                        </td>
                                                    </tr>
                                                    : Issued_CerificateStudents.Paginated_Issued_CerificateStudents.map((item, index) => {

                                                        let certificateStatusObj = item.certificate_status;
                                                        if (typeof certificateStatusObj === 'string') {
                                                            certificateStatusObj = JSON?.parse(certificateStatusObj);
                                                        }
                                                        const certificateStatus = certificateStatusObj?.map((item) => item?.certificateStatus)?.join(", ");

                                                        return (
                                                            <tr>
                                                                <td className='fs-13 black_300 fw-500 lh-xs bg_light '>
                                                                    {(Issued_CerificateStudents?.currentPage - 1) *
                                                                        Issued_CerificateStudents.perPage +
                                                                        index +
                                                                        1}
                                                                </td>
                                                                <td className='fs-13 black_300  lh-xs bg_light text-truncate' style={{ maxWidth: "150px" }} title={item?.name}>
                                                                    {item?.name}
                                                                </td>
                                                                <td className='fs-13 black_300  lh-xs bg_light text-truncate' style={{ maxWidth: "150px" }} title={item?.courses}>
                                                                    {item?.courses}
                                                                </td>
                                                                <td className='fs-13 black_300  lh-xs bg_light'>
                                                                    {item?.registrationnumber}
                                                                </td>

                                                                <td className="fs-13 black_300  lh-xs bg_light" >
                                                                    {certificateStatus === "" ? "Not At Issued" : certificateStatus === "request Submitted" ? "Pending" : certificateStatus === "issued" ? "Issued" : ""}
                                                                </td>

                                                                <td className="fs-13 black_300 lh-xs bg_light text-truncate" style={{ maxWidth: "120px" }}>
                                                                    {item?.certificate_status[0]?.internShip?.internShipCertificateStatus ? item?.certificate_status[0]?.internShip?.internShipCertificateStatus === "InternShip Not Issued" ? "Not Issued" : item?.certificate_status[0]?.internShip?.internShipCertificateStatus === "InternShip Request Submitted" ? "pending" : "Issued" : "Not At Issued"}
                                                                </td>

                                                                <td className="fs-13 black_300 lh-xs bg_light text-truncate" style={{ maxWidth: "120px" }}>
                                                                    {item?.certificate_status[0]?.iep?.iepCertificateStatus ? item?.certificate_status[0]?.iep?.iepCertificateStatus === "IEP Not Issued" ? "Not Issued" : item?.certificate_status[0]?.iep?.iepCertificateStatus === "IEP Request Submitted" ? "pending" : "Issued" : "Not At Issued"}
                                                                </td>


                                                                <td className='fs-13 black_300  lh-xs  bg_light'>
                                                                    {certificateStatus ===
                                                                        "issued" && (
                                                                            <div className="text-white rounded font-size-xxs   btn_issued_certificate fw-100 text-center pt-1 pb-1 ps-1"
                                                                                type='button'
                                                                                style={{ cursor: "not-allowed" }}
                                                                                disable
                                                                            >
                                                                                Certificate Issued
                                                                            </div>
                                                                        )
                                                                    }
                                                                </td>
                                                                <GateKeeper requiredModule="Student Management" submenumodule="Issued Certificate" submenuReqiredPermission="canUpdate">
                                                                    <td className="fs-13 black_300 lh-xs bg_light text-center text-truncate" style={{ maxWidth: "70px", cursor: "pointer" }}>
                                                                        {" "}<FaRotate className="edit_icon table_icons me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Re-issue" onClick={(e) => handleReIssueCertificate(e, item?.registrationnumber)} />
                                                                    </td>
                                                                </GateKeeper>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td className='fs-13 black_300  lh-xs  bg_light'>
                                                            No data
                                                        </td>
                                                    </tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className=" mt-3 align-items-center d-flex justify-content-between row text-center text-sm-start">
                                    <div className="col-sm">
                                        <PaginationInfo
                                            data={{
                                                length: Issued_CerificateStudents?.Paginated_Issued_CerificateStudents?.length,
                                                start: Issued_CerificateStudents?.startStudent,
                                                end: Issued_CerificateStudents?.endStudent,
                                                total: Issued_CerificateStudents?.searchResultStudents,
                                            }}
                                            loading={Issued_CerificateStudents?.loading}
                                        />

                                    </div>
                                    <div className="col-sm-auto mt-3 mt-sm-0 d-flex">
                                        <div className="mt-2">
                                            <select
                                                className="form-select form-control me-3 input_bg_color pagination-select"
                                                aria-label="Default select example"
                                                placeholder="Branch*"
                                                name="branch"
                                                id="branch"
                                                required
                                                onChange={handlePerPage}
                                                value={Issued_CerificateStudents?.perPage}
                                            >
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>

                                        <div className="ram">
                                            <Pagination
                                                currentPage={Issued_CerificateStudents?.currentPage}
                                                totalPages={Issued_CerificateStudents?.totalPages}
                                                loading={Issued_CerificateStudents?.loading}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default IssuedCertificates;



