import React, { useEffect, useState } from "react";
import "../../../assets/css/Forms.css";
import { useBranchContext } from "../../../dataLayer/hooks/useBranchContext";
import { useDepartmentContext } from "../../../dataLayer/hooks/useDepartmentContext";
import { useRoleContext } from "../../../dataLayer/hooks/useRoleContext";
import { toast } from "react-toastify";
import axios from "axios";
import Button from "../../components/button/Button";
import { useUserContext } from "../../../dataLayer/hooks/useUserContext";
import { useNavigate, useParams } from "react-router";
import BackButton from "../../components/backbutton/BackButton";
import Select from 'react-select';
import { useCourseContext } from "../../../dataLayer/hooks/useCourseContext";
import { ERPApi } from "../../../serviceLayer/interceptor";


function CreateUserForm() {
  const { BranchState } = useBranchContext();
  const { DepartmentState } = useDepartmentContext();
  const { UsersState: { TotalUsersWithOutCountellers } } = useUserContext();
  const { RoleState } = useRoleContext();
  const { courseState } = useCourseContext();
  const navigate = useNavigate();
  const { id } = useParams();


  const coursesList = courseState?.courses?.map((course, index) => ({
    label: course.course_name, value: course.id
  }))

  const [selectedCourses, setSelectedCourses] = useState([]);
  const handleCourseAdd = (value) => {
    setSelectedCourses(value);
    setError((prev) => ({
      ...prev,
      courses: "",
    }));
  }


  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/user/viewuser/${id}`)
        .then((response) => {
          setFormData(response?.data?.user);
          console.log(response?.data?.user, "userresponceid");

          setSelectedCourses(response.data.user.courses.map(item => ({
            label: item?.course_name,
            value: item?.id
          })));
        })

        .catch((error) => {
          console.error("Error fetching course details:", error);
        });
    }
  }, [id]);


  const [formData, setFormData] = useState({
    branch: "",
    fullname: "",
    email: "",
    phonenumber: "",
    designation: "",
    department: "",
    reportto: "",
    branch: "",
    profile: "",
    aboutuser: "",
    reporttoid: "",
    slots: "",
  });

  console.log(formData, "formDataformData");

  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name === "phonenumber" && value.length > 10) {
      return;
    }
    if (name === "profile") {
      setSelectedCourses([])
      setFormData((prev) => ({
        ...prev,
        slots: "",
      }));
    }
    setFormData((prev) => {
      return {
        ...prev,
        //  [name]: value
        [name]: name === "slots" ? Number(value) : value,
      };
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.fullname || formData.fullname.trim() === "") {
      setError((prev) => ({
        ...prev,
        fullname: "Full name required",
      }));
      return;
    } else if (formData.fullname.length <= 2) {
      setError((prev) => ({
        ...prev,
        fullname: "Enter minimum 3 characters",
      }));
      return;
    }

    if (!formData.email || formData.email.trim() === "") {
      setError((prev) => ({
        ...prev,
        email: "Email required",
      }));
      return;
    } else if (formData.email) {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!emailPattern.test(formData.email)) {
        setError((prev) => ({
          ...prev,
          email: "Enter valid email",
        }));
        return;
      }
    }

    if (!formData.phonenumber || formData.phonenumber.trim() === "") {
      setError((prev) => ({
        ...prev,
        phonenumber: "Phone number required",
      }));
      return;
    } else if (formData.phonenumber.length !== 10) {
      setError((prev) => ({
        ...prev,
        phonenumber: "Incorrect mobile number",
      }));
      return;
    }

    if (!formData.designation || formData.designation.trim() === "") {
      setError((prev) => ({
        ...prev,
        designation: "Designation is required",
      }));
      return;
    } else if (formData.designation.length <= 2) {
      setError((prev) => ({
        ...prev,
        designation: "Enter minimum 3 characters",
      }));
      return;
    }

    if (!formData.department || formData.department.trim() === "") {
      setError((prev) => ({
        ...prev,
        department: "Department is required",
      }));
      return;
    }

    if (!formData.reporttoid) {
      setError((prev) => ({
        ...prev,
        reporttoid: "Report to is required",
      }));
      return;
    }

    if (!formData.profile || formData.profile.trim() === "") {
      setError((prev) => ({
        ...prev,
        profile: "Role is required",
      }));
      return;
    }
    if (!formData.branch || formData.branch.trim() === "") {
      setError((prev) => ({
        ...prev,
        branch: "Branch is required",
      }));
      return;
    }

    if (!formData.profile || formData.profile === "Trainer") {
      if (!selectedCourses || selectedCourses.length <= 0) {
        console.log(selectedCourses, "selectedCoursesdffdf")
        setError((prev) => ({
          ...prev,
          courses: "Courses is required",
        }));
        return;
      }
    }

    if (!formData.profile || formData.profile === "Trainer") {
      if (!formData.slots) {
        setError((prev) => ({
          ...prev,
          slots: "Slots is required",
        }));
        return;
      }
    }



    let user = {
      branch: formData.branch,
      fullname: formData.fullname,
      email: formData.email,
      phonenumber: formData.phonenumber,
      designation: formData.designation,
      department: formData.department,
      reportto: "",
      profile: formData.profile,
      user_remarks_history: [],
      aboutuser: formData?.aboutuser,
      reporttoid: formData?.reporttoid,
      courses: selectedCourses ? selectedCourses?.map((item) => item.value) : [],
      slots: formData?.slots ? formData?.slots : 0,
    };


    user = [user];
    const dataWithTitleCase = user.map((item) => {
      const newItem = {};

      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          if (typeof item[key] === "string" && key !== "email") {
            newItem[key] = item[key]
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");
          } else {
            newItem[key] = item[key];
          }
        }
      }

      return newItem;
    });
    user = dataWithTitleCase[0];
    console.log(user, "checkfromdata");

    if (!id) {
      try {
        const { data, status } = await toast.promise(ERPApi.post(`/user/create-user`, user),
          {
            loading: "Loading...",
            pending: "Verifying data...",
            success: {
              render({
                data: {
                  data: { user },
                },
              }) {
                return `User Created Successfully`;
              },
            },
            error: {
              render({ data: error }) {
                if (error?.response?.status === 409) {
                  return error?.response?.data?.message;
                } else {
                  return "User Created Failed. Please try again.";
                }
              },
            },
          }
        );

        if (status === 200) {
          navigate("/user/list");
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (id) {
      try {
        const { data, status } = await toast.promise(ERPApi.put(`/user/updateuser/${id}`, user),
          {
            loading: "Loading...",
            pending: 'verifying data',
            success: {
              render({
                data: {
                  data: { user },
                },
              }) {
                return `User Upated Successfully`;
              },
            },
            error: 'User Upated Failed',
          }
        );

        if (status === 200) {
          navigate(`/user/list`);
        }
      } catch (error) {
        console.error(error)
      }
    }
  };



  return (
    <div>
      {id && id ? <BackButton heading="Edit User Details" content="Back" /> : <BackButton heading="User Form" content="Back" />}
      <div className="container-fluid">
        <div className="card border-0">
          <div className="align-items-center"></div>
          <div className="card-body">
            <div className="live-prieview">
              <form>
                <div className="row d-flex">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        for="firstNameinput"
                        className="form-label fs-s fw-medium black_300"
                      >
                        Full Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={
                          error && error.fullname
                            ? "form-control fs-s bg-form text_color input_bg_color error-input "
                            : "form-control fs-s bg-form text_color input_bg_color text-capitalize"
                        }
                        placeholder="Enter Full Name"
                        id="firstNameinput"
                        name="fullname"
                        value={formData.fullname}
                        onChange={handleChange}
                      />

                      <div style={{ height: "8px" }}>
                        {error && error.fullname && (
                          <p className="text-danger m-0 fs-xs">
                            {error.fullname}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        for="email"
                        className="form-label fs-s fw-medium black_300"
                      >
                        Email Id<span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className={
                          error && error.email
                            ? "form-control fs-s bg-form text_color input_bg_color error-input"
                            : "form-control fs-s bg-form text_color input_bg_color"
                        }
                        placeholder="Enter Email Id"
                        id="firstNameinput"
                        name="email"
                        value={formData?.email}
                        readOnly={!!id}
                        // disabled={formData.email}
                        onChange={handleChange}
                      />
                      <div style={{ height: "8px" }}>
                        {error && error.email && (
                          <p className="text-danger m-0 fs-xs">{error.email}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        for="lastNameinput"
                        className="form-label fs-s fw-medium black_300"
                      >
                        Phone Number<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        maxLength="10"
                        className={
                          error && error.phonenumber
                            ? "form-control fs-s bg-form text_color input_bg_color error-input"
                            : "form-control fs-s bg-form text_color input_bg_color"
                        }
                        placeholder="Enter Phone Number"
                        id="firstNameinput"
                        name="phonenumber"
                        pattern="[0-9]{10}" // Added inputMode attribute here to restrict input to numeric characters

                        value={formData.phonenumber}
                        onChange={handleChange}

                      />
                      <div style={{ height: "8px" }}>
                        {error && error.phonenumber && (
                          <p className="text-danger m-0 fs-xs">
                            {error.phonenumber}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        for="lastNameinput"
                        className="form-label fs-s fw-medium black_300"
                      >
                        Designation<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={
                          error && error.designation
                            ? "form-control fs-s bg-form text_color input_bg_color error-input"
                            : "form-control fs-s bg-form text_color input_bg_color text-capitalize"
                        }
                        placeholder="Enter Designation"
                        id="designationNameinput"
                        name="designation"
                        value={formData.designation}
                        onChange={handleChange}
                      />
                      <div style={{ height: "8px" }}>
                        {error && error.designation && (
                          <p className="text-danger m-0 fs-xs">
                            {error.designation}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className=" col-md-4 ">
                    <label className="form-label fs-s fw-medium black_300">
                      Department<span className="text-danger">*</span>
                    </label>
                    <select
                      className={
                        error && error.department
                          ? "form-control fs-s bg-form text_color input_bg_color error-input "
                          : "form-control fs-s bg-form text_color input_bg_color select form-select text-capitalize"
                      }
                      aria-label=""
                      placeholder=""
                      name="department"
                      id="department"
                      value={formData.department}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled selected className="">
                        {" "}
                        Select the Department{" "}
                      </option>
                      {DepartmentState.departments &&
                        DepartmentState.departments.length > 0 &&
                        DepartmentState.departments.map((item, index) => (
                          <option key={index}>{item.department_name}</option>
                        ))}
                    </select>
                    <div style={{ height: "8px" }}>
                      {error && error.department && (
                        <p className="text-danger m-0 fs-xs">
                          {error.department}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" col-md-4 ">
                    <label className="form-label fs-s fw-medium black_300">
                      Report To<span className="text-danger">*</span>
                    </label>
                    <select
                      className={
                        error && error.reporttoid
                          ? "form-control fs-s bg-form text_color input_bg_color error-input "
                          : "form-control fs-s bg-form text_color input_bg_color select form-select text-capitalize"
                      }
                      aria-label="Default select example"
                      placeholder="Report To*"
                      name="reporttoid"
                      id="reporttoid"
                      value={formData.reporttoid}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select the Report To{" "}
                      </option>

                      {TotalUsersWithOutCountellers &&
                        TotalUsersWithOutCountellers.length > 0
                        ? TotalUsersWithOutCountellers.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item?.fullname}
                          </option>
                        ))
                        : null}
                    </select>

                    <div style={{ height: "8px" }}>
                      {error && error.reporttoid && (
                        <p className="text-danger m-0 fs-xs">
                          {error.reporttoid}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" col-md-4 ">
                    <label className="form-label fs-s fw-medium black_300">
                      Role<span className="text-danger">*</span>
                    </label>
                    <select
                      className={
                        error && error.profile
                          ? "form-control fs-s bg-form text_color input_bg_color error-input"
                          : "form-control fs-s bg-form text_color input_bg_color select form-select text-capitalize"
                      }
                      aria-label="Default select example"
                      placeholder="profile*"
                      name="profile"
                      id="profile"
                      value={formData.profile}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled selected>
                        {" "}
                        Select the Role{" "}
                      </option>
                      {
                        RoleState?.RolesData?.AllRoles && RoleState?.RolesData?.AllRoles.length > 0 &&
                        RoleState?.RolesData?.AllRoles.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          )
                        })
                      }
                    </select>
                    <div style={{ height: "8px" }}>
                      {error && error.profile && (
                        <p className="text-danger m-0 fs-xs">{error.profile}</p>
                      )}
                    </div>
                  </div>
                  <div className=" col-md-4 ">
                    <label className="form-label fs-s fw-medium black_300">
                      Branch<span className="text-danger">*</span>
                    </label>
                    <select
                      className={
                        error && error.branch
                          ? "form-control fs-s bg-form text_color input_bg_color error-input"
                          : "form-control fs-s bg-form text_color input_bg_color  select form-select "
                      }
                      aria-label="Default select example"
                      // placeholder="branch*"
                      // style={{ color: "black" }}
                      name="branch"
                      id="branch"
                      value={formData.branch}
                      onChange={handleChange}
                      required
                    >
                      <option
                        value=""
                        style={{ opacity: "0.5" }}
                        disabled
                        selected
                      >
                        {" "}
                        Select the Branch{" "}
                      </option>
                      {BranchState.branches &&
                        BranchState.branches.length > 0 &&
                        BranchState.branches.map((item) => (
                          <option >{item.branch_name}</option>
                        ))}
                    </select>
                    <div style={{ height: "8px" }}>
                      {error && error.branch && (
                        <p className="text-danger m-0 fs-xs">{error.branch}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        for="firstNameinput"
                        className="form-label fs-s fw-medium black_300"
                      >
                        About<span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        className={"form-control fs-s bg-form text_color input_bg_color text-capitalize"}
                        placeholder="Enter About the User"
                        id="firstNameinput"
                        name="aboutuser"
                        value={formData.aboutuser}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {
                    formData.profile === "Trainer" &&
                    <div className=" col-md-4 ">
                      <label className="form-label fs-s fw-medium black_300">
                        Courses<span className="text-danger">*</span>
                      </label>

                      <Select
                        className={` fs-s bg-form text_color input_bg_color ${error && error.courses ? 'error-input border border-red-500' : ''
                          }`}
                        isMulti
                        options={coursesList}
                        classNamePrefix="select"
                        value={selectedCourses}
                        onChange={(selectedOption) => {
                          handleCourseAdd(selectedOption);
                        }}
                      />

                      <div style={{ height: "8px" }}>
                        {error && error?.courses && (
                          <p className="text-danger m-0 fs-xs">{error.courses}</p>
                        )}
                      </div>
                    </div>
                  }


                  {
                    formData.profile === "Trainer" &&
                    <div className=" col-md-4 ">
                      <label className="form-label fs-s fw-medium black_300">
                        Slots<span className="text-danger">*</span>
                      </label>
                      <select
                        className={
                          error && error.slots
                            ? "form-control fs-s bg-form text_color input_bg_color error-input"
                            : "form-control fs-s bg-form text_color input_bg_color  select form-select "
                        }
                        aria-label="Default select example"
                        // placeholder="slots*"
                        // style={{ color: "black" }}
                        name="slots"
                        id="slots"
                        value={formData?.slots}
                        onChange={handleChange}
                        required
                      >
                        <option value="" style={{ opacity: "0.5" }} disabled selected >
                          {" "}
                          Select the Slots{" "}
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>

                      </select>
                      <div style={{ height: "8px" }}>
                        {error && error.slots && (
                          <p className="text-danger m-0 fs-xs">{error.slots}</p>
                        )}
                      </div>
                    </div>
                  }




                </div>
                <div className=" ">
                  <div className="d-flex justify-content-end mt-3">
                    {id && id ? <Button className={"btn_primary"} onClick={handleSubmit} >Update </Button> :
                      <Button className={"btn_primary"} onClick={handleSubmit} >Submit </Button>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateUserForm;
