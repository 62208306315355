import React, { useState } from 'react'
import BackButton from '../../../components/backbutton/BackButton';
import { MdFilterList } from "react-icons/md";
import ApplicantsProvider from './ApplicantsProvider';
import Usedebounce from '../../../../dataLayer/hooks/useDebounce/Usedebounce';
import { ERPApi } from '../../../../serviceLayer/interceptor';
import { toast } from 'react-toastify';
import GateKeeper from '../../../../rbac/GateKeeper';
import Swal from 'sweetalert2';
import Pagination from '../../../../utils/Pagination';
import PaginationInfo from '../../../../utils/PaginationInfo';


function Applicants() {


  const { ApplicantsState: { Applicants }, DispatchApplicants } = ApplicantsProvider();


  const { debouncesetSearch, debouncesetPage } = Usedebounce(DispatchApplicants);

  const handleSearch = (e) => {
    debouncesetSearch({ data: e.target.value });
  };

  const handlePerPage = (e) => {
    const selectedvalue = parseInt(e.target.value, 10);
    DispatchApplicants({
      type: "SET_PER_PAGE",
      payload: {
        data: selectedvalue,
      },
    });
  };

  // filters
  const [filters, setFilters] = useState({
    status: "",
  });



  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }))
  };

  const handleReset = () => {
    DispatchApplicants({
      type: "SET_FILTERS",
      payload: {
        data: {
          status: ""
        },
      },
    });
    setFilters({
      status: ""
    })
  };

  const handleSubmit = () => {
    DispatchApplicants({
      type: "SET_FILTERS",
      payload: {
        data: {
          status: filters.status,
        },
      },
    });
  }

  // Paginations
  const handlePageChange = (page) => {
    debouncesetPage({ data: page });
  }


  // Applicant Status
  // const [ApplicantStatus, setApplicantStatus] = useState({
  //   status: "",
  //   remarks: "",
  // });

  const [selectedApplicant, setSelectedApplicant] = useState({ id: null, status: "", remarks: "" });

  const handleApplicantStatus = (e) => {
    const { name, value } = e.target;
    setSelectedApplicant((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleStatusUpdate = (item) => {
    setSelectedApplicant({
      id: item.id,
      status: item.status,
      remarks: item.remarks || ""
    });
  };


  const handleSubmitStatus = async () => {

    const id = selectedApplicant.id;
    const updatedStatus = {
      status: selectedApplicant?.status,
      // remarks: selectedApplicant?.remarks
    }
    const updatedDataState = {
      status: selectedApplicant.status,
      ApplicantId: selectedApplicant.id,
      remarks: selectedApplicant.remarks
    }
    try {
      const { data, status } = await toast.promise(ERPApi.patch(`${process.env.REACT_APP_API_URL}/jobs/application/${id}`, updatedStatus),
        {
          loading: "Loading...",
          success: "Applicant Status Updated Successfully",
          error: {},
        }
      );
      if (status === 200) {
        DispatchApplicants({
          type: "UPDATE_STATUS_OF_APPLICANT",
          payload: updatedDataState,
        });
      }
      console.log(data, status, "showtheupdatedStatus")
    }
    catch (error) {
      console.log(error, "showthestatuseerro")
      if (error?.response?.status >= 500 || error?.response?.status <= 600) {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  const downloadCV = (fileUrl) => {
    const baseURL = "https://teksacademy.s3.ap-south-1.amazonaws.com/HRM/Applicants_CV/";
    if (fileUrl) {
      const fullURL = baseURL + fileUrl; // Concatenate the base URL with the fileUrl
      console.log(fullURL, "fullURL");
      const link = document.createElement("a");
      link.href = fullURL;
      link.download = fileUrl; // Use the fileUrl as the filename
      link.target = '_blank'; // Open the download in a new tab
      link.style.display = 'none'; // Hide the link element
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("CV is not Available");
    }
  };


  // Delete the Job Position
  const handleDeleteApplicant = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this Applicant",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        let ApplicantID = { id: id };
        try {
          const { data, status } = await ERPApi.delete(`${process.env.REACT_APP_API_URL}/jobs/application/${id}`, id)
          console.log(data, status, "seethdsvf");
          if (status === 200) {
            console.log(data, "hellobb");
            DispatchApplicants({
              type: "DELETE_APPLICANT",
              payload: ApplicantID,
            });

            Swal.fire({
              title: "Deleted!",
              text: "Applicant deleted Successfully.",
              icon: "success"
            });
          }
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 500) {
            toast.error(error?.response?.message);
          }
        }
      }
    });
  };



  return (
    <div>
      <BackButton heading="Applicants" content="Back" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card border-0">
              <div className="card-header">
                <div className="row justify-content-between">
                  <div className="col-sm-4">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search input_bg_color select"
                        placeholder="Search for..."
                        name="search"
                        required
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>

                  </div>
                  <div className="col-sm-6">
                    <div className="buttons_alignment">
                      <div className="fs-13 me-3 mt-2">
                      </div>
                      <button
                        className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        <MdFilterList className="me-1 mb-1 text_white" />
                        Filters
                      </button>

                    </div>
                  </div>
                </div>
                <div
                  className="offcanvas offcanvas-end bg_light"
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                >
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                      Filters
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="offcanvas-body p-2">
                    {/* Position name */}
                    {/* <div className="">
                      <label className="form-label fs-s fw-medium text_color">
                        Position
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder="Select the Position"
                        name="positionName"
                        id="positionName"
                        required
                        onChange={(e) => handleFilterChange(e)}
                        value={filters.positionName}
                      >
                        <option value="" disabled selected className="">Select the Position</option>
                        <option value="front-end-Developer">front-end-Developer</option>
                        <option value="Software Developer">Software Developer</option>
                        <option value="React Developer">React Developer</option>

                      </select>
                    </div> */}
                    {/* Status of the Applicant */}
                    <div className="mt-2">
                      <label className="form-label fs-s fw-medium black_300">
                        Status
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder="Select the Status"
                        name="status"
                        id="status"
                        required
                        onChange={(e) => handleFilterChange(e)}
                        value={filters?.status}
                      >
                        <option value="" disabled>Select the Status</option>
                        <option value="Screening">Screening</option>
                        <option value="Shortlisted">Shortlisted</option>
                        <option value="On-hold">On-hold</option>
                        <option value="Reject">Reject</option>
                        <option value="On-boarded">On-boarded</option>
                      </select>
                    </div>

                    {/* Location */}

                    {/* <div className="mt-2">
                      <label className="form-label fs-s fw-medium text_color">Location</label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        id="location"
                        name="location"
                        placeholder="Select the Location"
                        required
                        onChange={(e) => handleFilterChange(e)}
                        value={filters?.location}
                      >
                        <option value="" disabled selected>Select the location</option>
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Bengulore">Bengulore</option>
                        <option value="Pune">Pune</option>
                        <option value="Kolkata">Kolkata</option>
                      </select>
                    </div> */}

                    {/* <div className='mt-2'>
                      <label className="form-label fs-s fw-medium text_color">
                        From Date
                      </label>
                      <input
                        className="  form-control input_bg_color  date_input_color "
                        id="rdob"
                        name="fromDate"
                        type="date"

                      />
                    </div>

                    <div className="mt-2">
                      <label className="form-label fs-s fw-medium text_color">
                        To Date
                      </label>
                      <input

                        className=" form-control input_bg_color  date_input_color "
                        id="rdob"
                        name="toDate"
                        type="date"

                      />
                    </div>

                    <div className="mt-2">
                      <label
                        for="firstNameinput"
                        className="form-label fs-s fw-medium black_300"
                      >Batch Timing
                      </label>
                      <input
                        type="time"
                        className="form-control fs-s bg-form text_color input_bg_color"
                        id=""
                        name=""
                      />
                    </div> */}


                    <div className="position-absolute bottom-0 start-0 ms-2 mb-2">
                      <button className="btn btn_primary" type='button' data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => handleReset()}>
                        Clear
                      </button>
                    </div>
                    <div className="position-absolute bottom-0 end-0 me-2 mb-2">
                      <button className="btn btn_primary" type='button' data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => handleSubmit()} >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-card table-container table-scroll border-0">
                  <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                    <thead>
                      <tr className="">

                        <th
                          scope="col"
                          className="fs-13 lh-xs fw-600 "
                        >
                          S.No
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Email Id
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Contact No.
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Job Title
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Company Name
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600 "  >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">

                      {Applicants?.PaginatedApplicants && Applicants?.PaginatedApplicants.length > 0 ? (
                        Applicants?.loading ? (
                          <tr>
                            <td className="fs-13 black_300  lh-xs bg_light">loading...</td>
                          </tr>
                        ) : (
                          Applicants?.PaginatedApplicants.map((item, index) => {
                            const date = new Date(item?.applied_date);
                            const formattedDate = date?.toISOString()?.split("T")[0];

                            const applicantId = item?.id;

                            console.log(item, "itemdatahere");

                            return (
                              <tr key={applicantId}>
                                <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                  {(Applicants?.currentPage - 1) * Applicants.perPage + index + 1}
                                </td>
                                <td className="fs-13 black_300  lh-xs bg_light">{item?.applicant_name}</td>
                                <td className="fs-13 black_300  lh-xs bg_light">{item?.applicant_email}</td>
                                <td className="fs-13 black_300  lh-xs bg_light">{item.applicant_phone}</td>
                                <td className="fs-13 black_300  lh-xs bg_light "> {item?.job_posting?.title}</td>
                                <td className="fs-13 black_300  lh-xs bg_light ">{item?.job_posting?.company_name}</td>
                                <td className="fs-13 black_300  lh-xs bg_light ">{item?.status}</td>
                                <td className="fs-13 black_300  lh-xs bg_light cursor-pointer">

                                  <GateKeeper requiredModule="HR Management" requiredPermission="all" submenumodule="Applicants" submenuReqiredPermission="canRead">
                                    <button className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white"
                                      onClick={() => downloadCV(item?.resume)}
                                    >
                                      Download CV
                                    </button>
                                  </GateKeeper>

                                  <GateKeeper requiredModule="HR Management" requiredPermission="all" submenumodule="Applicants" submenuReqiredPermission="canUpdate">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      data-bs-whatever="@mdo"
                                      onClick={() => handleStatusUpdate(item)}
                                    >
                                      Status
                                    </button>
                                  </GateKeeper>

                                  <GateKeeper requiredModule="HR Management" requiredPermission="all" submenumodule="Applicants" submenuReqiredPermission="canDelete">
                                    <button className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white"
                                      onClick={() => handleDeleteApplicant(applicantId)}
                                    >
                                      Remove
                                    </button>
                                  </GateKeeper>

                                </td>
                              </tr>
                            );
                          })
                        )
                      ) : (
                        <tr>
                          <td className="fs-13 black_300  lh-xs bg_light">no data</td>
                        </tr>
                      )}



                      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1 className="modal-title fs-5" id="exampleModalLabel">Status Update</h1>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              <form>
                                <div className="mb-3">
                                  <label htmlFor="status" className="col-form-label">Status Update</label>
                                  <select
                                    className="form-control fs-s bg-form text_color input_bg_color select form-select text-capitalize"
                                    name="status"
                                    id="status"
                                    onChange={handleApplicantStatus}
                                    value={selectedApplicant.status}
                                    required
                                  >
                                    <option value="" disabled>Select the Status</option>
                                    <option value="Screening">Screening</option>
                                    <option value="Shortlisted">Shortlisted</option>
                                    <option value="On-hold">On-hold</option>
                                    <option value="Reject">Reject</option>
                                    <option value="On-boarded">On-boarded</option>
                                  </select>
                                </div>

                                {/* <div className="mb-3">
                                  <label htmlFor="remarks" className="col-form-label">Remarks:</label>
                                  <textarea
                                    className="form-control"
                                    id="remarks"
                                    name="remarks"
                                    required
                                    value={selectedApplicant.remarks}
                                    onChange={handleApplicantStatus}
                                  ></textarea>
                                </div> */}
                              </form>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleSubmitStatus}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>


                      {/* 1st row */}
                    </tbody>
                  </table>
                </div>
                <div className=" mt-3 align-items-center d-flex justify-content-between row text-center text-sm-start">
                  <div className="col-sm">
                    <PaginationInfo
                      data={{
                        length: Applicants?.PaginatedApplicants?.length,
                        start: Applicants?.startApplicant,
                        end: Applicants?.endApplicant,
                        total: Applicants?.searchResultApplicants,
                      }}
                      loading={Applicants?.loading}
                    />
                  </div>
                  <div className="col-sm-auto mt-3 mt-sm-0 d-flex pagination-res">
                    <div className="mt-2">
                      <select

                        className="form-select form-control me-3 input_bg_color pagination-select "
                        aria-label="Default select example"
                        required
                        onChange={(e) => handlePerPage(e)}
                        value={Applicants?.perPage}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="">
                      <Pagination
                        currentPage={Applicants?.currentPage}
                        totalPages={Applicants?.totalPages}
                        loading={Applicants?.loading}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
export default Applicants;

