import { useEffect, useReducer } from "react";
import { ERPApi } from "../../../../serviceLayer/interceptor";





const ApplicantsProvider = () => {

    const intialState = {
        Applicants: {
            PaginatedApplicants: [],
            filters: {
                status: "",
            },
            searchResultApplicants: null,
            perPage: 10,
            totalApplicants: null,
            totalPages: null,
            loading: false,
            startApplicant: null,
            endApplicant: null,
            search: "",
            currentPage: 1,
        },
    }

    const ApplicantsReducer = (state, action) => {
        // console.log(action.type, action.payload, "ApplicantsReducer")
        switch (action.type) {
            case "SET_PAGINATED_APPLICANTS":
                const reducerData = action?.payload;
                console.log(reducerData, "ApplicantsReducer23sdsdsd")

                return {
                    ...state,
                    Applicants: {
                        ...state.Applicants,
                        PaginatedApplicants: reducerData?.applications,
                        totalPages: reducerData?.totalPages,
                        searchResultApplicants: reducerData?.searchResultApplications,
                        perPage: reducerData?.pageSize,
                        startApplicant: reducerData?.startApplication,
                        endApplicant: reducerData?.endApplication,
                        totalApplicants: reducerData?.totalApplications,
                        currentPage: reducerData?.currentPage,
                    }
                }
            case "SET_LOADING":
                return {
                    ...state,
                    Applicants: {
                        ...state.Applicants,
                        loading: !state.Applicants.loading,
                    }
                }

            case "SET_SEARCH":
                return {
                    ...state,
                    Applicants: {
                        ...state.Applicants,
                        search: action?.payload?.data,
                        perPage: 10,
                        currentPage: 1,
                    }
                }


            case "SET_PER_PAGE":
                return {
                    ...state,
                    Applicants: {
                        ...state.Applicants,
                        perPage: action?.payload?.data,
                        currentPage: 1,
                    }
                }

            case "SET_CUSTOM_PAGE":
                return {
                    ...state,
                    Applicants: {
                        ...state.Applicants,
                        currentPage: action?.payload?.data,
                    }
                }

            case "SET_FILTERS":
                console.log(action.payload.data, "kfdkhgfjfgsjgf")
                return {
                    ...state,
                    Applicants: {
                        ...state.Applicants,
                        filters: action?.payload?.data,
                        perPage: 10,
                        currentPage: 1,
                    }
                }


            case "UPDATE_STATUS_OF_APPLICANT":
                const statusData = action?.payload;
                const ApplicantID = action?.payload?.ApplicantId;

                console.log(statusData, "ApplicantIDApplicantID")

                return {
                    ...state,
                    Applicants: {
                        ...state.Applicants,
                        PaginatedApplicants: state?.Applicants?.PaginatedApplicants?.map(applicant =>
                            applicant.id === ApplicantID
                                ? {
                                    ...applicant,
                                    status: statusData.status
                                }
                                : applicant
                        )
                    }
                };


            case "DELETE_APPLICANT":
                let id = action?.payload?.id;
                id = parseInt(id);
                return {
                    ...state,
                    Applicants: {
                        ...state.Applicants,
                        PaginatedApplicants: state.Applicants.PaginatedApplicants.filter((applicant) => applicant.id !== id)
                    }
                }

            default:
                return state;

        }
    }

    const [ApplicantsState, DispatchApplicants] = useReducer(ApplicantsReducer, intialState);
    console.log(ApplicantsState, "ApplicantsState")


    const getPaginatedApplicants = async () => {
        const { search, currentPage, perPage, filters } = ApplicantsState.Applicants;
        DispatchApplicants({ type: "SET_LOADING" });
        try {
            // /applications
            // const { status, data } = await ERPApi.get(`${process.env.REACT_APP_API_URL}/student/list_students?page=${currentPage}&pageSize=${perPage}&search=${search}`);
            const { status, data } = await ERPApi.get(`${process.env.REACT_APP_API_URL}/jobs/applications?page=${currentPage}&pageSize=${perPage}&search=${search}&filter[status]=${filters.status}`);
            console.log(status, data, "checkingthedata")
            if (status === 200) {
                DispatchApplicants({ type: "SET_PAGINATED_APPLICANTS", payload: data });
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            DispatchApplicants({ type: "SET_LOADING" });
        }
    };

    useEffect(() => {
        getPaginatedApplicants();
    }, []);

    useEffect(() => {
        getPaginatedApplicants();
    }, [ApplicantsState?.Applicants?.search,
    ApplicantsState?.Applicants?.currentPage,
    ApplicantsState?.Applicants?.perPage,
    ApplicantsState?.Applicants?.filters
    ])


    return {
        ApplicantsState,
        DispatchApplicants,
    }



}
export default ApplicantsProvider;