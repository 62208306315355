//websiteleadsconfi

export const intialState ={

    Webinar:{
        paginatedWebinar:[],
        filter:{
            fromDate:"",
            toDate:"",
            course:"",
        },
        searchResultWebinar:null,
        pageSize:10,
        totalWebinarCount:null,
        totalPages:null,
        loading:false,
        startWebinar:null,
        endWebinar:null,
        search:"",
        page:1,
    },

    // whatsapp
    whatsApp:{
        paginatedwhatsApp:[],
        filter:{
            fromDate:"",
            toDate:"",
            course:"",
        },
        searchResultwhatsApp:null,
        pageSize:10,
        totalwhatsAppCount:null,
        totalPages:null,
        loading:false,
        startwhatsApp:null,
        endwhatsApp:null,
        search:"",
        page:1,
    },

    DownloadSyllabus:{
        paginatedDownloadSyllabus:[],
        filter:{
            fromDate:"",
            toDate:"",
            // course:"",
        },
        searchResultDownloadSyllabus:null,
        pageSize:10,
        totalDownloadSyllabusCount:null,
        totalPages:null,
        loading:false,
        startDownloadSyllabus:null,
        endDownloadSyllabus:null,
        search:"",
        page:1,
    },
    //viewcourse
    ViewCourse:{
        paginatedViewCourse:[],
        filter:{
            fromDate:"",
            toDate:"",
            // course:"",
        },
        searchResultViewCourse:null,
        pageSize:10,
        totalViewCourseCount:null,
        totalPages:null,
        loading:false,
        startViewCourse:null,
        endViewCourse:null,
        search:"",
        page:1,
    },
//contact us 
ContactUs:{
    paginatedContactUs:[],
    filter:{
        fromDate:"",
        toDate:"",
        // course:"",
    },
    searchResultContactUs:null,
    pageSize:10,
    totalContactUsCount:null,
    totalPages:null,
    loading:false,
    startContactUs:null,
    endContactUs:null,
    search:"",
    page:1,
},
//HLP
HLPEnquireLeads:{
    paginatedHLPEnquireLeads:[],
    filter:{
        fromDate:"",
        toDate:"",
        course:"",
    },
    searchResultHLPEnquireLeads:null,
    pageSize:10,
    totalHLPEnquireLeadsCount:null,
    totalPages:null,
    loading:false,
    startHLPEnquireLeads:null,
    endHLPEnquireLeads:null,
    search:"",
    page:1,
},
//SLP
SLPEnquireLeads:{
    paginatedSLPEnquireLeads:[],
    filter:{
        fromDate:"",
        toDate:"",
        course:"",
    },
    searchResultSLPEnquireLeads:null,
    pageSize:10,
    totalSLPEnquireLeadsCount:null,
    totalPages:null,
    loading:false,
    startSLPEnquireLeads:null,
    endSLPEnquireLeads:null,
    search:"",
    page:1,
},
}