import React, { useEffect, useState } from 'react'
import GateKeeper from '../../../../rbac/GateKeeper';
// import BackButton from "../../../components/backbutton/BackButton";
import { BsPersonCheck } from "react-icons/bs";
import { PiStudent } from "react-icons/pi";
import BackButton from '../../../components/backbutton/BackButton';
import { ERPApi } from '../../../../serviceLayer/interceptor';
import CountUp from '../../../../utils/CountUp';



function HRdashboard() {
  const [dashboardState, setDashboardState] = useState(null);
  console.log(dashboardState, "dashboardState")
  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const { data, status } = await ERPApi.get(`${process.env.REACT_APP_API_URL}/jobs/dashboard/jobpostingandapplications`);
        console.log(data, status, "dashboardhrmanagement")
        if (status === 200) {
          setDashboardState(data);
        }
      } catch (error) {
        console.error('Error fetching job:', error);
      }
    };
    fetchDashboard();
  }, []);

  return (

    <div>

      <div className="card-body">
        <ul
          className="row nav nav-justified nav-fill"
          id="pills-tab"
          role="tablist"
        >
          <GateKeeper
            requiredModule="HR Management"
            submenumodule="Job Openings"
            submenuReqiredPermission="canRead"
          >
            <li
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 nav-item"
              role="presentation"
            >
              <button
                className="card card_animate nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"

              >
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-start text-uppercase fw-medium text-mute text-truncate mt-1 fs-14">
                      Total Job Post
                    </p>
                  </div>

                </div>
                <div className="d-flex align-items-end justify-content-between mt-2 mb-2 w-100">
                  <div className="text-start">
                    <h4 className="fs-22 fw-semibold ff-secondary mb-3 display_no">
                      <span className="counter-value" data-target="559.25">
                        <CountUp finalValue={dashboardState?.totalJobPostings} duration={1000} />
                      </span>{" "}
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-success-subtle rounded fs-3">
                      <BsPersonCheck className="light-blue-color fs-20" />
                    </span>
                  </div>
                </div>
              </button>
            </li>
          </GateKeeper>

          <GateKeeper
            requiredModule="HR Management"
            submenumodule="Applicants"
            submenuReqiredPermission="canRead"
          >
            <li
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 nav-item"
              role="presentation"
            >
              <button
                className="card nav-link card_animate"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"

              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-start text-uppercase fw-medium text-mute text-truncate mt-1 fs-14">
                      Application List
                    </p>
                  </div>
                  <div className="flex-shrink-0 text-end">
                    <h5 className="text-danger fs-14 mb-0">
                      {/* <FiArrowDownRight className="text-danger" />
                    -3.57 % */}
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-end mt-2 mb-2 justify-content-between w-100">
                  <div className="text-start">
                    <h4 className="fs-22 fw-semibold ff-secondary mb-3 display_no">
                      <span className="counter-value" data-target="36894">
                        <CountUp finalValue={dashboardState?.totalapplications} duration={1000} />
                      </span>
                    </h4>

                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-info-subtle rounded fs-3">
                      <PiStudent className="text-success fs-20" />
                    </span>
                  </div>
                </div>
              </button>
            </li>
          </GateKeeper>
        </ul>
      </div>
    </div>
  )
}

export default HRdashboard