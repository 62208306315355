import React, { useState } from "react";
import BackButton from '../../../components/backbutton/BackButton';

const BatchView = () => {
    const [activeButton, setActiveButton] = useState('toggle-button2');

    const handleToggleClick = (buttonId) => {
        setActiveButton(buttonId);
    };

    return (
        <div>
            <BackButton heading="Batches" content="Back" to="/" />
            <div className="container-fluid mt-3">
                <div className="card">
                    <div className="card-header">
                        <div className="card-body">
                            <div className="table-responsive table-scroll table-card border-0 dashboard-tables">
                                <table className="table table-centered align-middle table-nowrap equal-cell-table table-hover">
                                    <thead>
                                        <tr className="">
                                            <th
                                                scope="col"
                                                className="fs-13 lh-xs fw-600"
                                            >
                                                S.No
                                            </th>
                                            <th
                                                scope="col"
                                                className="fs-13 lh-xs  fw-600"
                                            >
                                                Student Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="fs-13 lh-xs  fw-600"
                                            >
                                                Enrollement Id
                                            </th>
                                            <th
                                                scope="col"
                                                className="fs-13 lh-xs  fw-600  "
                                            >
                                                Course
                                            </th>
                                            <th
                                                scope="col"
                                                className="fs-13 lh-xs  fw-600 text-truncate"
                                            >
                                                Training Mode
                                            </th>
                                            <th
                                                scope="col"
                                                className="fs-13 lh-xs  fw-600 text-truncate"
                                            >
                                               Admission
                                            </th>
                                            <th
                                                scope="col"
                                                className="fs-13 lh-xs fw-600  text-truncate"
                                            >
                                                Course Duration(in Session)
                                            </th>
                                            <th
                                                scope="col"
                                                className="fs-13 lh-xs  fw-600 text-truncate"
                                            >
                                                Attendance
                                            </th>
                                            <th
                                                scope="col"
                                                className="fs-13 lh-xs  fw-600 text-truncate"
                                            >
                                               Student Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="fs-13 black_300 fw-500 lh-xs bg_light">
                                                01
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light">
                                                Krishna
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light">
                                                TA67463489
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light">
                                               Python
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light ">
                                               Online
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light">
                                                12-03-2024
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light">
                                                100
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light h-10">
                                               12/100
                                            </td>
                                            <td className="fs-13 black_300  lh-xs bg_light h-10">
                                               A/In
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    );
}

export default BatchView;
