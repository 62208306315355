import React, { useEffect, useRef, useState } from 'react'
import { MdFilterList } from "react-icons/md";
import Button from '../../../components/button/Button';
import { Link } from "react-router-dom";
import AddBatch from './AddBatch.jsx';
import { AiFillEye } from "react-icons/ai";
import { HiMiniPlus } from "react-icons/hi2";
import BackButton from '../../../components/backbutton/BackButton';
import feerecordimg from "../../../../assets/images/feedetails/fee_records.png";
import BatchView from './BatchView';
import useBatchesProvider from "./batchesUtils/useBatchesProvider.jsx"
import Usedebounce from '../../../../dataLayer/hooks/useDebounce/Usedebounce.jsx';
import Pagination from '../../../../utils/Pagination.jsx';
import PaginationInfo from '../../../../utils/PaginationInfo.jsx';



const Batches = () => {

  const [showModal1, setShowModal1] = useState(false);
  const handleCloseModal1 = () => setShowModal1(false);

  const { BatchesState, BatchesState: { ActiveBatchesList, UpcomingBatchesList, CompletedBatchesList }, DispatchBatches, getPaginatedActiveBatches,
    getPaginatedUpcomingBatchs,
    getPaginatedCompletedBatches, } = useBatchesProvider();
  console.log(BatchesState, "BatchesState");

  const { debouncesetSearch, debouncesetPage } = Usedebounce(DispatchBatches);


  // handle Search
  const handleSearch = (e, batch) => {
    const contextMap = {
      ActiveBatch: "ACTIVE_BATCHES_LIST",
      UpcomingBatch: "UPCOMING_BATCHES_LIST",
      CompltedBatch: "COMPLETED_BATCHES_LIST",
    };
    const context = contextMap[batch];
    if (!context) return; // Exit if batch does not match any key in contextMap
    debouncesetSearch({ context, data: e.target.value });
  };


  // Handle PageSize
  const handlePerPage = (e, batches) => {
    const selectedValue = parseInt(e.target.value, 10);
    const batchContexts = {
      ActiveBatches: "ACTIVE_BATCHES_LIST",
      UpcomingBatches: "UPCOMING_BATCHES_LIST",
      CompletedBatches: "COMPLETED_BATCHES_LIST"
    };
    const context = batchContexts[batches];
    if (context) {
      DispatchBatches({
        type: "SET_PER_PAGE",
        payload: {
          context: context,
          data: selectedValue
        }
      });
    }
  };


  const [batchFilters, setBatchFilters] = useState({
    ActiveBatches: {
      fromDate: "",
      toDate: "",
      branch: "",
      trainerName: "",
      batchTiming: "",
    },
    UpcomingBatches: {
      fromDate: "",
      toDate: "",
      branch: "",
      trainerName: "",
      batchTiming: "",
    },
    CompletedBatches: {
      fromDate: "",
      toDate: "",
      branch: "",
      trainerName: "",
      batchTiming: "",
    }
  });



  console.log(batchFilters, "batchFilters");

  const handleFilters = (e, batchType) => {
    const { name, value } = e.target;
    setBatchFilters((prevFilters) => ({
      ...prevFilters,
      [batchType]: {
        ...prevFilters[batchType],
        [name]: value,
      },
    }));
  };

  const FiltersReset = (batches) => {
    const resetFields = {
      ActiveBatches: {
        fromDate: "",
        toDate: "",
        branch: "",
        trainerName: "",
        batchTiming: "",
      },
      UpcomingBatches: {
        fromDate: "",
        toDate: "",
        branch: "",
        trainerName: "",
        batchTiming: "",
      },
      CompletedBatches: {
        fromDate: "",
        toDate: "",
        branch: "",
        trainerName: "",
        batchTiming: "",
      },
    };

    // Update the state based on the batches parameter
    if (resetFields[batches]) {
      setBatchFilters({
        ...batchFilters,
        [batches]: { ...resetFields[batches] },
      });
      const contextString = batches.split(/(?=[A-Z])/).join('_').toUpperCase() + '_LIST';
      console.log({ ...resetFields[batches] }, "filterreset")
      DispatchBatches({
        type: "SET_FILTERS",
        payload: {
          context: contextString,
          data: { ...resetFields[batches] },
        },
      });
    }
  };

  //  handle the FilterSubmit
  const FiltersSubmit = (batches) => {
    const resetFields = {
      ActiveBatches: {
        fromDate: batchFilters?.ActiveBatches.fromDate,
        toDate: batchFilters?.ActiveBatches.toDate,
        branch: batchFilters?.ActiveBatches.branch,
        trainerName: batchFilters?.ActiveBatches.trainerName,
        batchTiming: batchFilters?.ActiveBatches.batchTiming,
      },
      UpcomingBatches: {
        fromDate: batchFilters?.UpcomingBatches.fromDate,
        toDate: batchFilters?.UpcomingBatches.toDate,
        branch: batchFilters?.UpcomingBatches.branch,
        trainerName: batchFilters?.UpcomingBatches.trainerName,
        batchTiming: batchFilters?.UpcomingBatches.batchTiming,
      },
      CompletedBatches: {
        fromDate: batchFilters?.CompletedBatches.fromDate,
        toDate: batchFilters?.CompletedBatches.toDate,
        branch: batchFilters?.CompletedBatches.branch,
        trainerName: batchFilters?.CompletedBatches.trainerName,
        batchTiming: batchFilters?.CompletedBatches.batchTiming,
      },
    };
    // Update the state based on the batches parameter
    if (resetFields[batches]) {
      setBatchFilters({
        ...batchFilters,
        [batches]: { ...resetFields[batches] },
      });
      const contextString = batches.split(/(?=[A-Z])/).join('_').toUpperCase() + '_LIST';
      console.log({ ...resetFields[batches] }, "filterreset")
      DispatchBatches({
        type: "SET_FILTERS",
        payload: {
          context: contextString,
          data: { ...resetFields[batches] },
        },
      });
    }
  };



  // handle the Pagination
  const handleActiveBatchCurrentPage = (page) => {
    debouncesetPage({ context: "ACTIVE_BATCHES_LIST", data: page });
  }

  const handleUpcomingBatchesCurrentPage = (page) => {
    debouncesetPage({ context: "UPCOMING_BATCHES_LIST", data: page });
  }

  const handleCompletedBatchesCurrentPage = (page) => {
    debouncesetPage({ context: "COMPLETED_BATCHES_LIST", data: page });
  }


  // handle the Tabs
  const [activeTabs, setActiveTable] = useState({
    ActiveBatches: true,
    UpcomingBatches: false,
    CompletedBatches: false,
  });

  const handleTabs = (batches) => {
    setActiveTable(prevState => ({
      ...prevState,  // Spread the previous state
      ActiveBatches: batches === "ActiveBatches",
      UpcomingBatches: batches === "UpcomingBatches",
      CompletedBatches: batches === "CompletedBatches",
    }));
  };


  useEffect(() => {
    const { ActiveBatches, UpcomingBatches, CompletedBatches } = activeTabs;
    if (ActiveBatches) {
      getPaginatedActiveBatches();
    } else if (UpcomingBatches) {
      getPaginatedUpcomingBatchs();
    } else if (CompletedBatches) {
      getPaginatedCompletedBatches();
    }
  }, [activeTabs]);




  return (
    <div>
      <BackButton heading="Batches" content="Back" to="/" />
      <div className="container-fluid mt-3">
        <ul className="nav gap-3 nav-tabs d-flex justify-content-center" id="pills-tab" role="tablist">
          {/* active batches */}
          <li className="nav-item w_100" role="presentation">
            <button
              className={`bg_white nav-link ${activeTabs.ActiveBatches ? "active" : ""} card card_animate w_100`}
              id="pills-activebatches-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-activebatches"
              type="button"
              role="tab"
              aria-controls="pills-activebatches"
              aria-selected={activeTabs.ActiveBatches}
              onClick={() => handleTabs("ActiveBatches")}
            >
              <div className="text-start ">
                <div className="d-flex justify-content-between align-items-center ">
                  <div>
                    <span className="fs_20 fw-500 me-2">Active Batches</span>
                    {/* <div className='mt-3 fs-14 lh-100 text_underline black_300'>
                      <p>12,000 <FaArrowRight /></p>
                    </div> */}
                  </div>
                  <div className="flex-shrink-0">
                    <div className="avatar-sm">
                      <span className="avatar-title bg-danger-subtle rounded-circle fs-1">
                        <img
                          src={feerecordimg}
                          className="img-fluid"
                          width="100px"
                          height="100px"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>
          {/* upcoming batches*/}
          <li className="nav-item w_100" role="presentation">
            <button
              className={`nav-link ${activeTabs.UpcomingBatches ? "active" : ""} card card_animate w_100`}
              id="pills-upcomingbatches-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-upcomingbatches"
              type="button"
              role="tab"
              aria-controls="pills-upcomingbatches"
              aria-selected={activeTabs.UpcomingBatches}
              onClick={() => handleTabs("UpcomingBatches")}
            >
              <div className="text-start">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span className="fs_20 fw-500 me-2">UpComing Batches</span>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="avatar-sm">
                      <span className="avatar-title bg-danger-subtle rounded-circle fs-1">
                        <img
                          src={feerecordimg}
                          className="img-fluid"
                          width="100px"
                          height="100px"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>
          {/* completed batches */}
          <li className="nav-item w_100" role="presentation">
            <button
              className={`nav-link ${activeTabs.CompletedBatches ? "active" : ""} card card_animate w_100`}
              id="pills-completedbatches-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-completedbatches"
              type="button"
              role="tab"
              aria-controls="pills-completedbatches"
              aria-selected={activeTabs.CompletedBatches}
              onClick={() => handleTabs("CompletedBatches")}
            >
              <div className="text-start">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span className="fs_20 fw-500 me-2">Completed Batches</span>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="avatar-sm">
                      <span className="avatar-title bg-danger-subtle rounded-circle fs-1">
                        <img
                          src={feerecordimg}
                          className="img-fluid"
                          width="100px"
                          height="100px"
                          alt=""
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </li>
        </ul>

        <div className="tab-content mt-3" id="pills-tabContent">

          {/* active batches */}

          {activeTabs.ActiveBatches && (
            <div
              className="tab-pane fade show active"
              id="pills-activebatches"
              role="tabpanel"
              aria-labelledby="pills-activebatches-tab"
            >
              <div className="row">

                <div className="col-xl-12">
                  <div className="card-header">
                    <div className="row justify-content-between">
                      <div className="col-sm-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search input_bg_color select"
                            placeholder="Search for..."
                            name="search"
                            required
                            onChange={(e) => handleSearch(e, "ActiveBatch")}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="buttons_alignment">
                          <div className="fs-13 me-3 mt-2">
                          </div>
                          <button
                            className="btn btn-sm btn-md btn_primary fs-13 me-2 text_white"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                          >
                            <MdFilterList className="me-1 mb-1 text_white" />
                            Filters
                          </button>

                          <button
                            type="button" onClick={(e) => setShowModal1(true)}
                            className="btn btn-sm btn-md btn_primary fs-13">
                            <HiMiniPlus className="text_white" />Create Batch
                          </button>
                        </div>
                      </div>

                    </div>
                    <div
                      className="offcanvas offcanvas-end bg_light"
                      id="offcanvasRight"
                      aria-labelledby="offcanvasRightLabel"
                    >
                      <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasRightLabel">
                          Filters
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="offcanvas-body p-2">
                        {/* Profile filter */}
                        <div>
                          <label className="form-label fs-s fw-medium text_color">Trainer Name</label>
                          <select
                            className="form-select form-control bg_input input_bg_color black_300 select"
                            id="trainerName"
                            name="trainerName"

                            value={batchFilters?.ActiveBatches?.trainerName}
                            onChange={(e) => handleFilters(e, "ActiveBatches")}
                            required

                          >
                            <option value="" disabled selected>Select the Trainers Name</option>
                            <option value="Bala" selected>Bala</option>
                          </select>
                        </div>

                        {/* Branch filter */}
                        <div className='mt-2'>
                          <label className="form-label fs-s fw-medium text_color">Branches</label>
                          <select
                            className="form-select form-control bg_input input_bg_color black_300 select"
                            id="branch"
                            name="branch"
                            value={batchFilters?.ActiveBatches?.branch}
                            onChange={(e) => handleFilters(e, "ActiveBatches")}
                            required

                          >
                            <option value="" disabled selected>Select the Trainers Name</option>
                            <option value="Ammerpet" selected>Ammerpet</option>
                          </select>
                        </div>

                        {/* batch timing */}

                        <div className="mt-2">
                          <label className="form-label fs-s fw-medium text_color">Batch Timing</label>
                          <input
                            type="time"
                            className="form-control fs-s bg-form text_color input_bg_color"
                            id="batchTiming"
                            name="batchTiming"
                            value={batchFilters?.ActiveBatches?.batchTiming}
                            onChange={(e) => handleFilters(e, "ActiveBatches")}
                            required
                          />
                        </div>

                        {/* fromDate filter */}
                        <div>
                          <label className="form-label fs-s fw-medium text_color">
                            From Date
                          </label>
                          <input
                            className="  form-control input_bg_color  date_input_color "
                            id="rdob"
                            type="date"
                            name="fromDate"
                            value={batchFilters?.ActiveBatches?.fromDate}
                            onChange={(e) => handleFilters(e, "ActiveBatches")}
                            required
                          />
                        </div>
                        {/* todate filter */}
                        <div className="mt-2">
                          <label className="form-label fs-s fw-medium text_color">
                            To Date
                          </label>
                          <input
                            className=" form-control input_bg_color  date_input_color "
                            id="rdob"
                            type="date"
                            name="toDate"
                            value={batchFilters?.ActiveBatches?.toDate}
                            onChange={(e) => handleFilters(e, "ActiveBatches")}
                            required
                          />
                        </div>

                        {/* Buttons */}
                        <div className="position-absolute bottom-0 start-0 ms-2 mb-2">
                          <button className="btn btn_primary"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"

                            onClick={() => FiltersReset("ActiveBatches")}
                          >
                            Clear
                          </button>
                        </div>
                        <div className="position-absolute bottom-0 end-0 me-2 mb-2">
                          <button className="btn btn_primary"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={() => FiltersSubmit("ActiveBatches")}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive table-card table-container table-scroll border-0">
                        <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                          <thead>
                            <tr className="">
                              <th
                                scope="col"
                                className="fs-13 lh-xs fw-600 "
                              >
                                S.No
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >
                                Branch
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >
                                Trainer Name
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >
                                Course

                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >
                                Batch Time

                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600 text-truncate "
                              >
                                Training Mode
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs fw-600  "
                              >
                                Start Date
                              </th>
                              <th
                                scope="col"

                                className="fs-13 lh-xs  fw-600  text-truncate" style={{ maxWidth: "70px" }} title='Total Duration(in Session)' >



                                Total Duration(in Session)
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600 text-truncate" style={{ maxWidth: "70px" }} title='Completed Session'>
                                Completed Session
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600 text-truncate " style={{ maxWidth: "70px" }} title='Total students
                              ' >
                                Total students
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600 "
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">

                            {
                              ActiveBatchesList.PaginatedActiveBatches &&
                                ActiveBatchesList.PaginatedActiveBatches.length > 0 ? (
                                ActiveBatchesList.loading ?
                                  <tr>
                                    <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                      loading...
                                    </td>
                                  </tr>
                                  :
                                  ActiveBatchesList.PaginatedActiveBatches.map((item, index) => {
                                    console.log(item?.name, "activebatchessti")
                                    return (
                                      <tr>
                                        <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                          {(ActiveBatchesList?.currentPage - 1) *
                                            ActiveBatchesList?.perPage +
                                            index + 1}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light">
                                          {item?.branch}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "120px" }} title={item.name}>
                                          {item?.name}

                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light">
                                          {item?.admissiondate}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "100px" }} title={item.email}>
                                          {item?.email}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.mobilenumber}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.modeoftraining}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.registrationnumber}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.id}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.id}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          <Link to="/batchmanagement/batcheview">
                                            <AiFillEye className="me-3 eye_icon" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                          </Link>
                                          <Link to="/batchmanagement/launchbutton">
                                            <button className="btn btn-sm btn-md btn_primary fs-13">
                                              Launch
                                            </button>
                                          </Link>
                                        </td>
                                      </tr>
                                    )
                                  })
                              )
                                : (
                                  <tr>
                                    <td className="fs-13 black_300  lh-xs bg_light ">
                                      no data
                                    </td>
                                  </tr>
                                )
                            }

                            <tr>
                              <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                01
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Shrii
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Online
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Ameerpet
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Python
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                10:30
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                20-05-2024
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                100
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                50
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                50
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                {/* <GateKeeper requiredModule="User Mangement" requiredPermission="all" submenumodule="User Details" submenuReqiredPermission="canRead"> */}

                                <Link to="/batchmanagement/batcheview">
                                  <AiFillEye className="me-3 eye_icon" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                </Link>
                                <Link to="/batchmanagement/launchbutton">
                                  <button className="btn btn-sm btn-md btn_primary fs-13">
                                    Launch
                                  </button>
                                </Link>
                                {/* </GateKeeper>  */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className=" mt-3 align-items-center d-flex justify-content-between row text-center text-sm-start">
                        <div className="col-sm">

                          <PaginationInfo
                            data={{
                              length: ActiveBatchesList?.PaginatedActiveBatches?.length,
                              start: ActiveBatchesList?.startBatch,
                              end: ActiveBatchesList?.endBatch,
                              total: ActiveBatchesList?.searchResultBatches,
                            }}
                            loading={ActiveBatchesList?.loading}
                          />
                        </div>
                        <div className="col-sm-auto mt-3 mt-sm-0 d-flex pagination-res">
                          <div className="mt-2">
                            <select
                              className="form-select form-control me-3 input_bg_color pagination-select "
                              aria-label="Default select example"
                              required
                              onChange={(e) => handlePerPage(e, "ActiveBatches")}
                              value={ActiveBatchesList?.perPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                            </select>
                          </div>

                          <div>
                            <Pagination
                              currentPage={ActiveBatchesList?.currentPage}
                              totalPages={ActiveBatchesList?.totalPages}
                              loading={ActiveBatchesList?.loading}
                              onPageChange={handleActiveBatchCurrentPage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}



          {/* UpComing Batches */}
          {activeTabs.UpcomingBatches && (
            <div
              className="tab-pane fade show active"
              id="pills-upcomingbatches"
              role="tabpanel"
              aria-labelledby="pills-upcomingbatches-tab"
            >
              <div className="row">
                <div className="col-xl-12">
                  <div className="card-header">
                    <div className="row justify-content-between">
                      <div className="col-sm-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search input_bg_color select"
                            placeholder="Search for..."
                            name="search"
                            required
                            onChange={(e) => handleSearch(e, "UpcomingBatch")}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="buttons_alignment">
                          <div className="fs-13 me-3 mt-2">

                          </div>

                          <button
                            className="btn btn_primary fs-13 me-2"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightToday"
                            aria-controls="offcanvasRight"
                          >
                            <MdFilterList className="me-1 mb-1" />
                            Filters
                          </button>
                          <button
                            type="button" onClick={(e) => setShowModal1(true)}
                            className="btn btn-sm btn-md btn_primary fs-13">
                            <HiMiniPlus className="text_white" />Create Batch
                          </button>
                        </div>

                      </div>
                    </div>

                    <div
                      className="offcanvas offcanvas-end  bg_light"
                      id="offcanvasRightToday"
                      aria-labelledby="offcanvasRightLabel"
                    >
                      <div className="offcanvas-header">
                        <h5
                          className="offcanvas-title text_color"
                          id="offcanvasRightLabel"
                        >
                          Filters
                        </h5>
                        <button
                          type="button"
                          className="btn-close text_color"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="offcanvas-body p-2 ">
                        {/* trainer filter */}
                        <div>
                          <label className="form-label fs-s fw-medium text_color">Trainer Name</label>
                          <select
                            className="form-select form-control bg_input input_bg_color black_300 select"
                            id="trainerName"
                            name="trainerName"
                            value={batchFilters?.UpcomingBatches?.trainerName}
                            onChange={(e) => handleFilters(e, "UpcomingBatches")}
                            required
                          >
                            <option value="" disabled selected>Select the Trainers Name</option>
                            <option value="Bala" selected>Bala</option>
                          </select>
                        </div>
                        {/* Branch filter */}
                        <div className='mt-2'>
                          <label className="form-label fs-s fw-medium text_color">Branch</label>
                          <select
                            className="form-select form-control bg_input input_bg_color black_300 select"
                            id="branch"
                            name="branch"
                            value={batchFilters?.UpcomingBatches?.branch}
                            onChange={(e) => handleFilters(e, "UpcomingBatches")}
                            required

                          >
                            <option value="" disabled selected>Select the Trainers Name</option>
                            <option value="Hi Tech" selected>Hi Tech</option>
                          </select>
                        </div>

                        {/* batch timing */}
                        <div className="mt-2">
                          <label className="form-label fs-s fw-medium text_color">Batch Timing</label>
                          <input
                            type="time"
                            className="form-control fs-s bg-form text_color input_bg_color"
                            id="batchTiming"
                            name="batchTiming"
                            value={batchFilters?.UpcomingBatches?.batchTiming}
                            onChange={(e) => handleFilters(e, "UpcomingBatches")}
                            required
                          />
                        </div>
                        {/* fromDate filter */}
                        <div>
                          <label className="form-label fs-s fw-medium text_color">
                            From Date
                          </label>
                          <input
                            className="  form-control input_bg_color  date_input_color "
                            id="rdob"
                            type="date"
                            name="fromDate"
                            value={batchFilters?.UpcomingBatches?.fromDate}
                            onChange={(e) => handleFilters(e, "UpcomingBatches")}
                            required
                          />
                        </div>
                        {/* todate filter */}
                        <div className="mt-2">
                          <label className="form-label fs-s fw-medium text_color">
                            To Date
                          </label>
                          <input
                            className=" form-control input_bg_color  date_input_color "
                            id="rdob"
                            type="date"
                            name="toDate"
                            value={batchFilters?.UpcomingBatches?.toDate}
                            onChange={(e) => handleFilters(e, "UpcomingBatches")}
                            required
                          />
                        </div>

                        <div>
                          <div className="position-absolute bottom-0 start-0 ms-2 mb-2">
                            <button
                              className="btn btn_primary"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={() => FiltersReset("UpcomingBatches")}
                            >
                              Clear
                            </button>
                          </div>
                          <div className="position-absolute bottom-0 end-0 me-2 mb-2">

                            <button
                              className="btn btn_primary"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={() => FiltersSubmit("UpcomingBatches")}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive table-card table-container table-scroll border-0">
                        <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                          <thead>
                            <tr className="">
                              <th
                                scope="col"
                                className="fs-13 lh-xs fw-600 "
                              >
                                S.No
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >

                                Branch
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >

                                Trainer Name
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >
                                Course

                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >
                                Batch Time

                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600 text-truncate "
                              >
                                Training Mode
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs fw-600  "
                              >
                                Start Date
                              </th>
                              <th
                                scope="col"

                                className="fs-13 lh-xs  fw-600  text-truncate" style={{ maxWidth: "100px" }} >



                                Total Duration(in Session)
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  text-truncate" style={{ maxWidth: "100px" }}>
                                Completed Session
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  text-truncate " style={{ maxWidth: "100px" }}  >
                                Total students
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600 "
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">

                            {
                              UpcomingBatchesList?.PaginatedUpcomingBatches &&
                                UpcomingBatchesList?.PaginatedUpcomingBatches.length > 0 ? (
                                UpcomingBatchesList?.loading ?
                                  <tr>
                                    <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                      loading...
                                    </td>
                                  </tr>
                                  :
                                  UpcomingBatchesList.PaginatedUpcomingBatches.map((item, index) => {

                                    console.log(item, "PaginatedUpcomingBacfdgdgtches")
                                    return (
                                      <tr>
                                        <td className="fs-13 black_300 fw-500 lh-xs bg_light ">

                                          {(UpcomingBatchesList?.currentPage - 1) *
                                            UpcomingBatchesList.perPage +
                                            index +
                                            1}

                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light">
                                          {item?.location}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "120px" }} title={item.name}>
                                          {item?.title}

                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light">
                                          {item?.closing_date}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "100px" }} title={item.email}>
                                          {item?.company_name}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.ctc_range}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.experience}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.status}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.id}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.id}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          <Link to="/batchmanagement/batcheview">
                                            <AiFillEye className="me-3 eye_icon" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                          </Link>
                                          <Link to="/batchmanagement/launchbutton">
                                            <button className="btn btn-sm btn-md btn_primary fs-13">
                                              Launch
                                            </button>
                                          </Link>
                                        </td>
                                      </tr>
                                    )
                                  })
                              )
                                : (
                                  <tr>
                                    <td className="fs-13 black_300  lh-xs bg_light ">
                                      no data
                                    </td>
                                  </tr>
                                )
                            }



                            <tr>
                              <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                01
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Shrii
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Online
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Ameerpet
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Python
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                10:30
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                20-05-2024
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                100
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                50
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                50
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                {/* <GateKeeper requiredModule="User Mangement" requiredPermission="all" submenumodule="User Details" submenuReqiredPermission="canRead"> */}

                                <Link to="/batchmanagement/batcheview">
                                  <AiFillEye className="me-3 eye_icon" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                </Link>
                                <Link to="/batchmanagement/launchbutton">
                                  Launch
                                </Link>
                                {/* </GateKeeper>  */}
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                      <div className=" mt-3 align-items-center d-flex justify-content-between row text-center text-sm-start">
                        <div className="col-sm">


                          <PaginationInfo
                            data={{
                              length: UpcomingBatchesList?.PaginatedUpcomingBatches?.length,
                              start: UpcomingBatchesList?.startBatch,
                              end: UpcomingBatchesList?.endBatch,
                              total: UpcomingBatchesList?.searchResultBatches,
                            }}
                            loading={UpcomingBatchesList?.loading}
                          />
                        </div>
                        <div className="col-sm-auto mt-3 mt-sm-0 d-flex pagination-res">
                          <div className="mt-2">
                            <select
                              className="form-select form-control me-3 input_bg_color pagination-select "
                              aria-label="Default select example"
                              required
                              onChange={(e) => handlePerPage(e, "UpcomingBatches")}
                              value={UpcomingBatchesList?.perPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                          <div>
                            <Pagination
                              currentPage={UpcomingBatchesList?.currentPage}
                              totalPages={UpcomingBatchesList?.totalPages}
                              loading={UpcomingBatchesList?.loading}
                              onPageChange={handleUpcomingBatchesCurrentPage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}


          {/* Completed Batches */}
          {activeTabs.CompletedBatches && (
            <div
              className="tab-pane fade show active"
              id="pills-completedbatches"
              role="tabpanel"
              aria-labelledby="pills-completedbatches-tab"

              tabindex="0">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card-header">
                    <div className="row justify-content-between">
                      <div className="col-sm-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search input_bg_color select"
                            placeholder="Search for..."
                            name="search"
                            required
                            onChange={(e) => handleSearch(e, "CompltedBatch")}
                          />

                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="buttons_alignment">
                          <div className="fs-13 me-3 mt-2">

                          </div>
                          <button
                            className="btn btn_primary fs-13 me-2"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightOverdue"
                            aria-controls="offcanvasRight"
                          >
                            <MdFilterList className="me-1 mb-1" />
                            Filters
                          </button>
                          <button
                            type="button" onClick={(e) => setShowModal1(true)}
                            className="btn btn-sm btn-md btn_primary fs-13">
                            <HiMiniPlus className="text_white" />Create Batch
                          </button>
                        </div>

                      </div>


                    </div>
                    <div
                      className="offcanvas offcanvas-end bg_light"
                      id="offcanvasRightOverdue"
                      aria-labelledby="offcanvasRightLabel"
                    >
                      <div className="offcanvas-header">
                        <h5
                          className="offcanvas-title text_color"
                          id="offcanvasRightLabel"
                        >
                          Filters
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="offcanvas-body p-2">

                        {/* triner filter */}
                        <div>
                          <label className="form-label fs-s fw-medium text_color">Trainer Name</label>
                          <select
                            className="form-select form-control bg_input input_bg_color black_300 select"
                            id="trainerName"
                            name="trainerName"
                            // value={CompletedBatchesfilters?.trainerName}
                            onChange={(e) => handleFilters(e, "CompletedBatches")}
                            required

                          >
                            <option value="" disabled selected>Select the Trainers Name</option>
                            <option value="krishna" selected>krishna</option>
                          </select>
                        </div>

                        {/* branch  filter*/}
                        <div className="mt-2">
                          <label className="form-label fs-s fw-medium txt-color">
                            Branch
                          </label>
                          <select
                            className="form-select input_bg_color text_color form-control select "
                            aria-label="Default select example"
                            placeholder="Branch*"
                            id="branch"
                            name="branch"
                            value={batchFilters?.CompletedBatches?.branch}
                            onChange={(e) => handleFilters(e, "CompletedBatches")}
                            required
                          >

                            <option value="" disabled selected> {" "} Select the Branch{" "}</option>
                            <option value=" Hi Tech" >  Hi Tech</option>

                          </select>
                        </div>
                        {/* batch timing */}
                        <div className="mt-2">
                          <label className="form-label fs-s fw-medium text_color">Batch Timing</label>
                          <input
                            type="time"
                            className="form-control fs-s bg-form text_color input_bg_color"
                            id="batchTiming"
                            name="batchTiming"
                            value={batchFilters?.CompletedBatches?.batchTiming}
                            onChange={(e) => handleFilters(e, "CompletedBatches")}
                            required

                          />
                        </div>
                        {/* fromdate filter */}
                        <div>
                          <label className="form-label fs-s fw-medium text_color">
                            From Date
                          </label>
                          <input
                            className="  form-control input_bg_color  date_input_color "
                            id="rdob"
                            type="date"
                            name="fromDate"
                            value={batchFilters?.CompletedBatches?.fromDate}
                            onChange={(e) => handleFilters(e, "CompletedBatches")}
                            required
                          />
                        </div>
                        {/* todate filter */}
                        <div className="mt-2">
                          <label className="form-label fs-s fw-medium text_color">
                            To Date
                          </label>
                          <input
                            className=" form-control input_bg_color  date_input_color "
                            id="rdob"
                            type="date"
                            name="toDate"
                            value={batchFilters?.CompletedBatches?.toDate}
                            onChange={(e) => handleFilters(e, "UpcomingBatches")}
                            required
                          />
                        </div>


                        <div>
                          <div className="position-absolute bottom-0 start-0 ms-2 mb-2">
                            <button
                              className="btn btn_primary"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={() => FiltersReset("CompletedBatches")}
                            >
                              Clear
                            </button>
                          </div>
                          <div className="position-absolute bottom-0 end-0 me-2 mb-2">

                            <button
                              className="btn btn_primary"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={() => FiltersSubmit("CompletedBatches")}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive table-card table-container table-scroll border-0">
                        <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                          <thead>
                            <tr className="">
                              <th scope="col" className="fs-13 lh-xs fw-600 ">S.No</th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >

                                Branch
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >

                                Trainer Name
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >
                                Course

                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  "
                              >
                                Batch Time

                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600 text-truncate "
                              >
                                Training Mode
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs fw-600  "
                              >
                                Start Date
                              </th>
                              <th
                                scope="col"

                                className="fs-13 lh-xs  fw-600  text-truncate" style={{ maxWidth: "100px" }} title='Total Duration(in session)'>

                                Total Duration(in Session)
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  text-truncate" style={{ maxWidth: "100px" }}>
                                Completed Session
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600  text-truncate " style={{ maxWidth: "100px" }}  >
                                Total students
                              </th>
                              <th
                                scope="col"
                                className="fs-13 lh-xs  fw-600 "
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">

                            {
                              CompletedBatchesList.PaginatedCompletedBatches &&
                                CompletedBatchesList.PaginatedCompletedBatches.length > 0 ? (
                                CompletedBatchesList.loading ?
                                  <tr>
                                    <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                      loading...
                                    </td>
                                  </tr>
                                  :
                                  CompletedBatchesList.PaginatedCompletedBatches.map((item, index) => {

                                    return (
                                      <tr>
                                        <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                          {(CompletedBatchesList?.currentPage - 1) *
                                            CompletedBatchesList.perPage +
                                            index +
                                            1}


                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light">
                                          {item?.status}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "120px" }} title={item.name}>
                                          {item?.applicant_name}

                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light">
                                          {item?.applicant_email}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "100px" }} title={item.email}>
                                          {item?.applied_date}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.applicant_phone}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.job_posting_id}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item?.applicant_teksenrollmentid}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item.id}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          {item.id}
                                        </td>
                                        <td className="fs-13 black_300  lh-xs bg_light ">
                                          <Link to="/batchmanagement/batcheview">
                                            <AiFillEye className="me-3 eye_icon" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                          </Link>
                                          <Link to="/batchmanagement/launchbutton">
                                            <button className="btn btn-sm btn-md btn_primary fs-13">
                                              Launch
                                            </button>
                                          </Link>
                                        </td>
                                      </tr>
                                    )
                                  })
                              )
                                : (
                                  <tr>
                                    <td className="fs-13 black_300  lh-xs bg_light ">
                                      no data
                                    </td>
                                  </tr>
                                )
                            }



                            <tr>
                              <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                01
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Shrii
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Online
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Ameerpet
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light">
                                Python
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                10:30
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                20-05-2024
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                100
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                50
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                50
                              </td>
                              <td className="fs-13 black_300  lh-xs bg_light ">
                                {/* <GateKeeper requiredModule="User Mangement" requiredPermission="all" submenumodule="User Details" submenuReqiredPermission="canRead"> */}

                                <Link to="/batchmanagement/batcheview">
                                  <AiFillEye className="me-3 eye_icon" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                </Link>
                                <Link to="/batchmanagement/launchbutton">
                                  Launch
                                </Link>
                                {/* </GateKeeper>  */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className=" mt-3 align-items-center d-flex justify-content-between row text-center text-sm-start">
                        <div className="col-sm">

                          <PaginationInfo
                            data={{
                              length: CompletedBatchesList?.PaginatedCompletedBatches?.length,
                              start: CompletedBatchesList?.startBatch,
                              end: CompletedBatchesList?.endBatch,
                              total: CompletedBatchesList?.searchResultBatches,
                            }}
                            loading={CompletedBatchesList?.loading}
                          />

                        </div>
                        <div className="col-sm-auto mt-3 mt-sm-0 d-flex pagination-res">
                          <div className="mt-2">
                            <select
                              className="form-select form-control me-3 input_bg_color pagination-select "
                              aria-label="Default select example"
                              required
                              onChange={(e) => handlePerPage(e, "CompletedBatches")}
                              value={CompletedBatchesList?.perPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                          <div>
                            <Pagination
                              currentPage={CompletedBatchesList?.currentPage}
                              totalPages={CompletedBatchesList?.totalPages}
                              loading={CompletedBatchesList?.loading}
                              onPageChange={handleCompletedBatchesCurrentPage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {
        showModal1 === true && (
          <AddBatch show={showModal1} handleClose={handleCloseModal1} />
        )
      }
    </div >
  )
};

export default Batches;