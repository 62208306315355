

import axios from "axios";

// download syllabus
// export const getDownloadSyllabusLeads=async(filters,search,perPage,currentPage)=>{
//     const mergObj ={...search, ...perPage, ...currentPage, ...filters}
//     console.log(mergObj, "djjfgjff")
//     const url =`${process.env.REACT_APP_API_URL}/websiteleads/dsformleads`;
//     return axios.post(url,mergObj)
// } 

// webinar 
// export const getWebinarLeads=async(filterobj, searchobj, perPageObj, currentPageObj)=>{
//     const mergObj={...filterobj, ...searchobj, ...perPageObj, ...currentPageObj}
//     const url=`${process.env.REACT_APP_API_URL}`;
//     return axios.post(url,mergObj)
// }

// Whatsapp 
// export const getwhatsAppLeads=async(filterobj, searchobj, perPageObj, currentPageObj)=>{
//     const mergObj={...filterobj, ...searchobj, ...perPageObj, ...currentPageObj}
//     const url=`${process.env.REACT_APP_API_URL}`;
//     return axios.post(url,mergObj)
// }
//SLPEnquireLeads
// export const getSLPEnquireLeads=async(filters,search,perPage,currentPage)=>{
//     const mergObj ={...search, ...perPage, ...currentPage, ...filters}
//     console.log(mergObj, "djjfgjff")
//     const url =`${process.env.REACT_APP_API_URL}/websiteleads/slpefleads`;
//     return axios.post(url,mergObj)
// } 


// download syllabus
export const getDownloadSyllabusLeads = async (
  filters,
  search,
  perPage,
  currentPage
) => {
  const mergObj = { ...search, ...perPage, ...currentPage, ...filters };
  console.log(mergObj, "djjfgjff");
  const url = `${process.env.REACT_APP_API_URL}/websiteleads/dsformleads`;
  return axios.post(url, mergObj);
};

// webinar
export const getWebinarLeads = async (
  filters,
  search,
  perPage,
  currentPage
) => {
  const mergObj = {
    ...search, ...perPage, ...currentPage, ...filters
  };
  const url = `${process.env.REACT_APP_API_URL}/websiteleads/webinardec`;
  return axios.post(url, mergObj);
};
//viewciurse
export const getViewCourseLeads = async (
  filters,
  search,
  perPage,
  currentPage
) => {
  const mergObj = {
    ...search, ...perPage, ...currentPage, ...filters
  };
  const url = `${process.env.REACT_APP_API_URL}/websiteleads/viewcoursesideleads `;
  return axios.post(url, mergObj);
};
//contactus
export const getContactUsLeads = async (
  filters,
  search,
  perPage,
  currentPage
) => {
  const mergObj = {
    ...search, ...perPage, ...currentPage, ...filters
  };
  const url = `${process.env.REACT_APP_API_URL}/websiteleads/contactusleads`;
  return axios.post(url, mergObj);
};

// Whatsapp
export const getwhatsAppLeads = async (
  filters,
  search,
  perPage,
  currentPage
) => {
  const mergObj = {
    ...search, ...perPage, ...currentPage, ...filters
  };
  const url = `${process.env.REACT_APP_API_URL}/websiteleads/whatsappleads`;
  return axios.post(url, mergObj);
};
// HLP
export const getHLPEnquireLeads = async (
  filters,
  search,
  perPage,
  currentPage
) => {
  const mergObj = {
    ...search, ...perPage, ...currentPage, ...filters
  };
  const url = `${process.env.REACT_APP_API_URL}/websiteleads/efleads`;
  return axios.post(url, mergObj);
};
// slp
export const getSLPEnquireLeads = async (
  filters,
  search,
  perPage,
  currentPage
) => {
  const mergObj = {
    ...search, ...perPage, ...currentPage, ...filters
  };
  const url = `${process.env.REACT_APP_API_URL}/websiteleads/slpefleads`;
  return axios.post(url, mergObj);
};













